import React, { useEffect, useState } from "react";
import { getAuthorizeCategory } from "../../redux/actions/about_us";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";

import ReactHtmlParser from "react-html-parser";
const Autorizatii = ({
  getAuthorizeCategory,
  authorizeListCategory,
  loading,
}) => {
  const [modal, setModal] = useState({
    show: false,
    url: "",
  });
  useEffect(() => {
    getAuthorizeCategory();
  }, []);
  const toggle = (url) =>
    setModal({
      show: !modal.show,
      url,
    });
  return (
    <>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              LPV
            </Link>
            <span /> Autorizatii
          </div>
        </div>
      </div>

      <section className="mt-50 mb-50">
        <div className="container">
          <div className="row">
            <div clasName="col-lg-12">
              <div>
                <h2 style={{ fontWeight: "600", textAlign: "center" }}>
                  Autorizatii pentru prestarea serviciilor
                </h2>
                <p style={{ textAlign: "center", color: "#0275d8" }}>
                  Societatea LPV SERVICE CONSULT SRL este autorizata AGFR, ANRE,
                  ISCIR si ISO pentru prestarea de servicii tehnice cu aplicatie
                  rezidentiala si industriala.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {authorizeListCategory?.map((category) => (
              <div
                className={
                  category.authorization_subcategory.length <= 1
                    ? "col-lg-6"
                    : "col-lg-12"
                }
                style={{ marginTop: "50px" }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img style={{ height: "40px" }} src={category?.image}></img>
                  <p style={{ margin: 0, fontWeight: "600" }}>
                    {category?.text}
                  </p>
                  <i
                    style={{ color: "green", fontSize: "1rem" }}
                    className={ReactHtmlParser(category.icon)}
                  ></i>
                </div>
                <hr />
                <div className="row">
                  {category?.authorization_subcategory?.map((subCategory) => (
                    <div
                      className={
                        category.authorization_subcategory.length <= 1
                          ? "col-lg-12"
                          : "col-lg-4"
                      }
                    >
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0 15px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          onClick={() => toggle(subCategory.file)}
                          // href={subCategory.file}
                          // download
                          // target="_blank"
                          // rel="noreferrer"
                        >
                          <img
                            style={{ objectFit: "contain" }}
                            src={subCategory?.image}
                            alt="ima"
                          />
                        </div>
                        <div>
                          <a
                            href={subCategory.file}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                              marginTop: "15px",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {subCategory?.text}
                            <i
                              className={ReactHtmlParser(subCategory.icon)}
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="row">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "80px",
                }}
              >
                <h5>OFERTANT IN SEAP</h5>
                <h6 style={{ textAlign: "center" }}>
                  Societatea noastra este inregistrata in Sistemul Electronic al
                  Achizitiilor Publice (SEAP) conform legii 98/2006. Catalogul
                  produselor ofertate poate fi consultat pe website-ul:
                  www.e-licitatie.ro. Pentru mai multe informatii va rugam sa ne
                  contactati la telefon 0724288126.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal?.show}
          toggle={() => toggle()}
          style={{ maxWidth: "1200px", width: "100%" }}
        >
          <ModalBody>
            <iframe
              title="pdf"
              src={modal?.url}
              width="1160rem"
              height="700rem"
            />
          </ModalBody>
        </Modal>
      </section>
    </>
  );
};

const mapStateToProps = ({ aboutUs }) => ({
  authorizeListCategory: aboutUs.authorizeCategoryList || [],
  loading: aboutUs?.loading,
});

export default connect(mapStateToProps, {
  getAuthorizeCategory,
})(Autorizatii);
