import React from "react";

const FilterAttributes = ({
  productAttributeContentList,
  setFilterState,
  filterState,
  categoryId,
}) => {
  const [sortedAttributes, setSortedAttributes] = React.useState([]);
  const handleAttributeCheck = (e, content, id) => {
    let newAttribute = [...filterState?.product_attribute_contents];
    if (e.target.checked) {
      newAttribute.push(id);
    } else {
      newAttribute = newAttribute.filter((item) => item !== id);
    }
    newAttribute = new Set(newAttribute);
    newAttribute = [...newAttribute];

    setFilterState({
      ...filterState,
      product_attribute_contents: newAttribute,
    });
  };

  productAttributeContentList.map((item) => {
    if (item) {
      item.contents.sort((a, b) =>
        a.content.localeCompare(b.content, undefined, { numeric: true })
      );
    }
  });

  return (
    <div className="sidebar-widget price_range range mb-30">
      <div className="widget-header position-relative mb-20 pb-10">
        <h5 className="widget-title mb-10">Filtru specificatii</h5>
      </div>
      <div className="list-group custom-list-group">
        <div className="list-group-item mb-10 mt-10">
          {productAttributeContentList?.map(
            (productAttributeList, productIndex) => {
              if (
                productAttributeList?.subsubcategories.some(
                  (subsub) => subsub.subsubcategory_id == categoryId
                )
              ) {
                return (
                  <div>
                    <label className="fw-900">
                      {productAttributeList?.title}
                    </label>
                    <div>
                      {productAttributeList?.contents?.map(
                        (productAttribute, index) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              flexDirection: "column",
                            }}
                            className="mb-3"
                          >
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                                lineHeight: "normal",
                                flexDirection: "row",
                              }}
                            >
                              <div>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={filterState?.product_attribute_contents?.includes(
                                    productAttribute?.id
                                  )}
                                  onClick={(e) =>
                                    handleAttributeCheck(
                                      e,
                                      productAttribute?.content,
                                      productAttribute?.id
                                    )
                                  }
                                />
                              </div>

                              <label
                                className="form-check-label"
                                htmlFor="exampleCheckbox1"
                              >
                                <span>{productAttribute?.content}</span>
                                {/* <span> (56)</span> */}
                              </label>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                );
              }
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterAttributes;
