import {
  GET_CATEGORIES,
  GET_PRODUCT_LIST,
  LOADING_PRODUCT,
  GET_PRODUCT_BESTSELLER_LIST,
  GET_PRODUCT_DETAILS,
  GET_PRODUCTS_SEARCH_LIST,
  GET_PRODUCT_TYPE_LIST,
  GET_PRODUCT_ATTRIBUTES_CONTENT_LIST,
  GET_PRODUCT_BRAND_LIST,
  GET_PRODUCT_ATTRIBUTE_SPECIFICATION,
  GET_PRODUCT_REVIEW_LIST,
  GET_QUESTION_BUNDLE_READ,
  GET_QUESTION_DETAIL,
  GET_SERVICE_LIST,
  GET_PRODUCT_ULTIMELE_LIST,
  PRODUCT_COMPARE,
} from "../types/types";

const initialState = {
  products: [],
  searchProducts: [],
  loading: false,
  categories: {},
  bestSellerProducts: [],
  productDetail: {},
  productTypeList: [],
  productAttributeContentList: [],
  productBrandList: [],
  pagination: {},
  attributesSpecification: [],
  reviewList: [],
  questionBundleDetail: {},
  questionDetail: {},
  serviceList: [],
  ultimateProduct: [],
  maximum_product_price:0,
  productCompare:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PRODUCT:
      return { ...state, loading: action.payload || false };
    case GET_PRODUCT_ULTIMELE_LIST:
      return {
        ...state,
        ultimateProduct: action.payload,
      };
    case GET_PRODUCT_LIST:
      return {
        ...state,
        products: action.payload.data,
        pagination: action.payload.pagination,
        maximum_product_price: action.payload.maximum_product_price,
      };
    case GET_PRODUCTS_SEARCH_LIST:
      return { ...state, searchProducts: action.payload };
    case GET_PRODUCT_BESTSELLER_LIST:
      return { ...state, bestSellerProducts: action.payload };
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case GET_PRODUCT_DETAILS:
      return { ...state, productDetail: action.payload };
    case GET_PRODUCT_TYPE_LIST:
      return { ...state, productTypeList: action.payload };
    case GET_PRODUCT_ATTRIBUTES_CONTENT_LIST:
      return { ...state, productAttributeContentList: action.payload };
    case GET_PRODUCT_BRAND_LIST:
      return { ...state, productBrandList: action.payload };
    case GET_PRODUCT_ATTRIBUTE_SPECIFICATION:
      return { ...state, attributesSpecification: action.payload };
    case GET_PRODUCT_REVIEW_LIST:
      return { ...state, reviewList: action.payload };
    case GET_QUESTION_BUNDLE_READ:
      return { ...state, questionBundleDetail: action.payload };
    case GET_QUESTION_DETAIL:
      return { ...state, questionDetail: action.payload };
    case GET_SERVICE_LIST:
      return { ...state, serviceList: action.payload };
    case PRODUCT_COMPARE:
      return { ...state, productCompare:action.payload};
    default:
      return state;
  }
}
