export const generateCalculatorKw = (kw) => {
  let result = null;
  switch (true) {
    case kw <= 24:
      result = [11467, 10229]; // 20, 24 kW
      break;
    case kw > 24 && kw <= 25:
      result = 10861; //  25 kW
      break;
    case kw > 27 && kw <= 30:
      result = [10238, 10207 ,10252]; //  28 kW, 29 kW, 30 kW
      break;
    case kw > 30 && kw <= 31:
      result = 10216; //  31 kW
      break;
    case kw > 31 && kw <= 32:
      result = 10235; //  32 kW
      break;
    case kw > 32 && kw <= 35:
      result = [10226, 10219, 10251]; //  33 kW, 34 kW, 35kW
      break;
    case kw > 41 && kw <= 42:
      result = 10246; // 42 kW
      break;
    case kw > 69 && kw <= 70:
      result = 10245; // 70 kW
      break;
    case kw > 84 && kw <= 85:
      result = 10244; // 85 kW
      break;
    case kw > 99 && kw <= 100:
      result = 10243; // 100 kW
      break;
    default:
      result = null;
  }
  return result;
};

export const generateCalculatorLitri = (litri) => {
  let result = null;
  switch (true) {
    case litri >= 30 && litri <= 150:
      result = [9152, 9445]; //  120 litri
      break;
    // case litri > 121 && litri <= 150:
    //   result = 9445; //  150 litri
    //   break;
    case litri > 151 && litri <= 180:
      result = 9164; //  180 litri
      break;
    case litri > 181 && litri <= 250:
      result = 9236; //  250 litri
      break;
    case litri > 251 && litri <= 350:
      result = 9255; //  350 litri
      break;
    case litri > 351 && litri <= 450:
      result = 9315; //  450 litri
      break;

    case litri > 451 && litri <= 550:
      result = 9326; //  550 litri
      break;
    case litri > 551 && litri <= 750:
      result = 9522; //  750 litri
      break;
    case litri > 751 && litri <= 1000:
      result = 9260; //  1000 litri
      break;
    case litri > 1001 && litri <= 1500:
      result = 9456; //  1500 litri
      break;
    default:
      result = null;
  }
  return result;
};
