import React from "react";
import { Link } from "react-router-dom";

const ProductRecom = ({}) => {
  return (
    <>
      <div className="site__body">
        <div className="page-header">
          <div className="page-header__container container">
            <div className="page-header__breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Calculator Recomandare</Link>
                    <svg className="breadcrumb-arrow" width="6px" height="9px">
                      <use xlinkHref="images/sprite.svg#arrow-rounded-right-6x9" />
                    </svg>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="page-header__title">
              <h1>Recomandare produse</h1>
            </div>
          </div>
        </div>
        <div className="block">
          <div className="container">
            <div className="row custom-calculator-row">
              <div className="col-lg-3 col-md-6">
                <div className="calculator-wrapper">
                  <div className="calculator-header">
                    <div className="calculator-circle-icon">
                      <i className="fas fa-cog"></i>
                    </div>
                  </div>
                  <div className="calculator-body">
                    <p>Centrala termica cu regin ACM instant</p>
                    <Link to="/recomandare-ct-acm-instant" role="button">
                      <span>Incepe</span>
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="calculator-wrapper">
                  <div className="calculator-header">
                    <div className="calculator-circle-icon">
                      <i className="fas fa-cog"></i>
                    </div>
                  </div>
                  <div className="calculator-body">
                    <p>Centrala termica cu regin ACM instant</p>
                    <Link to="/recomandare-ct-acm-boiler-extern" role="button">
                      <span>Incepe</span>
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="calculator-wrapper">
                  <div className="calculator-header">
                    <div className="calculator-circle-icon">
                      <i className="fas fa-cog"></i>
                    </div>
                  </div>
                  <div className="calculator-body">
                    <p>Sistem recomandare aer conditionat</p>
                    <Link to="/recomandare-ct-acm-boiler-extern" role="button">
                      <span>Incepe</span>
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="calculator-wrapper">
                  <div className="calculator-header">
                    <div className="calculator-circle-icon">
                      <i className="fas fa-cog"></i>
                    </div>
                  </div>
                  <div className="calculator-body">
                    <p>Sistem recomandare dedurizare</p>
                    <Link to="/recomandare-ct-acm-instant" role="button">
                      <span>Incepe</span>
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRecom;
