import React, { useEffect } from "react";

const FilterCategories = ({
  productCategoryContentList,
  setFilterState,
  filterState,
  categoryId,
}) => {
  useEffect(() => {
    let newCategory = [];

    Object.keys(productCategoryContentList).forEach((key) => {
      newCategory.push(productCategoryContentList[key].category_id);
    });

    newCategory = new Set(newCategory);
    newCategory = [...newCategory];

    setFilterState({
      ...filterState,
      subcategory_id_ls: newCategory,
    });
  }, []);

  const handleCategoryCheck = (e, content, id) => {
    let newCategory = [...filterState?.subcategory_id_ls];

    if (e.target.checked) {
      newCategory.push(id);
    } else {
      newCategory = newCategory.filter((item) => item !== id);
    }
    newCategory = new Set(newCategory);
    newCategory = [...newCategory];

    setFilterState({
      ...filterState,
      subcategory_id_ls: newCategory,
    });
  };

  return (
    <>
      {Object.keys(productCategoryContentList).map((key) => {
        if (productCategoryContentList[key].category_id === categoryId) {
          return (
            <div className="sidebar-widget price_range range mb-30">
              <div className="widget-header position-relative mb-20 pb-10">
                <h5 className="widget-title mb-10">Categoria de filtrare</h5>
              </div>
              <div className="list-group custom-list-group">
                <div className="list-group-item mb-10 mt-10">
                  <div>
                    <label className="fw-900">{key}</label>
                    <div>
                      {Object.keys(
                        productCategoryContentList[key].subcategories
                      ).map((subCategory) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "column",
                          }}
                        >
                          {" "}
                          <div
                            style={{
                              marginBottom: "5px",
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={filterState?.subcategory_id_ls?.includes(
                                productCategoryContentList[key].subcategories[
                                  subCategory
                                ].subcategory_id
                              )}
                              onClick={(e) =>
                                handleCategoryCheck(
                                  e,
                                  subCategory,
                                  productCategoryContentList[key].subcategories[
                                    subCategory
                                  ].subcategory_id
                                )
                              }
                            />
                            <label
                              className="form-check-label mb-0"
                              htmlFor="exampleCheckbox1"
                            >
                              <span>{subCategory}</span>
                              {/* <span> (56)</span> */}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default FilterCategories;
