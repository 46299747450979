import React from "react";

const OrderItem = ({ carts }) => {
  return carts?.map((cart) => (
    <tr>
      <td>{cart?.product?.title}</td>
      <td>{cart?.product?.price} Lei</td>
    </tr>
  ));
};

export default OrderItem;
