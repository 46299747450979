import React from "react";
import { productReviewList } from "../../../redux/actions/products";

const ShopTopBar = ({
  setFilterState,
  filterState,
  products,
  total_products
}) => {
  const handleSelect = (e) => {
    const sortValue = e.target.value.split("-");
 
    let order_by = "";
    let order_type = "";
    const sorted = e.target.value;
    if (sortValue.length > 1) {
      order_by = sortValue[0];
      order_type = sortValue[1];
    }
    setFilterState({
      ...filterState,
      order_by,
      order_type,
      sorted
    });
  };

  const changeItemsPerPage = (e) => {
    const itemPePage = e.target.value;
    setFilterState({
      ...filterState,
      items_per_page: itemPePage
    });
  };

  return (
    <div className="shop-product-fillter">
      <div className="totall-product">
        <p>
          {" "}
          Avem <strong className="text-brand">{total_products}</strong> produse
          pentru tine!
        </p>
      </div>
      <div className="sort-by-product-area">
        <div className="sort-by-cover mr-10">
          <div className="sort-by-product-wrap">
            <div className="shop-top-bar-filer-container">
              <label style={{ margin: "0" }}>Arata: </label>
              <select
                className="something-for-test"
                name
                id
                onChange={changeItemsPerPage}
                value={filterState?.items_per_page || 9}
              >
                <option value={9}>9</option>
                <option value={18}>18</option>
                <option value={27}>27</option>
              </select>
            </div>
          </div>
        </div>
        <div className="sort-by-cover">
          <div className="sort-by-product-wrap">
            <div className="shop-top-bar-filer-container">
              <label style={{ margin: "0" }}>Sorteaza: </label>
              <select
                name
                id
                onChange={handleSelect}
                value={filterState?.sorted || ""}
              >
                <option value="">Standard</option>
                <option value="name-asc">Nume (A-Z)</option>
                <option value="price-asc">Pret Crescator</option>
                <option value="price-dsc">Pret Descrescator</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopTopBar;
