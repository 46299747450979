import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  signupUser,
  loginUser,
  forgotPassword,
  GoogleLoginUser,
} from "../../redux/actions/auth";
import {
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { GOOGLE_CLIENT_ID } from "../../requests";
// import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Link } from "react-router-dom";

const Login = ({
  handleSubmit,
  reset,
  dispatch,
  loginUser,
  forgotPassword,
  GoogleLoginUser,
}) => {
  const handleLogin = (data) => {
    loginUser({ email: data.login_email, password: data.login_password });
    dispatch(reset("login_signup_form"));
  };
  const [passwordEye, setPasswordEye] = useState(true);

  const [modal, setModal] = useState(false);
  const [resetEmail, setResetEmail] = useState(false);

  const toggle = () => setModal(!modal);
  const handleResetPassword = () => {
    forgotPassword({ email: resetEmail });
    setTimeout(() => {
      toggle();
    }, 100);
  };

  const responseFacebook = (response) => {
    GoogleLoginUser(response, "FACEBOOK");
  };

  const responseGoogle = (response) => {
    GoogleLoginUser(response, "GOOGLE");
  };

  const responseGoogleFailed = (response) => {};
  return (
    <section className="pt-150 pb-150">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="row">
              {/* Radu hack, don't replicate */}
              <div className="col-lg-3" />
              <div className="col-lg-6">
                <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <h3 className="mb-30">Login</h3>
                    </div>
                    <form onSubmit={handleSubmit(handleLogin)}>
                      <div className="form-group">
                        <Field
                          required
                          component="input"
                          type="email"
                          id="form-email"
                          className="form-control"
                          placeholder="Enter email"
                          name="login_email"
                        />
                      </div>
                      <div className="form-group type_password">
                        <Field
                          required
                          component="input"
                          type={passwordEye ? "password" : "text"}
                          id="form-email"
                          className="form-control"
                          placeholder="Enter Password"
                          name="login_password"
                        />
                        <i
                          onClick={() => setPasswordEye(!passwordEye)}
                          className={`fa ${
                            passwordEye ? "fa-eye-slash" : "fa-eye"
                          }`}
                        ></i>
                      </div>
                      <div className="login_footer form-group">
                        <div className="chek-form">
                          {/* <div className="custome-checkbox">
                            <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox1" defaultValue />
                            <label className="form-check-label" htmlFor="exampleCheckbox1"><span>Remember me</span></label>
                          </div> */}
                        </div>
                        {/* <a className="text-muted" href="#">Forgot password?</a> */}
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-fill-out btn-block hover-up"
                          name="login"
                        >
                          Autentificare
                        </button>
                      </div>
                    </form>
                    <div className="divider-text-center mt-15 mb-15">
                      <span> Sau</span>
                    </div>
                    <ul className="btn-login list_none text-center mb-15">
                      <li>
                        <GoogleLogin
                          clientId={GOOGLE_CLIENT_ID}
                          buttonText="Login prin Google"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogleFailed}
                          className="btn btn-google hover-up"
                          render={(renderProps) => (
                            <button
                              onClick={renderProps.onClick}
                              className="btn btn-google hover-up"
                            >
                              Login prin Google
                            </button>
                          )}
                        />
                      </li>
                    </ul>
                    <div className="text-muted text-center">
                      Nu ai cont ? <Link to="/register">Creaza unul acum!</Link>
                    </div>
                    <div
                      style={{ color: "#0275d8", cursor: "pointer" }}
                      className="text-center"
                      onClick={toggle}
                    >
                      Ai uitat parola ?
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3" />
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <h5>Introduce adresa de E-mail in campul de mai jos.</h5>
            <FormGroup style={{ width: "100%" }}>
              <Input
                type="email"
                placeholder="Adresa E-mail"
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </FormGroup>
            <p className="text-center text-muted" style={{ fontSize: ".8rem" }}>
              Daca exista un cont asociat cu acesta iti trimitem un email cu
              instructiunile necesare pentru resetarea parolei
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleResetPassword}>
            Trimite Instructiuni
          </Button>
        </ModalFooter>
      </Modal>
    </section>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};
const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  isAuthenticated: auth.isAuthenticated,
  access: auth.access,
});

export default connect(mapStateToProps, {
  signupUser,
  loginUser,
  forgotPassword,
  GoogleLoginUser,
})(reduxForm({ form: "login_signup_form" })(Login));
