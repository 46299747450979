import React, { useEffect } from "react";
import { getCookiesList } from "../../redux/actions/legal";
import BlogLastEntry from "../Blog/sections/BlogLastEntry";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { getBlogPosts } from "../../redux/actions/blog";
import { Link } from "react-router-dom";

const Cookies = ({ getCookiesList, cookies, blogPosts, getBlogPosts }) => {
  useEffect(() => {
    getCookiesList();
    getBlogPosts();
  }, []);

  return (
    <>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              Home
            </Link>
            <span /> Terms of Service
          </div>
        </div>
      </div>
      <section className="mt-50 mb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              {cookies.map((cookie) => (
                <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                  <div className="single-header style-2">
                    <h2>{cookie.title}</h2>
                  </div>
                  <div className="single-content">
                    {ReactHtmlParser(cookie.message)}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-3 primary-sidebar sticky-sidebar">
              <div className="widget-area">
                <div className="sidebar-widget widget_alitheme_lastpost mb-20">
                  <div className="widget-header position-relative mb-20 pb-10">
                    <h5 className="widget-title">Trending Now</h5>
                  </div>
                  <div className="row">
                    <BlogLastEntry blogPosts={blogPosts} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({ legal, blog }) => ({
  blogPosts: blog.blogPosts?.data || [],
  cookies: legal.cookies
});

export default connect(mapStateToProps, {
  getCookiesList,
  getBlogPosts
})(Cookies);
