import React from "react";
import Moment from "react-moment";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

const BlogFeaturedPost = ({ blogPosts }) => {
  return (
    <>
      {blogPosts?.map?.(
        (post, index) =>
          index === 0 && (
            <div className="col-12">
              <article className="first-post mb-30 wow fadeIn animated hover-up">
                <div className="img-hover-slide position-relative overflow-hidden">
                  <div className="post-thumb img-hover-scale">
                    <Link to={`/blog/${post.id}`}>
                      <img
                        src={post.image}
                        alt={post.title}
                        style={{ width: "100%", borderRadius: "10px" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="entry-content">
                  <br />
                  <h2 className="post-title mb-20">
                    <Link className="text-limit-2" to={`/blog/${post.id}`}>
                      {post.title}
                    </Link>
                  </h2>
                  <p className="post-exerpt font-medium text-muted mb-30 text-limit-3">
                    {ReactHtmlParser(post?.content)}
                  </p>
                  <div className="mb-20 entry-meta meta-2">
                    <div className="font-xs ">
                      <span className="post-by">
                        By <a href="blog-category-grid.html">{post.author} </a>
                      </span>
                      <span className="post-on">
                        <Moment format="DD.MM.YYYY">{post.created_date}</Moment>
                      </span>
                    </div>
                    <Link to={`/blog/${post.id}`} className="btn btn-sm">
                      Citeste articolul
                      <i className="fi-rs-arrow-right ml-10" />
                    </Link>
                  </div>
                </div>
              </article>
            </div>
          )
      )}
    </>
  );
};

export default BlogFeaturedPost;
