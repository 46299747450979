import React, { useState } from "react";
import { Link } from "react-router-dom";
import slug from "slug";
import ProductQuickView from "../../../Common/ProductQuickView";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { handleCompare } from "../../../helper/cart";

import { Modal, ModalBody } from "reactstrap";

import ReactStars from "react-rating-stars-component";

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {
  storeProductInCart,
  storeInWishlist,
  storeLocalStorageCart,
} from "../../../redux/actions/wishlist";

const ProductCardBestSeller = ({
  products,
  storeProductInCart,
  storeInWishlist,
  storeLocalStorageCart,
}) => {
  const handleAddToCart = (product) => {
    product["qty"] = 1;
    if (localStorage.getItem("access")) {
      storeProductInCart({
        products: [{ product_id: product?.id, quantity: product?.qty || 1 }],
      });
    } else {
      storeLocalStorageCart(product);
    }
  };

  const handleAddToWishlist = (product) => {
    storeInWishlist({ product_id_ls: [product?.id] });
  };

  const [modal, setModal] = useState({ show: false, id: "" });

  const toggle = (show = false, id) =>
    setModal({
      show,
      id,
    });

  return (
    <>
      <Carousel
        infinite={true}
        arrows={false}
        draggable={true}
        autoPlay
        autoPlaySpeed={4000}
        customTransition="all 1s linear"
        minimumTouchDrag={80}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 3,
          },
        }}
      >
        {products?.map(
          (product, index) =>
            index >= 0 &&
            index <= 19 && (
              <div className="product-cart-wrap" style={{ margin: "0 5px" }}>
                <div className="product-img-action-wrap">
                  <div
                    className="product-img product-img-zoom"
                    style={{ height: "180px" }}
                  >
                    <Link
                      to={`/product/${product?.id}/${slug(
                        product?.title || "product",
                        "_"
                      )}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <img
                        className="default-img"
                        src={product?.images?.[0]?.image}
                        alt={product?.title}
                        style={{ objectFit: "contain" }}
                      />
                      {/* <img
                        className="hover-img"
                        src={product?.images?.[1]?.image}
                        alt={product?.title}
                        style={{ objectFit: "contain" }}
                      /> */}
                    </Link>
                  </div>
                  <div className="product-action-1">
                    <a
                      onClick={() => toggle(true, product?.id)}
                      aria-label="Vizualizeaza rapid"
                      className="action-btn hover-up"
                    >
                      <i
                        style={{ fontSize: ".8rem" }}
                        className="far fa-eye"
                      ></i>
                    </a>
                    <a
                      aria-label="Adauga la favorit"
                      className="action-btn hover-up"
                      onClick={() => handleAddToWishlist(product)}
                    >
                      <i
                        style={{ fontSize: ".8rem" }}
                        className="far fa-heart"
                      ></i>
                    </a>
                    <a
                      aria-label="Compara"
                      className="action-btn hover-up"
                      onClick={() => handleCompare(product)}
                    >
                      <i
                        style={{ fontSize: ".8rem" }}
                        className="fas fa-random"
                      ></i>
                    </a>
                  </div>
                  <div
                    className="
                    product-badges
                    product-badges-position
                    product-badges-mrg
                  "
                  >
                    {" "}
                    {product?.discount_percentage > 0 ? (
                      <span className="hot">
                        {product.discount_percentage}% Reducere
                      </span>
                    ) : null}
                    {product?.discount_amount > 0 ? (
                      <span className="best">
                        -{product.discount_amount} RON
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="product-content-wrap">
                  <div className="product-category">
                    <span>{product?.categories?.category?.name}</span>
                  </div>
                  <h2
                    style={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to={`/product/${product?.id}/${slug(
                        product?.title || "product",
                        "_"
                      )}`}
                      style={{
                        textDecoration: "none",
                      }}
                      className="text-limit-2"
                    >
                      {product?.title}
                    </Link>
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                    }}
                  >
                    {product.reviews_count < 1 ? (
                      <div style={{ margin: "0.875rem 0" }}></div>
                    ) : (
                      <ReactStars
                        count={product?.average_rating}
                        size={16}
                        emptyIcon={<i className="far fa-star"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        color="#ffd700"
                        edit={false}
                      />
                    )}
                  </div>
                  {product?.send_offer === true ? (
                    <>
                      <a
                        href={`/product/${product?.id}/${slug(
                          product?.title || "product",
                          "_"
                        )}`}
                        style={{ marginBottom: "4px" }}
                        className="product-price"
                      >
                        <span style={{ fontSize: ".9rem" }}>Cere Oferta </span>
                      </a>
                      <div className="product-action-1 show">
                        <a
                          aria-label="Vezi produs"
                          className="action-btn hover-up"
                          href={`/product/${product?.id}/${slug(
                            product?.title || "product",
                            "_"
                          )}`}
                        >
                          <i className="fas fa-eye"></i>
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="product-price">
                        <span>{product?.price} RON </span>
                        {product?.discount_percentage > 0 ||
                        product?.discount_amount > 0 ? (
                          <span className="old-price">
                            {product?.price_including_vat}
                            RON
                          </span>
                        ) : null}
                      </div>
                      <div className="product-action-1 show">
                        <a
                          aria-label="Adauga in cos"
                          className="action-btn hover-up"
                          onClick={() => handleAddToCart(product)}
                        >
                          <i className="fas fa-cart-plus"></i>
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
        )}
      </Carousel>
      <Modal
        isOpen={modal?.show}
        toggle={() => toggle()}
        style={{ maxWidth: "1100px", width: "100%" }}
      >
        <ModalBody>
          <ProductQuickView id={modal?.id} />
        </ModalBody>
      </Modal>
    </>
  );
};

ProductCardBestSeller.propTypes = {
  // product: PropTypes.object.isRequired
};

const mapStateToProps = ({ product, banner }) => ({});
export default connect(mapStateToProps, {
  storeInWishlist,
  storeProductInCart,
  storeLocalStorageCart,
})(ProductCardBestSeller);
