import axios from "axios";
import { toastr } from "react-redux-toastr";
import { formValueSelector } from "redux-form";
import requests, { BACKEND_URL, ITEMS_PER_PAGE } from "../../requests";
import {
  GET_CATEGORIES,
  GET_PRODUCT_LIST,
  GET_PRODUCT_ULTIMELE_LIST,
  LOADING_PRODUCT,
  GET_PRODUCT_BESTSELLER_LIST,
  GET_PRODUCT_DETAILS,
  GET_PRODUCTS_SEARCH_LIST,
  GET_PRODUCT_TYPE_LIST,
  GET_PRODUCT_ATTRIBUTES_CONTENT_LIST,
  GET_PRODUCT_BRAND_LIST,
  GET_PRODUCT_ATTRIBUTE_SPECIFICATION,
  GET_PRODUCT_REVIEW_LIST,
  GET_QUESTION_BUNDLE_READ,
  GET_QUESTION_DETAIL,
  GET_SERVICE_LIST,
  PRODUCT_COMPARE,
} from "../types/types";
import { errorHandle, tokenConfig } from "./common";
import { getCarts } from "./wishlist";

export const getCategories = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const categoriesData = await axios.get(
        `${BACKEND_URL}${requests.CATEGORIES_API}`
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({ type: GET_CATEGORIES, payload: categoriesData.data.data });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const getProducts = (
  data,
  search = false,
  pagination = true,
  ultimele = false,
  
) => {
  return async (dispatch, getState) => {
   
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productData = await axios.post(
        `${BACKEND_URL}${requests.PRODUCT_LIST}`,
        {
          ...data,
          items_per_page: data?.items_per_page || ITEMS_PER_PAGE,
          pagination
          
        }
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });

      if (ultimele) {
        dispatch({
          type: GET_PRODUCT_ULTIMELE_LIST,
          payload: productData.data.data,
        });
      } else {
        if (search) {
          dispatch({
            type: GET_PRODUCTS_SEARCH_LIST,
            payload: productData.data.data,
          });
        } else {
          dispatch({
            type: GET_PRODUCT_LIST,
            payload: {
              data: productData.data.data,
              pagination: {
                next_page_num: productData.data?.next_page_num,
                total_number_of_products:
                  productData.data?.total_number_of_products,
              },
              maximum_product_price: productData.data.maximum_product_price,
            },
          });
        }
      }
      return {
        result: productData.data
      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const clearSearch = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTS_SEARCH_LIST,
      payload: [],
    });
  };
};

export const getBestSellerProducts = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productData = await axios.post(
        `${BACKEND_URL}${requests.PRODUCT_LIST}`,
        { ...data, items_per_page: ITEMS_PER_PAGE }
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_PRODUCT_BESTSELLER_LIST,
        payload: productData.data.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const getProductDetail = (id) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productDetail = await axios.get(
        `${BACKEND_URL}${requests.PRODUCT_READ}${id}`
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_PRODUCT_DETAILS,
        payload: productDetail?.data?.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const clearProductDetail = () => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_PRODUCT_DETAILS, payload: {} });
  };
};

export const getProductTypeList = (id) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productTypeList = await axios.get(
        `${BACKEND_URL}${requests.PRODUCT_TYPE_LIST}`
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_PRODUCT_TYPE_LIST,
        payload: productTypeList.data.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const getProductAttributeContentList = (id, existing_contents = []) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productAttributeContentList = await axios.post(
        `${BACKEND_URL}${requests.PRODUCT_ATTRIBUTE_CONTENT}`,
        {
          subsubcategory_id: id,
          existing_contents,
        }
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_PRODUCT_ATTRIBUTES_CONTENT_LIST,
        payload: productAttributeContentList.data.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const getProductBrandList = (
  id,
  category_id = null,
  subcategory_id = null
) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productBrandList = await axios.post(
        `${BACKEND_URL}${requests.PRODUCT_BRAND}`,
        {
          subsubcategory_id: id,
          category_id,
          subcategory_id,
        }
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_PRODUCT_BRAND_LIST,
        payload: productBrandList.data.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const getProductSpecification = (id, props = {}) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productAttributeList = await axios.post(
        `${BACKEND_URL}${requests.PRODUCT_ATTRIBUTE_SPECIFICATION}${id}`,
        { ...props }
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_PRODUCT_ATTRIBUTE_SPECIFICATION,
        payload: productAttributeList.data.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const productReviewList = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productListReview = await axios.post(
        `${BACKEND_URL}${requests.PRODUCT_REVIEW_LIST}`,
        data
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_PRODUCT_REVIEW_LIST,
        payload: productListReview.data.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const createProductReview = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productReview = await axios.post(
        `${BACKEND_URL}${requests.PRODUCT_REVIEW_CREATE}`,
        data,
        tokenConfig()
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });

      if (productReview.status === 200) {
        toastr.success("Review Create", "Product Review Created");
        return {
          success: true,
        }

      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
      return {
        success: false,
      }
    }
  };
};

export const sendOfferRequest = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state
    const formData = new FormData();
    for (let i = 0; i < data.images.length; i++) {
      formData.append("image", data.images[i]);
    }
    try {
      const sendOfferImage = await axios.post(
        `${BACKEND_URL}${requests.SEND_OFFER_IMAGE}`,
        formData
      );
      if (sendOfferImage.status === 200) {
        const sendOffer = await axios.post(
          `${BACKEND_URL}${requests.SEND_OFFER_REQUEST}`,
          { ...data, images: sendOfferImage?.data?.data?.images || [] }
        );
        dispatch({
          type: LOADING_PRODUCT,
          payload: false,
        });
        if (sendOffer.status === 200) {
          toastr.success("Send Offer", "Send Offer Created");
        }
      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const getProductQuestionBundleRead = (id) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productQuestionBundle = await axios.post(
        `${BACKEND_URL}${requests.QUESTION_BUNDLE_READ}${id}`,
        {}
        // tokenConfig()
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_QUESTION_BUNDLE_READ,
        payload: productQuestionBundle.data.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const getQuestionDetail = (id) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const productQuestion = await axios.get(
        `${BACKEND_URL}${requests.QUESTION_DETAIL}${id}`
        // tokenConfig()
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_QUESTION_DETAIL,
        payload: productQuestion.data.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const getServiceList = (id) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const serviceList = await axios.get(
        `${BACKEND_URL}${requests.SERVICE_LIST}`,
        tokenConfig()
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      dispatch({
        type: GET_SERVICE_LIST,
        payload: serviceList.data.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};

export const productCompare = (ids, onlyOne) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state
    if (ids?.length > 0) {
      try {
        const productCompare = await axios.post(
          `${BACKEND_URL}${requests.PRODUCT_COMPARE}`,
          {
            products: ids,
          }
        );
        dispatch({
          type: LOADING_PRODUCT,
          payload: false,
        });
        dispatch({
          type: PRODUCT_COMPARE,
          payload: onlyOne ? [] : productCompare.data.data,
        });
      } catch (error) {
        errorHandle(error, dispatch, LOADING_PRODUCT);
      }
    }
  };
};

export const removeServices = (id) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRODUCT,
      payload: true,
    });
    //   Get Token from state

    try {
      const removeService = await axios.post(
        `${BACKEND_URL}${requests.REMOVE_SERVICES_FROM_ORDER}`,
        { order_product_ls: [id] },
        tokenConfig()
      );
      dispatch({
        type: LOADING_PRODUCT,
        payload: false,
      });
      if (removeService.status === 200) {
        toastr.success(
          "Remove Services",
          "Remove services from cart successfully"
        );
        dispatch(getCarts());
      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_PRODUCT);
    }
  };
};
