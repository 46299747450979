import React from 'react'
import { Link } from 'react-router-dom'
import FaqSection from './sections/FaqSection'

const Cart = () => {
  return (
    <section>
      <div className="site__body">
        <div className="page-header">
          <div className="page-header__container container">
            <div className="page-header__breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Prima pagina</Link>
                    <svg className="breadcrumb-arrow" width="6px" height="9px">
                      <use xlinkHref="images/sprite.svg#arrow-rounded-right-6x9" />
                    </svg>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Intrebari frecvente
                  </li>
                </ol>
              </nav>
            </div>
            <div className="page-header__title">
              <h1> Intrebari frecvente</h1>
            </div>
          </div>
        </div>
        <div className="block faq">
          <div className="container custom-faq-container">
            <FaqSection />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cart
