import React, { useEffect } from "react";
import { connect } from "react-redux";
import UserMenuSideNav from "./section/UserMenuSideNav";
import Avatar from "react-avatar";
import { getOrdersList } from "../../redux/actions/auth";
import moment from "moment";
import { orderStatus } from "../../requests";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap"
import { Link } from "react-router-dom";
 
const UserDashboard = ({ user, getOrdersList, ordersList }) => {
  let history = useHistory();
  useEffect(() => {
    getOrdersList();
  }, []);
  const [fullName, setFullName] = React.useState('');
  const navigateToOrderDetails = (orderId) => {
    history.push(`/user-details-order/${orderId}`);
  }
  useEffect(() => {
    if(user) {
      setFullName(user.first_name + " " + user.last_name);
    }
  }, [user]);
  return (
    <>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                LPV
              </Link>
              <span>Contul Meu</span>
              <span>Sumar Cont</span>
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row mt-40 mb-40">
              <div className="col-lg-3">
                <UserMenuSideNav />
              </div>
              <div className="col-lg-9">
                <div className="row mt-4 mt-lg-0">
                  <div className="col-lg-12 text-center">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                      }}
                    >
                      <h5 style={{ margin: "0" }}>Sumar Cont</h5>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-6">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <div
                        className="profile-index-avatar"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {user?.avatar === null ? (
                          <Avatar
                            name={fullName}
                            size="90"
                            round={true}
                            className="letters-avatar"
                            colors={["#0275d8", "#d82f3d", "#01baef"]}
                            maxInitials="2"
                          />
                        ) : (
                          <img
                            src={user?.avatar}
                            alt={user?.first_name}
                            width={90}
                            height={90}
                            style={{ objectFit: "cover", borderRadius: "50%" }}
                          />
                        )}
                      </div>
                      <h5
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="profile-index-name mt-4"
                      >
                        {`${user?.first_name || ""} ${user?.last_name || ""}`}
                      </h5>
                      <h6
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="profile-index-email mt-2"
                      >
                        {user?.email}
                      </h6>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="profile-index-edit mt-20"
                      >
                        <a
                          href="/user-edit-profile"
                          className="btn btn-secondary btn-sm"
                        >
                          Editeaza Profilul
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4 mt-lg-0">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                        position: "relative",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "15px",
                          padding: "5px 8px",
                          background: "#0275d8",
                          color: "#fff",
                          borderRadius: "0 0 5px 5px",
                          fontSize: ".8rem",
                        }}
                      >
                        Adresa Principala
                      </div>
                      <h5>
                        {" "}
                        {`${user?.first_name || ""} ${user?.last_name || ""}`}
                      </h5>
                      <div className="address-card__row">
                        {user?.shippling_address?.country},{" "}
                        {user?.shippling_address?.city}
                        <br />
                        {user?.shippling_address?.street_name},{" "}
                        {user?.shippling_address?.street_number}
                        <br />
                        {user?.shippling_address?.street_details}
                        <br />
                        Cod postal: {user?.shippling_address?.zip_code}
                      </div>
                      <hr />
                      <div>
                        <h6>Numar telefon</h6>
                        <div>{user?.phone_number}</div>
                      </div>
                      <div className="mt-3">
                        <h6>Adresa E-mail</h6>
                        <div>{user?.email}</div>
                      </div>
                      <div className="mt-15">
                        <Link to="/user-address">Editeaza Adresa</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4 custom-table-profile-desktop">
                  <div className="col-lg-12">
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Comanda</th>
                          <th>Data</th>
                          <th>Status</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ordersList?.slice(0, 4).map((orderDetail) => (
                          <tr
                            key={orderDetail.id}
                          onClick={() => {navigateToOrderDetails(orderDetail.id)}}
                            className={
                              orderStatus(orderDetail?.status)?.name ===
                              "Pending"
                                ? "my-account-td-pending"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Cancelled"
                                ? "my-account-td-order-cancelled"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Processing"
                                ? "my-account-td-order-processing"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Shipped"
                                ? "my-account-td-order-in-tranzit"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Delivered"
                                ? "my-account-td-order-completed"
                                : null
                            }
                          >
                            <td>
                              <a
                                className="table-anchor"
                                href={`/user-details-order/${orderDetail?.id}`}
                              >
                                <span className="my-account-in-tranzit">
                                  #{orderDetail?.id}
                                </span>
                              </a>
                            </td>
                            <td className="my-account-in-tranzit">
                              {moment(orderDetail?.ordered_at).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td
                              className={
                                orderStatus(orderDetail?.status)?.name ===
                                "Pending"
                                  ? "my-account-pending"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Cancelled"
                                  ? "my-account-order-cancelled"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Processing"
                                  ? "my-account-order-processing"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Shipped"
                                  ? "my-account-order-in-tranzit"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Delivered"
                                  ? "my-account-order-completed"
                                  : null
                              }
                            >
                              {orderStatus(orderDetail?.status)?.name ===
                              "Pending"
                                ? "Comanda Noua"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Cancelled"
                                ? "Comanda Anulata"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Processing"
                                ? "Comanda Preluata"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Shipped"
                                ? "In Tranzit"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Delivered"
                                ? "Comanda Livrata"
                                : null}
                            </td>
                            <td className="my-account-in-tranzit">
                              {orderDetail?.total_amount} Lei pentru{" "}
                              {orderDetail?.products?.length || 0} produse
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="row mt-4 custom-table-profile-mobile">
                  <div className="col-lg-12">
                    <Table className="table table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Status</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ordersList?.slice(0, 4).map((orderDetail) => (
                          <tr
                            key={orderDetail?.id}
                            onClick={()=> navigateToOrderDetails(orderDetail?.id)}
                            className={
                              orderStatus(orderDetail?.status)?.name ===
                              "Pending"
                                ? "my-account-td-pending"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Cancelled"
                                ? "my-account-td-order-cancelled"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Processing"
                                ? "my-account-td-order-processing"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Shipped"
                                ? "my-account-td-order-in-tranzit"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Delivered"
                                ? "my-account-td-order-completed"
                                : null
                            }
                          >
                            <td>
                              <a
                                className="table-anchor"
                                href={`/user-details-order/${orderDetail?.id}`}
                              >
                                <span>#{orderDetail?.id}</span>
                              </a>
                            </td>
                            <td
                              className={
                                orderStatus(orderDetail?.status)?.name ===
                                "Pending"
                                  ? "my-account-pending"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Cancelled"
                                  ? "my-account-order-cancelled"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Processing"
                                  ? "my-account-order-processing"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Shipped"
                                  ? "my-account-order-in-tranzit"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Delivered"
                                  ? "my-account-order-completed"
                                  : null
                              }
                            >
                              {orderStatus(orderDetail?.status)?.name ===
                              "Pending"
                                ? "Comanda Noua"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Cancelled"
                                ? "Comanda Anulata"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Processing"
                                ? "Comanda Preluata"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Shipped"
                                ? "In Tranzit"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Delivered"
                                ? "Comanda Livrata"
                                : null}
                            </td>
                            <td>{orderDetail?.total_amount || 0} RON</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  ordersList: auth.ordersList?.data,
});
export default connect(mapStateToProps, { getOrdersList })(UserDashboard);
