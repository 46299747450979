import React from "react";

const Verify = () => {
  return (
    <section className="pt-150 pb-150">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="row">
              <div className="col-lg-3" />
              <div className="col-lg-6">
                <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <h3 className="mb-30">Verificare Email</h3>
                    </div>
                    <p>Verifica emailul in sectiunea Inbox si Spam!</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Verify;
