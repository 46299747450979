import React, { useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { handleCompare } from "../../../helper/cart";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import slug from "slug";
import { Modal, ModalBody } from "reactstrap";
import ProductQuickView from "../../../Common/ProductQuickView";
import {
  storeProductInCart,
  storeInWishlist,
  storeLocalStorageCart,
} from "../../../redux/actions/wishlist";

const ProductCardCategory = ({
  products,
  storeProductInCart,
  storeInWishlist,
  storeLocalStorageCart,
}) => {
  const handleAddToCart = (product) => {
    product["qty"] = 1;
    if (localStorage.getItem("access")) {
      storeProductInCart({
        products: [{ product_id: product?.id, quantity: product?.qty || 1 }],
      });
    } else {
      storeLocalStorageCart(product);
      toastr.success("Produs adaugat in cos");
    }
  };

  const handleAddToWishlist = (product) => {
    storeInWishlist({ product_id_ls: [product?.id] });
  };

  const [modal, setModal] = useState({
    show: false,
    id: "",
  });

  const toggle = (show = false, id) =>
    setModal({
      show,
      id,
    });

  return (
    <>
      {products?.map(
        (product, index) =>
          product.show_carousel &&
          index >= 0 &&
          index <= 7 && (
            <div className="col-lg-3 col-md-4 col-12 col-sm-6" key={product.id}>
              <div className="product-cart-wrap mb-30">
                <div className="product-img-action-wrap">
                  <div
                    style={{ height: "250px" }}
                    className="product-img product-img-zoom"
                  >
                    <Link
                      to={`/product/${product?.id}/${slug(
                        product?.title || "product",
                        "_"
                      )}`}
                      style={{
                        textDecoration: "none",
                        maxHeight: "250px",
                        height: "100%",
                      }}
                    >
                      <img
                        className="default-img"
                        src={product?.images?.[0]?.image}
                        alt={product?.title}
                        style={{ objectFit: "contain" }}
                      />
                      {/* <img
                        className="hover-img"
                        src={product?.images?.[1]?.image}
                        alt={product?.title}
                        style={{ objectFit: "contain" }}
                      /> */}
                    </Link>
                  </div>
                  <div className="product-action-1">
                    <a
                      onClick={() => toggle(true, product?.id)}
                      aria-label="Vizualizeaza rapid"
                      className="action-btn hover-up"
                    >
                      <i className="far fa-eye"></i>
                    </a>
                    <a
                      aria-label="Adauga la favorit"
                      className="action-btn hover-up"
                      onClick={() => handleAddToWishlist(product)}
                    >
                      <i className="far fa-heart"></i>
                    </a>
                    <a
                      aria-label="Compara"
                      className="action-btn hover-up"
                      onClick={() => handleCompare(product)}
                    >
                      <i className="fas fa-random"></i>
                    </a>
                  </div>
                  <div
                    className="
                    product-badges
                    product-badges-position
                    product-badges-mrg
                  "
                  >
                    {" "}
                    {product?.discount_percentage > 0 ? (
                      <span className="hot">
                        {product.discount_percentage}% Reducere
                      </span>
                    ) : null}
                    {product?.discount_amount > 0 ? (
                      <span className="best">
                        -{product.discount_amount} RON
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="product-content-wrap">
                  <div className="product-category">
                    <span href="shop-grid-right.html">
                      {product?.categories?.category?.name}
                    </span>
                  </div>
                  <h2
                    style={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to={`/product/${product?.id}/${slug(
                        product?.title || "product",
                        "_"
                      )}`}
                      style={{
                        textDecoration: "none",
                      }}
                      className="text-limit-2"
                    >
                      {product?.title}
                    </Link>
                  </h2>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {product.reviews_count < 1 ? (
                      <div style={{ margin: "0.875rem 0" }}></div>
                    ) : (
                      <ReactStars
                        count={product?.average_rating}
                        size={20}
                        emptyIcon={<i className="far fa-star"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        color="#ffd700"
                        edit={false}
                      />
                    )}

                    <span>
                      <span>
                        {product?.reviews_count > 0 && (
                          <>
                            {" "}
                            {product?.reviews_count}{" "}
                            {product?.reviews_count == 1
                              ? "Recenzie"
                              : "Recenzii"}
                          </>
                        )}
                      </span>
                    </span>
                  </div>
                  {product?.send_offer === true ? (
                    <a
                      href={`/product/${product?.id}/${slug(
                        product?.title || "product",
                        "_"
                      )}`}
                      style={{ fontWeight: "600" }}
                    >
                      Cere Oferta
                    </a>
                  ) : (
                    <div
                      className="product-price"
                      style={{
                        minHeight: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{product?.price} RON </span>
                      {product?.discount_percentage > 0 ||
                      product?.discount_amount > 0 ? (
                        <span className="old-price">
                          {product?.price_including_vat}
                          RON
                        </span>
                      ) : null}
                    </div>
                  )}
                  {product?.send_offer === true ? (
                    <div className="product-action-1 show">
                      <a
                        aria-label="Cere oferta"
                        className="action-btn hover-up"
                        href={`/product/${product?.id}/${slug(
                          product?.title || "product",
                          "_"
                        )}`}
                      >
                        <i className="fas fa-eye"></i>
                      </a>
                    </div>
                  ) : (
                    <div className="product-action-1 show">
                      <a
                        aria-label="Adauga in cos"
                        className="action-btn hover-up"
                        onClick={() => handleAddToCart(product)}
                      >
                        <i className="fas fa-cart-plus"></i>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
      )}
      <Modal
        isOpen={modal?.show}
        toggle={() => toggle()}
        style={{ maxWidth: "1100px", width: "100%" }}
      >
        <ModalBody>
          <ProductQuickView id={modal?.id} />
        </ModalBody>
      </Modal>
    </>
  );
};

ProductCardCategory.propTypes = {
  product: PropTypes.object,
};

const mapStateToProps = ({ product, banner }) => ({});
export default connect(mapStateToProps, {
  storeProductInCart,
  storeInWishlist,
  storeLocalStorageCart,
})(ProductCardCategory);
