import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getOrdersList } from "../../../redux/actions/auth";
import { orderStatus } from "../../../requests";
import UserMenuSideNav from "./UserMenuSideNav";
import ReactPaginate from "react-paginate";
import { Table } from "reactstrap";
import { useHistory, Link } from "react-router-dom";
const OrderHistory = ({ getOrdersList, pagination, ordersList }) => {
  let history = useHistory();
  const items_per_page = 10;
  const next_page_num = pagination?.next_page_num || 1;
  const total_number_of_products = pagination?.total_number_of_products || 1;
  const navigateToOrderDetails = (orderId) => {
    history.push(`/user-details-order/${orderId}`);
  };
  useEffect(() => {
    getOrdersList({
      item_per_page: items_per_page,
    });
  }, []);

  return (
    <>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                LPV
              </Link>
              <span>Contul Meu</span>
              <span>Istoric Comenzi</span>
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row mt-40 mb-40">
              <div className="col-lg-3">
                <UserMenuSideNav />
              </div>
              <div className="col-lg-9">
                <div className="row mt-4 mt-lg-0">
                  <div className="col-lg-12">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                      }}
                    >
                      <h5 className="text-center" style={{ margin: "0" }}>
                        Istoric Comenzi
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 custom-table-profile-desktop">
                  <div className="col-lg-12">
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Comanda</th>
                          <th>Data</th>
                          <th>Status</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ordersList?.map((orderDetail) => (
                          <tr
                            key={orderDetail.id}
                            onClick={() => {
                              navigateToOrderDetails(orderDetail.id);
                            }}
                            className={
                              orderStatus(orderDetail?.status)?.name ===
                              "Pending"
                                ? "my-account-td-pending"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Cancelled"
                                ? "my-account-td-order-cancelled"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Processing"
                                ? "my-account-td-order-processing"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Shipped"
                                ? "my-account-td-order-in-tranzit"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Delivered"
                                ? "my-account-td-order-completed"
                                : null
                            }
                          >
                            <td>
                              <a
                                className="table-anchor"
                                href={`/user-details-order/${orderDetail?.id}`}
                              >
                                <span className="my-account-in-tranzit">
                                  #{orderDetail?.id}
                                </span>
                              </a>
                            </td>
                            <td className="my-account-in-tranzit">
                              {moment(orderDetail?.ordered_at).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                            <td
                              className={
                                orderStatus(orderDetail?.status)?.name ===
                                "Pending"
                                  ? "my-account-pending"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Cancelled"
                                  ? "my-account-order-cancelled"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Processing"
                                  ? "my-account-order-processing"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Shipped"
                                  ? "my-account-order-in-tranzit"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Delivered"
                                  ? "my-account-order-completed"
                                  : null
                              }
                            >
                              {orderStatus(orderDetail?.status)?.name ===
                              "Pending"
                                ? "Comanda Noua"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Cancelled"
                                ? "Comanda Anulata"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Processing"
                                ? "Comanda Preluata"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Shipped"
                                ? "In Tranzit"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Delivered"
                                ? "Comanda Livrata"
                                : null}
                            </td>
                            <td className="my-account-in-tranzit">
                              {orderDetail?.total_amount} Lei pentru{" "}
                              {orderDetail?.products?.length || 0} produse
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <ReactPaginate
                      pageCount={Math.ceil(
                        parseInt(total_number_of_products) /
                          parseInt(items_per_page)
                      )}
                      activePage={next_page_num && next_page_num - 1}
                      itemsCountPerPage={items_per_page}
                      totalItemsCount={total_number_of_products}
                      pageRangeDisplayed={2}
                      containerClassName="pagination justify-content-center"
                      activeClassName="active"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link page-link_with__arrow--left"
                      nextLinkClassName="page-link page-link_with__arrow--right"
                      disabledClassName="disabled"
                      previousLabel="Inapoi"
                      nextLabel="Inainte"
                      onPageChange={(e) => {
                        getOrdersList({
                          page_num: e.selected + 1,
                          item_per_page: items_per_page,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-4 custom-table-profile-mobile">
                  <div className="col-lg-12">
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Status</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ordersList?.map((orderDetail) => (
                          <tr
                            className={
                              orderStatus(orderDetail?.status)?.name ===
                              "Pending"
                                ? "my-account-td-pending"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Cancelled"
                                ? "my-account-td-order-cancelled"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Processing"
                                ? "my-account-td-order-processing"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Shipped"
                                ? "my-account-td-order-in-tranzit"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Delivered"
                                ? "my-account-td-order-completed"
                                : null
                            }
                          >
                            <td>
                              <a
                                className="table-anchor"
                                href={`/user-details-order/${orderDetail?.id}`}
                              >
                                <span>#{orderDetail?.id}</span>
                              </a>
                            </td>
                            <td
                              className={
                                orderStatus(orderDetail?.status)?.name ===
                                "Pending"
                                  ? "my-account-pending"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Cancelled"
                                  ? "my-account-order-cancelled"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Processing"
                                  ? "my-account-order-processing"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Shipped"
                                  ? "my-account-order-in-tranzit"
                                  : orderStatus(orderDetail?.status)?.name ===
                                    "Delivered"
                                  ? "my-account-order-completed"
                                  : null
                              }
                            >
                              {orderStatus(orderDetail?.status)?.name ===
                              "Pending"
                                ? "Comanda Noua"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Cancelled"
                                ? "Comanda Anulata"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Processing"
                                ? "Comanda Preluata"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Shipped"
                                ? "In Tranzit"
                                : orderStatus(orderDetail?.status)?.name ===
                                  "Delivered"
                                ? "Comanda Livrata"
                                : null}
                            </td>
                            <td>{orderDetail?.total_amount || 0} RON</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ReactPaginate
                      pageCount={Math.ceil(
                        parseInt(total_number_of_products) /
                          parseInt(items_per_page)
                      )}
                      activePage={next_page_num && next_page_num - 1}
                      itemsCountPerPage={items_per_page}
                      totalItemsCount={total_number_of_products}
                      pageRangeDisplayed={2}
                      containerClassName="pagination justify-content-center"
                      activeClassName="active"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link page-link_with__arrow--left"
                      nextLinkClassName="page-link page-link_with__arrow--right"
                      disabledClassName="disabled"
                      previousLabel="Inapoi"
                      nextLabel="Inainte"
                      onPageChange={(e) => {
                        getOrdersList({
                          page_num: e.selected + 1,
                          item_per_page: items_per_page,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  ordersList: auth.ordersList.data,
  pagination: {
    next_page_num: auth.ordersList.next_page_num,
    total_number_of_products: auth.ordersList.total_number_of_products,
  },
});
export default connect(mapStateToProps, { getOrdersList })(OrderHistory);
