import React from 'react'

const FaqSection = () => {
  return (
    <>
      <div className="faq__section">
        <div className="faq__section-body">
          <div className="row">
            <div className="faq__section-column col-12 col-lg-6">
              <div className="typography">
                <h6> domain/faq/list - title</h6>
                <p>domain/faq/list - message</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FaqSection
