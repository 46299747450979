import React from "react";
import css from "../assets/css/main.css";
/* eslint-disable */ import icon from "../assets/css/vendors/icons/css/all.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "../Common/Layout";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import store from "../redux/reducers";

import Home from "../components/Home/";
import Shop from "../components/Shop/";
import ProductPage from "../components/ProductPage/";
import Contact from "../components/ContactUs/";
import AboutUs from "../components/AboutUs/";
import Blog from "../components/Blog/";
import BlogPage from "../components/BlogPage/";
import NotFound from "../components/NotFound/";
import LogIn from "../components/Login/";
import Register from "../components/Register/";
import Verify from "../components/Register/Verify";
import Cart from "../components/Cart/";
import WishList from "../components/Wishlist/";
import CheckOut from "../components/CheckOut/";

import Compare from "../components/Compare/";
import OrderConfirm from "../components/OrderConfirm/";
import Faq from "../components/Faq/";
import TermsAndConditions from "../components/TermsAndConditions/";
import ReturnPolicy from "../components/ReturnPolicy";
import Cookies from "../components/Cookies";
import PrivacyProtection from "../components/PrivacyProtection";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { HiddenEasterEgg } from "react-hidden-easter-egg";
import { ConfettiCanvas } from "react-raining-confetti";
import UserProfile from "./UserProfile";
import userEditProfile from "./UserProfile/section/EditProfile";
import userOrderHistory from "./UserProfile/section/UserOrderHistory";
import userAddress from "./UserProfile/section/Address";
import userDetailOrder from "./UserProfile/section/OrderDetails";
import ProductRecom from "./ProductRecom/index";
import RecomandareCTACMInstant from "./ProductRecom/RecomandareCTACMInstant";
import CTACMBoilerEtern from "./ProductRecom/CTACMBoilerEtern";
import { info } from "toastr";
import OrderFailed from "./OrderFailed";
import Autorizatii from "./Autorizatii";

const App = () => {
  const alertOptions = {
    timeout: 3000,
    newestOnTop: false,
    position: "top-right",
    preventDuplicates: true,
    transitionIn: "fadeIn",
    transitionOut: "fadeOut",
    progressBar: true,
    closeOnToastrClick: true,
  };
  const accessToken = localStorage.getItem("access");

  if (accessToken && window.location.pathname === "/log_in") {
    return window.location.replace("/");
  }
  return (
    <Provider store={store}>
      <HiddenEasterEgg
        code={["c", "o", "n", "f", "e", "t", "t", "i"]}
        resetEggMs={10000}
      >
        <ConfettiCanvas active={true} fadingMode="LIGHT" stopAfterMs={5000} />
      </HiddenEasterEgg>
      <BrowserRouter>
        <ReduxToastr getState={(state) => state.toastr} {...alertOptions} />
        <>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/categorie/:slug/:id" component={Shop} />
              <Route
                exact
                path="/product_category/:category"
                component={Shop}
              />
              <Route
                exact
                path="/product_sub_category/:sub_category"
                component={Shop}
              />
              <Route exact path="/recomandare/:id" component={Shop} />
              <Route exact path="/searchResult" component={Shop} />
              <Route exact path="/promotion_product" component={Shop} />
              <Route exact path="/autorizatii" component={Autorizatii} />
              <Route exact path="/product/:id/:slug" component={ProductPage} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/despre-noi" component={AboutUs} />
              <Route exact path="/log_in" component={LogIn} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/register_verify" component={Verify} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/blog/:id" component={BlogPage} />
              <Route exact path="/cart" component={Cart} />
              <Route exact path="/orderconfirm" component={OrderConfirm} />
              <Route exact path="/orderfailed" component={OrderFailed} />
              <Route exact path="/wishlist" component={WishList} />
              <Route exact path="/checkout" component={CheckOut} />
              <Route exact path="/comparator" component={Compare} />
              <Route exact path="/intrebari-frecvente" component={Faq} />
              <Route exact path="/user-address" component={userAddress} />
              <Route exact path="/profil" component={UserProfile} />
              <Route
                exact
                path="/recomandare-produse"
                component={ProductRecom}
              />
              <Route
                exact
                path="/recomandare-ct-acm-instant"
                component={RecomandareCTACMInstant}
              />
              <Route
                exact
                path="/recomandare-ct-acm-boiler-extern"
                component={CTACMBoilerEtern}
              />
              <Route
                exact
                path="/user-order-history"
                component={userOrderHistory}
              />
              <Route
                exact
                path="/user-details-order/:id"
                component={userDetailOrder}
              />
              <Route
                exact
                path="/user-edit-profile"
                component={userEditProfile}
              />
              <Route
                exact
                path="/termeni-si-conditii"
                component={TermsAndConditions}
              />
              <Route exact path="/politica-cookies" component={Cookies} />
              <Route
                exact
                path="/politica-de-confidentialitate"
                component={PrivacyProtection}
              />
              <Route exact path="/termeni-retur" component={ReturnPolicy} />
              {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
              <Route path="*">
                <Home />
              </Route>
            </Switch>
          </Layout>
        </>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
