import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BillingDetails from "./sections/BillingDetails";
import OrderSumary from "./sections/OrderSumary";
import {
  getCarts,
  stripePaymentCheckStatus,
  placeOrder
} from "../../redux/actions/wishlist";

import { createAddress, load_user } from "../../redux/actions/auth";
import { getServiceList } from "../../redux/actions/products";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import StripeElement from "../../Common/Payment/Stripe/StripeElement";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";

const WishList = ({
  load_user,
  getCarts,
  carts,
  summary,
  user,
  paymentLoading,
  paymentSuccessfully,
  stripePaymentCheckStatus,
  getServiceList,
  serviceList,
  createAddress,
  placeOrder
}) => {
  useEffect(() => {
    getCarts();
    load_user();
    getServiceList();
  }, []);

  const [address, setAddress] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [shippingCheck, setShippingCheck] = useState(true);
  const [stripeModal, setStripeModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("STRIPE");

  const handlePaymentCreate = () => {
    if (address?.user) {
      delete address?.user;
      delete address?.id;
    }
    if (billingAddress?.user) {
      delete billingAddress?.user;
      delete billingAddress?.id;
    }

    const paymentObject = {
      payment_method_type: paymentMethod,
      total_amount: summary?.total_price - (summary?.voucher_discount || 0),
      product_item_id_ls: carts?.map((cart) => cart?.id),
      shipping_address: {...address,  address_type: "SHIPPING"} || {},
      billing_address:
        (shippingCheck
          ? { ...address, address_type: "BILLING" }
          : billingAddress) || {},
      user_type: persoanaFizica
        ? "NORMAL_USER"
        : persoanaJuridica
        ? "COMPANY"
        : ""
    };
    placeOrder(paymentObject);
  };

  const toggleModal = () => {
    if (paymentMethod === "STRIPE") {
      setStripeModal(!stripeModal);
    } else {
      handlePaymentCreate();
    }
  };

  const returnServiceDataByCartId = (id) => {
    const serviceData = serviceList?.filter(
      (service) => service?.order_product_id == id
    );
    return serviceData?.[0]?.services;
  };
  const user_address = user?.shippling_address || {};
  const billing_address = user?.billing_address || {};

  useEffect(() => {
    setAddress({
      ...(user?.shippling_address || {})
    });
    setBillingAddress({
      ...(user?.billing_address || {})
    });
  }, [user]);

  const handleChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value
    });
  };

  const handleChangeBillingAddress = (e) => {
    setBillingAddress({
      ...billingAddress,
      [e.target.name]: e.target.value
    });
  };

  const createUserAddress = (create_address, address_type) => {
    createAddress({ ...create_address, address_type });
  };

  const handleStripe = (data) => {
   
    if (address?.user) {
      delete address?.user;
      delete address?.id;
    }
    if (billingAddress?.user) {
      delete billingAddress?.user;
      delete billingAddress?.id;
    }
    if (data?.code) {
      toastr.error(
        data?.code || "Error in Card",
        data?.message || "Check Card Details"
      );
    } else if (data?.type && !data?.id) {
      toastr.error(
        data?.type || "Internal Error",
        data?.message || "try again we not charged from your card"
      );
    } else if (data?.id) {
      const paymentObject = {
        payment_method_type: paymentMethod,
        // TODO: once Fix pass dynamic token
        stripe_payment_method_id: data?.id,
        total_amount: summary?.total_price - (summary?.voucher_discount || 0),
        product_item_id_ls: carts?.map((cart) => cart?.id),
        shipping_address: address || {},
        billing_address:
          (shippingCheck
            ? { ...address, address_type: "BILLING" }
            : billingAddress) || {},
        user_type: persoanaFizica
          ? "NORMAL_USER"
          : persoanaJuridica
          ? "COMPANY"
          : ""
      };
      stripePaymentCheckStatus(paymentObject);
      if (paymentSuccessfully) {
      }
    }
  };

  const [persoanaFizica, setPersoanaFizica] = useState(true);
  const [persoanaJuridica, setPersoanaJuridica] = useState(false);

  const handlePersoanaFizica = () => {
    setPersoanaFizica(true);
    setPersoanaJuridica(false);
    setAddress({
      ...(user?.shippling_address || {})
    });
    setBillingAddress({
      ...(user?.billing_address || {})
    });
  };

  const handlePersoanaJuridica = () => {
    setPersoanaFizica(false);
    setPersoanaJuridica(true);
    setAddress({ address_type: "SHIPPING" });
    setBillingAddress({ address_type: "BILLING" });
  };
  
  return (
    <>
      <div className="site__body">
        <div className="checkout block box_margin">
          <div className="container">
            <div className="row">
              <div className="page-header breadcrumb-wrap">
                <div className="container">
                  <div className="breadcrumb">
                    <Link to="/" rel="nofollow">
                      LPV
                    </Link>
                    <span></span> Checkout
                  </div>
                </div>
              </div>
              <section className="mt-50 mb-50">
                <div className="row">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "20px"
                    }}
                  >
                    <button
                      style={{ fontSize: ".8rem" }}
                      type="button"
                      className={
                        persoanaFizica ? "btn btn-primary" : "btn btn-secondary"
                      }
                      onClick={handlePersoanaFizica}
                    >
                      Persoana Fizica
                    </button>
                    <button
                      style={{ fontSize: ".8rem" }}
                      type="button"
                      className={
                        persoanaJuridica
                          ? "btn btn-primary"
                          : "btn btn-secondary"
                      }
                      onClick={handlePersoanaJuridica}
                    >
                      Personana Juridica
                    </button>
                  </div>

                  {persoanaFizica ? (
                    <div className="col-md-6">
                      <div className="mb-25">
                        <h4>Adresa de livrare/de interventie</h4>
                      </div>
                      <form>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="checkout-first-name">Nume</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              id="checkout-first-name"
                              placeholder="Nume"
                              name="first_name"
                              value={address?.first_name}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="checkout-last-name">Prenume</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              id="checkout-last-name"
                              placeholder="Prenume"
                              name="last_name"
                              value={address?.last_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="checkout-first-name">Telefon</label>{" "}
                            <input
                              type="number"
                              className="form-control"
                              id="checkout-first-name"
                              placeholder="Numar Telefon"
                              name="phone_number"
                              value={address?.phone_number}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="checkout-last-name">Email</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              id="checkout-last-name"
                              placeholder="Adresa Email"
                              name="email"
                              value={address?.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group">
                            <label htmlFor="checkout-street-address">
                              Tara
                            </label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Tara dvs."
                              name="country"
                              value={address?.country}
                              onChange={handleChange}
                            />
                            <div className="form-group">
                              <label htmlFor="checkout-street-address">
                                Oras
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Orasul dvs."
                                name="city"
                                value={address?.city}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-city">Street</label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                id="checkout-city"
                                name="street_name"
                                placeholder="Numele Strazii"
                                value={address?.street_name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-street-address">
                                Numar Strada
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                id="checkout-address"
                                name="street_number"
                                placeholder="Numarul Strazii"
                                value={address?.street_number}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-street-address">
                                Detalii adresa
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Bloc / Scara / Apartament etc."
                                name="street_details"
                                value={address?.street_details}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-cod">Cod Postal</label>{" "}
                              <input
                                id="checkout-cod"
                                type="text"
                                className="form-control"
                                placeholder="Codul Postal"
                                name="zip_code"
                                value={address?.zip_code}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="row">
                        {Object?.keys?.(user_address)?.length <= 0 && (
                          <div className="col-md-6">
                            <button
                              style={{ width: "100%" }}
                              type="button"
                              className="btn btn-secondary"
                              onClick={() =>
                                createUserAddress(address, "SHIPPING")
                              }
                            >
                              Salveaza adresa de livrare
                            </button>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px"
                          }}
                          className="col-md-6 custom-spacing"
                        >
                          <input
                            style={{ margin: "0" }}
                            className="form-check-input"
                            type="checkbox"
                            id="sameAddress"
                            checked={shippingCheck}
                            onChange={() => setShippingCheck(!shippingCheck)}
                          />
                          <label
                            for="sameAddress"
                            style={{ padding: "0", margin: "0" }}
                          >
                            Foloseste aceeasi adresa pentru facturare
                          </label>
                        </div>
                      </div>
                      {!shippingCheck && (
                        <>
                          <div className="mt-50 mb-25">
                            <h4>Adresa de facturare</h4>
                          </div>
                          <form>
                            <div className="row">
                              <div className="form-group col-md-6">
                                <label htmlFor="checkout-first-name">
                                  Nume
                                </label>{" "}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="checkout-first-name"
                                  placeholder="Nume"
                                  name="first_name"
                                  value={billingAddress?.first_name}
                                  onChange={handleChangeBillingAddress}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="checkout-last-name">
                                  Prenume
                                </label>{" "}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="checkout-last-name"
                                  placeholder="Prenume"
                                  name="last_name"
                                  value={billingAddress?.last_name}
                                  onChange={handleChangeBillingAddress}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6">
                                <label htmlFor="checkout-first-name">
                                  Telefon
                                </label>{" "}
                                <input
                                  type="number"
                                  className="form-control"
                                  id="checkout-first-name"
                                  placeholder="Numar Telefon"
                                  name="phone_number"
                                  value={billingAddress?.phone_number}
                                  onChange={handleChangeBillingAddress}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="checkout-last-name">
                                  Email
                                </label>{" "}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="checkout-last-name"
                                  placeholder="Adresa Email"
                                  name="email"
                                  value={billingAddress?.email}
                                  onChange={handleChangeBillingAddress}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group">
                                <label htmlFor="checkout-street-address">
                                  Tara
                                </label>{" "}
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Tara dvs."
                                  name="country"
                                  value={billingAddress?.country}
                                  onChange={handleChangeBillingAddress}
                                />
                                <div className="form-group">
                                  <label htmlFor="checkout-street-address">
                                    Oras
                                  </label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-street-address"
                                    placeholder="Orasul dvs."
                                    name="city"
                                    value={billingAddress?.city}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-city">Strada</label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-city"
                                    name="street_name"
                                    placeholder="Numele Strazii"
                                    value={billingAddress?.street_name}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-street-address">
                                    Numar Strada
                                  </label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-address"
                                    name="street_number"
                                    placeholder="Numarul Strazii"
                                    value={billingAddress?.street_number}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-street-address">
                                    Detalii adresa
                                  </label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-street-address"
                                    placeholder="Bloc / Scara / Apartament etc."
                                    name="street_details"
                                    value={billingAddress?.street_details}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-street-address">
                                    Cod Postal
                                  </label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Codul Postal"
                                    name="zip_code"
                                    value={billingAddress?.zip_code}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="row">
                            {Object?.keys?.(billing_address)?.length <= 0 && (
                              <div className="col-md-6">
                                <button
                                  style={{ width: "100%" }}
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    createUserAddress(billingAddress, "BILLING")
                                  }
                                >
                                  Salveaza adresa de facturare
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                  {persoanaJuridica ? (
                    <div className="col-md-6">
                      <div className="mb-25">
                        <h4>Adresa de livrare</h4>
                      </div>
                      <form>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="checkout-first-name">Nume</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              id="checkout-first-name"
                              placeholder="Nume"
                              name="first_name"
                              value={address?.first_name}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="checkout-last-name">Prenume</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              id="checkout-last-name"
                              placeholder="Prenume"
                              name="last_name"
                              value={address?.last_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="checkout-first-name">
                              Telephone
                            </label>{" "}
                            <input
                              type="number"
                              className="form-control"
                              id="checkout-first-name"
                              placeholder="Numar Telefon"
                              name="phone_number"
                              value={address?.phone_number}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="checkout-last-name">Email</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              id="checkout-last-name"
                              placeholder="Adresa Email"
                              name="email"
                              value={address?.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group">
                            <label htmlFor="checkout-street-address">
                              Tara
                            </label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Tara dvs."
                              name="country"
                              value={address?.country}
                              onChange={handleChange}
                            />
                            <div className="form-group">
                              <label htmlFor="checkout-street-address">
                                Oras
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Orasul dvs."
                                name="city"
                                value={address?.city}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-city">Strada</label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                id="checkout-city"
                                name="street_name"
                                placeholder="Numele Strazii"
                                value={address?.street_name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-street-address">
                                Numar Strada
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                id="checkout-address"
                                name="street_number"
                                placeholder="Numarul Strazii"
                                value={address?.street_number}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-street-address">
                                Detalii adresa
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Bloc / Scara / Apartament etc."
                                name="street_details"
                                value={address?.street_details}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-street-address">
                                Cod Postal
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Codul Postal"
                                name="zip_code"
                                value={address?.zip_code}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-company-name">
                                Nume Firma
                              </label>{" "}
                              <input
                                type="text"
                                id="checkout-company-name"
                                className="form-control"
                                placeholder="Nume firma"
                                name="company_name"
                                value={address?.company_name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-cui">CUI</label>{" "}
                              <input
                                id="checkout-cui"
                                type="text"
                                className="form-control"
                                placeholder="Cui"
                                name="cui"
                                value={address?.cui}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="checkout-jcode">
                                Numar inregistrare firma
                              </label>{" "}
                              <input
                                id="checkout-jcode"
                                type="text"
                                className="form-control"
                                placeholder="Codul J"
                                name="jcode"
                                value={address?.jcode}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="row">
                        {Object?.keys?.(user_address)?.length <= 0 && (
                          <div className="col-md-6">
                            <button
                              style={{ width: "100%" }}
                              type="button"
                              className="btn btn-secondary"
                              onClick={() =>
                                createUserAddress(address, "SHIPPING")
                              }
                            >
                              Salveaza adresa de livrare
                            </button>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px"
                          }}
                          className="col-md-6 custom-spacing"
                        >
                          <input
                            style={{ margin: "0" }}
                            className="form-check-input"
                            type="checkbox"
                            id="sameAddress"
                            checked={shippingCheck}
                            onChange={() => setShippingCheck(!shippingCheck)}
                          />
                          <label
                            for="sameAddress"
                            style={{ padding: "0", margin: "0" }}
                          >
                            Foloseste aceeasi adresa pentru facturare
                          </label>
                        </div>
                      </div>
                      {!shippingCheck && (
                        <>
                          <div className="mt-50 mb-25">
                            <h4>Adresa de facturare</h4>
                          </div>
                          <form>
                            <div className="row">
                              <div className="form-group col-md-6">
                                <label htmlFor="checkout-first-name">
                                  Nume
                                </label>{" "}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="checkout-first-name"
                                  placeholder="Nume"
                                  name="first_name"
                                  value={billingAddress?.first_name}
                                  onChange={handleChangeBillingAddress}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="checkout-last-name">
                                  Prenume
                                </label>{" "}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="checkout-last-name"
                                  placeholder="Prenume"
                                  name="last_name"
                                  value={billingAddress?.last_name}
                                  onChange={handleChangeBillingAddress}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6">
                                <label htmlFor="checkout-first-name">
                                  Telephone
                                </label>{" "}
                                <input
                                  type="number"
                                  className="form-control"
                                  id="checkout-first-name"
                                  placeholder="Numar Telefon"
                                  name="phone_number"
                                  value={billingAddress?.phone_number}
                                  onChange={handleChangeBillingAddress}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="checkout-last-name">
                                  Email
                                </label>{" "}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="checkout-last-name"
                                  placeholder="Adresa Email"
                                  name="email"
                                  value={billingAddress?.email}
                                  onChange={handleChangeBillingAddress}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group">
                                <label htmlFor="checkout-street-address">
                                  Tara
                                </label>{" "}
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Tara dvs."
                                  name="country"
                                  value={billingAddress?.country}
                                  onChange={handleChangeBillingAddress}
                                />
                                <div className="form-group">
                                  <label htmlFor="checkout-street-address">
                                    Oras
                                  </label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-street-address"
                                    placeholder="Orasul dvs."
                                    name="city"
                                    value={billingAddress?.city}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-city">Street</label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-city"
                                    name="street_name"
                                    placeholder="Numele Strazii"
                                    value={billingAddress?.street_name}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-street-address">
                                    Numar Strada
                                  </label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-address"
                                    name="street_number"
                                    placeholder="Numarul Strazii"
                                    value={billingAddress?.street_number}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-street-address">
                                    Detalii adresa
                                  </label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-street-address"
                                    placeholder="Bloc / Scara / Apartament etc."
                                    name="street_details"
                                    value={billingAddress?.street_details}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-street-address">
                                    Cod Postal
                                  </label>{" "}
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Codul Postal"
                                    name="zip_code"
                                    value={billingAddress?.zip_code}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-company-name">
                                    Nume Firma
                                  </label>{" "}
                                  <input
                                    type="text"
                                    id="checkout-company-name"
                                    className="form-control"
                                    placeholder="Nume firma"
                                    name="company_name"
                                    value={billingAddress?.company_name}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-cui">CUI</label>{" "}
                                  <input
                                    id="checkout-cui"
                                    type="text"
                                    className="form-control"
                                    placeholder="Cui"
                                    name="cui"
                                    value={billingAddress?.cui}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="checkout-jcode">
                                    Numar inregistrare firma
                                  </label>{" "}
                                  <input
                                    id="checkout-jcode"
                                    type="text"
                                    className="form-control"
                                    placeholder="Codul J"
                                    name="jcode"
                                    value={billingAddress?.jcode}
                                    onChange={handleChangeBillingAddress}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="row">
                            {Object?.keys?.(billing_address)?.length <= 0 && (
                              <div className="col-md-6">
                                <button
                                  style={{ width: "100%" }}
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    createUserAddress(billingAddress, "BILLING")
                                  }
                                >
                                  Salveaza adresa de facturare
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}

                  <div className="col-md-6">
                    <div className="mb-25">
                      <h4>Detalii Comanda</h4>
                    </div>
                    <table className="table">
                      <tr>
                        <th>Produs</th>
                        <th>Pret</th>
                      </tr>
                      {carts?.map((cart) => {
                        const serviceDetails = returnServiceDataByCartId(
                          cart?.id
                        );
                  
                        return (
                          <>
                            <tr>
                              <td
                                style={{
                                  borderBottom:
                                    serviceDetails?.length > 0 ? "none" : null,
                                  borderRight:
                                    serviceDetails?.length > 0 ? "none" : null
                                }}
                              >
                                <span style={{ fontWeight: "600" }}>
                                  {cart?.product?.title}
                                </span>{" "}
                                x {cart?.quantity}
                              </td>
                              <td
                                style={{
                                  borderBottom:
                                    serviceDetails?.length > 0 ? "none" : null,
                                  borderLeft:
                                    serviceDetails?.length > 0 ? "none" : null,
                                  fontWeight: "600"
                                }}
                                className="text-center"
                              >
                                {cart?.visual_price} Ron
                              </td>
                            </tr>
                            {/* {serviceDetails?.length > 0 && (
                              <tr>
                                <td colSpan={2}>
                                  <strong>Servicii</strong>
                                </td>
                              </tr>
                            )} */}

                            <tr
                              style={{
                                borderTop: "none",
                                borderBottom: "none"
                              }}
                            >
                              <td
                                style={{
                                  borderTop: "none",
                                  borderBottom: "none",
                                  paddingTop: "0",
                                  paddingBottom: "0"
                                }}
                                colSpan="2"
                              >
                                <div
                                  style={{
                                    display: "grid",
                                    gap: "5px",
                                    width: "100%",
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                    padding: "0"
                                  }}
                                >
                                  {serviceDetails?.map((serviceDetail) => (
                                    <>
                                      {serviceDetail?.charge_including_vat >
                                      0 ? (
                                        <div>
                                          {/* <div>
                                        <i
                                          style={{
                                            color: "green",
                                            fontSize: ".7rem"
                                          }}
                                          className="fas fa-circle"
                                        ></i>
                                      </div> */}
                                          <div
                                            style={{ lineHeight: "normal" }}
                                            className="services-cart-checkout"
                                          >
                                            <div
                                              style={{
                                                fontStyle: "italic",
                                                fontWeight: "600",
                                                color: "gray"
                                              }}
                                            >
                                              {serviceDetail?.service_title}
                                            </div>
                                            <div
                                              style={{
                                                fontSize: ".8rem",
                                                color: "gray",
                                                fontStyle: "italic"
                                              }}
                                            >
                                              {
                                                serviceDetail?.charge_including_vat
                                              }{" "}
                                              Ron
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      <tr>
                        <td colSpan="2" style={{color: "#0275d8", fontWeight: "600"}}>Sumar Comanda</td>
                      </tr>
                      <tr>
                        <th c>Pret Produse</th>
                        <td className="product-subtotal text-center">
                          {summary?.total_products_amount} Ron
                        </td>
                      </tr>
                      {summary?.total_services_amount > 0 ? (
                        <tr>
                          <th>Pret Servicii</th>
                          <td className="text-center">
                            {summary?.total_services_amount} Ron
                          </td>
                        </tr>
                      ) : null}
                      {summary?.voucher_discount > 0 ? (
                        <tr>
                          <th>Discount</th>
                          <td className="text-center">
                            - {summary?.voucher_discount} Ron
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <th>Total</th>
                        <td className="product-subtotal text-center">
                          <span className="text-brand fw-900">
                            {summary?.total_price -
                              (summary?.voucher_discount || 0)}{" "}
                            Ron
                          </span>
                        </td>
                      </tr>
                    </table>
                    <div className="bt-1 border-color-1 mt-30 mb-30" />
                    <div className="payment_method">
                      <div className="mb-25">
                        <h5>Metoda de plata:</h5>
                      </div>
                      <div className="payment_option">
                        <div className="custome-radio">
                          <input
                            className="form-check-input"
                            required
                            type="radio"
                            name="payment_option"
                            id="exampleRadios3"
                            value="BANK_TRANSFER"
                            checked={paymentMethod === "BANK_TRANSFER"}
                            onChange={() => setPaymentMethod("BANK_TRANSFER")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios3"
                          >
                            Transfer Bancar
                          </label>
                        </div>
                        <div className="custome-radio">
                          <input
                            className="form-check-input"
                            required
                            type="radio"
                            name="payment_option"
                            id="exampleRadios4"
                            value="COD"
                            checked={paymentMethod === "COD"}
                            onChange={() => setPaymentMethod("COD")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios4"
                          >
                            Plata la ramburs
                          </label>
                        </div>
                        <div className="custome-radio">
                          <input
                            className="form-check-input"
                            required
                            type="radio"
                            name="payment_option"
                            id="exampleRadios5"
                            value="STRIPE"
                            checked={paymentMethod === "STRIPE"}
                            onChange={() => setPaymentMethod("STRIPE")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios5"
                          >
                            Plata prin card
                          </label>
                        </div>
                      </div>
                    </div>
                    <button
                      style={{ width: "100%" }}
                      className="btn btn-fill-out mt-30"
                      onClick={toggleModal}
                    >
                      Trimite Comanda
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={paymentMethod === "STRIPE" && stripeModal}
        toggle={() => toggleModal()}
        centered
      >
        <ModalHeader
          toggle={() => toggleModal()}
          // close={renderCloseBtn}
          tag="div"
        >
          <h5 className="modal-title">
            <span className="align-middle">Stripe</span>
          </h5>
        </ModalHeader>
        <ModalBody>
          <StripeElement
            stripeData={handleStripe}
            paymentLoading={paymentLoading || false}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ wishlist, product, auth }) => ({
  carts: wishlist.carts,
  summary: wishlist.summary,
  user: auth.user,
  paymentSuccessfully: wishlist.paymentSuccessfully,
  paymentLoading: wishlist.paymentLoading,
  serviceList: product.serviceList
});
export default connect(mapStateToProps, {
  getCarts,
  load_user,
  stripePaymentCheckStatus,
  getServiceList,
  createAddress,
  placeOrder
})(WishList);
