// User registration
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

// User authentication
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const AUTH_LOADING = "AUTH_LOADING";

// User email activation
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAIL = "ACTIVATION_FAIL";

// Reset password
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_CONFIRM_SUCCESS = "RESET_PASSWORD_CONFIRM_SUCCESS";
export const RESET_PASSWORD_CONFIRM_FAIL = "RESET_PASSWORD_CONFIRM_FAIL";

// Check if user is aithenticated
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";

// Load the user
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_ORDER_LIST = "USER_ORDER_LIST";
export const USER_ORDER_DETAILS = "USER_ORDER_DETAILS";

export const USER_LOADED_FAIL = "USER_LOADED_FAIL";

// Google authentication
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";

// Facebook authentication
export const FACEBOOK_AUTH_SUCCESS = "FACEBOOK_AUTH_SUCCESS";
export const FACEBOOK_AUTH_FAIL = "FACEBOOK_AUTH_FAIL";
// Address
export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST";
export const GET_ADDRESS_VIEW = "GET_ADDRESS_VIEW";

// About us
export const GET_ABOUT_US = "GET_ABOUT_US";
export const LOADING_ABOUT_US = "LOADING_ABOUT_US";

// Director Message
export const GET_DIRECTOR_MESSAGE = "GET_DIRECTOR_MESSAGE";
export const LOADING_DIRECTOR_MESSAGE = "LOADING_DIRECTOR_MESSAGE";

// About us contact
export const GET_ABOUT_US_CONTACT_LIST = "GET_ABOUT_US_CONTACT_LIST";
export const LOADING_ABOUT_US_CONTACT_LIST = "LOADING_ABOUT_US_CONTACT_LIST";

// About us team
export const GET_ABOUT_US_TEAM_LIST = "GET_ABOUT_US_TEAM_LIST";
export const LOADING_ABOUT_US_TEAM_LIST = "LOADING_ABOUT_US_TEAM_LIST";

// About us timeline
export const GET_ABOUT_US_LIST_TIMELINE = "GET_ABOUT_US_LIST_TIMELINE";
export const LOADING_ABOUT_US_LIST_TIMELINE = "LOADING_ABOUT_US_LIST_TIMELINE";

// Contact us
export const GET_CONTACT_DETAILS_LIST = "GET_CONTACT_DETAILS_LIST";

// export const GET_ABOUT_US_TEAM_LIST = "GET_ABOUT_US_TEAM_LIST";
// export const LOADING_ABOUT_US_TEAM_LIST = "LOADING_ABOUT_US_TEAM_LIST";

// FAQ

// Product
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_ULTIMELE_LIST = "GET_PRODUCT_ULTIMELE_LIST";

export const GET_PRODUCTS_SEARCH_LIST = "GET_PRODUCTS_SEARCH_LIST";

export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";

export const GET_PRODUCT_TYPE_LIST = "GET_PRODUCT_TYPE_LIST";
export const GET_PRODUCT_ATTRIBUTES_CONTENT_LIST =
  "GET_PRODUCT_ATTRIBUTES_CONTENT_LIST";

export const GET_PRODUCT_BRAND_LIST = "GET_PRODUCT_BRAND_LIST";

export const GET_PRODUCT_ATTRIBUTE_SPECIFICATION =
  "GET_PRODUCT_ATTRIBUTE_SPECIFICATION";

export const GET_PRODUCT_REVIEW_LIST = "GET_PRODUCT_REVIEW_LIST";

export const LOADING_PRODUCT = "LOADING_PRODUCT";
export const GET_PRODUCT_BESTSELLER_LIST = "GET_PRODUCT_BESTSELLER_LIST";
// Categories
export const GET_CATEGORIES = "GET_CATEGORIES";

// Question Bundle
export const GET_QUESTION_BUNDLE_READ = "GET_QUESTION_BUNDLE_READ";
export const GET_QUESTION_DETAIL = "GET_QUESTION_DETAIL";

// Voucher

// Hero Banner
export const LOADING_BANNER = "LOADING_BANNER";
export const HEROS_BANNER = "HEROS_BANNER";

// Feautured Banner
export const LOADING_FEAUTURED_BANNER = "LOADING_FEAUTURES_BANNER";
export const FEAUTURED_BANNER = "FEAUTURES_BANNER";

// Commercial Banner
export const LOADING_COMMERCIAL_BANNER = "LOADING_COMMERCIAL_BANNER";
export const COMMERCIAL_BANNER = "COMMERCIAL_BANNER";

// Partner Banner
export const LOADING_PARTNER_BANNER = "LOADING_PARTNER_BANNER";
export const PARTNER_BANNER = "PARTNER_BANNER";

// Wishlist
export const LOADING_WISHLIST = "ADD_IN_WISHLIST";
export const GET_WISHLIST = "GET_WISHLIST";

// Cart
export const LOADING_CART = "ADD_IN_CART";
export const GET_CARTS = "GET_CART";

export const LOADING_PAYMENT = "LOADING_PAYMENT";
export const PAYMENT_SUCCESSFULLY = "PAYMENT_SUCCESSFULLY";

// Blog
export const LOADING_BLOG_POSTS = "LOADING_BLOG_POSTS";
export const BLOG_POSTS = "BLOG_POSTS";
export const BLOG_READ_POST = "BLOG_READ_POST";

//Voucher
export const VERIFY_VOUCHER = "VERIFY_VOUCHER";

//FOOTER
export const LOADING_FOOTER = "LOADING_FOOTER";
export const FOOTER_LIST = "FOOTER_LIST";

//TERMS
export const LOADING_TERMS = "LOADING_TERMS";
export const TERMS_LIST = "TERMS_LIST";

//COOKIES
export const LOADING_COOKIES = "LOADING_COOKIES";
export const COOKIES_LIST = "COOKIES_LIST";

//PRIVACY
export const LOADING_PRIVACY = "LOADING_PRIVACY";
export const PRIVACY_LIST = "PRIVACY_LIST";

//RETURN
export const LOADING_RETURN = "LOADING_RETURN";
export const RETURN_LIST = "RETURN_LIST";

export const GET_SERVICE_LIST = "GET_SERVICE_LIST";

export const GET_FIRST_BANNER = "GET_FIRST_BANNER";
export const GET_TWO_BANNER = "GET_TWO_BANNER";

export const WEBSITE_INFO = "WEBSITE_INFO";

export const PRODUCT_COMPARE = "PRODUCT_COMPARE";

export const AUTHORIZED_CATEGORY_LIST = "AUTHORIZED_CATEGORY_LIST";
export const GET_AVAILABLE_DATE = "GET_AVAILABLE_DATE";

export const LOCAL_STORAGE_CART = "LOCAL_STORAGE_CART";
