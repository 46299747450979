import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getFooterList, subscribeNewsletter } from "../redux/actions/footer";
import PropTypes from "prop-types";
import logo from "../assets/imgs/theme/logo.svg";
import ReactHtmlParser from "react-html-parser";
import Complaints from "../components/ContactUs/sections/Complaints"
import Suggestions from "../components/ContactUs/sections/Sugestions"
import Feedback from "../components/ContactUs/sections/Feedback"
import { Link, useLocation } from "react-router-dom";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const Footer = ({ getFooterList, footerList, subscribeNewsletter }) => {
  useEffect(() => {
    getFooterList();
    
  }, []);

  return (
    <footer className="main">
      <section className="newsletter p-30 text-white wow fadeIn animated">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 mb-md-3 mb-lg-0">
              <div className="row align-items-center">
                <div className="col flex-horizontal-center">
                  <h4 className="font-size-20 mb-0 ml-3">
                    Aboneaza-te la newsletter !
                  </h4>
                </div>
                <div className="col my-4 my-md-0 des">
                  <h5 className="font-size-15 ml-4 mb-0">
                    ..te tinem la curent <strong>cu toate noutatile !</strong>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              {/* Subscribe Form */}
              <form className="form-subcriber d-flex wow fadeIn animated" onSubmit={(e)=>{
                e.preventDefault();
                subscribeNewsletter({ email: e.currentTarget.email.value });
                e.currentTarget.reset()
              }}>
                <input
                  type="email"
                  className="form-control bg-white font-small"
                  placeholder="Adresa dvs de email"
                  name="email"
                  required
                />
                <button className="btn bg-dark text-white" type="submit">
                  Subscribe
                </button>
              </form>
              {/* End Subscribe Form */}
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding footer-mid">
        <div className="container pt-15 pb-20">
          <div className="row">
            {Object.keys(footerList)?.map?.(
              (section, index) =>
                section === "Contact" && (
                  <div
                    className="col-lg-4 col-md-6"
                    key={`${section}-${index}`}
                  >
                    <div className="widget-about font-md mb-md-5 mb-lg-0">
                      <div className="logo logo-width-1 wow fadeIn animated">
                      <ScrollToTop />
                        <Link to="/">
                          <img src={logo} alt="LPV Logo" />
                        </Link>
                      </div>
                      {/* <h5 className="mt-20 mb-10 fw-600 text-grey-4 wow fadeIn animated">{section}</h5> */}
                      {footerList?.[section]?.items?.map?.(
                        (sectionContent, index) => (
                          <>
                            <p className="wow fadeIn animated">
                              <strong>Adresa: </strong>
                              {ReactHtmlParser(sectionContent.message)}
                            </p>
                            <br />
                            {ReactHtmlParser(sectionContent.address)}
                          </>
                        )
                      )}
                    </div>
                  </div>
                )
            )}
            {Object.keys(footerList)?.map?.(
              (section, index) =>
                index > 0 && (
                  <div className="col-lg-2 col-md-3">
                    <h5 className="widget-title wow fadeIn animated">
                      {section}
                    </h5>
                    <ul className="footer-list wow fadeIn animated mb-sm-5 mb-md-0">
                      {footerList?.[section]?.items?.map?.(
                        (sectionContent, index) => (
                          <li>
                            <Link to={sectionContent.url}>
                              {" "}
                              {sectionContent.display_link}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )
            )}
            <div className="col-lg-4">
              <h5 className="widget-title wow fadeIn animated">Social Media</h5>
              <div className="row">
                <div className="col-md-4 col-lg-12 mt-md-3 mt-lg-0">
                  <ul style={{ display: "flex", gap: "10px", padding: "0" }}>
                    <li className="footer-newsletter__social-link footer-newsletter__social-link--facebook">
                      <a
                        href="https://www.facebook.com/LPVserviceconsult/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          style={{ color: "#3c5a99", fontSize: "1.5rem" }}
                          className="fab fa-facebook-f"
                        />
                      </a>
                    </li>
                    <li
                      className="
                    footer-newsletter__social-link
                    footer-newsletter__social-link--youtube
                  "
                    >
                      <a
                        href="https://m.youtube.com/channel/UCpIIn6AFXQxQqPa-phh2_3Q/channels?cbrd=1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          style={{ color: "#e52e2e", fontSize: "1.5rem" }}
                          className="fab fa-youtube"
                        />
                      </a>
                    </li>
                    <li
                      className="
                    footer-newsletter__social-link
                    footer-newsletter__social-link--instagram
                  "
                    >
                      <a
                        href="https://www.instagram.com/lpvservice/?hl=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          style={{ color: "#815dc7", fontSize: "1.5rem" }}
                          className="fab fa-instagram"
                        />
                      </a>
                    </li>
                  </ul>
                  <hr />
                  <Complaints />
                  <br />
                  <Suggestions />
                  <br />
                  <Feedback />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container pb-20 wow fadeIn animated">
        <div className="row">
          <div className="col-12 mb-20">
            <div className="footer-bottom" />
          </div>
          <div className="col-lg-6">
            <p className="float-md-left font-sm text-muted mb-0">
              © 2021, <strong className="text-brand">LPV Service</strong> - All
              rights reserved
            </p>
          </div>
          <div className="col-lg-6">
            <p
              className="text-lg-end text-startghhghg
             font-sm text-muted mb-0"
            >
              Designed by{" "}
              <a href="/" target="_blank">
                Project Protocol
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  footerList: PropTypes.array.isRequired,
};

const mapStateToProps = ({ footer }) => ({
  footerList: footer.footerList,
});

export default connect(mapStateToProps, {
  getFooterList,
  subscribeNewsletter,
})(Footer);
