import {
  GET_ABOUT_US,
  LOADING_ABOUT_US,
  GET_DIRECTOR_MESSAGE,
  LOADING_DIRECTOR_MESSAGE,
  LOADING_ABOUT_US_CONTACT_LIST,
  GET_ABOUT_US_CONTACT_LIST,
  LOADING_ABOUT_US_TEAM_LIST,
  GET_ABOUT_US_TEAM_LIST,
  GET_CONTACT_DETAILS_LIST,
  GET_ABOUT_US_LIST_TIMELINE,
  LOADING_ABOUT_US_LIST_TIMELINE,
  WEBSITE_INFO,
  AUTHORIZED_CATEGORY_LIST,
} from "../types/types";

const initialState = {
  aboutUs: [],
  directorMessage: [],
  aboutUsContact: [],
  aboutTeamData: [],
  loading: false,
  contactDetails: [],
  aboutUsTimeline: [],
  websiteInfoDetails: {},
  authorizeCategoryList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_ABOUT_US:
      return { ...state, loading: action.payload || false };

    case LOADING_DIRECTOR_MESSAGE:
      return { ...state, loading: action.payload || false };

    case LOADING_ABOUT_US_CONTACT_LIST:
      return { ...state, loading: action.payload || false };

    case LOADING_ABOUT_US_TEAM_LIST:
      return { ...state, loading: action.payload || false };

    case LOADING_ABOUT_US_LIST_TIMELINE:
      return { ...state, loading: action.payload || false };

    case GET_ABOUT_US:
      return { ...state, aboutUs: action.payload };

    case GET_DIRECTOR_MESSAGE:
      return { ...state, directorMessage: action.payload };

    case GET_ABOUT_US_CONTACT_LIST:
      return { ...state, aboutUsContact: action.payload };

    case GET_ABOUT_US_TEAM_LIST:
      return { ...state, aboutTeamData: action.payload };

    case GET_CONTACT_DETAILS_LIST:
      return { ...state, contactDetails: action.payload };

    case GET_ABOUT_US_LIST_TIMELINE:
      return { ...state, aboutUsTimeline: action.payload };
    case WEBSITE_INFO:
      return { ...state, websiteInfoDetails: action.payload };
    case AUTHORIZED_CATEGORY_LIST:
      return { ...state, authorizeCategoryList: action.payload };
    default:
      return state;
  }
}
