import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactHtmlParser from "react-html-parser";

const TeamModalDescription = ({ teamMember }) => {
  const [modalTeam, setModalTeam] = useState(false);
  const toggleTeamDescription = () => setModalTeam(!modalTeam);

  return (
    <>
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          fontWeight: "500",
          textDecoration: "underline",
          cursor: "pointer"
        }}
        className="aboutTeamDescriptionButton"
        onClick={toggleTeamDescription}
      >
        Vezi detalii
      </span>

      <Modal isOpen={modalTeam} toggle={toggleTeamDescription}>
        <ModalHeader
          className="team-member-header"
          toggle={toggleTeamDescription}
        >
          <p style={{ fontWeight: "600", margin: "0" }}>{teamMember?.name}</p>
          <i onClick={toggleTeamDescription} className="fas fa-times"></i>
        </ModalHeader>
        <ModalBody>
          <img
            style={{
              width: "100%",
              height: "350px",
              objectFit: "contain"
            }}
            src={teamMember.image}
            alt={teamMember?.name}
          />
          <div className="teamMemberDescriptionText mt-20">
            {ReactHtmlParser(teamMember.description)}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default TeamModalDescription;
