import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import VerticalTimeline from "./VerticalTimeline";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AboutUsText = ({
  aboutUs,
  directorMessage,
  aboutUsContact,
  aboutUsTimeline
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const boxVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1
      }
    }
  };

  return (
    <>
      <section className="section-padding">
        <div className="container pt-25">
          <div className="row">
            <div className="col-lg-12 mb-lg-0 mb-4">
              <div className="mb-15" style={{ display: "flex", gap: "20px" }}>
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <i className="fas fa-phone-alt text-brand"></i>
                  <h6
                    style={{ margin: "0" }}
                    className="mt-0 font-sm text-brand wow fadeIn animated"
                  >
                    <a href="tel:0374491507">{aboutUsContact?.phone}</a>
                  </h6>
                </div>
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <i className="fas fa-envelope text-brand"></i>
                  <h6
                    style={{ margin: "0" }}
                    className="mt-0 font-sm text-brand wow fadeIn animated"
                  >
                    {aboutUsContact?.email}
                  </h6>
                </div>
              </div>
              <div className="col-lg-12">
                <img
                  src={directorMessage?.image}
                  alt={directorMessage?.title}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "300px",
                    borderRadius: "5px"
                  }}
                />
              </div>
              <h1 className="font-heading mb-40 mt-50">
                {ReactHtmlParser(aboutUs?.title)}
              </h1>
              <p>{ReactHtmlParser(aboutUs?.content)}</p>
            </div>
          </div>
        </div>
      </section>

      {/* <VerticalTimeline aboutUsTimeline={aboutUsTimeline} /> */}

      {/* <h1
        className="about-us__title custom-aboutus-title"
        style={{ marginTop: "50px" }}
      >
        {ReactHtmlParser(directorMessage?.title)}
      </h1>
      <div className="about-us__text typography about-us-custom-container row">
        <p className="col-lg-7">{ReactHtmlParser(directorMessage?.message)}</p>
        <div className="col-lg-5">
          <motion.img
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={boxVariants}
            style={{
              width: "100%",
              objectFit: "100%",
            }}
            className="custom-image-about-us-director"
            src={directorMessage?.image}
            alt="LPV Director General"
          ></motion.img>
        </div>
      </div> */}
    </>
  );
};

export default AboutUsText;
