import React, { useState } from "react";
import slugify from "react-slugify";

const MobileMenu = ({ category, index, categories }) => {
  const [showSubmenuMobile, setSubmenuMobile] = useState(false);

  const handleSubmenuMobileClick = () => {
    setSubmenuMobile(!showSubmenuMobile);
  };

  return (
    <li
      key={`${category}-${index}`}
      className="menu-item-has-children menu-mobile-li pt-3 pb-3"
    >
      <div
        onClick={handleSubmenuMobileClick}
        style={{ display: "flex", alignItems: "center" }}
      >
        <i className="far fa-chevron-down menu-expand"></i>
        <a
          className={
            category === "Climatizare"
              ? "anchor-active-1"
              : category === "Termice"
              ? "anchor-active-2"
              : category === "Servicii tehnice"
              ? "anchor-active-3"
              : null
          }
        >
          {category}
        </a>
      </div>
      <ul
        style={{ padding: "0" }}
        className={
          showSubmenuMobile ? "dropdown-mobile-active mt-3" : "dropdown-mobile"
        }
      >
        {Object.keys(categories?.[category]?.subcategories)?.map?.(
          (subCategories, index) => (
            <li
              //   className={
              //     category === "Climatizare"
              //       ? "background-active-1"
              //       : category === "Termice"
              //       ? "background-active-2"
              //       : category === "Servicii tehnice"
              //       ? "background-active-3"
              //       : null
              //   }
              style={{
                padding: "0",
                borderRadius: "5px",
                padding: "10px"
              }}
            >
              <p
                key={`${subCategories}-${index}`}
                className={
                  category === "Climatizare"
                    ? "anchor-active-11"
                    : category === "Termice"
                    ? "anchor-active-22"
                    : category === "Servicii tehnice"
                    ? "anchor-active-33"
                    : null
                }
                style={{
                  margin: "0",
                  fontWeight: "600"
                }}
              >
                {subCategories}
              </p>
              <ul>
                {categories?.[category]?.["subcategories"]?.[subCategories]?.[
                  "subsubcategories"
                ]?.map((subSubCategories) => (
                  <li
                    className="mobile-menu-subsub-list"
                    style={{
                      padding: "20px 0"
                    }}
                  >
                    <a
                      key={subSubCategories?.subsubcategory_id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                      href={`/categorie/${slugify(
                        subSubCategories?.subsubcategory_name
                      )}/${subSubCategories?.subsubcategory_id}`}
                    >
                      <span>{subSubCategories?.subsubcategory_name}</span>
                      <i
                        style={{ fontSize: ".7rem" }}
                        className="far fa-chevron-right"
                      ></i>
                    </a>
                  </li>
                ))}
                {categories?.[category]?.["subcategories"]?.[subCategories]?.[
                  "custom_products"
                ]?.map((customProduct) => (
                  <li
                    className="mobile-menu-subsub-list"
                    style={{
                      padding: "20px 0"
                    }}
                    key={customProduct?.id}
                  >
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                      href={`/product/${customProduct?.id}/${slugify(
                        customProduct?.title
                      )}`}
                    >
                      <span> {customProduct?.title}</span>
                      <i
                        style={{ fontSize: ".7rem" }}
                        className="far fa-chevron-right"
                      ></i>
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          )
        )}
      </ul>
    </li>
  );
};

export default MobileMenu;
