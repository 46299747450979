import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import CartTable from "./sections/CartTable";
import CartSummary from "./sections/CartSummary";
import { getCartProduct, getTotal, getTotalApi } from "../../helper/cart";
import { getCarts, voucherVerify } from "../../redux/actions/wishlist";
import { load_user } from "../../redux/actions/auth";
import { Link } from "react-router-dom";

const Cart = ({
  getCarts,
  carts,
  voucherVerify,
  voucher,
  handleSubmit,
  summary,
  load_user,
  user,
}) => {
  const [inCartProduct, setInCartProduct] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("access")) {
      getCarts();
      load_user();
    } else {
      const cartProduct = getCartProduct();
      setInCartProduct(cartProduct?.products);
      const totalValue = getTotal(cartProduct?.products);
      setTotalValue(totalValue);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access")) {
      setInCartProduct(carts);
      const totalValue = getTotalApi(carts);
      setTotalValue(totalValue);
    }
  }, [carts]);

  const handleVoucher = (data) => {
    const code = data.code;
    voucherVerify(code);
  };

  return (
    <>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                LPV
              </Link>
              <span /> Magazin
              <span /> Cosul Tau
            </div>
          </div>
        </div>
        <section className="mt-50 mb-50">
          <div className="container">
            <div className="row">
              {inCartProduct?.length > 0 ? (
                <div className="col-12">
                  <div className="table-responsive custom-table-cart">
                    <CartTable carts={inCartProduct} />
                  </div>
                  {user && (
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="mb-30">
                          <div className="heading_s1 mb-3">
                            <h4>Ai un voucher ?</h4>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <form
                                style={{ display: "flex", gap: "15px" }}
                                className="cart__coupon-form"
                                onSubmit={handleSubmit(handleVoucher)}
                              >
                                <Field
                                  required
                                  component="input"
                                  type="text"
                                  id="input-coupon-code"
                                  className="form-control"
                                  placeholder="Cod cupon"
                                  name="code"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-secondary"
                                >
                                  Aplica
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="divider center_icon mt-25 mb-25">
                    <i
                      style={{ color: "#e2e9e1", fontSize: "1.5rem" }}
                      className="far fa-info-circle"
                    ></i>
                  </div>
                  <div className="row mb-10">
                    <div className="col-lg-6 col-md-12"></div>
                    <div className="col-lg-6 col-md-12">
                      <CartSummary
                        totalValue={totalValue}
                        voucher={voucher}
                        summary={summary}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="col-12 m-auto"
                  style={{ "text-align": "center" }}
                >
                  <p className="font-lg text-grey-700 mb-30">
                    Nu ai nici un produs in cosul de cumparaturi
                  </p>

                  <a
                    className="btn btn-default submit-auto-width font-xs hover-up"
                    href="/"
                  >
                    <i className="fas fa-store-alt"></i> Inapoi la prima pagina
                  </a>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

Cart.propTypes = {
  carts: PropTypes.array.isRequired,
};

const mapStateToProps = ({ wishlist, product, auth }) => ({
  carts: wishlist.carts,
  voucher: wishlist.voucher,
  summary: wishlist.summary,
  user: auth.user,
});
export default connect(mapStateToProps, {
  getCarts,
  voucherVerify,
  load_user,
})(
  reduxForm({
    form: "contact_us_form",
  })(Cart)
);
