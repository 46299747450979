import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const BlogEntry = ({ blogPosts }) => {
  return (
    <>
      {blogPosts?.map?.(
        (post, index) =>
          index > 0 && (
            <div className="col-lg-6">
              <article className="wow fadeIn animated hover-up mb-30">
                <div className="post-thumb img-hover-scale">
                  <Link to={`/blog/${post.id}`}>
                    <img src={post.image} alt={post.title} />
                  </Link>
                  <div className="entry-meta">
                    <p
                      className="entry-meta meta-2"
                      style={{
                        backgroundColor: "#0275d8",
                        padding: "5px 15px",
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: ".7rem"
                      }}
                    >
                      {post.author}
                    </p>
                  </div>
                </div>
                <div className="entry-content-2">
                  <br />
                  <h3 className="post-title mb-15">
                    <Link className="text-limit-2" to={`/blog/${post.id}`}>
                      {post.title}
                    </Link>
                  </h3>
                  <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                    <div>
                      <span className="post-on">
                        {" "}
                        <i className="fi-rs-clock" />
                        <Moment format="DD.MM.YYYY">{post.created_date}</Moment>
                      </span>
                    </div>
                    <Link to={`/blog/${post.id}`} className="text-brand">
                      Citeste articolul <i className="fi-rs-arrow-right" />
                    </Link>
                  </div>
                </div>
              </article>
            </div>
          )
      )}
    </>
  );
};

export default BlogEntry;
