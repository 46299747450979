import { BLOG_POSTS, LOADING_BLOG_POSTS,BLOG_READ_POST } from "../types/types";

const initialState = {
  blogPosts: [],
  loading: false,
  blogPost:{}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_BLOG_POSTS:
      return { ...state, loading: action.payload || false };
    case BLOG_POSTS:
      return { ...state, blogPosts: action.payload };
      case BLOG_READ_POST:
        return { ...state, blogPost: action.payload };
    default:
      return state;
  }
}
