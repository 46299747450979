import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getReadBlogPost, getBlogPosts } from "../../redux/actions/blog";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const BlogPage = ({ match, blogPost, getReadBlogPost, getBlogPosts }) => {
  useEffect(() => {
    getReadBlogPost(match?.params?.id);
  }, [match?.params?.id]);

  useEffect(() => {
    getBlogPosts();
  }, []);

  return (
    <>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              LPV
            </Link>
            <span>Blog</span>
            <span>{blogPost?.title}</span>
          </div>
        </div>
      </div>
      <section className="mt-50 mb-50">
        <div className="container custom">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="row"
          >
            <div className="col-lg-10 m-auto">
              <div className="single-page pl-30">
                <div className="single-header style-2">
                  <motion.h1
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    className="mb-30"
                  >
                    {blogPost?.title}
                  </motion.h1>
                  {/* <div className="single-header-meta">
                    <div className="entry-meta meta-1 font-xs mt-15 mb-15">
                      <span className="post-by">
                        Scris de {blogPost?.author}
                      </span>
                      <span className="post-on has-dot">
                        {moment(blogPost?.modified_date).format("DD.MM.YYYY")}
                      </span>
                      <span className="time-reading has-dot">8 mins read</span>
                      <span className="hit-count  has-dot">29k Views</span>
                    </div>
                    <div className="social-icons single-share">
                      <ul className="text-grey-5 d-inline-block">
                        <li>
                          <strong className="mr-10">Share this:</strong>
                        </li>
                        <li className="social-facebook">
                          <a href="#">
                            <img
                              src="assets/imgs/theme/icons/icon-facebook.svg"
                              alt
                            />
                          </a>
                        </li>
                        <li className="social-twitter">
                          {" "}
                          <a href="#">
                            <img
                              src="assets/imgs/theme/icons/icon-twitter.svg"
                              alt
                            />
                          </a>
                        </li>
                        <li className="social-instagram">
                          <a href="#">
                            <img
                              src="assets/imgs/theme/icons/icon-instagram.svg"
                              alt
                            />
                          </a>
                        </li>
                        <li className="social-linkedin">
                          <a href="#">
                            <img
                              src="assets/imgs/theme/icons/icon-pinterest.svg"
                              alt
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                 */}
                  <figure className="single-thumbnail">
                    <img
                      style={{ width: "100%" }}
                      src={blogPost?.image}
                      alt={blogPost.title}
                    />
                  </figure>
                </div>
                <motion.div
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  className="single-content"
                >
                  {ReactHtmlParser(blogPost?.content)}
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({ blog }) => ({
  blogPost: blog.blogPost,
  blogPosts: blog.blogPosts?.data || []
});

export default connect(mapStateToProps, {
  getReadBlogPost,
  getBlogPosts
})(BlogPage);
