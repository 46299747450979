import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { load_user } from "../../../redux/actions/auth";

const CartSummary = ({ user, totalValue, voucher, summary }) => {
  const history = useHistory();
  const handleCheckout = () => {
    if (localStorage.getItem("access")) {
      return history.push("/checkout");
    } else {
      return history.push("/log_in");
    }
  };

  return (
    <>
      <div>
        <table className="table">
          <tbody>
            {summary?.total_products_amount > 0 && (
              <tr>
                <td colSpan="2" style={{ border: "none" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ fontWeight: "700", fontSize: "1rem" }}>
                      Valoare Produse:
                    </span>{" "}
                    <span style={{ fontWeight: "600", fontSize: "1rem" }}>
                      {summary?.total_products_amount} RON
                    </span>
                  </div>
                </td>
              </tr>
            )}
            {summary?.total_services_amount > 0 && (
              <tr>
                <td colSpan="2" style={{ border: "none" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ fontWeight: "700", fontSize: "1rem" }}>
                      Valoare Servicii:
                    </span>{" "}
                    <span style={{ fontWeight: "600", fontSize: "1rem" }}>
                      {summary?.total_services_amount} RON
                    </span>
                  </div>
                </td>
              </tr>
            )}
            {summary?.voucher_discount > 0 && (
              <tr>
                <td colSpan="2" style={{ border: "none" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        fontWeight: "700",
                        fontSize: ".8rem",
                        color: "darkgreen",
                      }}
                    >
                      Valoare Discount:
                    </span>{" "}
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: ".8rem",
                        color: "darkgreen",
                      }}
                    >
                      -{summary?.voucher_discount} RON
                    </span>
                  </div>
                </td>
              </tr>
            )}

            <tr>
              <td colSpan="2" style={{ border: "none" }}>
                <hr />
                {summary?.voucher_discount > 0 && (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ fontWeight: "900", fontSize: ".9rem" }}>
                      Valoare fara discount
                    </span>{" "}
                    <span
                      style={{
                        fontWeight: "900",
                        fontSize: ".9rem",
                        textDecoration: "line-through",
                      }}
                    >
                      {summary?.total_price} RON
                    </span>
                  </div>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontWeight: "900", fontSize: "1.1rem" }}>
                    Valoare totala:
                  </span>{" "}
                  <span
                    style={{
                      fontWeight: "900",
                      fontSize: "1.1rem",
                      color: "#0275d8",
                    }}
                  >
                    {(summary?.total_price || totalValue?.totalAmount) -
                      summary?.voucher_discount || totalValue?.amount}{" "}
                    RON
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <a onClick={handleCheckout} className="btn mt-2 custom-button-checkout">
        {" "}
        <i className="fi-rs-box-alt mr-10" /> Catre checkout
      </a>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});
export default connect(mapStateToProps, {})(CartSummary);
