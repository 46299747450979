import React, { useEffect } from "react";
import WishListTable from "./sections/WishListTable";
import { connect } from "react-redux";
import { getWishlist } from "../../redux/actions/wishlist";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const WishList = ({ getWishlist, wishlists }) => {
  useEffect(() => {
    getWishlist();
  }, []);

  return (
    <>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              LPV
            </Link>
            <span></span> Wishlist
          </div>
        </div>
      </div>
      {wishlists.length > 0 ? (
        <WishListTable wishlists={wishlists} />
      ) : (
        <div className="container">
          <div className="row align-items-center height-50vh text-center mb-100">
            <div className="col-lg-8 m-auto">
              <p className="font-lg text-grey-700 mb-30">
                Nu ai nici un produs in lisa de dorinte
              </p>

              <a
                className="btn btn-default submit-auto-width font-xs hover-up "
                href="/"
              >
                <i className="fas fa-store-alt"></i> Inapoi la prima pagina
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

WishList.propTypes = {
  wishlists: PropTypes.array.isRequired,
};

const mapStateToProps = ({ wishlist }) => ({
  wishlists: wishlist.wishlists,
});

export default connect(mapStateToProps, {
  getWishlist,
})(WishList);
