import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { getOrderDetails } from "../../../redux/actions/auth";
import UserMenuSideNav from "./UserMenuSideNav";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import slug from "slug";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, Spinner, Alert } from "reactstrap";
import ReactStars from "react-rating-stars-component";
import { createProductReview } from "../../../redux/actions/products";
import { BulletList } from "react-content-loader";

const OrderDetails = ({
  match,
  orderDetails,
  getOrderDetails,
  createProductReview,
}) => {
  const id = match.params.id;
  const [modal, setModal] = useState(false);
  const [review, setReview] = useState({});
  const toggle = () => setModal(!modal);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const toggleShowReviewModal = () => setShowReviewModal(!showReviewModal);
  const [orderDetailsData, setOrderDetailsData] = useState([]);
  const [productAndOrderId, setProductAndOrderId] = useState({
    productId: "",
    orderId: "",
  });
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getOrderDetails(id);
  }, [id]);
  useEffect(() => {
    setOrderDetailsData(orderDetails);
  }, [orderDetails]);
  const ratingChanged = (newRating) => {
    setReview({
      ...review,
      stars: newRating,
    });
  };
  const handleReviewSubmit = () => {
    if (!review.stars || !review.content) {
      return setHasError(true);
    }
    setHasError(false);
    setLoading(true);
    createProductReview({
      ...review,
      order_product_id: productAndOrderId.orderId,
      product: productAndOrderId.productId,
    }).then((res) => {
      setLoading(false);
      if (res.success) {
        getOrderDetails(id);
        setReview({});
        toggle();
      }
    });
  };
  return (
    <>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                LPV
              </Link>
              <span>Contul Meu</span>
              <span>Detalii Comanda</span>
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row mt-40 mb-40">
              <div className="col-lg-3">
                <UserMenuSideNav />
              </div>
              <div className="col-lg-9">
                <div className="row mt-4 mt-lg-0">
                  <div className="col-lg-12">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                      }}
                    >
                      <h5 style={{ margin: "0" }}>
                        Comanda cu numarul #{orderDetailsData?.id}
                      </h5>
                      <p className="mt-3" style={{ lineHeight: "2" }}>
                        Plasata in data de{" "}
                        <mark
                          className={
                            orderDetailsData?.status === "PENDING"
                              ? "my-account-pending-details"
                              : orderDetailsData?.status === "CANCELLED"
                              ? "my-account-order-cancelled-details"
                              : orderDetailsData?.status === "PROCESSING"
                              ? "my-account-order-processing-details"
                              : orderDetailsData?.status === "SHIPPED"
                              ? "my-account-order-in-tranzit-details"
                              : orderDetailsData?.status === "DELIVERED"
                              ? "my-account-order-completed-details"
                              : null
                          }
                        >
                          {moment(orderDetailsData?.ordered_at).format(
                            "DD.MM.YYYY"
                          )}
                        </mark>{" "}
                        are statusul de{" "}
                        <mark
                          className={
                            orderDetailsData?.status === "PENDING"
                              ? "my-account-pending-details"
                              : orderDetailsData?.status === "CANCELLED"
                              ? "my-account-order-cancelled-details"
                              : orderDetailsData?.status === "PROCESSING"
                              ? "my-account-order-processing-details"
                              : orderDetailsData?.status === "SHIPPED"
                              ? "my-account-order-in-tranzit-details"
                              : orderDetailsData?.status === "DELIVERED"
                              ? "my-account-order-completed-details"
                              : null
                          }
                        >
                          {" "}
                          {orderDetailsData?.status === "PENDING"
                            ? "Comanda Noua"
                            : orderDetailsData?.status === "CANCELLED"
                            ? "Comanda Anulata"
                            : orderDetailsData?.status === "PROCESSING"
                            ? "Comanda Preluata"
                            : orderDetailsData?.status === "SHIPPED"
                            ? "Comanda In Tranzit"
                            : orderDetailsData?.status === "DELIVERED"
                            ? "Comanda Livrata"
                            : null}
                        </mark>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 custom-table-profile-desktop">
                  <div className="col-lg-12">
                    {loading && !orderDetailsData.products && (
                      <div>
                        <BulletList
                          speed={1}
                          height={220}
                          viewBox="0 0 476 124"
                          backgroundColor="#c2c2c2"
                          foregroundColor="#938a8a"
                        />
                      </div>
                    )}
                    {!loading && orderDetailsData && orderDetailsData.products && (
                      <table>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Produs</th>
                            <th>Cantitate</th>
                            {orderDetailsData.is_delivered && (
                              <th>Revizuire</th>
                            )}

                            <th>Pret Buc</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderDetailsData?.products?.map((product, index) => (
                            <>
                              <tr
                                className={
                                  orderDetailsData?.status === "PENDING"
                                    ? "my-account-td-pending"
                                    : orderDetailsData?.status === "CANCELLED"
                                    ? "my-account-td-order-cancelled"
                                    : orderDetailsData?.status === "PROCESSING"
                                    ? "my-account-td-order-processing"
                                    : orderDetailsData?.status === "SHIPPED"
                                    ? "my-account-td-order-in-tranzit"
                                    : orderDetailsData?.status === "DELIVERED"
                                    ? "my-account-td-order-completed"
                                    : null
                                }
                                key={index}
                              >
                                <td>
                                  <a
                                    className="table-anchor"
                                    href={`/product/${
                                      product?.product?.id
                                    }/${slug(
                                      product?.title || "product",
                                      "_"
                                    )}`}
                                  >
                                    <span className="my-account-in-tranzit">
                                      {index + 1}
                                    </span>
                                  </a>
                                </td>
                                <td className="w-50">
                                  <div className="d-flex">
                                    <img
                                      src={product?.product?.images[0]?.image}
                                      className="product-image "
                                    />
                                    <a
                                      className="ml-2"
                                      href={`/product/${
                                        product?.product?.id
                                      }/${slug(
                                        product?.title || "product",
                                        "_"
                                      )}`}
                                    >
                                      {product?.product?.title}
                                    </a>
                                  </div>
                                </td>
                                <td>{product?.quantity}</td>
                                {orderDetailsData.is_delivered && (
                                  <td>
                                    {product.review ? (
                                      <>
                                        <ReactStars
                                          size={16}
                                          emptyIcon={
                                            <i className="far fa-star"></i>
                                          }
                                          fullIcon={
                                            <i className="fa fa-star"></i>
                                          }
                                          color2="#ffd700"
                                          color1="yellow"
                                          edit={false}
                                          value={product?.review?.stars}
                                        />
                                        <p className="text-left">
                                          {product?.review?.content.substring(
                                            0,
                                            100
                                          )}{" "}
                                          {product?.review?.content.length >=
                                            20 && (
                                            <>
                                              ...{" "}
                                              <span
                                                role="button"
                                                className="text-primary"
                                                onClick={toggleShowReviewModal}
                                              >
                                                read more
                                              </span>
                                              <Modal
                                                isOpen={showReviewModal}
                                                toggle={toggleShowReviewModal}
                                              >
                                                <ModalBody>
                                                  {product?.review?.content}
                                                </ModalBody>
                                              </Modal>
                                            </>
                                          )}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          color="primary"
                                          onClick={() => {
                                            toggle();
                                            setProductAndOrderId({
                                              productId: product?.product?.id,
                                              orderId: product?.id,
                                            });
                                          }}
                                          size="sm"
                                        >
                                          Add review
                                        </Button>
                                        <Modal isOpen={modal} toggle={toggle}>
                                          <ModalBody>
                                            <div className="comment-form">
                                              <h4 className="mb-15">
                                                Adauga o recenzie
                                              </h4>
                                              <ReactStars
                                                size={30}
                                                emptyIcon={
                                                  <i className="far fa-star"></i>
                                                }
                                                fullIcon={
                                                  <i className="fa fa-star"></i>
                                                }
                                                color2="#ffd700"
                                                color1="yellow"
                                                onChange={ratingChanged}
                                                edit={true}
                                                value={review?.stars}
                                              />{" "}
                                              <div className="row mt-15">
                                                <div className="col-lg-12 col-md-12">
                                                  <form
                                                    className="form-contact comment_form"
                                                    action="#"
                                                    id="commentForm"
                                                  >
                                                    <div className="row">
                                                      <div className="col-12">
                                                        <div className="form-group">
                                                          <textarea
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            className="form-control w-100"
                                                            name="comment"
                                                            id="comment"
                                                            rows={9}
                                                            value={
                                                              review?.content
                                                            }
                                                            placeholder="Scrie-ti parerea"
                                                            onChange={(e) => {
                                                              setReview({
                                                                ...review,
                                                                content:
                                                                  e.target
                                                                    .value,
                                                              });
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {hasError && (
                                                      <Alert color="danger">
                                                        Please fill all fields!
                                                      </Alert>
                                                    )}
                                                    <div className="form-group">
                                                      <Button
                                                        color="primary"
                                                        onClick={(evt) => {
                                                          evt.preventDefault();
                                                          handleReviewSubmit();
                                                        }}
                                                        size="sm"
                                                        disabled={loading}
                                                      >
                                                        {loading ? (
                                                          <Spinner size="sm">
                                                            Loading...
                                                          </Spinner>
                                                        ) : (
                                                          <span>
                                                            {" "}
                                                            Posteaza recenzie
                                                          </span>
                                                        )}
                                                      </Button>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          </ModalBody>
                                        </Modal>
                                      </>
                                    )}
                                  </td>
                                )}

                                <td className="my-account-in-tranzit">
                                  {product?.product?.price} RON
                                </td>
                              </tr>
                              <tr
                                className={
                                  orderDetailsData?.status === "PENDING"
                                    ? "my-account-td-pending"
                                    : orderDetailsData?.status === "CANCELLED"
                                    ? "my-account-td-order-cancelled"
                                    : orderDetailsData?.status === "PROCESSING"
                                    ? "my-account-td-order-processing"
                                    : orderDetailsData?.status === "SHIPPED"
                                    ? "my-account-td-order-in-tranzit"
                                    : orderDetailsData?.status === "DELIVERED"
                                    ? "my-account-td-order-completed"
                                    : null
                                }
                              >
                                {product?.services?.length > 0 ? (
                                  <td colSpan="4">
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "15px",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {product?.services?.map(
                                        (service, index) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "10px",
                                              alignItems: "center",
                                              lineHeight: "normal",
                                              width: "31%",
                                            }}
                                          >
                                            <div style={{ fontSize: "1.3rem" }}>
                                              {ReactHtmlParser(service?.icon)}
                                            </div>
                                            <div>
                                              <div
                                                className="text-limit-2"
                                                style={{ fontWeight: "600" }}
                                                data-tip
                                                data-for={
                                                  service?.service_title
                                                }
                                              >
                                                {service?.service_title}
                                              </div>
                                              <ReactTooltip
                                                id={service?.service_title}
                                                type="dark"
                                              >
                                                <span
                                                  style={{ cursor: "help" }}
                                                >
                                                  {service?.service_title}
                                                </span>
                                              </ReactTooltip>
                                              <div
                                                style={{ fontSize: ".7rem" }}
                                              >
                                                {service?.service_title ===
                                                  "Metal" ||
                                                service?.service_title ===
                                                  "Pex" ||
                                                service?.service_title ===
                                                  "Pexal" ||
                                                service?.service_title ===
                                                  "PPR" ||
                                                service?.service_title ===
                                                  "Cupru" ? (
                                                  <p
                                                    style={{
                                                      color: "orange",
                                                      fontSize: ".9rem",
                                                      fontStyle: "italic",
                                                    }}
                                                  >
                                                    Informatie - Instalatie
                                                    existenta
                                                  </p>
                                                ) : service?.charge_including_vat >
                                                  0 ? (
                                                  <p>
                                                    {
                                                      service?.charge_including_vat
                                                    }{" "}
                                                    RON
                                                  </p>
                                                ) : (
                                                  <span
                                                    style={{
                                                      fontStyle: "italic",
                                                    }}
                                                  >
                                                    Urmeaza sa fiti contactat
                                                    dupa plasarea comenzii
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                            </>
                          ))}
                          <tr>
                            <td colSpan="6">
                              <div
                                style={{
                                  padding: "5px 0",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {orderDetailsData?.invoice_details
                                  ?.pdf_document ? (
                                  <a
                                    href={
                                      orderDetailsData?.invoice_details
                                        ?.pdf_document
                                    }
                                    style={{
                                      border: "1px solid blue",
                                      padding: "5px 20px",
                                      borderRadisu: "5px",
                                      marginLeft: "15px",
                                      color: "blue",
                                    }}
                                  >
                                    {
                                      orderDetailsData?.invoice_details
                                        ?.invoice_no
                                    }
                                  </a>
                                ) : null}

                                <h5 style={{ margin: "0" }}>
                                  Valoare Totala:{" "}
                                  <span style={{ color: "#0275d8" }}>
                                    {orderDetailsData?.total_amount}
                                  </span>{" "}
                                  Ron
                                </h5>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div className="row mt-4 custom-table-profile-mobile">
                  <div className="col-lg-12">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Produs</th>
                          <th>Pret Buc</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetailsData?.products?.map((product, index) => (
                          <>
                            <tr
                              className={
                                orderDetailsData?.status === "PENDING"
                                  ? "my-account-td-pending"
                                  : orderDetailsData?.status === "CANCELLED"
                                  ? "my-account-td-order-cancelled"
                                  : orderDetailsData?.status === "PROCESSING"
                                  ? "my-account-td-order-processing"
                                  : orderDetailsData?.status === "SHIPPED"
                                  ? "my-account-td-order-in-tranzit"
                                  : orderDetailsData?.status === "DELIVERED"
                                  ? "my-account-td-order-completed"
                                  : null
                              }
                            >
                              <td>
                                <a
                                  className="table-anchor"
                                  href={`/product/${
                                    product?.product?.id
                                  }/${slug(product?.title || "product", "_")}`}
                                >
                                  <span className="my-account-in-tranzit">
                                    {index + 1}
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a
                                  href={`/product/${
                                    product?.product?.id
                                  }/${slug(product?.title || "product", "_")}`}
                                >
                                  {product?.product?.title} x{" "}
                                  {product?.quantity}
                                </a>
                              </td>
                              <td className="my-account-in-tranzit">
                                {product?.product?.price} RON
                              </td>
                            </tr>
                            <tr
                              className={
                                orderDetailsData?.status === "PENDING"
                                  ? "my-account-td-pending"
                                  : orderDetailsData?.status === "CANCELLED"
                                  ? "my-account-td-order-cancelled"
                                  : orderDetailsData?.status === "PROCESSING"
                                  ? "my-account-td-order-processing"
                                  : orderDetailsData?.status === "SHIPPED"
                                  ? "my-account-td-order-in-tranzit"
                                  : orderDetailsData?.status === "DELIVERED"
                                  ? "my-account-td-order-completed"
                                  : null
                              }
                            >
                              {orderDetailsData?.services.length === 0 ? (
                                <td colSpan="4">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "15px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {product?.services?.map(
                                      (service, index) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            lineHeight: "normal",
                                            width: "100%",
                                          }}
                                        >
                                          <div style={{ fontSize: "1.3rem" }}>
                                            {ReactHtmlParser(service?.icon)}
                                          </div>
                                          <div>
                                            <div
                                              className="text-limit-2"
                                              style={{ fontWeight: "600" }}
                                              data-tip
                                              data-for={service?.service_title}
                                            >
                                              {service?.service_title}
                                            </div>
                                            <ReactTooltip
                                              id={service?.service_title}
                                              type="dark"
                                            >
                                              <span style={{ cursor: "help" }}>
                                                {service?.service_title}
                                              </span>
                                            </ReactTooltip>
                                            <div style={{ fontSize: ".7rem" }}>
                                              {service?.service_title ===
                                                "Metal" ||
                                              service?.service_title ===
                                                "Pex" ||
                                              service?.service_title ===
                                                "Pexal" ||
                                              service?.service_title ===
                                                "PPR" ||
                                              service?.service_title ===
                                                "Cupru" ? (
                                                <p
                                                  style={{
                                                    color: "orange",
                                                    fontSize: ".9rem",
                                                    fontStyle: "italic",
                                                  }}
                                                >
                                                  Informatie - Instalatie
                                                  existenta
                                                </p>
                                              ) : service?.charge_including_vat >
                                                0 ? (
                                                <p>
                                                  {
                                                    service?.charge_including_vat
                                                  }{" "}
                                                  RON
                                                </p>
                                              ) : (
                                                <span
                                                  style={{
                                                    fontStyle: "italic",
                                                  }}
                                                >
                                                  Urmeaza sa fiti contactat dupa
                                                  plasarea comenzii
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </td>
                              ) : null}
                            </tr>
                          </>
                        ))}
                        <tr>
                          <td colSpan="6">
                            <div
                              style={{
                                padding: "5px 0",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                justifyContent: "center",
                              }}
                            >
                              <h5 style={{ margin: "0" }}>
                                Valoare Totala:{" "}
                                <span style={{ color: "#0275d8" }}>
                                  {orderDetailsData?.total_amount}
                                </span>{" "}
                                Ron
                              </h5>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-lg-6 mt-4 mt-lg-0">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                        height: "100%",
                      }}
                    >
                      <h5
                        className=" text-center"
                        style={{ marginBottom: "20px", color: "#0275d8" }}
                      >
                        Adresa de Livrare
                      </h5>
                      <hr />
                      <h5 className="address-profile-name mt-4">{`${orderDetailsData?.shipping_address?.first_name} ${orderDetailsData?.shipping_address?.last_name}`}</h5>
                      <div className="address-profile-info">
                        {orderDetailsData?.shipping_address?.country},{" "}
                        {orderDetailsData?.shipping_address?.city}
                        <br />
                        {orderDetailsData?.shipping_address?.street_name},{" "}
                        {orderDetailsData?.shipping_address?.street_name}
                        <br />
                        {orderDetailsData?.shipping_address?.street_details}
                        <br />
                        Cod postal:{" "}
                        {orderDetailsData?.shipping_address?.zip_code}
                      </div>
                      <div className="address-profile-contact mt-3">
                        <h5>Telefon</h5>
                        <div>
                          {orderDetailsData?.shipping_address?.phone_number}
                        </div>
                      </div>
                      <div className="address-profile-contact mt-3">
                        <h5>Adresa Email</h5>
                        <div>{orderDetailsData?.shipping_address?.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4 mt-lg-0">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                        height: "100%",
                      }}
                    >
                      <h5
                        className=" text-center"
                        style={{ marginBottom: "20px", color: "#0275d8" }}
                      >
                        Adresa de Facturare
                      </h5>
                      <hr />
                      <h5 className="address-profile-name mt-4">{`${orderDetailsData?.billing_address?.first_name} ${orderDetailsData?.billing_address?.last_name}`}</h5>
                      <div className="address-profile-info">
                        {orderDetailsData?.billing_address?.country},{" "}
                        {orderDetailsData?.billing_address?.city}
                        <br />
                        {orderDetailsData?.billing_address?.street_name},{" "}
                        {orderDetailsData?.billing_address?.street_name}
                        <br />
                        {orderDetailsData?.billing_address?.street_details}
                        <br />
                        Cod postal:{" "}
                        {orderDetailsData?.billing_address?.zip_code}
                      </div>
                      <div className="address-profile-contact mt-3">
                        <h5>Telefon</h5>
                        <div>
                          {orderDetailsData?.billing_address?.phone_number}
                        </div>
                      </div>
                      <div className="address-profile-contact mt-3">
                        <h5>Adresa Email</h5>
                        <div>{orderDetailsData?.billing_address?.email}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  orderDetails: auth.orderDetails,
});
export default connect(mapStateToProps, {
  getOrderDetails,
  createProductReview,
})(OrderDetails);
