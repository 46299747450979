import React, { useState, useRef, useEffect } from "react";
import logo from "../../../assets/imgs/theme/logo.svg";
import { getProducts, clearSearch } from "../../../redux/actions/products";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useDebouncedCallback } from "use-debounce";
import slug from "slug";
import { Link, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
  getCarts,
  getWishlist,
  deleteCart,
  getLocalStorageCart,
} from "../../../redux/actions/wishlist";

import {
  getCartProduct,
  getTotal,
  getTotalApi,
  DeleteCart,
} from "../../../helper/cart";
import { Button } from "reactstrap";

function useOutsideAlerter(ref, searchResultRef) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        searchResultRef.current.style.display = "none";
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const MiddleBar = ({
  getProducts,
  searchProducts,
  getWishlist,
  wishlists,
  getCarts,
  carts,
  deleteCart,
  clearSearch,
  localStorageCart,
  getLocalStorageCart,
}) => {
  const history = useHistory();
  const [searchProduct, setSearchProduct] = useState();
  const [inCartProduct, setInCartProduct] = useState([]);
  const [searchedProductList, setSearchedProductList] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [nextPageNumber, setNextPageNumber] = useState(1);
  const searchRef = useRef(null);
  const searchResultRef = useRef(null);
  const [searchText, setSearchText] = useState("");

  const debounced = useDebouncedCallback(
    (value) => {
      setSearchProduct(value);
      if (value) {
        setSearchedProductList([])
        setSearchText(value);
        searchProductItems();
        searchResultRef.current.style.display = "block";
      } else {
        searchResultRef.current.style.display = "none";
        clearSearch();
      }
    },
    // delay in ms
    1000
  );

  const searchProductItems = () => {
    getProducts(
      { query: searchText, page_num: nextPageNumber ? nextPageNumber : 1 },
      true,
      true
    ).then((res) => {
      if (res.result) {
        
        setSearchedProductList((prev) => [...prev, ...res.result.data]);

        setNextPageNumber(res.result.next_page_num);
      }
    });
  };

  const handleDownPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      history.push(`/searchResult?search=${e.target.value}`);
      searchResultRef.current.style.display = "none";
      clearSearch();
    }
  };

  useOutsideAlerter(searchRef, searchResultRef);

  useEffect(() => {
    if (localStorage.getItem("access")) {
      getCarts();
      getWishlist();
    } else {
      getLocalStorageCart();
      // setInCartProduct(cartsData);
    }
    handleCartToggle();
  }, []);

  useEffect(() => {
    if (localStorageCart?.products?.length > 0) {
      setInCartProduct(localStorageCart?.products);
    }
  }, [localStorageCart]);

  useEffect(() => {
    if (localStorageCart?.products?.length > 0) {
      setInCartProduct(localStorageCart?.products);
    }
  }, [localStorageCart]);

  useEffect(() => {
    if (localStorage.getItem("access")) {
      setInCartProduct(carts);
      const totalValue = getTotalApi(carts);
      setTotalValue(totalValue);
    }
  }, [carts]);

  const handleCartToggle = () => {
    if (localStorage.getItem("access")) {
      getCarts();
    } else {
      const cartsData = getCartProduct()?.products || [];
      setInCartProduct(cartsData);
      const totalValue = getTotal(cartsData);
      setTotalValue(totalValue);
    }
  };

  const handleGetProduct = () => {
    if (localStorage.getItem("access")) {
      getCarts();
    } else {
      const cartProduct = getCartProduct();
      setInCartProduct(cartProduct?.products);
    }
  };

  const handleRemoveProductFromCart = (product) => {
    if (localStorage.getItem("access")) {
      deleteCart({
        product_item_id: product?.id,
      });
    } else {
      DeleteCart(product?.id);
      handleGetProduct();
      toastr.success("Delete product from Cart");
    }
  };

  return (
    <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
      <div className="container">
        <div className="header-wrap">
          <div className="logo logo-width-1">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="header-right">
            <div className="search-style-2" ref={searchRef}>
              <form action="#">
                <input
                  type="text"
                  placeholder="Cauta produse..."
                  onChange={(e) => debounced(e.target.value)}
                  onKeyDown={(e) => handleDownPress(e)}
                />
              </form>
              <div
                ref={searchResultRef}
                style={{
                  display: searchedProductList.length >= 1 ? "block" : "none",
                }}
                className="search-results-wrapper search-results"
              >
                {searchedProductList?.length > 0 && (
                  <ul style={{ margin: "0", padding: "0" }}>
                    {searchedProductList?.map((productItem) => (
                      <li
                        key={productItem?.id}
                        className="search-result-container"
                      >
                        <img
                          src={productItem?.images?.[0]?.image}
                          alt={productItem?.title}
                        />
                        <div className="search-product-info">
                          <a
                            href={`/product/${productItem?.id}/${slug(
                              productItem?.title,
                              "_"
                            )}`}
                            className="submenu-title custom-ceva text-limit-2"
                          >
                            {productItem?.title}
                          </a>
                          <div className="search-product-info-text">
                            <p style={{ fontWeight: "600" }}>
                              {productItem?.price} RON
                            </p>
                            {productItem?.discount_amount > 0 ||
                            productItem?.discount_percentage > 0 ? (
                              <p style={{ textDecoration: "line-through" }}>
                                {productItem?.price_including_vat} RON
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="search-view-product-button">
                          <a
                            href={`/product/${productItem?.id}/${slug(
                              productItem?.title,
                              "_"
                            )}`}
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </div>
                      </li>
                    ))}
                    {nextPageNumber > 0 && (
                      <div className="my-2 text-center">
                        <Button
                          onClick={() => {
                            searchProductItems();
                          }}
                          size="sm"
                        >
                          Load more..
                        </Button>
                      </div>
                    )}
                  </ul>
                )}
              </div>
            </div>
            <div className="header-action-right">
              <div className="header-action-2">
                <div className="header-action-icon-2">
                  <Link to="/wishlist">
                    <i className="far fa-heart"></i>
                    <span className="pro-count blue">
                      {wishlists?.length || 0}
                    </span>
                  </Link>
                </div>
                <div className="header-action-icon-2">
                  <Link className="mini-cart-icon" to="/cart">
                    <i className="far fa-shopping-bag"></i>
                    <span className="pro-count blue">
                      {inCartProduct?.length || 0}
                    </span>
                  </Link>
                  <div className="style-4 cart-dropdown-wrap cart-dropdown-hm2">
                    <ul style={{ padding: "0" }}>
                      {inCartProduct?.map((product) => (
                        <>
                          <li style={{ display: "flex" }} key={product?.id}>
                            <div style={{ marginRight: "10px" }}>
                              <Link
                                to={`/product/${
                                  product?.product?.id || product.id
                                }/${slug(
                                  product?.product?.title || product?.title,
                                  "_"
                                )}`}
                              >
                                <img
                                  src={
                                    product?.product?.images?.[0]?.image ||
                                    product?.images?.[0]?.image
                                  }
                                  alt={product.product?.title || product?.title}
                                  style={{
                                    width: "100px",
                                    height: "100x",
                                    objectFit: "contain",
                                  }}
                                />
                              </Link>
                            </div>
                            <div style={{ width: "calc( 100% - 110px)" }}>
                              <div style={{ marginTop: "15px" }}>
                                <h5 style={{ fontSize: ".8rem" }}>
                                  <Link
                                    to={`/product/${
                                      product?.product?.id || product.id
                                    }/${slug(
                                      product?.product?.title || product?.title,
                                      "_"
                                    )}`}
                                    className="text-limit-2"
                                  >
                                    {product?.product?.title || product?.title}
                                  </Link>
                                </h5>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                }}
                              >
                                <span>
                                  {product?.quantity || product?.qty || 1} Buc
                                </span>
                                <span style={{ fontWeight: "600" }}>
                                  {product?.product?.price || product?.price}{" "}
                                  RON
                                </span>
                              </div>
                            </div>
                          </li>
                          <hr />
                        </>
                      ))}
                    </ul>
                    <div className="shopping-cart-footer">
                      <div className="shopping-cart-total">
                        <h4>
                          Total <span>{totalValue?.amount} Ron</span>
                        </h4>
                      </div>
                      <div className="shopping-cart-button">
                        <Link to="/cart" className="outline">
                          Vezi Cos
                        </Link>
                        <Link to="/checkout">Checkout</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MiddleBar.propTypes = {
  carts: PropTypes.array.isRequired,
  wishlists: PropTypes.array.isRequired,
  // reset: PropTypes.func.isRequired,
  // searchProducts: PropTypes.func.isRequired
};

const mapStateToProps = ({ product, wishlist }) => ({
  searchProducts: product.searchProducts,
  carts: wishlist.carts,
  wishlists: wishlist.wishlists,
  localStorageCart: wishlist.localStorageCart,
});
export default connect(mapStateToProps, {
  getProducts,
  getCarts,
  getWishlist,
  deleteCart,
  clearSearch,
  getLocalStorageCart,
})(MiddleBar);
