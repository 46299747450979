import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import {
  storeProductInCart,
  storeInWishlist,
  storeLocalStorageCart,
} from "../redux/actions/wishlist";
import { getProductDetail, sendOfferRequest } from "../redux/actions/products";
import ReactStars from "react-rating-stars-component";
import { handleCompare } from "../helper/cart";
import CarouselFinal from "./Carousel/CarouselFinal";
import ReactHtmlParser from "react-html-parser";
import logo from "../assets/imgs/theme/logo.svg";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useDropzone } from "react-dropzone";

function ProductQuickView({
  productDetail,
  product,
  storeProductInCart,
  storeInWishlist,
  id,
  getProductDetail,
  sendOfferRequest,
  loading,
  storeLocalStorageCart,
}) {
  const [qty, setQty] = useState(1);
  const [productImages, setProductImages] = useState([]);
  const [questionDataManage, setQuestionDataManage] = useState([]);
  const [additionalInformation, setAdditionalInformation] = useState({});

  const static_fields_visible = productDetail?.static_fields_visible;
  const handleAddToCart = (e, product) => {
    e.preventDefault();

    product["qty"] = qty || 1;
    if (localStorage.getItem("access")) {
      storeProductInCart({
        products: [
          { product_id: product?.id, quantity: +product?.qty || +qty },
        ],
      });
    } else {
      storeLocalStorageCart(product);
    }
  };

  const handleAddToWishlist = (product) => {
    storeInWishlist({ product_id_ls: [product?.id] });
  };

  useEffect(() => {
    if (id) {
      getProductDetail(id);
    }
  }, [id]);

  useEffect(() => {
    if (!loading) {
      toggle({ effect: true });
    }
  }, [loading]);

  const [modal, setModal] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const toggle = ({ effect = false }) => {
    if (!effect) setModal(!modal);
    setQuestionDataManage([]);
    setProductImages([]);
    setAdditionalInformation({});
    setModalStep(1);
  };

  const custom_product_questions =
    productDetail?.custom_product_questions?.slice(
      (modalStep - 1) * 3,
      modalStep * 3
    );

  const last_step =
    productDetail.custom_product_questions?.length - modalStep * 3 >= 1;

  const handleButtonForward = () => {
    if (last_step) {
      setModalStep(modalStep + 1);
    } else {
      sendOfferRequest({
        questions_and_answers: questionDataManage?.filter((step) => step),
        images: productImages,
        additional_notes: additionalInformation?.additional_notes,
        is_persoana_fizica:
          additionalInformation?.radio_value === "option1" ? true : false,
        is_persoana_juridica:
          additionalInformation?.radio_value === "option2" ? true : false,
        custom_product: id,
      });
    }
  };

  const handleButtonBack = () => {
    if (modalStep >= 2) {
      setModalStep(modalStep - 1);
    } else {
      setModalStep(1);
    }
  };

  const onDrop = (acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles.length > 0) {
      setProductImages((productImages) =>
        productImages.concat(...acceptedFiles)
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = productImages?.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleChange = (e, index, id) => {
    const dataQuestion = [...(questionDataManage || [])];
    dataQuestion[index] = {
      ...dataQuestion[index],
      question: id,
      answer: e.target.value,
    };
    setQuestionDataManage(dataQuestion);
  };

  return (
    <>
      <div className="row mb-20">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className="detail-gallery" style={{ position: "relative" }}>
            <CarouselFinal
              images={productDetail?.images?.map((image) => image.image)}
            />
            <div className="advantages-wrapper-picture">
              {productDetail?.question_bundle != null ? (
                <div className="advantage-picutre">
                  <div className="advantage-1">
                    <i className="fas fa-cog"></i>
                  </div>
                  <span className="advantage-1-text">
                    Servicii personalizate
                  </span>
                </div>
              ) : null}
              {/* <div className="advantage-picutre">
                <div className="advantage-2">
                  <i className="fas fa-undo-alt"></i>
                </div>
                <span className="advantage-2-text">Retur 15 zile</span>
              </div> */}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className="col-md-6 col-sm-12 col-xs-12"
        >
          <div className="detail-info">
            <h2 className="title-detail">{productDetail?.title}</h2>
            <div className="product-detail-rating">
              <div className="pro-details-brand">
                <div>
                  {productDetail?.is_custom_product === true ? (
                    <span style={{ fontSize: "1.2rem" }}>Avantaje:</span>
                  ) : (
                    <span>
                      Brand: <span>{productDetail?.brand_name}</span>
                      <br />
                    </span>
                  )}
                  {productDetail?.sku_code?.length > 0 ? (
                    <span>
                      Cod Produs: <span>{productDetail?.sku_code}</span>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="product-rate-cover text-end">
                <div style={{ display: "flex" }}>
                  {productDetail?.reviews_count < 1 ? (
                    <ReactStars
                      count={5}
                      size={16}
                      emptyIcon={<i className="far fa-star"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      color="#cbcbcb"
                      edit={false}
                    />
                  ) : (
                    <ReactStars
                      count={productDetail?.average_rating}
                      size={16}
                      emptyIcon={<i className="far fa-star"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      color="#ffd700"
                      edit={false}
                    />
                  )}

                  <span className="font-small ml-5 text-muted">
                    ({productDetail?.reviews?.length}{" "}
                    {productDetail?.reviews?.length === 1
                      ? "recenzie"
                      : "recenzii"}
                    )
                  </span>
                </div>
                {productDetail?.warranty?.length > 0 ? (
                  <div>Garantie: {productDetail?.warranty}</div>
                ) : null}
              </div>
            </div>
            <div>
              <Modal isOpen={modal}>
                <ModalHeader
                  toggle={toggle}
                  style={{ padding: "0", alignItems: "start" }}
                  className="ask-for-offer-header"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <i
                      style={{
                        color: "#333",
                        fontSize: "2rem",
                        cursor: "pointer",
                      }}
                      className="far fa-times"
                      onClick={toggle}
                    ></i>
                  </div>
                  <div style={{ marginTop: "2rem" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px 10px",
                          backgroundColor: "rgba(0, 0, 0 , 0.5)",
                          color: "#fff",
                          borderRadius: "5px",
                        }}
                      >
                        Cere oferta pentru {productDetail?.title}
                      </div>
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody>
                  {custom_product_questions?.map((question, index) => (
                    <div>
                      <div>{question?.title}</div>
                      <input
                        style={{ marginTop: "10px" }}
                        type="text"
                        onChange={(e) =>
                          handleChange(
                            e,
                            (modalStep - 1) * 3 + index,
                            question?.id
                          )
                        }
                        value={
                          questionDataManage?.[(modalStep - 1) * 3 + index]
                            ?.answer || ""
                        }
                      />
                      {/* {!questionDataManage?.[(modalStep - 1) * 3 + index]
                        ?.answer && (
                        <p style={{ color: "red" }}>
                          {question?.title} required
                        </p>
                      )} */}
                      <hr />
                    </div>
                  ))}
                  {static_fields_visible && !last_step && (
                    <>
                      <Label>Incarca poze relevante in vederea ofertarii</Label>
                      <FormGroup className="offer-dropzone">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p style={{ color: "#cbcbcb", fontSize: ".9rem" }}>
                            Click sau trage pozele aici
                          </p>
                        </div>
                        <aside>
                          <ul>{files}</ul>
                        </aside>
                      </FormGroup>
                      <hr />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="option1"
                            checked={
                              additionalInformation?.radio_value === "option1"
                            }
                            onChange={(e) =>
                              setAdditionalInformation({
                                ...additionalInformation,
                                radio_value: e.target.value,
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Persoana Fizica
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="option2"
                            checked={
                              additionalInformation?.radio_value === "option2"
                            }
                            onChange={(e) =>
                              setAdditionalInformation({
                                ...additionalInformation,
                                radio_value: e.target.value,
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Persoana Juridica
                          </label>
                        </div>
                      </div>
                      <hr />
                      <FormGroup>
                        <Label for="exampleText">
                          Alte informatii relevante in vederea ofertarii
                        </Label>
                        <Input
                          type="textarea"
                          name="text"
                          id="exampleText"
                          value={additionalInformation?.additional_notes || ""}
                          onChange={(e) =>
                            setAdditionalInformation({
                              ...additionalInformation,
                              additional_notes: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div>
                    <Button
                      style={{ fontSize: ".8rem" }}
                      color="secondary"
                      onClick={handleButtonBack}
                    >
                      Pasul anterior
                    </Button>{" "}
                    <Button
                      color="primary"
                      style={{ fontSize: ".8rem" }}
                      onClick={handleButtonForward}
                      disabled={loading}
                    >
                      {last_step ? "Pasul urmator" : "Trimite Oferta"}
                    </Button>
                    {/* <div
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        display: "block",
                        display: "flex",
                        fontSize: ".9rem",
                        alignContent: "center",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                      onClick={toggle}
                    >
                      Renunta
                    </div> */}
                  </div>
                </ModalFooter>
              </Modal>
            </div>
            {productDetail?.send_offer === true ? null : (
              <div className="clearfix product-price-cover">
                <div className="product-price primary-color float-left">
                  <ins>
                    <span className="text-brand">
                      {productDetail.price} RON
                    </span>
                  </ins>
                  {productDetail.discount_amount > 0 ||
                  productDetail.discount_percentage > 0 ? (
                    <ins>
                      <span className="old-price font-md ml-15">
                        {productDetail.price_including_vat} RON
                      </span>
                    </ins>
                  ) : null}
                  {productDetail.discount_amount > 0 ||
                  productDetail.discount_percentage > 0 ? (
                    <span className="save-price  font-md color3 ml-15">
                      -{" "}
                      {productDetail.discount_amount ||
                        productDetail?.discount_percentage_amount}{" "}
                      RON
                    </span>
                  ) : null}
                </div>
              </div>
            )}

            {productDetail?.is_custom_product === true ? (
              <div className="mb-20 mt-15">
                <div className="custom-advantages">
                  {productDetail?.advantages?.map((advantage) => (
                    <div
                      className="advantage-item"
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      {/* <div
                        style={{
                          border: "2px solid #cbcbcb",
                          padding: "10px",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: ".8rem"
                        }}
                      >
                        <i className={advantage?.icon}></i>
                      </div> */}
                      <i
                        style={{ fontSize: ".7rem", color: "#279131" }}
                        className="fas fa-circle"
                      ></i>
                      <div style={{ fontSize: "1rem" }}>{advantage?.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="mb-50 mt-50">
                <div
                  style={{
                    paddingLeft: "15px",
                    borderLeft: "2px solid #38cf46",
                  }}
                >
                  {ReactHtmlParser(productDetail?.short_description)}
                </div>
              </div>
            )}

            {productDetail?.is_custom_product === false ? (
              <div className="short-desc mb-30 mt-20">
                <div className="product-details-aditional-info">
                  <div className="product-details-quick-contact">
                    <div className="product-details-quick-contact-icon">
                      <i className="fas fa-mobile-alt"></i>
                    </div>
                    <div>
                      <div className="product-details-header">
                        Comanda telefonica
                      </div>
                      <div className="product-details-quick-contact-details product-details-subheader">
                        <a href="tel:0374491507">0374 491 507</a>
                      </div>
                    </div>
                  </div>
                  <div className="product-details-transport-wrapper">
                    <div className="product-details-transport-icon">
                      <i className="fas fa-box"></i>
                    </div>
                    <div>
                      <div className="product-details-header">
                        transport contra-cost
                      </div>
                      <div className="product-details-subheader">
                        Costul se comunica telefonic
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ marginTop: "40px" }}>
                <div style={{ fontWeight: "600", fontSize: "1rem" }}>
                  Autorizat
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <i
                    style={{ color: "green" }}
                    className="fas fa-check-circle"
                  ></i>
                  <div style={{ fontSize: "1rem" }}>
                    {productDetail?.authorization}
                  </div>
                </div>
              </div>
            )}
            {productDetail?.send_offer === true ? (
              <div
                style={{ marginBottom: "30px" }}
                className="detail-extralink mt-40"
              >
                <div className="product-extra-link2">
                  <button
                    className="button button-add-to-cart"
                    onClick={toggle}
                  >
                    Cere oferta
                  </button>
                  <a
                    aria-label="Add To Wishlist"
                    className="action-btn hover-up"
                    onClick={() => handleAddToWishlist(product)}
                  >
                    <i className="far fa-heart"></i>
                  </a>
                  <a
                    aria-label="Compare"
                    className="action-btn hover-up"
                    onClick={() => handleCompare(product)}
                  >
                    <i className="fas fa-random"></i>
                  </a>
                </div>
              </div>
            ) : (
              <div className="detail-extralink mt-40">
                <div>
                  <div className="input-group mb-3">
                    <div
                      style={{ height: "43px" }}
                      className="input-group-prepend"
                    >
                      <span
                        style={{ padding: "8px 20px" }}
                        className="input-group-text"
                        id="basic-addon1"
                      >
                        Cantitate
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control mr-20"
                      min={1}
                      value={qty}
                      onChange={(e) => setQty(e.target.value)}
                      aria-describedby="basic-addon1"
                      style={{
                        maxWidth: "100px",
                        padding: "0 20px",
                        height: "43px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                </div>
                <div className="product-extra-link2">
                  <button
                    type="submit"
                    className="button button-add-to-cart"
                    onClick={(e) => handleAddToCart(e, productDetail)}
                  >
                    Adauga in cos
                  </button>
                  <a
                    aria-label="Add To Wishlist"
                    className="action-btn hover-up"
                    onClick={() => handleAddToWishlist(product)}
                  >
                    <i className="far fa-heart"></i>
                  </a>
                  <a
                    aria-label="Compare"
                    className="action-btn hover-up"
                    onClick={() => handleCompare(productDetail)}
                  >
                    <i className="fas fa-random"></i>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {productDetail?.is_custom_product ? (
          <>
            <hr />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="mobile-hide"
            >
              <div>
                <img src={logo} alt="LPV Logo" style={{ maxHeight: "70px" }} />
              </div>
              <div>
                <div style={{ fontSize: "1rem" }}>Societate autorizata</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontWeight: "600", fontSize: "1rem" }}>
                    ISCIR |{" "}
                  </span>
                  <span style={{ fontWeight: "600", fontSize: "1rem" }}>
                    {" "}
                    AGFR |{" "}
                  </span>
                  <span style={{ fontWeight: "600", fontSize: "1rem" }}>
                    {" "}
                    ANRE
                  </span>
                </div>
              </div>
              <div>
                <div style={{ fontWeight: "600", fontSize: "1rem" }}>
                  +40 (374) 00 22 00
                </div>
                <div style={{ fontWeight: "600", fontSize: "1rem" }}>
                  comenzi@lpv.ro
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

const mapStateToProps = ({ product }) => ({
  productDetail: product?.productDetail,
  loading: product?.loading,
});
export default connect(mapStateToProps, {
  storeProductInCart,
  storeInWishlist,
  getProductDetail,
  sendOfferRequest,
  storeLocalStorageCart,
})(ProductQuickView);
