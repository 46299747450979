import React from "react";

const OrderPaymentMethod = ({
  agree,
  setAgree,
  setPaymentMethod,
  paymentMethod,
}) => {
  return (
    <>
      <div className="payment-methods">
        <ul className="payment-methods__list">
          {/* <li className="payment-methods__item payment-methods__item--active">
            <label className="payment-methods__item-header">
              <span className="payment-methods__item-radio input-radio">
                <span className="input-radio__body">
                  <input
                    className="input-radio__input"
                    name="checkout_payment_method"
                    type="radio"
                    defaultChecked="checked"
                    onChange={() => setPaymentMethod("bank_transfer")}
                  />{" "}
                  <span className="input-radio__circle" />{" "}
                </span>
              </span>
              <span className="payment-methods__item-title">
                Transfer Bancar
              </span>
            </label>
        
            <div className="payment-methods__item-container">
              <div className="payment-methods__item-description text-muted">
                Detalii transfer bancar
              </div>
            </div>
          </li>
       */}
          <li className="payment-methods__item">
            <label className="payment-methods__item-header">
              <span className="payment-methods__item-radio input-radio">
                <span className="input-radio__body">
                  <input
                    className="input-radio__input"
                    name="checkout_payment_method"
                    type="radio"
                    value={paymentMethod}
                    checked={paymentMethod === "stripe"}
                    onChange={() => setPaymentMethod("stripe")}
                  />
                  <span className="input-radio__circle" />{" "}
                </span>
              </span>
              <span className="payment-methods__item-title">
                Plata cu cardul
              </span>
            </label>
            <div className="payment-methods__item-container">
              <div className="payment-methods__item-description text-muted">
                Detalii plata cu cardul
              </div>
            </div>
          </li>
          {/* <li className="payment-methods__item">
            <label className="payment-methods__item-header">
              <span className="payment-methods__item-radio input-radio">
                <span className="input-radio__body">
                  <input
                    className="input-radio__input"
                    name="checkout_payment_method"
                    type="radio"
                    onChange={() => setPaymentMethod("cash_on_delivery")}
                  />{" "}
                  <span className="input-radio__circle" />{" "}
                </span>
              </span>
              <span className="payment-methods__item-title">
                PLata la livrare
              </span>
            </label>
            <div className="payment-methods__item-container">
              <div className="payment-methods__item-description text-muted">
                Detalii plata la livrare
              </div>
            </div>
          </li>
           */}
          {/* <li className="payment-methods__item">
            <label className="payment-methods__item-header">
              <span className="payment-methods__item-radio input-radio">
                <span className="input-radio__body">
                  <input
                    className="input-radio__input"
                    name="checkout_payment_method"
                    type="radio"
                    onChange={() => setPaymentMethod("paypal")}
                  />{" "}
                  <span className="input-radio__circle" />{" "}
                </span>
              </span>
              <span className="payment-methods__item-title">PayPal</span>
            </label>
            <div className="payment-methods__item-container">
              <div className="payment-methods__item-description text-muted">
                Pay via PayPal; you can pay with your credit card if you don’t
                have a PayPal account.
              </div>
            </div>
          </li>
       */}
        </ul>
      </div>
      <div className="checkout__agree form-group">
        <div className="form-check">
          <span className="form-check-input input-check">
            <span className="input-check__body">
              <input
                className="input-check__input"
                type="checkbox"
                id="checkout-terms"
                checked={agree}
                onChange={(e) => setAgree(!agree)}
              />{" "}
              <span className="input-check__box" />
              <svg className="input-check__icon" width="9px" height="7px">
                <use xlinkHref="images/sprite.svg#check-9x7" />
              </svg>
            </span>
          </span>
          <label className="form-check-label" htmlFor="checkout-terms" style={{ marginTop: "2px" }}>
            Sunt de acord cu{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="terms-and-conditions.html"
            >
              termenii si conditiile.
            </a>
            *
          </label>
        </div>
      </div>
    </>
  );
};

export default OrderPaymentMethod;
