import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";

import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import RecommendedAC from "../../../components/RecommendedCalculators/RecommendedAC";
import RecomandareCentralaExtern from "../../../components/RecommendedCalculators/RecommendedCTExtern";
import RecomandareCentralaIntern from "../../../components/RecommendedCalculators/RecommendedCTInstant";
import { Button } from "reactstrap";
import {
  getCategories,
  getProducts,
  clearSearch,
} from "../../../redux/actions/products";
import slugify from "react-slugify";
import logo from "../../../assets/imgs/theme/logo.svg";
import Avatar from "react-avatar";
import MobileMenu from "./MobileMenu";
import { useDebouncedCallback } from "use-debounce";
import { toastr } from "react-redux-toastr";
import { sortBy } from "lodash";
import slug from "slug";
import {
  getCarts,
  getWishlist,
  getLocalStorageCart,
} from "../../../redux/actions/wishlist";
import {
  getCartProduct,
  getTotal,
  getTotalApi,
  DeleteCart,
} from "../../../helper/cart";
import { Link } from "react-router-dom";

function useOutsideAlerter(
  ref,
  navSelectMenuRef,
  searchResultRef,
  setMenuOpen,
  menuOpen
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        searchResultRef.current.classList.remove("open-menu-custom");
        navSelectMenuRef.current.classList.remove("open");
        setMenuOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Navigation = ({
  getProducts,
  clearSearch,
  getCategories,
  categories,
  user,
  searchProducts,
  getCarts,
  carts,
  deleteCart,
  wishlists,
  websiteInfo,
  getLocalStorageCart,
  localStorageCart,
}) => {
  const [searchProduct, setSearchProduct] = useState();
  const [inCartProduct, setInCartProduct] = useState([]);
  const [searchedProductList, setSearchedProductList] = useState([]);
  const [nextPageNumber, setNextPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalValue, setTotalValue] = useState(0);
  const searchResultRef = useRef(null);
  const navRef = useRef(null);
  const navSelectRef = useRef(null);
  const navSelectMenuRef = useRef(null);
  useEffect(() => {
    if (localStorage.getItem("access")) {
      getCarts();
      getWishlist();
    } else {
      getLocalStorageCart();
    }
    handleCartToggle();
  }, []);

  useEffect(() => {
    if (localStorageCart?.products?.length > 0) {
      setInCartProduct(localStorageCart?.products);
    }
  }, [localStorageCart]);

  useEffect(() => {
    if (localStorage.getItem("access")) {
      setInCartProduct(carts);
      const totalValue = getTotalApi(carts);
      setTotalValue(totalValue);
    }
  }, [carts]);
  useEffect(() => {
    getCategories();
    // if (localStorage.getItem("access")) {
    //   getCarts();
    //   getWishlist();
    // } else {
    //   const cartsData = getCartProduct()?.products || [];
    //   setInCartProduct(cartsData);
    // }
    // handleCartToggle();
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("access")) {
  //     setInCartProduct(carts);
  //     const totalValue = getTotalApi(carts);
  //     setTotalValue(totalValue);
  //   }
  // }, [carts]);

  const handleCartToggle = () => {
    if (localStorage.getItem("access")) {
      getCarts();
    } else {
      const cartsData = getCartProduct()?.products || [];
      setInCartProduct(cartsData);
      const totalValue = getTotal(cartsData);
      setTotalValue(totalValue);
    }
  };

  const handleGetProduct = () => {
    if (localStorage.getItem("access")) {
      getCarts();
    } else {
      const cartProduct = getCartProduct();
      setInCartProduct(cartProduct?.products);
    }
  };

  const handleRemoveProductFromCart = (product) => {
    if (localStorage.getItem("access")) {
      deleteCart({
        product_item_id: product?.id,
      });
    } else {
      DeleteCart(product?.id);
      handleGetProduct();
      toastr.success("Delete product from Cart");
    }
  };

  const [menuOpen, setMenuOpen] = useState(false);
  useOutsideAlerter(
    navRef,
    navSelectMenuRef,
    navSelectRef,
    setMenuOpen,
    menuOpen
  );

  const handleMenuClick = () => {
    const menu = !menuOpen;
    if (!menu) {
      navSelectRef.current.classList.remove("open-menu-custom");
      navSelectMenuRef.current.classList.remove("open");
    } else {
      navSelectRef.current.classList.add("open-menu-custom");
      navSelectMenuRef.current.classList.add("open");
    }
    setMenuOpen(menu);
  };

  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenuClick = () => {
    setMobileMenu(!mobileMenu);
  };

  if (mobileMenu) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "scroll";
  }

  const [clickSearch, setClickSearch] = useState(false);

  const handleClickSearch = () => {
    setClickSearch(true);
  };
  const handleClickSearchExit = () => {
    setClickSearch(false);
  };

  const debounced = useDebouncedCallback(
    (value) => {
      setSearchProduct(value);
      if (value) {
        setSearchedProductList([]);
        setSearchText(value);
        searchProductItems();
        searchResultRef.current.style.display = "block";
      } else {
        searchResultRef.current.style.display = "none";
        clearSearch();
      }
    },
    // delay in ms
    1000
  );
  const searchProductItems = () => {
    getProducts(
      { query: searchText, page_num: nextPageNumber ? nextPageNumber : 1 },
      true,
      true
    ).then((res) => {
      if (res.result) {
        setSearchedProductList((prev) => [...prev, ...res.result.data]);
        setNextPageNumber(res.result.next_page_num);
      }
    });
  };

  const [dropdownRevizie, setDropdownRevizie] = useState(false);

  const toggleRevizie = () => setDropdownRevizie(!dropdownRevizie);

  const [dropdownIgienizare, setDropdownIgienizare] = useState(false);

  const toggleIgienizare = () => setDropdownIgienizare(!dropdownIgienizare);

  const [dropdownMontare, setDropdownMontare] = useState(false);

  const toggleMontare = () => setDropdownMontare(!dropdownMontare);

  const [dropdownRevizie2, setDropdownRevizie2] = useState(false);

  const toggleRevizie2 = () => setDropdownRevizie2(!dropdownRevizie2);

  const [dropdownVerificare, setDropdownVerificare] = useState(false);

  const toggleVerificare = () => setDropdownVerificare(!dropdownVerificare);

  const [customModal, setCustomModal] = useState(false);
  const [customModalHide, setCustomModaHide] = useState(true);

  const onCustomModalClick = () => setCustomModal(!customModal);

  return (
    <>
      <div
        onClick={handleMobileMenuClick}
        className={
          mobileMenu
            ? "mobile-menu-overlay show-overlay"
            : "mobile-menu-overlay"
        }
      ></div>
      <div className="header-bottom header-bottom-bg-color sticky-bar">
        <div className="container">
          <div className="header-wrap header-space-between position-relative">
            <div className="logo logo-width-1 d-block d-lg-none">
              <Link to="/">
                <img src={logo} alt="LPV Logo" />
              </Link>
            </div>
            <div className="header-nav d-none d-lg-flex">
              <div
                onClick={handleMenuClick}
                className="main-categori-wrap d-none d-lg-block"
              >
                <a
                  style={{
                    display: "flex !important",
                    gap: "10px",
                    alignItems: "center",
                  }}
                  className={
                    menuOpen
                      ? "categori-button-active open"
                      : "categori-button-active"
                  }
                  ref={navSelectMenuRef}
                >
                  <i
                    style={{ margin: "0", fontSize: "1.2rem" }}
                    className="fas fa-bars"
                  ></i>{" "}
                  Vezi Produse si Servicii
                </a>
              </div>
              <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block">
                <nav>
                  <ul style={{ marginBottom: "0" }}>
                    <li>
                      <Link to="/blog">
                        Blog
                        <i className="fi-rs-angle-down" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/despre-noi">
                        Despre Noi
                        <i className="fi-rs-angle-down" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/autorizatii">Autorizatii</Link>
                    </li>
                    <li>
                      <a href="#" onClick={onCustomModalClick}>
                        Solutii Tehnice
                      </a>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="hotline d-none d-lg-block">
              <p>
                <i className="fi-rs-headset" />
                <span>Telefon</span>{" "}
                <a href="tel:0374491507">{websiteInfo?.phone_number}</a>
              </p>
            </div>
            <div className="header-action-right d-block d-lg-none">
              <div className="header-action-2">
                <div className="header-action-icon-2">
                  <Link to={"/wishlist"}>
                    <i style={{ color: "black" }} className="far fa-heart"></i>
                    <span className="pro-count white">
                      {" "}
                      {wishlists?.length || 0}
                    </span>
                  </Link>
                </div>
                <div className="header-action-icon-2">
                  <Link className="mini-cart-icon" to="/cart">
                    <i
                      style={{ color: "black" }}
                      className="far fa-shopping-bag"
                    ></i>
                    <span className="pro-count white">
                      {inCartProduct?.length || 0}
                    </span>
                  </Link>
                  <div className="cart-dropdown-wrap cart-dropdown-hm2">
                    <ul>
                      {inCartProduct?.map((product) => (
                        <li key={product?.id}>
                          <div className="shopping-cart-img">
                            <a
                              href={`/product/${product.id}/${slug(
                                product?.product?.title || product?.title,
                                "_"
                              )}`}
                              target="_blank"
                            >
                              <img
                                src={
                                  product?.product?.images?.[0]?.image ||
                                  product?.images?.[0]?.image
                                }
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="shopping-cart-title">
                            <h4>
                              <a
                                href={`/product/${product.id}/${slug(
                                  product?.product?.title || product?.title,
                                  "_"
                                )}`}
                                target="_blank"
                              >
                                {product?.product?.title || product?.title}
                              </a>
                            </h4>
                            <h3>
                              <span>
                                {" "}
                                {product?.quantity || product?.qty || 1}
                              </span>
                              x
                              <span className="dropcart__product-price">
                                $ {product?.product?.price || product?.price}
                              </span>
                            </h3>
                          </div>
                          <div className="shopping-cart-delete">
                            <a
                              href="!#"
                              onClick={() =>
                                handleRemoveProductFromCart(product)
                              }
                            >
                              <i className="fi-rs-cross-small" />
                            </a>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="shopping-cart-footer">
                      <div className="shopping-cart-total">
                        <h4>
                          Total <span>${totalValue?.amount}</span>
                        </h4>
                      </div>
                      <div className="shopping-cart-button">
                        <Link to="/cart">View cart</Link>
                        <Link to="/checkout">Checkout</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  onClick={handleMobileMenuClick}
                  className="header-action-icon-2 d-block d-lg-none"
                >
                  <div className="burger-icon burger-icon-white">
                    <i
                      style={{ fontSize: "1.5rem" }}
                      className="fas fa-bars"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={navRef}>
        <div
          className={
            menuOpen
              ? "container custom-menu-container open-menu-custom"
              : "container custom-menu-container"
          }
          ref={navSelectRef}
        >
          <div style={{ minHeight: "475px" }} className="desktop-menu-wrapper">
            <div>
              <div className="col-3 custom-category-wrapper">
                <ul style={{ padding: "0" }}>
                  {Object.keys(categories)?.map?.((category, index) => (
                    <li key={`${category}-${index}`}>
                      <a
                        className="category-info-container"
                        href={`/product_category/${categories[category]?.category_id}`}
                      >
                        {category === "Climatizare" ? (
                          <i className="fas fa-air-conditioner"></i>
                        ) : category === "Termice" ? (
                          <i className="fas fa-fire"></i>
                        ) : category === "Servicii tehnice" ? (
                          <i className="fas fa-tools"></i>
                        ) : (
                          <i className="fas fa-cog"></i>
                        )}
                        <div>{category}</div>
                        <i className="fas fa-chevron-right"></i>
                      </a>
                      <div
                        className="submenu-container"
                        style={{
                          backgroundImage:
                            "url(" + categories[category].image_url + ")",
                        }}
                      >
                        <ul
                          style={{
                            width: "100%",
                            margin: "0",
                            height: "100%",
                            padding: "0",
                          }}
                          className="row"
                        >
                          {Object.keys(
                            categories?.[category]?.subcategories
                          )?.map?.((subCategories, index) => (
                            <li
                              key={index}
                              style={{ padding: "15px 5px" }}
                              className="col-3"
                            >
                              <a
                                key={`${subCategories}-${index}`}
                                className={
                                  category === "Termice"
                                    ? "submenu-title subtitle-color-termic"
                                    : category === "Servicii tehnice"
                                    ? "submenu-title subtitle-color-services"
                                    : "submenu-title"
                                }
                                style={{ padding: "0 10px" }}
                                href={`/product_sub_category/${categories?.[category]?.subcategories[subCategories]?.subcategory_id}`}
                              >
                                {subCategories}
                              </a>
                              <ul style={{ width: "100%", padding: "0" }}>
                                {categories?.[category]?.["subcategories"]?.[
                                  subCategories
                                ]?.["subsubcategories"]?.map(
                                  (subSubCategories) => (
                                    <li
                                      key={subSubCategories?.subsubcategory_id}
                                    >
                                      <a
                                        className="subsubcategory-item"
                                        style={{
                                          display: "block",
                                          width: "100%",
                                          padding: "5px 10px",
                                        }}
                                        href={`/categorie/${slugify(
                                          subSubCategories?.subsubcategory_name
                                        )}/${
                                          subSubCategories?.subsubcategory_id
                                        }`}
                                      >
                                        {subSubCategories?.subsubcategory_name}
                                      </a>
                                    </li>
                                  )
                                )}
                                {categories?.[category]?.["subcategories"]?.[
                                  subCategories
                                ]?.["custom_products"]?.map((customProduct) => (
                                  <>
                                    {customProduct?.title?.slice(0, 7) !==
                                      "Revizie" &&
                                      customProduct?.title.slice(0, 10) !==
                                        "Igienizare" &&
                                      customProduct?.title.slice(0, 7) !==
                                        "Montare" &&
                                      customProduct?.title.slice(0, 10) !==
                                        "Verificare" && (
                                        <li key={customProduct?.id}>
                                          <a
                                            className="subsubcategory-item"
                                            style={{
                                              display: "block",
                                              width: "100%",
                                              padding: "5px 10px",
                                            }}
                                            href={`/product/${
                                              customProduct?.id
                                            }/${slugify(customProduct?.title)}`}
                                          >
                                            {customProduct?.title}
                                          </a>
                                        </li>
                                      )}
                                  </>
                                ))}
                                {category === "Servicii tehnice" &&
                                  subCategories === "Climatizare" && (
                                    <div
                                      className={
                                        dropdownRevizie
                                          ? "dropDownSelected"
                                          : null
                                      }
                                    >
                                      <li
                                        onClick={toggleRevizie}
                                        className="dropDownVisible"
                                      >
                                        <span>Revizie aer conditionat</span>
                                        {dropdownRevizie ? (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-up"
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-down"
                                          ></i>
                                        )}
                                      </li>
                                      {dropdownRevizie ? <hr /> : null}

                                      {sortBy(
                                        categories?.[category]?.[
                                          "subcategories"
                                        ]?.[subCategories]?.["custom_products"],
                                        [
                                          function (o) {
                                            return o.title;
                                          },
                                        ]
                                      )?.map((customProduct) => (
                                        <>
                                          {customProduct?.title?.slice(0, 7) ===
                                            "Revizie" && (
                                            <li
                                              className={
                                                dropdownRevizie
                                                  ? "display-visible"
                                                  : "display-none"
                                              }
                                              key={customProduct?.id}
                                            >
                                              <a
                                                className="subsubcategory-item"
                                                style={{
                                                  display: "block",
                                                  width: "100%",
                                                  padding: "5px 10px",
                                                }}
                                                href={`/product/${
                                                  customProduct?.id
                                                }/${slugify(
                                                  customProduct?.title
                                                )}`}
                                              >
                                                {customProduct?.title}
                                              </a>
                                            </li>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  )}
                                {category === "Servicii tehnice" &&
                                  subCategories === "Climatizare" && (
                                    <div
                                      className={
                                        dropdownIgienizare
                                          ? "dropDownSelected"
                                          : null
                                      }
                                    >
                                      <li
                                        onClick={toggleIgienizare}
                                        className="dropDownVisible"
                                      >
                                        <span>Igienizare aer conditionat</span>
                                        {dropdownIgienizare ? (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-up"
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-down"
                                          ></i>
                                        )}
                                      </li>
                                      {dropdownIgienizare ? <hr /> : null}

                                      {categories?.[category]?.[
                                        "subcategories"
                                      ]?.[subCategories]?.[
                                        "custom_products"
                                      ]?.map((customProduct) => (
                                        <>
                                          {customProduct?.title?.slice(
                                            0,
                                            10
                                          ) === "Igienizare" && (
                                            <li
                                              className={
                                                dropdownIgienizare
                                                  ? "display-visible"
                                                  : "display-none"
                                              }
                                              key={customProduct?.id}
                                            >
                                              <a
                                                className="subsubcategory-item"
                                                style={{
                                                  display: "block",
                                                  width: "100%",
                                                  padding: "5px 10px",
                                                }}
                                                href={`/product/${
                                                  customProduct?.id
                                                }/${slugify(
                                                  customProduct?.title
                                                )}`}
                                              >
                                                {customProduct?.title}
                                              </a>
                                            </li>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  )}
                                {category === "Servicii tehnice" &&
                                  subCategories === "Climatizare" && (
                                    <div
                                      className={
                                        dropdownMontare
                                          ? "dropDownSelected"
                                          : null
                                      }
                                    >
                                      <li
                                        onClick={toggleMontare}
                                        className="dropDownVisible"
                                      >
                                        <span>Montare aer conditionat</span>
                                        {dropdownMontare ? (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-up"
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-down"
                                          ></i>
                                        )}
                                      </li>
                                      {dropdownMontare ? <hr /> : null}

                                      {categories?.[category]?.[
                                        "subcategories"
                                      ]?.[subCategories]?.[
                                        "custom_products"
                                      ]?.map((customProduct) => (
                                        <>
                                          {customProduct?.title?.slice(0, 7) ===
                                            "Montare" && (
                                            <li
                                              className={
                                                dropdownMontare
                                                  ? "display-visible"
                                                  : "display-none"
                                              }
                                              key={customProduct?.id}
                                            >
                                              <a
                                                className="subsubcategory-item"
                                                style={{
                                                  display: "block",
                                                  width: "100%",
                                                  padding: "5px 10px",
                                                }}
                                                href={`/product/${
                                                  customProduct?.id
                                                }/${slugify(
                                                  customProduct?.title
                                                )}`}
                                              >
                                                {customProduct?.title}
                                              </a>
                                            </li>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  )}
                                {category === "Servicii tehnice" &&
                                  subCategories === "Termice" && (
                                    <div
                                      className={
                                        dropdownRevizie2
                                          ? "dropDownSelected"
                                          : null
                                      }
                                    >
                                      <li
                                        onClick={toggleRevizie2}
                                        className="dropDownVisible"
                                      >
                                        <span>Revizie CT / Cazane</span>
                                        {dropdownRevizie2 ? (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-up"
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-down"
                                          ></i>
                                        )}
                                      </li>
                                      {dropdownRevizie2 ? <hr /> : null}

                                      {categories?.[category]?.[
                                        "subcategories"
                                      ]?.[subCategories]?.[
                                        "custom_products"
                                      ]?.map((customProduct) => (
                                        <>
                                          {customProduct?.title?.slice(0, 7) ===
                                            "Revizie" && (
                                            <li
                                              className={
                                                dropdownRevizie2
                                                  ? "display-visible"
                                                  : "display-none"
                                              }
                                              key={customProduct?.id}
                                            >
                                              <a
                                                className="subsubcategory-item"
                                                style={{
                                                  display: "block",
                                                  width: "100%",
                                                  padding: "5px 10px",
                                                }}
                                                href={`/product/${
                                                  customProduct?.id
                                                }/${slugify(
                                                  customProduct?.title
                                                )}`}
                                              >
                                                {customProduct?.title}
                                              </a>
                                            </li>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  )}
                                {category === "Servicii tehnice" &&
                                  subCategories === "Termice" && (
                                    <div
                                      className={
                                        dropdownVerificare
                                          ? "dropDownSelected"
                                          : null
                                      }
                                    >
                                      <li
                                        onClick={toggleVerificare}
                                        className="dropDownVisible"
                                      >
                                        <span>Verificare centrale termice</span>
                                        {dropdownVerificare ? (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-up"
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ marginLeft: "20px" }}
                                            className="fas fa-chevron-down"
                                          ></i>
                                        )}
                                      </li>
                                      {dropdownVerificare ? <hr /> : null}

                                      {categories?.[category]?.[
                                        "subcategories"
                                      ]?.[subCategories]?.[
                                        "custom_products"
                                      ]?.map((customProduct) => (
                                        <>
                                          {customProduct?.title?.slice(
                                            0,
                                            10
                                          ) === "Verificare" && (
                                            <li
                                              className={
                                                dropdownVerificare
                                                  ? "display-visible"
                                                  : "display-none"
                                              }
                                              key={customProduct?.id}
                                            >
                                              <a
                                                className="subsubcategory-item"
                                                style={{
                                                  display: "block",
                                                  width: "100%",
                                                  padding: "5px 10px",
                                                }}
                                                href={`/product/${
                                                  customProduct?.id
                                                }/${slugify(
                                                  customProduct?.title
                                                )}`}
                                              >
                                                {customProduct?.title}
                                              </a>
                                            </li>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  )}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  ))}
                  <li key={`promotion`} style={{ backgroundColor: "#a0282d" }}>
                    <a
                      className="category-info-container"
                      href="/promotion_product"
                    >
                      <i
                        style={{ color: "#fff" }}
                        className="fas fa-badge-percent"
                      ></i>
                      <div style={{ color: "#fff" }}>Promotii</div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ width: "100%" }}
        className={
          mobileMenu
            ? "mobile-header-active mobile-header-wrapper-style sidebar-visible"
            : "mobile-header-active mobile-header-wrapper-style"
        }
      >
        <div style={{ padding: "0" }} className="mobile-header-wrapper-inner">
          <div className="mobile-header-top">
            <div className="mobile-header-logo">
              <Link to="/">
                <img src={logo} alt="LPV Logo" />
              </Link>
            </div>
            <div
              onClick={handleMobileMenuClick}
              className="mobile-menu-close close-style-wrap close-style-position-inherit"
            >
              <button className="close-style search-close">
                <i className="icon-top" />
                <i className="icon-bottom" />
              </button>
            </div>
          </div>
          <div
            style={{ padding: "30px 20px 10px 20px" }}
            className="mobile-header-content-area"
          >
            <div className="mb-3">
              {user?.email.length > 0 ? (
                <div className="mobile-menu-account">
                  {user?.avatar === null ? (
                    <Avatar
                      name={user?.first_name}
                      size="110"
                      round={true}
                      className="letters-avatar"
                      colors={["#0275d8", "#d82f3d", "#01baef"]}
                      maxInitials="2"
                    />
                  ) : (
                    <img
                      src={user?.avatar}
                      alt={user?.first_name}
                      width={110}
                      height={110}
                      style={{ objectFit: "cover", borderRadius: "50%" }}
                    />
                  )}

                  <Link
                    to="/profil"
                    style={{ width: "100%" }}
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleMobileMenuClick}
                  >
                    <i className="fas fa-user mr-10"></i> Vezi profilul
                  </Link>
                </div>
              ) : (
                <>
                  <a
                    href="/log_in"
                    style={{ width: "100%" }}
                    type="button"
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-user mr-10"></i> Autentificare
                  </a>
                </>
              )}
            </div>
            <div
              className={
                clickSearch
                  ? "mobile-search search-style-3 mobile-header-border search-active"
                  : "mobile-search search-style-3 mobile-header-border"
              }
            >
              <div
                style={{ display: "flex", gap: "15px", alignItems: "center" }}
              >
                <form
                  onClick={handleClickSearch}
                  style={{ width: "100%" }}
                  action="#"
                >
                  <input
                    type="text"
                    placeholder="Cauta produse..."
                    onChange={(e) => debounced(e.target.value)}
                  />
                  <button type="submit">
                    <i className="far fa-search"></i>
                  </button>
                </form>
                {clickSearch ? (
                  <i
                    onClick={handleClickSearchExit}
                    style={{ fontSize: "1.5rem", color: "red" }}
                    className="far fa-times"
                  ></i>
                ) : null}
              </div>
              <div
                className={
                  clickSearch
                    ? "search-mobile-wrapper search-mobile-wrapper-active hide-scrollbar"
                    : "search-mobile-wrapper"
                }
              >
                <div
                  ref={searchResultRef}
                  style={{
                    display: searchedProductList.length > 0 ? "block" : "none",
                    width: "calc(100% - 52px)",
                    padding: "0",
                    border: "none",
                  }}
                  className="search-results-wrapper search-results"
                >
                  {searchedProductList?.length > 0 && (
                    <div>
                      {searchedProductList?.map((productItem) => (
                        <div
                          key={productItem?.id}
                          className="search-result-container"
                          style={{ borderBottom: "1px solid #cbcbcb" }}
                        >
                          <img
                            src={productItem?.images?.[0]?.image}
                            alt={productItem?.title}
                          />
                          <div className="search-product-info">
                            <a
                              href={`/product/${productItem?.id}/${slug(
                                productItem?.title,
                                "_"
                              )}`}
                              className="submenu-title custom-ceva text-limit-2"
                            >
                              {productItem?.title}
                            </a>
                            <div className="search-product-info-text">
                              <p style={{ fontWeight: "600" }}>
                                {productItem?.price} RON
                              </p>
                              {productItem?.discount_amount > 0 ||
                              productItem?.discount_percentage > 0 ? (
                                <p style={{ textDecoration: "line-through" }}>
                                  {productItem?.price_including_vat} RON
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {searchedProductList?.length > 0 && nextPageNumber > 0 && (
                    <div className="my-2 text-center">
                      <Button
                        onClick={() => {
                          searchProductItems();
                        }}
                        size="sm"
                      >
                        Load more..
                      </Button>
                    </div>
                  )}
                  {
                    !searchedProductList.length && (
                      <p className="text-center ">Nici un rezultat gasit</p>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="mobile-menu-wrap mobile-header-border">
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="mt-3 main-categori-wrap mobile-header-border"
              >
                <i
                  style={{ fontSize: "1rem" }}
                  className="fal fa-th-large mr-10"
                ></i>
                <a
                  style={{ padding: "0" }}
                  className="categori-button-active-2"
                >
                  Produse si servicii
                </a>
              </div>
              {/* mobile menu start */}
              <nav>
                <ul style={{ padding: "0" }} className="mobile-menu">
                  {Object.keys(categories)?.map?.((category, index) => (
                    <MobileMenu
                      category={category}
                      index={index}
                      categories={categories}
                    />
                  ))}
                  <li key={`promotion`} style={{ backgroundColor: "#a0282d" }}>
                    <a
                      className="category-info-container"
                      href="/promotion_product"
                    >
                      {/* <i
                        style={{ color: "#fff" }}
                        className="fas fa-badge-percent"
                      ></i> */}
                      <div style={{ color: "#fff" }}>Promotii</div>
                    </a>
                  </li>
                </ul>
              </nav>
              {/* mobile menu end */}
            </div>
            <div className="mobile-header-info-wrap mobile-header-border">
              <div className="single-mobile-header-info mt-10">
                <Link to="/blog" onClick={handleMobileMenuClick}>
                  {" "}
                  Blog{" "}
                </Link>
              </div>
              <div className="single-mobile-header-info mt-10">
                <Link to="/contact" onClick={handleMobileMenuClick}>
                  {" "}
                  Contact{" "}
                </Link>
              </div>

              <div className="single-mobile-header-info mt-10">
                <Link to="/autorizatii" onClick={handleMobileMenuClick}>
                  {" "}
                  Autorizatii{" "}
                </Link>
              </div>
              <div className="single-mobile-header-info mt-10">
                <a
                  style={{ color: "#0d6efd", textDecoration: "underline" }}
                  onClick={() => {
                    handleMobileMenuClick();
                    onCustomModalClick();
                  }}
                >
                  {" "}
                  Solutii Tehnice{" "}
                </a>
              </div>

              <div className="single-mobile-header-info mt-10">
                <Link to="/despre-noi" onClick={handleMobileMenuClick}>
                  {" "}
                  Despre Noi{" "}
                </Link>
              </div>
              <div className="mt-10 mobile-menu-contact">
                <button type="button" className="btn btn-secondary btn-sm">
                  <a style={{ color: "#fff" }} href="mailto: comenzi@lpv.ro">
                    <i className="far fa-envelope mr-10"></i>comenzi@lpv.ro
                  </a>
                </button>
                <button type="button" className="btn btn-secondary btn-sm">
                  <a style={{ color: "#fff" }} href="tel:0374491507 ">
                    <i className="fas fa-phone-alt mr-10"></i>0374.491.507
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={{ maxWidth: "1200px" }}
        isOpen={customModal}
        toggle={onCustomModalClick}
      >
        <ModalHeader>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <h5 style={{ color: "#fff" }}>Solutii Tehnice</h5>
            <i
              style={{ color: "#fff", fontSize: "1.3rem" }}
              className="far fa-times"
              onClick={onCustomModalClick}
            ></i>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <RecommendedAC />
              </div>
              <div className="col-lg-4">
                <RecomandareCentralaExtern
                  setCustomModal={setCustomModal}
                  customModal={customModal}
                />
              </div>
              <div className="col-lg-4">
                <RecomandareCentralaIntern />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div style={{ display: "flex", gap: "20px" }}>
            <div style={{ width: "100%" }}>
              <p style={{ backgroundColor: "#DAE9FF", padding: "15px" }}>
                <i
                  style={{
                    color: "#0275d8",
                    fontSize: "1.2rem",
                    marginRight: "10px",
                  }}
                  className="fas fa-info-circle"
                ></i>
                Pentru siguranta si confortul tau{" "}
                <strong>LPV Service Consult</strong> iti propune solutii tehnice
                digitale pentru alegerea aerului conditionat si a centralei
                termice rezidentiale.
              </p>
              <hr />
              <div>
                <h5>Beneficiile tale:</h5>
                <ul style={{ marginTop: "15px" }}>
                  <li
                    style={{
                      backgroundColor: "#E5FFE7",
                      padding: "5px 10px",
                      marginBottom: "10px",
                    }}
                  >
                    <i
                      style={{ color: "green", marginRight: "10px" }}
                      className="fas fa-check"
                    ></i>
                    Economisire timp
                  </li>
                  <li
                    style={{
                      backgroundColor: "#E5FFE7",
                      padding: "5px 10px",
                      marginBottom: "10px",
                    }}
                  >
                    <i
                      style={{ color: "green", marginRight: "10px" }}
                      className="fas fa-check"
                    ></i>
                    Eliminarea erorilor umane de prelucrare a informatiilor
                  </li>
                  <li
                    style={{
                      backgroundColor: "#E5FFE7",
                      padding: "5px 10px",
                      marginBottom: "10px",
                    }}
                  >
                    <i
                      style={{ color: "green", marginRight: "10px" }}
                      className="fas fa-check"
                    ></i>
                    100% automatizare si personalizare a informatiei
                  </li>
                </ul>
              </div>
            </div>
            {/* <div style={{ width: "30%" }}>
              <img
                style={{ pointerEvents: "none" }}
                src="https://i.imgur.com/FkW2R3d.png"
                alt="Solutii Tehnice Image"
              />
            </div> */}
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ product, wishlist, auth, aboutUs }) => ({
  categories: product.categories,
  user: auth.user,
  searchProducts: product.searchProducts,
  wishlists: wishlist.wishlists,
  websiteInfo: aboutUs.websiteInfoDetails,
  carts: wishlist.carts,
  localStorageCart: wishlist?.localStorageCart,
});
export default connect(mapStateToProps, {
  getCategories,
  getProducts,
  clearSearch,
  getCarts,
  getLocalStorageCart,
})(Navigation);
