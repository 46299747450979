import React from "react";

const FilterBrand = ({ productBrandList, setFilterState, filterState }) => {
  const handleBrandCheck = (e, id, index) => {
    let newBrands = [...filterState?.product_brands];
    if (e.target.checked) {
      newBrands.push(id);
    } else {
      newBrands = newBrands.filter((item) => item !== id);
    }
    setFilterState({
      ...filterState,
      product_brands: newBrands,
    });
  };

  return (
    <div className="sidebar-widget price_range range mb-30">
      <div className="widget-header position-relative mb -20 pb-10">
        <h5 className="widget-title mb-10">Filtru Brand</h5>
      </div>
      <div className="list-group custom-list-group">
        <div className="list-group-item mb-10 mt-10">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
            }}
          >
            {productBrandList?.map((productBrand, productIndex) => (
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <input
                  style={{ margin: "0" }}
                  className="form-check-input"
                  type="checkbox"
                  checked={filterState?.product_brands?.includes(
                    productBrand?.id
                  )}
                  onClick={(e) => handleBrandCheck(e, productBrand?.id)}
                />
                <label style={{ margin: "0" }} className="form-check-label">
                  <span>{productBrand?.title}</span>
                  {/* <span> (56)</span> */}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBrand;
