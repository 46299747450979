import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

import Breadcrumbs from "./sections/Breadcrumbs.js";
import FilterPrice from "./sections/FilterPrice.js";
import FilterAttributes from "./sections/FilterAttributes.js";
import FilterCategories from "./sections/FilterCategories.js";
import FilterSubCategories from "./sections/FilterSubCategories.js";
import FilterBrand from "./sections/FilterBrand.js";
import ShopTopBar from "./sections/ShopTopBar.js";
import {
  getProducts,
  getProductAttributeContentList,
  getProductBrandList,
} from "../../redux/actions/products.js";
import ShopProduct from "./sections/ShopProduct.js";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import {
  generateCalculatorKw,
  generateCalculatorLitri,
} from "../../Common/calculator";

const Shop = ({
  match,
  getProducts,
  products,
  getProductAttributeContentList,
  productAttributeContentList,
  getProductBrandList,
  productBrandList,
  productCategoryContentList,
  pagination,
  maximum_product_price,
}) => {
  const id = match?.params?.id;

  let category_slug = match?.params?.category;
  let sub_category_slug = match?.params?.sub_category;
  category_slug = category_slug && parseInt(category_slug);
  sub_category_slug = sub_category_slug && parseInt(sub_category_slug);

  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);

  const search = useLocation().search;
  let pathname = useLocation().pathname;
  const secret_key = "test_secret_key_1";
  const urlPath = pathname;
  pathname = pathname === "/searchResult" || pathname === "/promotion_product";
  const promotion_product = urlPath === "/promotion_product";
  const search_result_product = urlPath === "/searchResult";
  const product_category = urlPath === `/product_category/${category_slug}`;
  const product_sub_category =
    urlPath === `/product_sub_category/${sub_category_slug}`;
  let subCategoryList = {};

  Object.keys(productCategoryContentList).map((key) => {
    Object.keys(productCategoryContentList[key].subcategories).map(
      (subCategory) => {
        if (
          productCategoryContentList[key].subcategories[subCategory]
            .subcategory_id === sub_category_slug
        ) {
          subCategoryList = {
            subCategory,
            ...productCategoryContentList[key].subcategories[subCategory],
          };
        }
      }
    );
  });

  const btu = new URLSearchParams(search).get("btu");
  const kw = new URLSearchParams(search).get("kw");
  const kww = new URLSearchParams(search).get("kww");

  const litri = new URLSearchParams(search).get("litri");

  const search_params = new URLSearchParams(search).get("search");
  const search_params_category_search = new URLSearchParams(search).get(
    "category_search"
  );

  let search_params_category = new URLSearchParams(search).get(
    "category_search"
  );

  search_params_category =
    search_params_category && JSON.parse(search_params_category);

  const items_per_page = !pathname ? 9 : 12;

  const [filterState, setFilterState] = useState({
    product_attributes: [],
    items_per_page: items_per_page,
    product_brands: [],
    product_attribute_contents: [],
    subcategory_id_ls: [],
    subsubcategory_id_ls: [],
  });

  useEffect(() => {
    if (pathname) {
      setFilterState({ ...filterState, items_per_page: 12 });
    }
  }, [pathname]);

  useEffect(() => {
    if (btu !== null) {
      if (+btu > 24000) {
        let product_filter_contents = [4818, 5259, 4356, 5123, 5021];
        product_filter_contents = new Set(product_filter_contents);
        product_filter_contents = [...product_filter_contents];

        setFilterState({
          ...filterState,
          product_attribute_contents: [
            ...(filterState?.product_attribute_contents || []),
            ...product_filter_contents,
          ],
        });
      } else {
        let product_filter_contents = [];
        if (+btu <= 9000) {
          product_filter_contents.push(4818);
        } else if (+btu <= 12000) {
          product_filter_contents.push(5259);
        } else if (+btu <= 10000) {
          product_filter_contents.push(5259);
        } else if (+btu <= 18000) {
          product_filter_contents.push(4356);
        } else if (+btu <= 21000) {
          product_filter_contents.push(5021);
        } else if (+btu <= 24000) {
          product_filter_contents.push(5123);
        }

        setFilterState({
          ...filterState,
          product_attribute_contents: [
            ...(filterState?.product_attribute_contents || []),
            ...product_filter_contents,
          ],
        });
      }
    }
  }, [btu]);

  useEffect(() => {
    if (kw != null || kww != null) {
      let product_filter_contents = [];
      if (kw) {
        product_filter_contents.push(4180);
      }
      if (kww) {
        product_filter_contents.push(2823);
      }
      const newKw = kw || kww;
      const contentId = generateCalculatorKw(parseFloat(newKw));
      if (contentId) {
        if (contentId.length > 1) {
          contentId.map(item => product_filter_contents.push(item))
        } else {
          product_filter_contents.push(contentId)
        }

      }

      setFilterState({
        ...filterState,
        product_attribute_contents: [
          ...(filterState?.product_attribute_contents || []),
          ...product_filter_contents,
        ],
      });
    }
  }, [kw, kww]);

  useEffect(() => {
    if (litri != null) {
      let product_filter_contents = [];
      product_filter_contents.push(9433, 9163);
      const contentId = generateCalculatorLitri(parseFloat(litri));

      if (contentId) {

        if (contentId.length > 1) {
          contentId.map(item => product_filter_contents.push(item))
        } else {
          product_filter_contents.push(contentId)
        }

      }

      // product_filter_contents.push(9433, 9163);
      // if (+litri <= 30) {
      //   product_filter_contents.push(9554, 9562, 9570);
      // } else if (+litri >= 31 && +litri <= 61) {
      //   product_filter_contents.push(9538);
      // } else if (+litri > 61 && +litri <= 91) {
      //   product_filter_contents.push(9546);
      // } else if (+litri > 91 && +litri <= 121) {
      //   product_filter_contents.push(9530, 9152);
      // } else if (+litri > 121 && +litri <= 151) {
      //   product_filter_contents.push(9445);
      // } else if (+litri > 151 && +litri <= 181) {
      //   product_filter_contents.push(9164);
      // } else if (+litri > 181 && +litri <= 251) {
      //   product_filter_contents.push(9236);
      // } else if (+litri > 251 && +litri <= 351) {
      //   product_filter_contents.push(9360, 9200);
      // } else if (+litri > 351 && +litri <= 551) {
      //   product_filter_contents.push(9489, 9500);
      // } else if (+litri > 551 && +litri <= 751) {
      //   product_filter_contents.push(9511);
      // } else if (+litri < 751) {
      //   product_filter_contents.push(9260, 9456);
      // }

      setFilterState({
        ...filterState,
        product_attribute_contents: [
          ...(filterState?.product_attribute_contents || []),
          ...product_filter_contents,
        ],
      });
    }
  }, [litri]);

  useEffect(() => {
    if (id && !isNaN(id)) {
      getProducts({
        // query: search_params,
        ...filterState,
        subsubcategory_id: id,

        product_attributes: filterState?.product_attributes?.filter(
          (attr) => attr
        ),
        product_attribute_contents:
          filterState?.product_attribute_contents?.filter((attr) => attr),
        product_brands: filterState?.product_brands?.filter((attr) => attr),
        items_per_page: filterState?.items_per_page || items_per_page,
      });
    }
    if (search_result_product) {
      getProducts({
        ...filterState,
        only_discounted_products: promotion_product,
      });
    }

    if (search_params_category_search) {
      getProducts({
        ...filterState,
        // query: search_params_category_search,
        only_discounted_products: promotion_product,
        subsubcategory_id: id,
        category_id: category_slug,
        subcategory_id: sub_category_slug,
        product_brands: filterState?.product_brands?.filter((attr) => attr),
      });
    }

    if (
      !(category_slug || sub_category_slug) &&
      search_params_category_search
    ) {
      getProducts({
        ...filterState,
        // query: search_params_category_search,
        only_discounted_products: promotion_product,
        subsubcategory_id: id,
        product_brands: filterState?.product_brands?.filter((attr) => attr),
      });
    }
    if (category_slug || sub_category_slug) {
      getProducts({
        ...filterState,

        category_id: category_slug,
        subcategory_id: sub_category_slug,
        product_brands: filterState?.product_brands?.filter((attr) => attr),
      });
    }
    if (promotion_product) {
      getProducts({
        ...filterState,
        // query: search_params,

        only_discounted_products: promotion_product,
      });
    }

    getProductAttributeContentList(id, filterState?.product_attribute_contents);

    getProductBrandList(id, category_slug, sub_category_slug);

    history.push({
      pathname: window.location.pathname,
      search: `?category_search=${JSON.stringify(filterState)}`,
    });
  }, [
    id,
    filterState,
    promotion_product,
    search_result_product,
    category_slug,
    product_category,
    product_sub_category,
  ]);

  useEffect(() => {
    if (search_params_category) {
      setFilterState(search_params_category);
    }
  }, []);

  useEffect(() => {
    if (search_params) {
      setFilterState({
        ...filterState,
        query: search_params,
      });
    }
  }, [search_params]);

  // HIDE OR SHOW FILTERS ON MOBILE

  function clickFilters() {
    setShowFilters(!showFilters);
  }

  if (showFilters) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "scroll";
  }

  const resetFilter = () => {
    setFilterState({
      subsubcategory_id: id,
      product_attributes: [],
      subcategory_id_ls: [],
      subsubcategory_id_ls: [],
      items_per_page: 9,
      product_brands: [],
      price_min: "",
      price_max: "",
    });
  };

  const [mobileFilters, setMobileFilters] = useState(false);

  function OpenMobileFilters() {
    setMobileFilters(true);
  }

  function CloseMobileFilters() {
    setMobileFilters(false);
  }

  const next_page_num =
    pagination?.next_page_num ||
    (search_params_category?.page_num &&
      search_params_category?.page_num + 1) ||
    1;
  const total_number_of_products = pagination?.total_number_of_products || 0;
  const columns = !pathname ? 9 : 12;

  return (
    <>
      <main className="main">
        {!(product_category || product_sub_category) && (
          <div className="page-header breadcrumb-wrap">
            <div className="container">
              <div className="breadcrumb">
                <Link to="/" rel="nofollow">
                  LPV
                </Link>
                <span /> {products[0]?.categories?.category?.name}
                <span> {products[0]?.categories?.subsubcategory?.name} </span>
              </div>
            </div>
          </div>
        )}
        <section className="mt-50 mb-50">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className={`col-lg-${columns}`}>
                <div
                  className="custom-show-filters-mobile"
                  onClick={OpenMobileFilters}
                >
                  Filtreaza produsele
                </div>
                {!pathname && (
                  <ShopTopBar
                    setFilterState={setFilterState}
                    filterState={filterState}
                    products={products}
                    total_products={total_number_of_products}
                  />
                )}
                <div className="row product-grid-3">
                  <ShopProduct products={products} pathname={pathname} />
                </div>
                {/*pagination*/}

                <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
                  <ReactPaginate
                    pageCount={Math.ceil(
                      parseInt(total_number_of_products) /
                      parseInt(filterState?.items_per_page || items_per_page)
                    )}
                    forcePage={next_page_num - 2}
                    activePage={next_page_num && next_page_num - 1}
                    itemsCountPerPage={
                      filterState?.items_per_page || items_per_page
                    }
                    totalItemsCount={total_number_of_products}
                    pageRangeDisplayed={2}
                    containerClassName="pagination justify-content-center"
                    activeClassName="active"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link page-link_with__arrow--left"
                    nextLinkClassName="page-link page-link_with__arrow--right"
                    disabledClassName="disabled"
                    nextLabel={<i className="fas fa-chevron-right"></i>}
                    previousLabel={<i className="fas fa-chevron-left"></i>}
                    onPageChange={(e) => {
                      setFilterState({
                        ...filterState,
                        page_num: e.selected + 1,
                        query:
                          filterState?.query ||
                          search_params_category_search?.query ||
                          "",
                      });
                    }}
                  />
                </div>
              </div>
              <div
                className={
                  mobileFilters
                    ? "col-lg-3 primary-sidebar sticky-sidebar mobile-sidebar-visible"
                    : "col-lg-3 primary-sidebar sticky-sidebar mobile-sidebar-hidden"
                }
              >
                <div className="mobile-filter-header">
                  <h4 style={{ margin: "0" }}>Filtreaza Produsele</h4>
                  <i
                    onClick={CloseMobileFilters}
                    style={{
                      color: "red",
                      fontSize: "1.4rem",
                      marginRight: "5px",
                    }}
                    className="fas fa-times"
                  ></i>
                </div>
                {!pathname && (
                  <>
                    {product_category && (
                      <FilterCategories
                        setFilterState={(data) => setFilterState(data)}
                        categoryId={category_slug}
                        filterState={filterState}
                        productCategoryContentList={productCategoryContentList}
                      />
                    )}
                    {product_sub_category && (
                      <FilterSubCategories
                        setFilterState={(data) => setFilterState(data)}
                        filterState={filterState}
                        productSubCategoryList={subCategoryList}
                        subCategoryId={sub_category_slug}
                      />
                    )}

                    <FilterPrice
                      setFilterState={(data) => setFilterState(data)}
                      filterState={filterState}
                      maximum_product_price={maximum_product_price}
                    />
                    <FilterBrand
                      setFilterState={(data) => setFilterState(data)}
                      filterState={filterState}
                      productBrandList={productBrandList}
                    />
                    {!(product_category || product_sub_category) && (
                      <FilterAttributes
                        categoryId={id}
                        setFilterState={(data) => setFilterState(data)}
                        filterState={filterState}
                        productAttributeContentList={
                          productAttributeContentList
                        }
                      />
                    )}
                    <button
                      className="btn btn-sm btn-secondary"
                      style={{ width: "100%" }}
                      onClick={() =>
                        setFilterState({
                          product_attributes: [],
                          product_categories: [],
                          items_per_page: 9,
                          product_brands: [],
                          product_attribute_contents: [],
                        })
                      }
                    >
                      <i
                        onCLick={resetFilter}
                        className="fas fa-times mr-5"
                      ></i>{" "}
                      Reseteaza filtre
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

Shop.propTypes = {
  products: PropTypes.array.isRequired,
  heroBanner: PropTypes.array.isRequired,
  bannerLoading: PropTypes.bool,
};

const mapStateToProps = ({ product }) => ({
  products: product.products,
  pagination: product.pagination,
  productAttributeContentList: product.productAttributeContentList,
  productBrandList: product.productBrandList,
  maximum_product_price: product.maximum_product_price,
  productCategoryContentList: product.categories,
});

export default connect(mapStateToProps, {
  getProducts,
  getProductAttributeContentList,
  getProductBrandList,
})(Shop);
