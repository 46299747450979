import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import requests, { BACKEND_URL } from '../../requests'
import {
  LOADING_TERMS,
  TERMS_LIST,
  LOADING_COOKIES,
  COOKIES_LIST,
  LOADING_PRIVACY,
  PRIVACY_LIST,
  LOADING_RETURN,
  RETURN_LIST
} from '../types/types'

import { errorHandle } from './common'

export const getTermsList = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_TERMS,
      payload: true
    })
    //   Get Token from state

    try {
      const termsData = await axios.get(`${BACKEND_URL}${requests.TERMS_LIST}`)
      dispatch({
        type: LOADING_TERMS,
        payload: false
      })

      dispatch({ type: TERMS_LIST, payload: termsData.data.data })
    } catch (error) {
 
      errorHandle(error, dispatch, LOADING_TERMS)
    }
  }
}

export const getCookiesList = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_COOKIES,
      payload: true
    })
    //   Get Token from state

    try {
      const cookiesData = await axios.get(
        `${BACKEND_URL}${requests.COOKIES_LIST}`
      )
      dispatch({
        type: LOADING_COOKIES,
        payload: false
      })

      dispatch({ type: COOKIES_LIST, payload: cookiesData.data.data })
    } catch (error) {
     
      errorHandle(error, dispatch, LOADING_COOKIES)
    }
  }
}

export const getPrivacyList = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PRIVACY,
      payload: true
    })
    //   Get Token from state

    try {
      const privacyData = await axios.get(
        `${BACKEND_URL}${requests.PRIVACY_LIST}`
      )
      dispatch({
        type: LOADING_PRIVACY,
        payload: false
      })

      dispatch({ type: PRIVACY_LIST, payload: privacyData.data.data })
    } catch (error) {
  
      errorHandle(error, dispatch, LOADING_PRIVACY)
    }
  }
}

export const getReturnList = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_RETURN,
      payload: true
    })
    //   Get Token from state

    try {
      const returnData = await axios.get(
        `${BACKEND_URL}${requests.RETURN_LIST}`
      )
      dispatch({
        type: LOADING_RETURN,
        payload: false
      })

      dispatch({ type: RETURN_LIST, payload: returnData.data.data })
    } catch (error) {
 
      errorHandle(error, dispatch, LOADING_RETURN)
    }
  }
}
