import {
  LOADING_WISHLIST,
  GET_WISHLIST,
  LOADING_CART,
  GET_CARTS,
  VERIFY_VOUCHER,
  LOADING_PAYMENT,
  PAYMENT_SUCCESSFULLY,
  GET_AVAILABLE_DATE,
  LOCAL_STORAGE_CART,
} from "../types/types";

const initialState = {
  loading: false,
  wishlists: [],
  carts: [],
  voucher: {},
  summary: {},
  paymentLoading: false,
  paymentSuccessfully: false,
  availableAppointmentDate: {},
  localStorageCart:{},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_WISHLIST:
      return { ...state, loading: action.payload || false };
    case LOADING_PAYMENT:
      return { ...state, paymentLoading: action.payload || false };
    case GET_WISHLIST:
      return { ...state, wishlists: action.payload };
    case PAYMENT_SUCCESSFULLY:
      return { ...state, paymentSuccessfully: action.payload };

    case LOADING_CART:
      return { ...state, loading: action.payload || false };
    case GET_CARTS:
      return {
        ...state,
        carts: action.payload.carts,
        summary: action.payload.summary,
      };
    case VERIFY_VOUCHER:
      return { ...state, voucher: action.payload };
    case GET_AVAILABLE_DATE:
      return { ...state, availableAppointmentDate: action.payload };
    case LOCAL_STORAGE_CART:
      return { ...state, localStorageCart: action.payload };
    default:
      return state;
  }
}
