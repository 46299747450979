import React, { useEffect, useState } from "react";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";

const FilterPrice = ({
  setFilterState,
  filterState,
  maximum_product_price,
}) => {
  const [min, setMin] = useState(filterState?.price_min);
  const [max, setMax] = useState(filterState?.price_max);

  useEffect(() => {
    setMin(filterState?.price_min || 0);
    setMax(filterState?.price_max || maximum_product_price);
  }, [filterState?.price_max, filterState?.price_min, maximum_product_price]);
  const maximumValue = 100000;
  const handlePriceCheck = () => {
    setFilterState({
      ...filterState,
      price_min: min,
      price_max: max,
    });
  };

  function handleChangeValue(value) {
    const minValue = value?.[0] || 0;
    const maxValue = value?.[1] || 0;
    if (maxValue <= maximumValue) {
      setMin(minValue);
      setMax(maxValue);
    }
  }

  return (
    <div className="sidebar-widget price_range range mb-30">
      <div className="widget-header position-relative mb-20 pb-10">
        <h5 className="widget-title mb-10">Filtru Pret</h5>
      </div>
      <div className="price-filter">
        <Range
          min={0}
          max={maximum_product_price || max}
          defaultValue={[min, max]}
          onChange={handleChangeValue}
          tipFormatter={(value) => `${value}%`}
          value={[min, max]}
        />
        <div className="mt-4">
          <input
            type="number"
            placeholder="Pret minim"
            value={min}
            onChange={(e) => handleChangeValue([e.target.value, max])}
          />
        </div>
        <div className="mt-3">
          <input
            type="number"
            placeholder="Pret maxim"
            value={max}
            onChange={(e) => handleChangeValue([min, e.target.value])}
          />
        </div>
      </div>
      <button
        onClick={handlePriceCheck}
        className="btn btn-sm btn-default mt-3"
        style={{ width: "100%" }}
      >
        <i className="fad fa-sort-down mr-5"></i> Filtreaza
      </button>
    </div>
  );
};

export default FilterPrice;
