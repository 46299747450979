import React from "react";
import {
  storeProductInCart,
  deleteWishlist
} from "../../../redux/actions/wishlist";
import { connect } from "react-redux";
import ReactStars from "react-rating-stars-component";

import { Link } from "react-router-dom";
import slug from "slug";

const WishListTable = ({ storeProductInCart, deleteWishlist, wishlists }) => {
  const handleAddToCart = (product) => {
    storeProductInCart({
      products: [{ product_id: product?.id, quantity: 1 }]
    });
  };

  const handleDeleteFromWishlist = (product) => {
    deleteWishlist({
      product_id_ls: [product?.id]
    });
  };

  return (
    <>
      <section className="mt-50 mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table shopping-summery text-center">
                  <thead>
                    <tr className="main-heading">
                      <th scope="col" colSpan={2}>
                        Produs
                      </th>
                      <th scope="col">Pret</th>
                      <th scope="col">Actiune</th>
                      <th scope="col">Sterge</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wishlists?.map((wishlist) => (
                      <tr>
                        <td className="image product-thumbnail">
                          <Link
                            to={`/product/${wishlist?.id}/${slug(
                              wishlist?.title || "product",
                              "_"
                            )}`}
                          >
                            <img
                              src={
                                wishlist?.images?.[0]?.image ||
                                "images/products/product-1.jpg"
                              }
                              alt={wishlist?.title}
                            />
                          </Link>
                        </td>
                        <td className="product-des product-name">
                          <Link
                            to={`/product/${wishlist?.id}/${slug(
                              wishlist?.title || "product",
                              "_"
                            )}`}
                          >
                            <h5 className="product-name">{wishlist?.title}</h5>
                          </Link>
                          <p className="font-xs">
                            <ReactStars
                              count={wishlist?.average_rating}
                              size={24}
                              emptyIcon={<i className="far fa-star"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              color="#ffd700"
                              edit={false}
                            />
                            {wishlist?.reviews.length} Recenzii
                          </p>
                        </td>
                        <td className="price" data-title="Pret">
                          <span>{wishlist?.price || "None"} Lei</span>
                        </td>
                        <td className="text-right" data-title="Cos">
                          <button
                            className="btn btn-sm"
                            onClick={() => handleAddToCart(wishlist)}
                          >
                            <i className="fi-rs-shopping-bag mr-5" />
                            Adauga in cos
                          </button>
                        </td>
                        <td className="action" data-title="Sterge">
                          <a onClick={() => handleDeleteFromWishlist(wishlist)}>
                            <i
                              className="fas fa-trash"
                              style={{ color: "red" }}
                            ></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  storeProductInCart,
  deleteWishlist
})(WishListTable);
