import axios from "axios";
import { toastr } from "react-redux-toastr";
import requests, { BACKEND_URL } from "../../requests";
import {
  LOADING_BANNER,
  HEROS_BANNER,
  LOADING_FEAUTURED_BANNER,
  FEAUTURED_BANNER,
  LOADING_COMMERCIAL_BANNER,
  COMMERCIAL_BANNER,
  LOADING_PARTNER_BANNER,
  PARTNER_BANNER,
  GET_FIRST_BANNER,
  GET_TWO_BANNER,
} from "../types/types";

import { errorHandle, tokenConfig } from "./common";

export const getHerosData = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_BANNER,
      payload: true,
    });
    //   Get Token from state

    try {
      const herosData = await axios.get(
        `${BACKEND_URL}${requests.HEROS_BANNER}`
      );
      dispatch({
        type: LOADING_BANNER,
        payload: false,
      });

      dispatch({ type: HEROS_BANNER, payload: herosData.data.data });
    } catch (error) {
     
      errorHandle(error, dispatch, LOADING_BANNER);
    }
  };
};

export const getFeauturedBanner = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_FEAUTURED_BANNER,
      payload: true,
    });
    //   Get Token from state

    try {
      const feauturedBannerData = await axios.get(
        `${BACKEND_URL}${requests.FEAUTURED_BANNER}`
      );
      dispatch({
        type: LOADING_FEAUTURED_BANNER,
        payload: false,
      });
      dispatch({
        type: FEAUTURED_BANNER,
        payload: feauturedBannerData.data.data,
      });
    } catch (error) {

      errorHandle(error, dispatch, LOADING_FEAUTURED_BANNER);
    }
  };
};

export const getCommercialBanner = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_COMMERCIAL_BANNER,
      payload: true,
    });
    //   Get Token from state

    try {
      const commercialBannerData = await axios.get(
        `${BACKEND_URL}${requests.COMMERCIAL_BANNER}`
      );
      dispatch({
        type: LOADING_COMMERCIAL_BANNER,
        payload: false,
      });
      dispatch({
        type: COMMERCIAL_BANNER,
        payload: commercialBannerData.data.data,
      });
    } catch (error) {
 
      errorHandle(error, dispatch, LOADING_COMMERCIAL_BANNER);
    }
  };
};

export const getPartnerBanner = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PARTNER_BANNER,
      payload: true,
    });
    //   Get Token from state

    try {
      const partnerBannerData = await axios.get(
        `${BACKEND_URL}${requests.PARTNER_BANNER}`
      );
      dispatch({
        type: LOADING_PARTNER_BANNER,
        payload: false,
      });

      dispatch({
        type: PARTNER_BANNER,
        payload: partnerBannerData.data.data,
      });
    } catch (error) {
   
      errorHandle(error, dispatch, LOADING_PARTNER_BANNER);
    }
  };
};

export const getFirstBanner = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PARTNER_BANNER,
      payload: true,
    });
    //   Get Token from state

    try {
      const firstBanner = await axios.get(
        `${BACKEND_URL}${requests.BANNER_ONE}`
      );
      dispatch({
        type: LOADING_PARTNER_BANNER,
        payload: false,
      });

      dispatch({
        type: GET_FIRST_BANNER,
        payload: firstBanner.data.data,
      });
    } catch (error) {
     
      errorHandle(error, dispatch, LOADING_PARTNER_BANNER);
    }
  };
};

export const getSecondBanner = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PARTNER_BANNER,
      payload: true,
    });
    //   Get Token from state

    try {
      const secondBanner = await axios.get(
        `${BACKEND_URL}${requests.BANNER_TWO}`
      );
      dispatch({
        type: LOADING_PARTNER_BANNER,
        payload: false,
      });

      dispatch({
        type: GET_TWO_BANNER,
        payload: secondBanner.data.data,
      });
    } catch (error) {
     
      errorHandle(error, dispatch, LOADING_PARTNER_BANNER);
    }
  };
};
