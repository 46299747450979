import CryptoJS from "crypto-js";
const secret_key = "test_secret_key_1";

export const encryptedString = (data) => {
  const first_enc = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secret_key
  ).toString();

  const second_enc = CryptoJS.AES.encrypt(
    JSON.stringify(first_enc),
    secret_key
  ).toString();

  const third_enc = CryptoJS.AES.encrypt(
    JSON.stringify(second_enc),
    secret_key
  ).toString();

  return first_enc;
};

export const decryptedString = (hash) => {
  const hashString = hash && hash?.split(" ").join("+");
  const bytes = hashString && CryptoJS.AES.decrypt(hashString, secret_key);
  return bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const validationInput = (event) => {
  const { value, min, max } = event.target;
  if (+value <= +max) {
    return value;
  } else {
    return max;
  }
  // return Math.max(Number(min), Math.min(Number(max), Number(value)));
};
