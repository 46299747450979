import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  getCartProduct,
  getTotal,
  getTotalApi,
  UpdateCartQty,
  DeleteCart,
  UpdateServiceAppointmentLocal,
  UpdateServiceAppointmentQuestion,
  RemoveServices,
} from "../../../helper/cart";

import { Link } from "react-router-dom";
import slug from "slug";

import ReactHtmlParser from "react-html-parser";
import {
  getCarts,
  updateInCart,
  deleteCart,
  addServiceInCart,
  getAvailableDate,
  createAppointmentUser,
} from "../../../redux/actions/wishlist";

import { URL } from "../../../requests";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  getProductQuestionBundleRead,
  getQuestionDetail,
  getServiceList,
  removeServices,
  getProductDetail,
} from "../../../redux/actions/products";
import DatePicker from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";

const CartTable = ({
  getCarts,
  carts,
  updateInCart,
  deleteCart,
  getProductQuestionBundleRead,
  questionBundleDetail,
  getQuestionDetail,
  questionDetail,
  addServiceInCart,
  getServiceList,
  serviceList,
  user,
  removeServices,
  availableAppointmentDate,
  getAvailableDate,
  createAppointmentUser,
}) => {
  const [availableTimes, setAvailableTimes] = useState([]);
  const rangeOfTime = [
    "0 - 2",
    "2 - 4",
    "4 - 6",
    "6 - 8",
    "8 - 10",
    "10 - 12",
    "12 - 14",
    "14 - 16",
    "16 - 18",
    "18 - 20",
    "20 - 22",
    "22 - 24",
  ];

  const handleDateChange = (date) => {
    const newDate = moment(date).format("YYYY-MM-DD");
    const times = availableAppointmentDate[newDate];
    const newTimes = times
      .map((time, index) => times[index])
      .filter((time) => time);

    setSelectDate({
      ...selectDate,
      show: true,
      appointment_date: newDate,
      appointment_time: newTimes?.[0] || null,
    });

    setAvailableTimes(newTimes);
  };

  const [products, setProducts] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [questions, setQuestions] = useState({});
  const [selectedQuestion, setSelectedQuestion] = useState({
    index: 0,
    question: {},
  });

  const yesterday = moment().subtract(0, "day");
  const afterTwoMonth = moment().add(55, "days");
  const avilableDate =
    availableAppointmentDate &&
    Object.keys(availableAppointmentDate)
      ?.map?.((key) =>
        Object.values(availableAppointmentDate[key]).length > 0 ? key : null
      )
      .filter((key) => key);

  const disabledDate = (current) => {
    return (
      current.isAfter(yesterday) &&
      current.isBefore(afterTwoMonth) &&
      avilableDate.includes(current.format("YYYY-MM-DD"))
    );
  };

  const returnServiceDataByCartId = (id) => {
    const serviceData = serviceList?.filter(
      (service) => service?.order_product_id == id
    );
    
    return serviceData?.[0]?.services;
  };

  const [selectService, setSelectService] = useState([]);
  const [selectServiceDetails, setSelectServiceDetails] = useState({
    show: false,
    details: [],
  });
  const [selectDate, setSelectDate] = useState({
    show: false,
    appointment_date: "",
    appointment_time: "",
    product: true,
  });

  const handleGetProduct = () => {
    if (localStorage.getItem("access")) {
      getCarts();
    } else {
      const cartProduct = getCartProduct();
      setProducts(cartProduct?.products);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access")) {
      setProducts(carts);
      const totalValue = getTotalApi(carts);
      setTotalValue(totalValue?.totalAmount);
      getServiceList();
    } else {
      const totalValue = getTotal(carts);
      setTotalValue(totalValue?.totalAmount);
    }
  }, [carts]);

  useEffect(() => {
    handleGetProduct();
    getAvailableDate();
  }, []);

  useEffect(() => {
    if (questionBundleDetail?.id) {
      setSelectedQuestion({
        index: selectedQuestion.index + 1,
        question: questionBundleDetail?.questions?.[selectedQuestion.index],
      });
      setQuestions(questionBundleDetail);
    }
  }, [questionBundleDetail]);

  const redirectTONext = () => {
    // TODO: if will you get one answer then it will skip
    if (questionDetail?.answers?.length == 1) {
      handleSelectedQuestions(questionDetail?.answers?.[0]?.id);
    }
  };
  const [displayService, setDisplayService] = useState(false);
  
  const checkServices = () => {
    let display_service_pop = false;

    for (let i = 0; i < products?.length; i++) {
      let serviceDetails = returnServiceDataByCartId(products[i]?.id);
      serviceDetails =
        serviceDetails?.details || products?.[i]?.service_question_data || [];
      
      if (
        !(
          products[i]?.is_custom_product ||
          products[i]?.product?.is_custom_product
        ) &&
        serviceDetails?.length <= 0
      ) {
        display_service_pop = true;
      }
    }
    setDisplayService(!display_service_pop);
  };
  useEffect(() => {
    checkServices();
  }, [products, serviceList]);

  useEffect(() => {
    if (questionDetail?.id) {
      setSelectedQuestion({
        ...selectedQuestion,
        question: questionDetail,
      });
    }
    redirectTONext();
  }, [questionDetail]);

  const handleQtyChange = (product, plus, qty) => {
    if (localStorage.getItem("access")) {
      updateInCart({
        product_item_id: product?.id,
        quantity: plus ? parseInt(qty) + 1 : parseInt(qty) - 1,
      });
      getCarts();
    } else {
      UpdateCartQty(product?.id, plus);
      handleGetProduct();
    }
  };

  const handleRemoveProductFromCart = (product) => {
    if (localStorage.getItem("access")) {
      deleteCart({
        product_item_id: product?.id,
      });
    } else {
      DeleteCart(product?.id);
      handleGetProduct();
      toastr.success("Delete product from Cart");
    }
  };

  const [modal, setModal] = useState({
    show: false,
  });

  const toggle = (id, show = false, cartId) => {
    if (id) {
      getProductQuestionBundleRead(id);
    }
    setModal({
      id,
      show,
      cartId,
    });
    setSelectService([]);
    setSelectServiceDetails({ show: false, details: [] });
    setQuestions({});
    setSelectedQuestion({
      index: 0,
      question: {},
    });
    setSelectDate({
      show: false,
      appointment_date: "",
      appointment_time: "",
      product: true,
    });
  };

  // CALCULATOR QUESTIONS

  const [existaAerMontat, setExistaAerMontat] = useState();
  const [selectAnswerId, setSelectAnswerId] = useState();

  const [existaGura, setExistaGura] = useState(false);

  const [seDemonteazaAC, setSeDemonteazaAC] = useState(false);

  const [cladireIzolata, setCladireIzolata] = useState(false);

  const [existaConsole, setExistaConsole] = useState(false);

  const [kitSurub, setKitSurub] = useState(false);

  const [metriiNecesari, setMetriiNecesari] = useState();

  const [montajAer, setMontajAer] = useState(true);

  // CALCULATOR PRICE

  const [priceToAdd, setPriceToAdd] = useState(0);

  // CALCULATOR STEPS

  const [calculatorStep, setCalculatorStep] = useState("start");

  function ExistaAerConditionat() {
    if (existaAerMontat) {
      setCalculatorStep("daExistaAerConditionat");
    } else setCalculatorStep("nuExistaAerConditionat");
  }

  function ExistaGuraDeTraseu() {
    if (existaGura) {
      setCalculatorStep("punctul3");
    } else {
      setCalculatorStep("punctul3");
      setPriceToAdd(priceToAdd + 100);
    }
  }

  function SeDemonteazaAC() {
    if (seDemonteazaAC) {
      setCalculatorStep("punctul3");
      setPriceToAdd(priceToAdd + 150);
    } else {
      setCalculatorStep("punctul3");
    }
  }

  function EsteCladireaIzolata() {
    if (cladireIzolata) {
      setPriceToAdd(priceToAdd + 50);
      setCalculatorStep("punctul4");
    } else {
      setCalculatorStep("punctul4");
    }
  }

  function ExistaConsolePentruUnitate() {
    if (existaConsole) {
      setCalculatorStep("punctul5");
    } else {
      setCalculatorStep("punctul5");
      setPriceToAdd(priceToAdd + 50);
    }
  }

  function ExistaKitSurub() {
    if (kitSurub) {
      setCalculatorStep("punctul6");
    } else {
      setCalculatorStep("punctul6");
      setPriceToAdd(priceToAdd + 50);
    }
  }

  function EstimareMetrii() {
    if (metriiNecesari === 1) {
      setPriceToAdd(priceToAdd + 65);
      setCalculatorStep("punctul7");
    }
    if (metriiNecesari === 2) {
      setPriceToAdd(priceToAdd + 130);
      setCalculatorStep("punctul7");
    }
    if (metriiNecesari === 3) {
      setPriceToAdd(priceToAdd + 195);
      setCalculatorStep("punctul7");
    }
    if (metriiNecesari === 4) {
      setPriceToAdd(priceToAdd + 260);
      setCalculatorStep("punctul7");
    } else {
      setPriceToAdd(priceToAdd + 325);
      setCalculatorStep("punctul7");
    }
  }

  const handleSelectedQuestions = (id) => {
    if (id) {
      getQuestionDetail(id);
    } else {
      if (questionBundleDetail?.questions?.[selectedQuestion.index]?.id) {
        setSelectedQuestion({
          index: selectedQuestion.index + 1,
          question: questionBundleDetail?.questions?.[selectedQuestion.index],
        });
      } else {
        setQuestions();
        setSelectedQuestion({
          index: 0,
          question: {},
        });
        setModal({
          show: false,
        });
        if (selectService.length > 0) {
          // addServiceInCart({ services: selectService }, modal?.cartId);
          setSelectServiceDetails({
            details: selectServiceDetails?.details,
            show: true,
            cartId: modal?.cartId,
          });
          setSelectDate({
            show: true,
            appointment_time: "",
            appointment_date: "",
          });
        } else {
          setSelectService([]);
        }
      }
    }
    setExistaAerMontat();
    setSelectAnswerId();
  };

  const handleAddService = (id) => {
    if (id) {
      const service = [...(selectService || []), id];
      const serviceDetails = [
        ...(selectServiceDetails?.details || []),
        selectedQuestion?.question,
      ];
      setSelectService(service);
      setSelectServiceDetails({ show: false, details: serviceDetails });
      if (questionBundleDetail?.questions?.[selectedQuestion.index]?.id) {
        setSelectedQuestion({
          index: selectedQuestion.index + 1,
          question: questionBundleDetail?.questions?.[selectedQuestion.index],
        });
      } else {
        setQuestions();
        setSelectedQuestion({
          index: 0,
          question: {},
        });
        setModal({
          show: false,
        });
        if (service.length > 0) {
          setSelectServiceDetails({
            details: serviceDetails,
            show: true,
            cartId: modal?.cartId,
          });
          setSelectDate({
            show: true,
            appointment_time: "",
            appointment_date: "",
          });
        } else {
          setSelectService([]);
        }
      }
    }
    setExistaAerMontat();
    setSelectAnswerId();
  };

  const handleCreateAppointment = (card_id) => {
    const appointment_time = selectDate.appointment_time;
    let appointment_time_next = availableTimes.indexOf(appointment_time) + 1;
    let new_appointment_time = `${appointment_time}`;

    if (appointment_time_next && availableTimes?.[appointment_time_next]) {
      new_appointment_time += `,${availableTimes[appointment_time_next]}`;
    }
    if (localStorage.getItem("access")) {
      updateInCart({
        temp_appointment_date: selectDate.appointment_date,
        temp_appointment_time: new_appointment_time,
        product_item_id: card_id,
      });
    } else {
      UpdateServiceAppointmentLocal(card_id, {
        ...selectDate,
        temp_appointment_date: selectDate.appointment_date,
        temp_appointment_time: new_appointment_time,
        product_item_id: card_id,
      });
    }

    setSelectDate({ appointment_time: "", appointment_date: "", show: false });
  };

  const handleConfirmationService = () => {
    const service = selectService;
    const defaultService = questionBundleDetail?.default_services?.map(
      (defaultService) => defaultService?.id
    );
    // const appointment_time = selectDate.appointment_time;
    // let appointment_time_next = availableTimes.indexOf(appointment_time) + 1;
    // let new_appointment_time = `${appointment_time}`;

    // if (appointment_time_next && availableTimes?.[appointment_time_next]) {
    //   new_appointment_time += `,${availableTimes[appointment_time_next]}`;
    // }
    if (localStorage.getItem("access")) {
      addServiceInCart(
        {
          services: [...service, ...defaultService],
        },
        selectServiceDetails?.cartId
      );
    } else {
      UpdateServiceAppointmentQuestion(
        selectServiceDetails?.cartId,
        [...service, ...defaultService],
        [
          ...selectServiceDetails?.details,
          ...questionBundleDetail?.default_services,
        ]
      );
    }
    handleCreateAppointment(selectServiceDetails?.cartId);
    setSelectService([]);
    setSelectServiceDetails({
      show: false,
      details: [],
    });
  };

  const removeService = (id) => {
    if (localStorage.getItem("access")) {
      removeServices(id);
    } else {
      RemoveServices(id);
    }
  };

  const [closeInfo, setCloseInfo] = useState(true);

  const handleCloseInfo = () => {
    setCloseInfo(false);
  };

  return (
    <>
      <table className="table shopping-summery text-center clean">
        <thead>
          <tr className="main-heading">
            <th scope="col">Poza</th>
            <th style={{ textAlign: "left" }} scope="col">
              Produs
            </th>
            <th style={{ textAlign: "left" }} className="no-tablet" scope="col">
              Pret Buc
            </th>
            <th style={{ textAlign: "left" }} scope="col">
              Cantitate
            </th>
            <th style={{ textAlign: "left" }} scope="col">
              Total
            </th>
            <th className="no-mobile" scope="col">
              Elimina
            </th>
          </tr>
        </thead>
        <tbody>
          {products?.map((product) => {
            
            let serviceDetails = returnServiceDataByCartId(product?.id);
            serviceDetails = product?.service_question_data || serviceDetails;
            

            return (
              <>
                <tr>
                  <td
                    style={{ position: "relative" }}
                    className="image product-thumbnail"
                  >
                    <Link
                      to={`/product/${
                        product?.product?.id || product?.id
                      }/${slug(product?.title || "product", "_")}`}
                    >
                      <img
                        className="cart-phone-image"
                        src={
                          product?.product?.images?.[0]?.image ||
                          product?.images?.[0]?.image
                        }
                        alt={product?.product?.title}
                      />
                    </Link>
                    <a
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                      onClick={() => handleRemoveProductFromCart(product)}
                      className="text-muted mobile-delete-product"
                    >
                      <i
                        style={{ fontSize: "2rem" }}
                        className="fas fa-times-circle"
                      ></i>
                    </a>
                  </td>
                  <td
                    style={{ textAlign: "left" }}
                    className="product-des product-name no-tablet"
                  >
                    <Link
                      to={`/product/${
                        product?.product?.id || product?.id
                      }/${slug(product?.title || "product", "_")}`}
                    >
                      <h5 className="product-name">
                        {product?.product?.title}
                      </h5>
                    </Link>
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                      }}
                      className="font-xs"
                    >
                      <span>
                        Brand:{" "}
                        {product?.product?.brand_name || product?.brand_name}
                      </span>
                      {product?.product?.discount_amount ||
                      product?.discount_amount > 0 ? (
                        <span style={{ color: "orange" }}>
                          Discount:{" "}
                          {product?.product?.discount_amount ||
                            product?.discount_amount}{" "}
                          RON
                        </span>
                      ) : null}
                    </div>
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <div className="cart-table-mobile">
                      <div
                        style={{ fontWeight: "600" }}
                        className="cart-data-title"
                      >
                        Pret
                      </div>
                      <div>
                        <span style={{ fontWeight: "600" }}>
                          {product?.product?.price || product?.price} RON
                        </span>
                        <br />
                        {product?.product?.discount_amount > 0 ||
                        product?.product?.discount_percentage_amount > 0 ? (
                          <span style={{ textDecoration: "line-through" }}>
                            {product?.product?.price_including_vat ||
                              product?.price_including_vat}
                            RON
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  <td style={{ textAlign: "left" }} className="text-center">
                    <div className="cart-table-mobile">
                      <div
                        style={{ fontWeight: "600" }}
                        className="cart-data-title"
                      >
                        Cantitate
                      </div>
                      <div className="detail-qty border radius">
                        <a
                          onClick={() =>
                            handleQtyChange(
                              product,
                              false,
                              product?.quantity || 1
                            )
                          }
                          className="qty-down"
                        >
                          <i
                            style={{ fontSize: ".7rem" }}
                            className="far fa-chevron-down"
                          ></i>
                        </a>
                        <span className="qty-val">
                          {product?.qty || product?.quantity}
                        </span>
                        <a
                          onClick={() =>
                            handleQtyChange(
                              product,
                              true,
                              product?.qty || product?.quantity || 1
                            )
                          }
                          className="qty-up"
                        >
                          <i
                            style={{ fontSize: ".7rem" }}
                            className="far fa-chevron-up"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td style={{ textAlign: "left" }} className="text-right">
                    <div className="cart-table-mobile">
                      <div
                        style={{ fontWeight: "600" }}
                        className="cart-data-title"
                      >
                        Total
                      </div>
                      <span>
                        {(product?.product?.price_including_vat ||
                          product?.price_including_vat) *
                          (product?.qty || product?.quantity)}{" "}
                        RON
                      </span>
                    </div>
                  </td>
                  <td className="action no-mobile">
                    <div className="cart-table-mobile">
                      <div className="cart-data-title">Elimina produs</div>
                      <a
                        onClick={() => handleRemoveProductFromCart(product)}
                        className="text-muted"
                      >
                        <i
                          style={{ color: "red", fontSize: "1.2rem" }}
                          className="fas fa-times-circle"
                        ></i>
                      </a>
                    </div>
                  </td>
                </tr>

                {product?.question_bundle ||
                product?.product?.question_bundle ? (
                  <>
                    {serviceDetails?.length > 0 && (
                      <>
                        <tr>
                          <td
                            colSpan="10"
                            style={{
                              padding: "20px 0",
                              borderTop: "1px solid #efefef",
                            }}
                            className="cart-services-td"
                          >
                            <div
                              style={{ flexWrap: "wrap", gap: "10px" }}
                              className="cart-service-container"
                            >
                              {serviceDetails?.map((serviceDetail) => (
                                <div
                                  className="cart-service-wrapper"
                                  style={{ width: "32%" }}
                                >
                                  {ReactHtmlParser(serviceDetail?.icon)}
                                  <div className="cart-service-info">
                                    <div>
                                      <span
                                        data-tip
                                        data-for={serviceDetail?.service_title}
                                        className="cart-service-title text-limit-2"
                                      >
                                        {serviceDetail?.service_title}
                                      </span>
                                      <ReactTooltip
                                        id={serviceDetail?.service_title}
                                        type="dark"
                                      >
                                        <span style={{ cursor: "help" }}>
                                          {serviceDetail?.service_title}
                                        </span>
                                      </ReactTooltip>
                                    </div>
                                    <div>
                                      <span className="cart-service-price">
                                        {serviceDetail?.service_title ===
                                          "Metal" ||
                                        serviceDetail?.service_title ===
                                          "Pex" ||
                                        serviceDetail?.service_title ===
                                          "Pexal" ||
                                        serviceDetail?.service_title ===
                                          "PPR" ||
                                        serviceDetail?.service_title ===
                                          "Cupru" ? (
                                          <p
                                            style={{
                                              color: "orange",
                                              fontSize: ".9rem",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            Informatie - Instalatie existenta
                                          </p>
                                        ) : serviceDetail?.charge_including_vat >
                                          0 ? (
                                          <span>
                                            {
                                              serviceDetail?.charge_including_vat
                                            }{" "}
                                            RON
                                          </span>
                                        ) : (
                                          <span style={{ fontStyle: "italic" }}>
                                            Urmeaza sa fiti contactat dupa
                                            plasarea comenzii
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div
                              style={{
                                marginTop: "15px",
                                marginLeft: "15px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <i
                                    style={{
                                      fontSize: "1rem",
                                      marginRight: "5px",
                                    }}
                                    className="far fa-calendar-alt"
                                  ></i>
                                  Data:{" "}
                                  {product?.temp_appointment_date ||
                                    product?.appointment_data
                                      ?.temp_appointment_date}{" "}
                                  <i
                                    style={{
                                      fontSize: "1rem",
                                      margin: "0 5px 0 5px",
                                    }}
                                    className="far fa-clock"
                                  ></i>
                                  Ora:{" "}
                                  {product?.temp_appointment_time?.[0] ||
                                    product?.appointment_data
                                      ?.temp_appointment_time?.[0]}
                                  :00
                                </span>
                              </div>
                              <button
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                  border: "none",
                                  backgroundColor: "#a0282d",
                                  padding: "10px",
                                  borderRadius: "5px",
                                }}
                                onClick={() => removeService(product?.id)}
                              >
                                <p
                                  style={{
                                    color: "#fff",
                                    fontWeight: "500",
                                    fontSize: ".9rem",
                                  }}
                                >
                                  Elimina servicii si programare
                                </p>
                                <i
                                  style={{ color: "#fff", fontSize: "1rem" }}
                                  className="fal fa-times"
                                ></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                    {serviceDetails?.length <= 0 && (
                      <tr style={{ textAlign: "left" }}>
                        <td colSpan="10" className="cart-services-td">
                          <button
                            onClick={() => {
                              toggle(
                                product?.question_bundle ||
                                  product?.product?.question_bundle,
                                true,
                                product?.id
                              );
                              handleCloseInfo();
                            }}
                            type="button"
                            className={
                              closeInfo
                                ? "btn btn-secondary custom-button-checkout glow"
                                : "btn btn-secondary custom-button-checkout"
                            }
                            style={{ zIndex: "11", position: "relative" }}
                          >
                            <i
                              style={{ marginRight: "10px" }}
                              className="fas fa-cog"
                            ></i>
                            Servicii recomandate
                          </button>
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <>
                    {!(
                      product?.appointment_data?.temp_appointment_date ||
                      product?.appointment_data?.temp_appointment_time ||
                      product?.temp_appointment_date ||
                      product?.temp_appointment_time
                    ) &&
                      (product?.is_custom_product ||
                        product?.product?.is_custom_product) && (
                        <tr style={{ textAlign: "left" }}>
                          <td colSpan="10" className="cart-services-td">
                            <button
                              onClick={() => {
                                setSelectDate({
                                  show: true,
                                  appointment_time: "",
                                  appointment_date: "",
                                  card_id: product?.id,
                                });
                              }}
                              type="button"
                              className={
                                closeInfo
                                  ? "btn btn-secondary custom-button-checkout glow"
                                  : "btn btn-secondary custom-button-checkout"
                              }
                              style={{ zIndex: "11", position: "relative" }}
                            >
                              <i
                                style={{ marginRight: "10px" }}
                                className="fas fa-cog"
                              ></i>
                              Programeaza serviciul
                            </button>
                          </td>
                        </tr>
                      )}
                    {(product?.appointment_data?.temp_appointment_date ||
                      product?.appointment_data?.temp_appointment_time ||
                      product?.temp_appointment_date ||
                      product?.temp_appointment_time) && (
                      <tr style={{ textAlign: "left" }}>
                        <td colSpan="10" className="cart-services-td">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              <i
                                style={{
                                  fontSize: "1rem",
                                  marginRight: "5px",
                                }}
                                className="far fa-calendar-alt"
                              ></i>
                              Data:{" "}
                              {product?.appointment_data
                                ?.temp_appointment_date ||
                                product?.temp_appointment_date}{" "}
                              <i
                                style={{
                                  fontSize: "1rem",
                                  margin: "0 5px 0 5px",
                                }}
                                className="far fa-clock"
                              ></i>
                              Ora:{" "}
                              {product?.appointment_data
                                ?.temp_appointment_time?.[0] ||
                                product?.temp_appointment_time?.[0]}
                              :00
                            </span>
                          </div>
                          <button
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                              border: "none",
                              backgroundColor: "#a0282d",
                              padding: "10px",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              if (localStorage.getItem("access")) {
                                updateInCart({
                                  temp_appointment_date: null,
                                  temp_appointment_time: null,
                                  product_item_id: product?.id,
                                });
                              } else {
                                RemoveServices(product?.id);
                              }
                            }}
                          >
                            <p
                              style={{
                                color: "#fff",
                                fontWeight: "500",
                                fontSize: ".9rem",
                              }}
                            >
                              Elimina programare
                            </p>
                            <i
                              style={{ color: "#fff", fontSize: "1rem" }}
                              className="fal fa-times"
                            ></i>
                          </button>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </>
            );
          })}
        </tbody>
      </table>
      <Modal
        style={{ paddingTop: "40px !important" }}
        isOpen={modal?.show}
        toggle={() => toggle()}
      >
        <ModalHeader
          className="custom-modal-header-cart"
          toggle={() => toggle()}
          style={{ display: "flex" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 10px",
                backgroundColor: "rgba(0, 0, 0 ,0.4)",
                color: "#fff",
                marginTop: "-80px",
                borderRadius: "5px",
              }}
              className="card-2"
            >
              <i
                style={{ marginRight: "10px", fontSize: "1.1rem" }}
                className="far fa-question-circle"
              ></i>
              <span>{questions?.title}</span>
            </div>
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: "0", marginTop: "-1px" }}>
          <>
            {!selectedQuestion?.question?.is_service ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "5px",
                    padding: "15px",
                    backgroundColor: "#ffb53e",
                  }}
                >
                  <div>
                    <i
                      style={{
                        color: "#fff",
                        marginRight: "10px",
                        fontSize: "1rem",
                      }}
                      className="fas fa-cog"
                    ></i>
                  </div>
                  <span
                    style={{
                      fontSize: "1.2rem",
                      color: "#fff",
                      fontWeight: "500",
                    }}
                  >
                    {selectedQuestion?.question?.question}
                  </span>
                </div>

                {selectedQuestion?.question?.answers?.length > 0 ? (
                  selectedQuestion?.question?.answers?.map((answer) => (
                    <FormGroup
                      style={{
                        borderBottom: "1px solid #efefef",
                        padding: "15px",
                      }}
                      check
                      className="answer-action"
                    >
                      <Label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          padding: "10px 0",
                        }}
                        onClick={() => setSelectAnswerId(answer?.id)}
                      >
                        <Input
                          type="radio"
                          name="questionAnswer"
                          onChange={() => setSelectAnswerId(answer?.id)}
                          checked={selectAnswerId == answer?.id}
                          value={true}
                          style={{
                            fontSize: "1rem",
                            marginLeft: "15px",
                            flexShrink: "0",
                          }}
                        />
                        <span style={{ fontWeight: "600" }}>
                          {answer?.service_title || answer?.question}
                        </span>
                      </Label>
                    </FormGroup>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      padding: "20px 10px",
                    }}
                  >
                    <div
                      style={{
                        border: "2px solid #0275d8",
                        borderRadius: "5px",
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "50%",
                        fontSize: "1rem",
                        fontWeight: "600",
                      }}
                      onClick={() => setExistaAerMontat(true)}
                      className={`answer-action ${
                        existaAerMontat == true && "answer_action_select"
                      }`}
                    >
                      Da
                    </div>
                    <div
                      style={{
                        border: "2px solid #0275d8",
                        borderRadius: "5px",
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "50%",
                        fontSize: "1rem",
                        fontWeight: "600",
                      }}
                      onClick={(event) => setExistaAerMontat(false)}
                      className={`answer-action ${
                        existaAerMontat == false && "answer_action_select"
                      }`}
                    >
                      Nu
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "5px",
                    padding: "15px",
                    backgroundColor: "#ffb53e",
                  }}
                >
                  <i
                    style={{
                      fontSize: "1rem",
                      color: "#fff",
                      marginRight: "10px",
                    }}
                    className="fas fa-cog custom-question-icon"
                  ></i>
                  <span style={{ fontSize: "1.2rem", color: "#fff" }}>
                    Serviciu adaugat produsului selectat:
                  </span>
                </div>
                <ul
                  className="custom-modal-calculator-list"
                  style={{
                    border: "2px solid #0275d8",
                    margin: "0 10px",
                    marginTop: "20px",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span style={{ fontSize: "1rem" }}>
                      {ReactHtmlParser(selectedQuestion?.question?.icon)}
                    </span>
                    <span style={{ fontSize: "1rem" }}>
                      {selectedQuestion?.question?.service_title}
                      {selectedQuestion?.question?.service_title === "Metal" ||
                      selectedQuestion?.question?.service_title === "Pex" ||
                      selectedQuestion?.question?.service_title === "Pexal" ||
                      selectedQuestion?.question?.service_title === "PPR" ||
                      selectedQuestion?.question?.service_title === "Cupru" ? (
                        <p
                          style={{
                            color: "orange",
                            fontSize: ".9rem",
                            fontStyle: "italic",
                          }}
                        >
                          Informatie - Instalatie Existenta
                        </p>
                      ) : selectedQuestion?.question?.charge_amount === 0 ? (
                        <p
                          style={{
                            fontSize: ".9rem",
                            fontStyle: "italic",
                            color: "orange",
                          }}
                        >
                          Urmeaza sa fiti contactat de un operator dupa plasarea
                          comenzii
                        </p>
                      ) : (
                        <span style={{ fontSize: "1rem" }}>
                          {" "}
                          - {selectedQuestion?.question?.charge_amount} Lei
                        </span>
                      )}
                    </span>
                    <i
                      style={{
                        color: "green",
                        marginLeft: "auto",
                        fontSize: "1.2rem",
                      }}
                      className="far fa-check-circle"
                    ></i>
                  </li>
                </ul>
                {selectedQuestion?.question?.charge_amount > 0 ? (
                  <>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        padding: "0 15px 15px 15px",
                      }}
                      className="custom-calculator-grand-total"
                    >
                      <span>
                        TOTAL :{" "}
                        <span
                          style={{ fontWeight: "600" }}
                          className="custom-calculator-price"
                        >
                          {selectedQuestion?.question?.charge_amount} Lei
                        </span>
                      </span>
                      <span>
                        TOTAL CU TVA :{" "}
                        <span
                          style={{ fontWeight: "600" }}
                          className="custom-calculator-price"
                        >
                          {selectedQuestion?.question?.charge_including_vat} Lei
                        </span>
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </>
        </ModalBody>
        <ModalFooter>
          {selectedQuestion?.question?.is_service ? (
            <Button
              color="primary"
              onClick={() => handleAddService(selectedQuestion?.question?.id)}
            >
              Pasul urmator
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() =>
                handleSelectedQuestions(
                  selectAnswerId
                    ? selectAnswerId
                    : existaAerMontat
                    ? selectedQuestion?.question?.yes
                    : selectedQuestion?.question?.no
                )
              }
              disabled={selectAnswerId == null && existaAerMontat == null}
            >
              Pasul Urmator
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={selectDate?.show} toggle={() => toggle()}>
        <ModalHeader className="custom-modal-header-cart">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 10px",
                backgroundColor: "rgba(0, 0, 0 ,0.4)",
                color: "#fff",
                marginTop: "-80px",
                borderRadius: "5px",
              }}
              className="card-2"
            >
              Selecteaza data si ora
            </div>
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: "0", marginTop: "-1px" }}>
          <>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // marginBottom: "10px",
                  padding: "20px",
                  backgroundColor: "#ffb53e",
                  color: "black",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p style={{ color: "#fff", fontWeight: "500" }}>
                      Selecteaza data
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          padding: "12px",
                          background: "#fff",
                          marginRight: "-184px",
                          borderRadius: "3px",
                          width: "220px",
                        }}
                        className="custom-callendar-icon"
                      >
                        <i className="far fa-calendar-alt"></i>
                      </div>
                      <DatePicker
                        timeFormat={false}
                        isValidDate={disabledDate}
                        onChange={(date) => handleDateChange(date)}
                        className="custom-date-picke"
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: "130px" }}>
                    <p style={{ color: "#fff" }}>Selecteaza Ora</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        style={{ backgroundColor: "#fff" }}
                        type="select"
                        onChange={(e) =>
                          setSelectDate({
                            ...selectDate,
                            show: true,
                            appointment_time: e.target.value,
                          })
                        }
                      >
                        {availableTimes?.map((time, index) => (
                          <option key={time} value={time}>
                            {time}:00
                          </option>
                        ))}
                      </Input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </ModalBody>

        <ModalFooter style={{ borderTop: "none", marginTop: "-20px" }}>
          {selectServiceDetails?.show ? (
            <Button
              style={{ marginTop: "20px" }}
              color="success"
              disabled={
                selectDate?.appointment_date == null ||
                selectDate?.appointment_time == null
              }
              onClick={() => setSelectDate({ ...selectDate, show: false })}
            >
              Pasul urmator
            </Button>
          ) : (
            <>
              <Button
                style={{ marginTop: "20px" }}
                color="success"
                disabled={
                  selectDate?.appointment_date == null ||
                  selectDate?.appointment_time == null
                }
                onClick={() => handleCreateAppointment(selectDate?.card_id)}
              >
                Adauga
              </Button>
              {/* <Button
                color="primary"
                onClick={() => {
                  setSelectDate({
                    appointment_time: "",
                    appointment_date: "",
                    show: false,
                  });
                  setAvailableTimes([]);
                }}
              >
                Cancel
              </Button> */}
            </>
          )}
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={!selectDate?.show && selectServiceDetails?.show}
        toggle={() => {
          setSelectServiceDetails({
            show: false,
            details: [],
          });
          setSelectService([]);
        }}
      >
        <ModalHeader
          className="custom-modal-header-cart"
          toggle={() => {
            setSelectServiceDetails({
              show: false,
              details: [],
            });
            setSelectService([]);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 10px",
                backgroundColor: "rgba(0, 0, 0 ,0.4)",
                color: "#fff",
                marginTop: "-80px",
                borderRadius: "5px",
              }}
              className="card-2"
            >
              <div style={{ color: "#fff" }}>Sumar Servicii</div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: "0", marginTop: "-1px" }}>
          <>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "5px",
                  padding: "15px",
                  backgroundColor: "#ffb53e",
                  color: "#fff",
                }}
              >
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {/* <i
                    style={{
                      marginRight: "10px",
                      marginBottom: "20px",
                      color: "#fff"
                    }}
                    className="fas fa-cog custom-question-icon"
                  ></i> */}
                  <p style={{ color: "#fff", fontWeight: "500" }}>
                    Servicii adaugate produsului selectat:
                  </p>
                </div>
              </div>
              <ul
                className="custom-modal-calculator-list-service_details"
                style={{ margin: "30px 15px", paddingLeft: "12px" }}
              >
                <>
                  {selectServiceDetails?.details?.map((detail) => (
                    <li>
                      {ReactHtmlParser(detail?.icon)} {detail.service_title}
                      <br />
                      {detail?.service_title === "Metal" ||
                      detail?.service_title === "Pex" ||
                      detail?.service_title === "Pexal" ||
                      detail?.service_title === "PPR" ||
                      detail?.service_title === "Cupru" ? (
                        <p
                          style={{
                            color: "orange",
                            fontSize: ".9rem",
                            fontStyle: "italic",
                          }}
                        >
                          Informatie - Instalatie Existenta
                        </p>
                      ) : detail?.charge_including_vat > 0 ? (
                        <p>Total cu TVA: {detail?.charge_including_vat} RON </p>
                      ) : (
                        <p
                          style={{
                            color: "orange",
                            fontSize: ".9rem",
                            fontStyle: "italic",
                          }}
                        >
                          Urmeaza sa fiti contactat de un operator dupa plasarea
                          comenzii
                        </p>
                      )}
                      <hr />
                    </li>
                  ))}

                  {questionBundleDetail?.default_services?.map(
                    (defaultService) => (
                      <li>
                        {ReactHtmlParser(defaultService?.icon)}
                        {defaultService?.service_title}
                        <br />
                        Total cu TVA: {defaultService?.charge_including_vat} RON
                        <br />
                        <hr />
                      </li>
                    )
                  )}
                </>
              </ul>
            </div>
          </>
        </ModalBody>
        <ModalFooter style={{ borderTop: "none", marginTop: "-20px" }}>
          <Button color="success" onClick={() => handleConfirmationService()}>
            Adauga servicii
          </Button>

          <Button
            color="secondary"
            onClick={() => {
              setSelectService([]);
              setSelectServiceDetails({
                show: false,
                details: [],
              });
              setSelectDate({
                show: false,
                appointment_date: "",
                appointment_time: "",
              });
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {!displayService && (
        <div
          className={
            closeInfo
              ? ""
              : "cart-focus-hidden"
          }
          onClick={handleCloseInfo}
        >
          <div>Vezi serviciile recomandate</div>
        </div>
      )}
    </>
  );
};

CartTable.propTypes = {
  carts: PropTypes.array.isRequired,
};

const mapStateToProps = ({ product, wishlist, auth }) => ({
  questionBundleDetail: product.questionBundleDetail,
  questionDetail: product.questionDetail,
  serviceList: product.serviceList,
  availableAppointmentDate: wishlist.availableAppointmentDate,
  user: auth.user,
  productDetail: product?.productDetail,
});
export default connect(mapStateToProps, {
  getCarts,
  updateInCart,
  deleteCart,
  getProductQuestionBundleRead,
  getQuestionDetail,
  addServiceInCart,
  getServiceList,
  removeServices,
  getAvailableDate,
  createAppointmentUser,
  getProductDetail,
})(CartTable);
