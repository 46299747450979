import axios from "axios";
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_CONFIRM_FAIL,
  LOGOUT,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_FAIL,
  AUTHENTICATED_SUCCESS,
  GOOGLE_AUTH_FAIL,
  GOOGLE_AUTH_SUCCESS,
  FACEBOOK_AUTH_SUCCESS,
  FACEBOOK_AUTH_FAIL,
  AUTH_LOADING,
  GET_ADDRESS_LIST,
  USER_ORDER_LIST,
  USER_ORDER_DETAILS,
} from "../types/types";
import requests, { BACKEND_AUTH_URL, BACKEND_URL, URL } from "../../requests";
import { errorHandle, tokenConfig } from "./common";
import { toastr } from "react-redux-toastr";
import { storeProductInCart, addServiceInCart, updateInCart } from "./wishlist";
import { getCartProduct, removeCart } from "../../helper/cart";

// Register User
export const signupUser = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    //   Get Token from state

    try {
      const signUp = await axios.post(
        `${BACKEND_AUTH_URL}${requests.SIGNUP_USER}`,
        data
      );
      dispatch({
        type: AUTH_LOADING,
        payload: false,
      });
      window.location.href = "/register_verify";
      toastr.success("User Register Successfully. Check your mail");
      // dispatch({ type: LOGIN_SUCCESS, payload: signUp.data.data });
    } catch (error) {
      errorHandle(error, dispatch, AUTH_LOADING);
    }
  };
};

export const loginUser = (data, fromComponent = true) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    //   Get Token from state

    try {
      const loginUserData = await axios.post(
        `${BACKEND_AUTH_URL}${requests.LOGIN_USER}`,
        data
      );
      dispatch({
        type: AUTH_LOADING,
        payload: false,
      });
      const carts = getCartProduct()?.products;
      const products = carts?.map?.((cart) => ({
        product_id: cart?.id,
        quantity: cart?.qty || 1,
        temp_appointment_date: cart?.appointment_data?.appointment_date || null,
        temp_appointment_time:
          cart?.appointment_data?.temp_appointment_time || null,
        services: cart.question_data,
      }));

      dispatch({ type: LOGIN_SUCCESS, payload: loginUserData.data?.access });
      toastr.success("User Login Successfully");
      if (products?.length > 0) {
        dispatch(
          storeProductInCart(
            {
              products,
            },

            false
          )
        );
      }

      if (fromComponent) {
        removeCart();
        setTimeout(() => {
          window.location.replace("/");
        }, 1000);
      }
    } catch (error) {
      errorHandle(error, dispatch, AUTH_LOADING);
    }
  };
};

// Load the user
export const load_user = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    try {
      const res = await axios.get(
        `${BACKEND_URL}${requests.LOAD_USER}`,
        tokenConfig()
      );
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const getOrdersList = (data) => async (dispatch) => {
  if (localStorage.getItem("access")) {
    try {
      const res = await axios.post(
        `${BACKEND_URL}${requests.ORDER_LIST}`,
        {
          ...data,
          pagination: data?.pagination || true,
          items_per_page: data?.item_per_page || 10,
          page_num: data?.page_num || 1,
        },
        tokenConfig()
      );
      dispatch({
        type: USER_ORDER_LIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const getOrderDetails = (id) => async (dispatch) => {
  if (localStorage.getItem("access")) {
    try {
      const res = await axios.get(
        `${BACKEND_URL}${requests.ORDER_DETAILS}${id}`,
        tokenConfig()
      );
      dispatch({
        type: USER_ORDER_DETAILS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

// Update User
export const updateUserProfile = (data) => async (dispatch) => {
  if (localStorage.getItem("access")) {
    try {
      const userUpdate = await axios.post(
        `${BACKEND_URL}${requests.UPDATE_USER_PROFILE}`,
        data,
        tokenConfig()
      );
      if (userUpdate.status === 200) {
        toastr.success("User Profile", "User Profile Updated");
        dispatch(load_user());
      }
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

// Password

export const passwordUpdate = (data) => async (dispatch) => {
  if (localStorage.getItem("access")) {
    axios
      .post(`${BACKEND_URL}${requests.CHANGE_PASSWORD}`, data, tokenConfig())
      .then((res) => {
        if (res.status === 200) {
          toastr.success("User Password", "User Password Updated");
          dispatch(load_user());
        }
      })
      .catch((err) => {
        toastr.error("You have entered wrong password..");
      });
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

// address
export const getAddressList = () => async (dispatch) => {
  dispatch({
    type: AUTH_LOADING,
    payload: true,
  });
  try {
    const addressList = await axios.get(
      `${BACKEND_URL}${requests.ADDRESS_LIST}`,
      tokenConfig()
    );
    dispatch({
      type: AUTH_LOADING,
      payload: false,
    });
    dispatch({
      type: GET_ADDRESS_LIST,
      payload: addressList.data.data,
    });
  } catch (err) {
    errorHandle(err, dispatch, AUTH_LOADING);
  }
};

export const createAddress = (data) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("first_name", data?.first_name);
    formData.append("last_name", data?.last_name);
    formData.append("phone_number", data?.phone_number);
    formData.append("email", data?.email);
    formData.append("address_type", data?.address_type);
    formData.append("country", data?.country);
    formData.append("city", data?.city);
    formData.append("street_name", data?.street_name);
    formData.append("street_number", data?.street_number);
    formData.append("street_details", data?.street_details);
    formData.append("zip_code", data?.zip_code);
    if (data?.id) {
      const userUpdate = await axios.post(
        `${BACKEND_URL}${requests.ADDRESS_UPDATE}${data?.id}`,
        formData,
        tokenConfig()
      );
      if (userUpdate.status === 200) {
        toastr.success("User Address", "User Address Update");
        dispatch(load_user());
        dispatch(getAddressList());
      }
    } else {
      const userUpdate = await axios.post(
        `${BACKEND_URL}${requests.ADDRESS_CREATE}`,
        formData,
        tokenConfig()
      );
      if (userUpdate.status === 200) {
        toastr.success("User Address", "User Address Created");
        const formUserData = new FormData();
        data?.address_type === "BILLING"
          ? formUserData.append(
              "billing_address_id",
              userUpdate?.data?.data?.address_id
            )
          : formUserData.append(
              "shipping_address_id",
              userUpdate?.data?.data?.address_id
            );
        dispatch(updateUserProfile(formUserData));
        dispatch(load_user());
        dispatch(getAddressList());
      }
    }
  } catch (err) {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

// Facebook authentication
export const facebookAuthenticate = (state, code) => async (dispatch) => {
  if (state && code && !localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const details = {
      state: state,
      code: code,
    };

    const formBody = Object.keys(details)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
      )
      .join("&");

    try {
      const res = await axios.post(
        `http://localhost:8000/auth/o/facebook/?${formBody}`,
        config
      );

      dispatch({
        type: FACEBOOK_AUTH_SUCCESS,
        payload: res.data,
      });

      dispatch(load_user());
    } catch (err) {
      dispatch({
        type: FACEBOOK_AUTH_FAIL,
      });
    }
  }
};

// Google authentication
export const googleAuthenticate = (state, code) => async (dispatch) => {
  if (state && code && !localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const details = {
      state: state,
      code: code,
    };

    const formBody = Object.keys(details)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
      )
      .join("&");

    try {
      const res = await axios.post(
        `http://localhost:8000/auth/o/google-oauth2/?${formBody}`,
        config
      );

      dispatch({
        type: GOOGLE_AUTH_SUCCESS,
        payload: res.data,
      });

      dispatch(load_user());
    } catch (err) {
      dispatch({
        type: GOOGLE_AUTH_FAIL,
      });
    }
  }
};

// Check if user is aithenticated
export const checkAuthenticated = () => async (dispatch) => {
  if (typeof window == "undefined") {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ token: localStorage.getItem("access") });

    try {
      const res = await axios.post(
        `${BACKEND_AUTH_URL}${requests.CHECK_AUTHENTICATED}`,
        body,
        config
      );

      if (res.data.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

// User registration
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(
      `${BACKEND_AUTH_URL}${requests.LOGIN_USER}`,
      body,
      config
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(load_user());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const GoogleLoginUser = (data, type = "GOOGLE") => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    //   Get Token from state
    let url = requests.GOOGLE_LOGIN;
    if (type === "FACEBOOK") {
      url = requests.FACEBOOK_LOGIN;
    }
    try {
      const loginUserData = await axios.post(`${BACKEND_URL}${url}`, data);
      dispatch({
        type: AUTH_LOADING,
        payload: false,
      });
      if (loginUserData.status === 200) {
        dispatch({
          type: GOOGLE_AUTH_SUCCESS,
          payload: loginUserData.data?.data,
        });
        toastr.success("User Login Successfully");
        dispatch(load_user());

        setTimeout(() => {
          window.location.replace("/");
        }, 1000);
      }
    } catch (error) {
      errorHandle(error, dispatch, AUTH_LOADING);
    }
  };
};

// User registration
export const signup =
  ({ first_name, last_name, email, password, re_password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      password,
      re_password,
    });

    try {
      const res = await axios.post(
        `${BACKEND_AUTH_URL}${requests.SIGNUP_USER}`,
        body,
        config
      );

      dispatch({
        type: SIGNUP_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: SIGNUP_FAIL,
      });
    }
  };

// User email code activation
export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ uid, token });

  try {
    /* eslint-disable */ const res = await axios.post(
      `${BACKEND_AUTH_URL}${requests.VERIFY}`,
      body,
      config
    );

    dispatch({
      type: ACTIVATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
  }
};

// Reset password
export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  try {
    const res = await axios.post(
      `${BACKEND_AUTH_URL}${requests.RESET_PASSWORD}`,
      body,
      config
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
    });
  }
};

// Reset passowrd confirm
export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
      const res = await axios.post(
        `${BACKEND_AUTH_URL}${requests.RESET_PASSWORD_CONFIRM}`,
        body,
        config
      );

      dispatch({
        type: RESET_PASSWORD_CONFIRM_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: RESET_PASSWORD_CONFIRM_FAIL,
      });
    }
  };

// Logout
export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const forgotPassword = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    //   Get Token from state

    try {
      const forgotPassword = await axios.post(
        `${BACKEND_URL}${requests.FORGOT_PASSWORD}`,
        data
      );
      dispatch({
        type: AUTH_LOADING,
        payload: false,
      });
      if (forgotPassword.status === 200) {
        toastr.success(forgotPassword?.data?.message);
      }

      // dispatch({ type: LOGIN_SUCCESS, payload: signUp.data.data });
    } catch (error) {
      errorHandle(error, dispatch, AUTH_LOADING);
    }
  };
};
