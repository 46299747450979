import { toastr } from "react-redux-toastr";

export const storeInCart = (product) => {
  const getData = JSON.parse(localStorage.getItem("cart")) || [];

  let newData = [];
  product["service_question_data"] = [];
  if (getData?.length > 0) {
    let found = false;
    const getNewData = getData?.map((prod) => {
      if (prod.id == product.id) {
        prod["qty"] = parseInt(prod.qty || 1) + 1;
        found = true;
      }
      return prod;
    });
    if (!found) {
      newData = [...getData, product];
    } else {
      newData = [...getData];
    }
  } else {
    newData = [product];
  }
  // window.location.href = window.location.pathname;
  localStorage.setItem("cart", JSON.stringify(newData));
};

export const UpdateCartQty = (id, plus) => {
  const getData = JSON.parse(localStorage.getItem("cart")) || [];
  let newData = [];
  if (getData?.length > 0) {
    const newProduct = getData?.map((data) => {
      if (data.id == id) {
        if (plus) {
          data["qty"] = (data.qty || 1) + 1;
        } else {
          data["qty"] = data.qty > 1 ? data.qty - 1 : 1;
        }
      }
      return data;
    });
    newData = newProduct;
  }
  localStorage.setItem("cart", JSON.stringify(newData));
};

export const DeleteCart = (id) => {
  const getData = JSON.parse(localStorage.getItem("cart")) || [];
  let newData = [];
  if (getData?.length > 0) {
    const newProduct = getData?.filter((data) => data.id != id);
    newData = newProduct;
  }
  localStorage.setItem("cart", JSON.stringify(newData));
};

export const removeCart = () => {
  localStorage.removeItem("cart");
  return;
};

export const getCartProduct = () => {
  const getData = JSON.parse(localStorage.getItem("cart")) || [];
  return { products: getData, count: getData?.length || 0 };
};

export const getTotal = (products) => {
  const total = products?.reduce(
    (total, current) => {
      const price = parseFloat(current.price || 0);
      const qty = parseInt(current.qty || 1);
      const amount = qty * price;
      const percentValue = ((current.vat_percentage || 0) * amount) / 100;
      return {
        amount: total?.amount + amount,
        totalAmount: (total?.totalAmount + amount + percentValue) * qty,
        percentValue: total?.percentValue + percentValue,
      };
    },
    { amount: 0, totalAmount: 0, percentValue: 0 }
  );

  return total;
};

export const getTotalApi = (products) => {
  const total = products?.reduce(
    (total, current) => {
      const price = parseFloat(current?.product?.price || 0);
      const qty = parseInt(current.quantity || 1);
      const amount = qty * price;
      const percentValue =
        ((current?.product?.vat_percentage || 0) * amount) / 100;
      return {
        amount: total?.amount + amount,
        totalAmount: total?.totalAmount + amount + percentValue,
        percentValue: total?.percentValue + percentValue,
      };
    },
    { amount: 0, totalAmount: 0, percentValue: 0 }
  );
  return total;
};

export const handleCompare = (product) => {
  const compareDate = JSON.parse(localStorage.getItem("compare"));
  if (!compareDate) {
    localStorage.setItem("compare", JSON.stringify([product?.id]));
  } else if (compareDate?.length > 0) {
    let valuesData = [...compareDate, product?.id];
    valuesData = valuesData.filter(
      (value, index) => valuesData.indexOf(value) === index
    );
    localStorage.setItem("compare", JSON.stringify(valuesData));
  }
  toastr.success("Compare", "Product added to compare list");
};

export const UpdateServiceAppointmentLocal = (id, service) => {
  const getAppointment = JSON.parse(localStorage.getItem("cart")) || [];
  let newData = [];
  if (getAppointment?.length > 0) {
    const newProduct = getAppointment?.map((data) => {
      if (data.id == id) {
        if (service) {
          data["appointment_data"] = service;
        }
      }
      return data;
    });
    newData = newProduct;
  }
  localStorage.setItem("cart", JSON.stringify(newData));
  toastr.success("Services", "Services added to cart");
  window.location.href = window.location.pathname;
};

export const UpdateServiceAppointmentQuestion = (
  id,
  question,
  serviceDetails
) => {
  const getAppointment = JSON.parse(localStorage.getItem("cart")) || [];
  let newData = [];
  if (getAppointment?.length > 0) {
    const newProduct = getAppointment?.map((data) => {
      if (data.id == id) {
        if (question) {
          data["question_data"] = question;
          data["service_question_data"] = serviceDetails;
        }
      }
      return data;
    });
    newData = newProduct;
  }
  localStorage.setItem("cart", JSON.stringify(newData));
  toastr.success("Question", "Question added to cart");
  window.location.href = window.location.pathname;
};

export const RemoveServices = (id) => {
  const getAppointment = JSON.parse(localStorage.getItem("cart")) || [];
  let newData = [];
  if (getAppointment?.length > 0) {
    const newProduct = getAppointment?.map((data) => {
      if (data.id == id) {
        if (data.appointment_data) {
          delete data["appointment_data"];
        }
        if (data["question_data"]) {
          //delete question data
          delete data["question_data"];
        }
        delete data["question_data"];
        if (data["service_question_data"]) {
          data["service_question_data"] = [];
        }
      }
      return data;
    });
    newData = newProduct;
  }
  localStorage.setItem("cart", JSON.stringify(newData));
  toastr.success("Deleted Successfully");
  window.location.href = window.location.pathname;
};
