import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { validationInput } from "../../helper/common";
import icon2 from "../../assets/imgs/popup/popup-2.png";
import { generateCalculatorKw, generateCalculatorLitri } from "../../Common/calculator";
import AskForOfferModal from "./AskForOfferModal/AskForOfferModal";

const RecomandareCentralaExtern = ({ setCustomModal, customModal }) => {
  const [modal, setModal] = useState(false);
  const [showAskForModal, setShowAskForModal] = useState(false);
  const [showAskForOffer, setShowAskForOffer] = useState(false);
  const [showAskForOfferOnBoiler, setShowAskForOfferOnBoiler] = useState(false);

  const askForOffer = () => {
    setShowAskForModal(true);
  };
  const closeAskForModal = () => {
    setShowAskForModal(false);
  };
  const toggle = () => {
    if (customModal) {
      setCustomModal(false);
    }
    setModal(false);
  };

  const toggleSecond = () => {
    setModal(!modal);
  };

  const [surface, setSurface] = useState();
  const [roomHeight, setRoomHeight] = useState();
  const [bathroom, setBathroom] = useState();
  const [kitchen, setKitchen] = useState();
  const [boiler, setBoiler] = useState();

  // let result = (surface * roomHeight * 50) / 1000 + bathroom * 2 + kitchen * 2;
  // let boilerResult = boiler * 30;
  let result = (surface * roomHeight * 50) / 1000 + bathroom * 2 + kitchen * 2;
  let boilerResult = boiler * 30;

  useEffect(() => {
    const contentId = generateCalculatorKw(parseFloat(result));
    if (contentId === null) {
      setShowAskForOffer(true);
    } else {
      setShowAskForOffer(false);
    }
  }, [result]);

  useEffect(() => {
    const contentId = generateCalculatorLitri(parseFloat(boilerResult));
    if (contentId === null) {
      setShowAskForOfferOnBoiler(true);
    } else {
      setShowAskForOfferOnBoiler(false);
    }
  }, [boilerResult]);

  return (
    <div style={{ width: "100%" }}>
      <div
        className="recom-ctext banner-features wow fadeIn animated hover-up animated"
        style={{
          backgroundColor: "fff",
          border: "1px solid white",
          filter: "drop-shadow(5px 5px 5px rgba(160, 160, 160, 0.25))",
          borderRadius: "5px",
          padding: "20px 15px 15px 10px",
          fontWeight: "700",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          height: "200px",
          position: "relative",
        }}
        onClick={toggleSecond}
      >
        <div className="blur"></div>
        <div
          style={{
            zIndex: "5",
            padding: "0 20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img src={icon2} alt="icon" />

          <span style={{ lineHeight: "1", fontSize: "1.1rem" }}>
            Recomandare centrala termica cu boiler extern
          </span>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#fff" }}>
              Recomandare centrala termica cu boiler extern
            </span>
            <i
              style={{ color: "#fff", fontSize: "1.3rem" }}
              className="far fa-times"
              onClick={toggleSecond}
            ></i>
          </div>
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div
              style={{
                minHeight: "65px",
                display: "flex",
                alignItems: "center",
                minWidth: "80px",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "65px" }}
                src="https://i.imgur.com/ilNpJSh.png"
                alt="surface"
              />
            </div>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label for="surface">Ce suprafata utila are casa dvs?</Label>
                <Input
                  type="number"
                  name="surface"
                  id="surface"
                  placeholder="Suprafata utila (mp)"
                  value={surface}
                  max={300}
                  onChange={(e) => {
                    const value = validationInput(e);
                    setSurface(value);
                  }}
                />
              </FormGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div
              style={{
                minHeight: "65px",
                display: "flex",
                alignItems: "center",
                minWidth: "80px",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "65px" }}
                src="https://i.imgur.com/AtWtnTI.png"
                alt="roomHeight"
              />
            </div>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label for="roomHeight">
                  Ce inaltime medie au incaperile dvs.?
                </Label>
                <Input
                  type="number"
                  name="roomHeight"
                  id="roomHeight"
                  placeholder="Inaltime camera (m)"
                  value={roomHeight}
                  max={300}
                  onChange={(e) => {
                    const value = validationInput(e);
                    setRoomHeight(value);
                  }}
                />
              </FormGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div
              style={{
                minHeight: "65px",
                display: "flex",
                alignItems: "center",
                minWidth: "80px",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "65px" }}
                src="https://i.imgur.com/4JNOwv8.png"
                alt="roomHeight"
              />
            </div>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label for="kitchenNumber">Cate bucatarii aveti?</Label>
                <Input
                  type="number"
                  name="kitchenNumber"
                  id="kitchenNumber"
                  placeholder="Numarul de bucatarii (buc)"
                  value={kitchen}
                  max={300}
                  onChange={(e) => {
                    const value = validationInput(e);
                    setKitchen(value);
                  }}
                />
              </FormGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div
              style={{
                minHeight: "65px",
                display: "flex",
                alignItems: "center",
                minWidth: "80px",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "65px" }}
                src="https://i.imgur.com/mcy4bS4.png"
                alt="roomHeight"
              />
            </div>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label for="numberBathroom">Cate bai aveti?</Label>
                <Input
                  type="number"
                  name="numberBathroom"
                  id="numberBathroom"
                  placeholder="Numarul de bai (buc)"
                  value={bathroom}
                  max={300}
                  onChange={(e) => {
                    const value = validationInput(e);
                    setBathroom(value);
                  }}
                />
              </FormGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div
              style={{
                minHeight: "65px",
                display: "flex",
                alignItems: "center",
                minWidth: "80px",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "65px" }}
                src="https://i.imgur.com/un4OOBP.png"
                alt="roomHeight"
              />
            </div>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label for="boiler">
                  Pentru boiler separat de centrala termica va rugam sa
                  mentionati cate persoane locuiesc in cladire?
                </Label>
                <Input
                  type="number"
                  name="boiler"
                  id="boiler"
                  placeholder="Numarul de persoane (pers)"
                  value={boiler}
                  max={300}
                  onChange={(e) => {
                    const value = validationInput(e);
                    setBoiler(value);
                  }}
                />
              </FormGroup>
            </div>
          </div>
          {result > 0 || boilerResult > 0 ? (
            <>
              {" "}
              <hr />
              {result > 0 ? (
                <p
                  style={{
                    padding: "10px",
                    border: "2px solid #0275d8",
                    borderRadius: "5px",
                    color: "#0275d8",
                  }}
                >
                  Calculul de putere CT necesara este:{" "}
                  <strong style={{ textDecoration: "underline" }}>
                    {result.toFixed(2)} Kw
                  </strong>
                </p>
              ) : null}
              {boilerResult > 0 ? (
                <p
                  style={{
                    padding: "10px",
                    border: "2px solid #0275d8",
                    borderRadius: "5px",
                    color: "#0275d8",
                  }}
                >
                  Capacitate boiler necesara:{" "}
                  <strong style={{ textDecoration: "underline" }}>
                    {boilerResult.toFixed(2)} litri
                  </strong>
                </p>
              ) : null}
            </>
          ) : null}
        </ModalBody>
        {result > 0 || boilerResult > 0 ? (
          <ModalFooter>
            {!showAskForOfferOnBoiler ? (
              <Link
                to={`/recomandare/18?litri=${boilerResult}`}
                target="_blank"
              >
                <Button color="second">Vezi oferta boilere</Button>
              </Link>
            ) : 
              (
                <Button
                color="primary"
                onClick={() => {
                  askForOffer();
                }}
              >
                Solicitați o ofertă boilere
              </Button>
              )
            }
            {!showAskForOffer ? (
              <Link to={`/recomandare/9?kww=${result}`} target="_blank">
                <Button color="primary">Vezi oferta centrale</Button>
              </Link>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  askForOffer();
                }}
              >
                Solicitați o ofertă centrale
              </Button>
            )}
          </ModalFooter>
        ) : null}
      </Modal>
      {showAskForModal ? (
        <AskForOfferModal
          showModal={showAskForModal}
          onClose={() => {
            closeAskForModal();
          }}
        />
      ) : null}
    </div>
  );
};

export default RecomandareCentralaExtern;
