import React, { useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../../requests";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const AskForOfferModal = ({showModal, onClose}) => {
  const [openModal, setOpenModal] = useState(showModal);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [comment, setComment] = useState('');

  const closeModal = () => {
    setOpenModal(false);
    onClose();
  };
  const sendInfoToEmail = () => {
      let emailInfo = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        telephone: phoneNumber,
        message: comment,
        type: 'CALCULATOR_OFFER',
        status: true,
        company_name: 'Project Protocol',
        address: 'Bucharest',
        title: 'Calculator Offer'
      }
        axios.post(
            `${BACKEND_URL}contact-us/create`,
            { ...emailInfo }
        ).then(() => {
            setOpenModal(false);
            toastr.success("Email Sent Successfully!!");
        }).catch((error) => {
            toastr.error('Something went wrong!!');
        });
  }
  return (
    <div style={{ width: "100%" }}>
      <Modal isOpen={openModal} style={{ maxWidth: "500px" }}>
        <ModalHeader>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <span style={{ color: "#fff" }}>Cere oferta</span>
            <i
              style={{ color: "#fff", fontSize: "1.3rem" }}
              className="far fa-times"
              onClick={closeModal}
            ></i>
          </div>
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label for="surface">Nume</Label>
                <Input type="text" placeholder="Nume" value={firstName} onChange={(e) => {
                    setFirstName(e.target.value);
                  }} />
              </FormGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label>Numele de familie</Label>
                <Input type="text" placeholder="Numele de familie" value={lastName} onChange={(e) => {
                    setLastName(e.target.value)
                }} />
              </FormGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label>E-mail</Label>
                <Input
                  id="exampleEmail"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                      setEmail(e.target.value)
                  }}
                  placeholder="E-mail"
                />
              </FormGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label>Număr de telefon</Label>
                <Input type="text" placeholder="Număr de telefon" value={phoneNumber} onChange={(e) => {
                    setPhoneNumber(e.target.value)
                }} />
              </FormGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label>Mesaj</Label>
                <Input
                  id="exampleText"
                  name="text"
                  type="textarea"
                  value={comment}
                  onChange={(e) => {
                      setComment(e.target.value)
                  }}
                  placeholder="Mesaj"
                />
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={sendInfoToEmail}>Trimite cerere</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AskForOfferModal;
