import {
  LOADING_BANNER,
  HEROS_BANNER,
  LOADING_FEAUTURED_BANNER,
  FEAUTURED_BANNER,
  LOADING_COMMERCIAL_BANNER,
  COMMERCIAL_BANNER,
  LOADING_PARTNER_BANNER,
  PARTNER_BANNER,
  GET_FIRST_BANNER,
  GET_TWO_BANNER,
} from "../types/types";

const initialState = {
  herosBanner: [],
  feauturedBanner: [],
  commercialBanner: [],
  partnerBanner: [],
  loading: false,
  firstBanner: {},
  secondBanner: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_BANNER:
      return { ...state, loading: action.payload || false };
    case LOADING_FEAUTURED_BANNER:
      return { ...state, loading: action.payload || false };
    case LOADING_COMMERCIAL_BANNER:
      return { ...state, loading: action.payload || false };
    case LOADING_PARTNER_BANNER:
      return { ...state, loading: action.payload || false };
    case HEROS_BANNER:
      return { ...state, herosBanner: action.payload };
    case FEAUTURED_BANNER:
      return { ...state, feauturedBanner: action.payload };
    case COMMERCIAL_BANNER:
      return { ...state, commercialBanner: action.payload };
    case PARTNER_BANNER:
      return { ...state, partnerBanner: action.payload };
    case GET_FIRST_BANNER:
      return { ...state, firstBanner: action.payload };
    case GET_TWO_BANNER:
      return { ...state, secondBanner: action.payload };
    default:
      return state;
  }
}
