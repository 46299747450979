import React from "react";
import Moment from "react-moment";
import ReactHtmlParser from "react-html-parser";

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const BlogEntry = ({ blogPosts }) => {
  return (
    <>
      <Carousel
        infinite={true}
        arrows={false}
        draggable={true}
        // autoPlay
        // autoPlaySpeed={4000}
        customTransition="all 1s linear"
        minimumTouchDrag={80}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 2,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
          },
        }}
      >
        {blogPosts.map(
          (post, index) =>
            index >= 0 &&
            index <= 5 && (
              <article
                className="wow fadeIn animated blog-entry-article"
                key={post.id}
              >
                <div
                  style={{ minHeight: "150px" }}
                  className="d-block"
                >
                  <div className="post-thumb d-flex mr-15">
                    <a className="color-white" href={`/blog/${post.id}`}>
                      <img
                        style={{
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={post.image}
                        alt={post.title}
                      />
                    </a>
                  </div>
                  <div
                    className="post-content"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      paddingTop: '10px'
                    }}
                  >
                    <h4 className="post-title ">
                      <a className="text-limit-2" href={`/blog/${post.id}`}>
                        {post.title}
                      </a>
                    </h4>
                    <p className="text-limit-3 blog-parser">
                      {ReactHtmlParser(post.content)}
                    </p>
                    <div className="entry-meta meta-1 font-xs color-grey mt-10">
                      <div>
                        <span className="post-on">
                          <Moment format="DD.MM.YYYY">
                            {post.created_date}
                          </Moment>
                        </span>
                      </div>
                      <Link to={`/blog/${post.id}`}>Citeste articol</Link>
                    </div>
                  </div>
                </div>
              </article>
            )
        )}
      </Carousel>
    </>
  );
};

export default BlogEntry;
