import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { signupUser, loginUser } from "../../redux/actions/auth";
import { Link } from "react-router-dom";

const Login = ({ signupUser, handleSubmit, reset, dispatch, loading }) => {
  const [passwordEye, setPasswordEye] = useState(true);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const handleSignup = (data) => {
    setWarningMessage("");
    if (!checked) {
        setWarningMessage("You cannot create an account without checking the terms and conditions");
    } else if (data?.password === data?.re_password) {
      signupUser(data);
      dispatch(reset("login_signup_form"));
    } else {
      setWarningMessage("Password and Confirm Password are not same");
    }
  };
  return (
    <section className="pt-150 pb-150">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="row">
              <div className="col-lg-3" />
              <div className="col-lg-6">
                <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <h3 className="mb-30">Creaza cont</h3>
                    </div>

                    <form onSubmit={handleSubmit(handleSignup)}>
                      <div className="form-group">
                        <Field
                          required
                          component="input"
                          type="email"
                          id="form-email"
                          className="form-control"
                          placeholder="Adresa Email"
                          name="email"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          required
                          component="input"
                          type="text"
                          id="form-email"
                          className="form-control"
                          placeholder="Nume"
                          name="first_name"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          required
                          component="input"
                          type="text"
                          id="form-email"
                          className="form-control"
                          placeholder="Prenume"
                          name="last_name"
                        />
                      </div>
                      <div className="form-group type_password">
                        <Field
                          required
                          component="input"
                          type={passwordEye ? "password" : "text"}
                          id="form-email"
                          className="form-control"
                          placeholder="Parola"
                          name="password"
                        />
                        <i
                          onClick={() => setPasswordEye(!passwordEye)}
                          className={`fa ${
                            passwordEye ? "fa-eye-slash" : "fa-eye"
                          }`}
                        ></i>
                      </div>
                      <div className="form-group type_password">
                        <Field
                          required
                          component="input"
                          type={confirmPasswordEye ? "password" : "text"}
                          id="form-email"
                          className="form-control"
                          placeholder="Repeta parola"
                          name="re_password"
                        />
                        <i
                          onClick={() =>
                            setConfirmPasswordEye(!confirmPasswordEye)
                          }
                          className={`fa ${
                            confirmPasswordEye ? "fa-eye-slash" : "fa-eye"
                          }`}
                        ></i>
                      </div>
                      {warningMessage?.length > 0 && (
                        <div className="text-center">
                          <p className="text-danger">{warningMessage}</p>
                        </div>
                      )}
                      <div className="login_footer form-group">
                        <div className="chek-form">
                          <div className="custome-checkbox">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="checkbox"
                              id="exampleCheckbox12"
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheckbox12"
                            >
                              <span>
                                Sunt de acord cu termenii &amp; conditiile.
                              </span>
                            </label>
                          </div>
                        </div>
                        <Link to="/termeni-si-conditii">
                          <i className="fi-rs-book-alt mr-5 text-muted" />
                          Afla mai multe
                        </Link>
                      </div>
                      <div className="form-group">
                        {loading ? (
                          "Loading...."
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-fill-out btn-block hover-up"
                            name="login"
                          >
                            Creaza cont
                          </button>
                        )}
                      </div>
                    </form>
                    <div className="divider-text-center mt-15 mb-15">
                      <span> SAU</span>
                    </div>
                    {/* <ul className="btn-login list_none text-center mb-15">
                                            <li><a href="#" className="btn btn-facebook hover-up mb-lg-0 mb-sm-4">Login cu Facebook</a></li>
                                            <li><a href="#" className="btn btn-google hover-up">Login cu Google</a></li>
                                        </ul> */}
                    <div className="text-muted text-center">
                      Ai deja un cont ? <Link to="/log_in">Logheaza-te acum!</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};
const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  isAuthenticated: auth.isAuthenticated,
  access: auth.access,
});

export default connect(mapStateToProps, { signupUser, loginUser })(
  reduxForm({ form: "login_signup_form" })(Login)
);
