import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { signupUser, loginUser } from "../../redux/actions/auth";
import PropTypes from "prop-types";

const Login = ({
    signupUser,
    handleSubmit,
    reset,
    dispatch,
    loading,
    isAuthenticated,
    loginUser,
}) => {
    const handleLogin = (data) => {
       
        loginUser({ email: data.login_email, password: data.login_password });
        dispatch(reset("login_signup_form"));
    };

    const handleSignup = (data) => {
        signupUser(data);
        dispatch(reset("login_signup_form"));
    };
    return (
        <section>
            <div className="site__body">
                <div className="block box_margin">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex mx-auto d-block">
                                <div className="card flex-grow-1 mb-md-0">
                                    <div className="card-body">
                                        <div className="container">
                                            <div className="col-12 text-center" style={{ backgroundColor: '' }}>
                                                <h3 className="card-title package_summ-title">Order Summary</h3>
                                            </div>
                                            <div className="rounded mx-auto d-block text-center	">
                                                <img src="https://static.thenounproject.com/png/1292357-200.png" className="img-fluid " alt="Responsive image" />
                                            </div>
                                            <div className="col-12 text-center" style={{ marginTop: '20px' }}>
                                                <blockquote className="blockquote text-center">
                                                    <h4 className="mb-0 card-title">Order Failed</h4>
                                                    <p className="lead">
                                                        Your order is failed due to some reasone. So, Retry your order or Make another payment.
                                                    </p>
                                                </blockquote>
                                            </div>
                                            <div className="col-12 text-center">
                                                <button type="button" className="btn btn-primary" style={{ minWidth: '150px' }}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

Login.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
};
const mapStateToProps = ({ auth }) => ({
    loading: auth.loading,
    isAuthenticated: auth.isAuthenticated,
    access: auth.access,
});

export default connect(mapStateToProps, {
    signupUser,
    loginUser,
})(
    reduxForm({
        form: "login_signup_form",
    })(Login)
);
