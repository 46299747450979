import { LOADING_FOOTER, FOOTER_LIST } from "../types/types";

const initialState = {
  footerList: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FOOTER:
      return { ...state, loading: action.payload || false };
    case FOOTER_LIST:
      return { ...state, footerList: action.payload };
    default:
      return state;
  }
}
