import React, { useState } from "react";
import { createContactUs } from "../../../redux/actions/about_us";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import Select from "react-select";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const categoryList = [
  {
    value: "Comercializare de echipamente termice",
    label: "Comercializare de echipamente termice",
  },
  {
    value: "Comercializare de echipamente de climatizare",
    label: "Comercializare de echipamente de climatizare",
  },
  { value: "Servicii tehnice", label: "Servicii tehnice" },
];

const subcategoryList = [
  { value: "Montaj CT", label: "Montaj CT" },
  { value: "Punere in functiune CT", label: "Punere in functiune CT" },
  {
    value: "Eliberare autorizare de functionare ISCIR",
    label: "Eliberare autorizare de functionare ISCIR",
  },
  { value: "Revizie centrala termica", label: "Revizie centrala termica" },
  {
    value: "Interventii centrala termice",
    label: "Interventii centrala termice",
  },
  { value: "Revizie cazane industriale", label: "Revizie cazane industriale" },
  {
    value: "Verificare tehnica cazane industriale",
    label: "Verificare tehnica cazane industriale",
  },
  {
    value: "Verificare supape de siguranta",
    label: "Verificare supape de siguranta",
  },
  { value: "Montaj aer conditionat", label: "Montaj aer conditionat" },
  { value: "Igienizare aer conditionat", label: "Igienizare aer conditionat" },
  { value: "Revizie aer conditionat", label: "Revizie aer conditionat" },
  {
    value: "Mentenanta si reparatii chiller",
    label: "Mentenanta si reparatii chiller",
  },
  {
    value: "Mentenanta si reparatii CTA",
    label: "Mentenanta si reparatii CTA",
  },
  {
    value: "Revizie si igienizare ventiloconvectori",
    label: "Revizie si igienizare ventiloconvectori",
  },
  { value: "Instalatii electrice", label: "Instalatii electrice" },
  {
    value: "Dirigentie de santier electrice",
    label: "Dirigentie de santier electrice",
  },
  {
    value: "Automatizare si tablouri electrice",
    label: "Automatizare si tablouri electrice",
  },
  { value: "Servicii sanitare", label: "Servicii sanitare" },
  { value: "Servicii renovare", label: "Servicii renovare" },
  { value: "RSVTI", label: "RSVTI" },
];

const Feedback = ({
  handleSubmit,
  reset,
  dispatch,
  loading,
  createContactUs,
}) => {
  const handleCreateContactForm = (data) => {
    const formData = new FormData();
    formData.append("email", data?.email);
    formData.append("title", data?.first_name || "Reclamatie Client");
    formData.append("message", data?.message);
    formData.append("first_name", data?.first_name);
    formData.append("last_name", data?.last_name);
    formData.append("address", data?.address);
    formData.append("context", data?.context);
    formData.append("telephone", data?.telephone);
    formData.append("company_name", data?.company_name);
    formData.append("type", "FEEDBACK");
    formData.append("category", category);
    formData.append("subcategory", subcategory);

    formData.append("viteza_de_raspuns_rating", vitezaDeRaspunsRating);
    formData.append("claritate_raspunsuri_rating", claritateRaspunsuri);
    formData.append("respectarea_promisiunilor_rating", respectareaPromisiunilor);
    formData.append("profesionalismul_personalului_rating", profesionalismulPersonalului);
    formData.append("raportul_calitate_pret_rating", raportCalitatePret);
    formData.append("usurinta_de_navigare_website_rating", usurintaDeNavigareWebsite);
    formData.append("usurinta_de_gasire_informatiei_rating", usurintaDeGasireInformatiei);
    formData.append("usurinta_in_realizarea_platii_rating", usurintaInRealizareaPlatii);
    formData.append("usurinta_in_utilizarea_comparatorului_rating", usurintaInUtilizareaComparatorului);
    formData.append("usurinta_in_utilizarea_programatorului_rating", usurintaInUtilizareaProgramatorului);
    formData.append("usurinta_in_configurarea_ofertei_rating", usurintaInConfigurareaOfertei);
    formData.append("atitudine_rating", atitudine);
    formData.append("cunostinte_tehnice_rating", cunostinteTehnice);
    formData.append("tinuta_rating", tinuta);
    formData.append("profesionalismul_pers_rating", profesionalismulPers);

    createContactUs(formData);
    dispatch(reset("contact_us_form"));
    setModal(false);
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [category, setCategory] = useState("");

  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption.value);
  };

  const [subcategory, setSubcategory] = useState("");

  const handleSubCategoryChange = (selectedOption) => {
    setSubcategory(selectedOption.value);
  };

  const [vitezaDeRaspunsRating, setVitezaDeRaspunsRating] = useState("");
  const [claritateRaspunsuri, setClaritateRaspunsuri] = useState("");
  const [respectareaPromisiunilor, setRespectareaPromisiunilor] = useState("");
  const [profesionalismulPersonalului, setProfesionalismulPersonalului] = useState("");
  const [raportCalitatePret, setRaportCalitatePret] = useState("");

  const [usurintaDeNavigareWebsite, setUsurintaDeNavigareWebsite] = useState("");
  const [usurintaDeGasireInformatiei, setUsurintaDeGasireInformatiei] = useState("");
  const [usurintaInRealizareaPlatii, setUsurintaInRealizareaPlatii] = useState("");
  const [usurintaInUtilizareaComparatorului, setUsurintaInUtilizareaComaparatorului] = useState("");
  const [usurintaInUtilizareaProgramatorului, setUsurintaInUtilizareaProgramatorului] = useState("");
  const [usurintaInConfigurareaOfertei, setUsurintaInConfigurareaOfertei] = useState("");

  const [atitudine, setAtitudine] = useState("");
  const [cunostinteTehnice, setCunostinteTehnice] = useState("");
  const [tinuta, setTinuta] = useState("");
  const [profesionalismulPers, setProfesionalismulPers] = useState("");


  return (
    <>
      <a style={{ color: "black" }} onClick={toggle}>
        Feedback
      </a>
      <Modal
        style={{ maxWidth: "1000px", width: "100%" }}
        isOpen={modal}
        toggle={toggle}
      >
        <form
          className="contact-form-style text-center"
          id="contact-form"
          onSubmit={handleSubmit(handleCreateContactForm)}
          method="post"
        >
          <ModalHeader toggle={toggle}>
            <span style={{ color: "#fff" }}>
              Completeaza formularul de feedback
            </span>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 mb-20">
                <div
                  className="mb-20"
                  style={{
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "left",
                    fontWeight: "500",
                  }}
                >
                  Draga client,
                  <br />
                  <br />
                  Pentru ca am colaborat recent, dorim sa aflam parerea
                  dumneavoastra despre aceasta experienta.
                  <br />
                  <br />
                  Stim ca timpul dumneavoastra este pretios, de aceea am
                  pregatit cateva intrebari intr-un formular online la care
                  puteti raspunde in doar 3 minute. Raspunsurile dumneavoastra
                  ne vor ajuta sa imbunatatim calitatea serviciilor noastre si
                  sa construim impreuna o relatie frumoasa si pe viitor.
                  <br />
                  <br />
                  Feedback-ul dvs. este important pentru noi in acest va dorim
                  sa va oferim un cod de fidelitate pe care va invitam sa-l
                  folositi pentru urmatoarea solicitare de servicii.
                </div>
              </div>
              <hr />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    component="input"
                    type="text"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Nume Companie (optional)"
                    name="company_name"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="text"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Nume"
                    name="first_name"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="text"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Prenume"
                    name="last_name"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="text"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Locatie"
                    name="address"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="number"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Telefon"
                    name="telephone"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="email"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Adresa Email"
                    name="email"
                  />
                </div>
              </div>
    
              <div
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                Selecteaza domeniile in care ati colaborat cu lpv
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="col-lg-6" style={{ textAlign: "left" }}>
                  <Select
                    options={categoryList}
                    onChange={handleCategoryChange}
                  />
                </div>
                {category === "Servicii tehnice" && (
                  <div className="col-lg-6" style={{ textAlign: "left" }}>
                    <Select
                      options={subcategoryList}
                      onChange={handleSubCategoryChange}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                Evaluare personal de programare/vanzare LPV (daca e cazul)
                <br />
                Acordati un calificativ aspectelor de mai jos in functie de
                interactiunile pe care le-ati avut cu personalul de
                programare/vanzare din LPV:
              </div>
              <div style={{ width: "100%", marginBottom: "15px" }}>
                <table>
                  <tr>
                    <th style={{ textAlign: "left" }}>#</th>
                    <th>Slab</th>
                    <th>Potrivita</th>
                    <th>Foarte Bine</th>
                    <th>Nu stiu/Nu raspund</th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Viteza de răspuns la solicitările dvs..
                    </td>

                    <td
                      onClick={() => {
                        setVitezaDeRaspunsRating("LOW");
                      }}
                    >
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="viteza_de_raspuns_rating"
                        value="LOW"
                        checked={vitezaDeRaspunsRating == "LOW"}
                      />
                    </td>
                    <td  onClick={() => {
                        setVitezaDeRaspunsRating("MEDIUM");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="viteza_de_raspuns_rating"
                        value="MEDIUM"
                        checked={vitezaDeRaspunsRating == "MEDIUM"}
                      />
                    </td>
                    <td  onClick={() => {
                        setVitezaDeRaspunsRating("HIGH");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="viteza_de_raspuns_rating"
                        value="HIGH"
                        checked={vitezaDeRaspunsRating == "HIGH"}
                      />
                    </td>
                    <td  onClick={() => {
                        setVitezaDeRaspunsRating("IDK");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="viteza_de_raspuns_rating"
                        value="IDK"
                        checked={vitezaDeRaspunsRating == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>Claritate raspunsuri</td>
                    <td
                    onClick={() => {
                      setClaritateRaspunsuri("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="claritate_raspunsuri_rating"
                        value="LOW"
                        checked={claritateRaspunsuri == "LOW"}
                      />
                    </td>
                    <td  onClick={() => {
                        setClaritateRaspunsuri("MEDIUM");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="claritate_raspunsuri_rating"
                        value="MEDIUM"
                        checked={claritateRaspunsuri == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() => {
                        setClaritateRaspunsuri("HIGH");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="claritate_raspunsuri_rating"
                        value="HIGH"
                        checked={claritateRaspunsuri == "HIGH"}
                      />
                    </td>
                    <td onClick={() => {
                        setClaritateRaspunsuri("IDK");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="claritate_raspunsuri_rating"
                        value="IDK"
                        checked={claritateRaspunsuri == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Respectarea promisiunilor
                    </td>
                    <td onClick={() => {
                        setRespectareaPromisiunilor("LOW");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_realizarea_platii_rating"
                        value="LOW"
                        checked={respectareaPromisiunilor == "LOW"}
                      />
                    </td>
                    <td onClick={() => {
                        setRespectareaPromisiunilor("MEDIUM");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="respectarea_promisiunilor_rating"
                        value="MEDIUM"
                        checked={respectareaPromisiunilor == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() => {
                        setRespectareaPromisiunilor("HIGH");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="respectarea_promisiunilor_rating"
                        value="HIGH"
                        checked={respectareaPromisiunilor == "HIGH"}
                      />
                    </td>
                    <td onClick={() => {
                        setRespectareaPromisiunilor("IDK");
                      }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="respectarea_promisiunilor_rating"
                        value="IDK"
                        checked={respectareaPromisiunilor == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Profesionalismul personalului de vanzari/dispecerat
                    </td>
                    <td onClick={() =>{
                      setProfesionalismulPersonalului("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="profesionalismul_personalului_rating"
                        value="LOW"
                        checked={profesionalismulPersonalului == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setProfesionalismulPersonalului("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="profesionalismul_personalului_rating"
                        value="MEDIUM"
                        checked={profesionalismulPersonalului == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setProfesionalismulPersonalului("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="profesionalismul_personalului_rating"
                        value="HIGH"
                        checked={profesionalismulPersonalului == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setProfesionalismulPersonalului("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="profesionalismul_personalului_rating"
                        value="IDK"
                        checked={profesionalismulPersonalului == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Raportul calitate – pret pentru serviciul prestat
                    </td>
                    <td onClick={() =>{
                      setRaportCalitatePret("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="raport_calitate_pret_rating"
                        value="LOW"
                        checked={raportCalitatePret == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setRaportCalitatePret("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="raport_calitate_pret_rating"
                        value="MEDIUM"
                        checked={raportCalitatePret == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setRaportCalitatePret("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="raport_calitate_pret_rating"
                        value="HIGH"
                        checked={raportCalitatePret == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setRaportCalitatePret("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="raport_calitate_pret_rating"
                        value="IDK"
                        checked={raportCalitatePret == "IDK"}
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                Evaluare platforma digitala LPV
              </div>
              <div style={{ width: "100%", marginBottom: "15px" }}>
                <table>
                  <tr>
                    <th style={{ textAlign: "left" }}>#</th>
                    <th>Slab</th>
                    <th>Potrivita</th>
                    <th>Foarte Bine</th>
                    <th>Nu stiu/Nu raspund</th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Usurinta de navigare pe site-ul lpvservice.ro
                    </td>

                    <td onClick={() =>{
                      setUsurintaDeNavigareWebsite("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_de_navigare_website_rating"
                        value="LOW"
                        checked={usurintaDeNavigareWebsite == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaDeNavigareWebsite("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_de_navigare_website_rating"
                        value="MEDIUM"
                        checked={usurintaDeNavigareWebsite == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaDeNavigareWebsite("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_de_navigare_website_rating"
                        value="HIGH"
                        checked={usurintaDeNavigareWebsite == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaDeNavigareWebsite("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_de_navigare_website_rating"
                        value="IDK"
                        checked={usurintaDeNavigareWebsite == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Usurinta in gasirea informatiei dorite
                    </td>
                    <td onClick={() =>{
                      setUsurintaDeGasireInformatiei("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_de_gasire_informatiei_rating"
                        value="LOW"
                        checked={usurintaDeGasireInformatiei == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaDeGasireInformatiei("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_de_gasire_informatiei_rating"
                        value="MEDIUM"
                        checked={usurintaDeGasireInformatiei == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaDeGasireInformatiei("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_de_gasire_informatiei_rating"
                        value="HIGH"
                        checked={usurintaDeGasireInformatiei == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaDeGasireInformatiei("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_de_gasire_informatiei_rating"
                        value="IDK"
                        checked={usurintaDeGasireInformatiei == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Usurinta in realizarea platii on-line
                    </td>
                    <td onClick={() =>{
                      setUsurintaInRealizareaPlatii("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_realizarea_platii_rating"
                        value="LOW"
                        checked={usurintaInRealizareaPlatii == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInRealizareaPlatii("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_realizarea_platii_rating"
                        value="MEDIUM"
                        checked={usurintaInRealizareaPlatii == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInRealizareaPlatii("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_realizarea_platii_rating"
                        value="HIGH"
                        checked={usurintaInRealizareaPlatii == "HIGH"}
                      />
                    </td>
                    <td  onClick={() =>{
                      setUsurintaInRealizareaPlatii("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_realizarea_platii_rating"
                        value="IDK"
                        checked={usurintaInRealizareaPlatii == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Usurinta in utilizare comparator de preturi
                    </td>
                    <td onClick={() =>{
                      setUsurintaInUtilizareaComaparatorului("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_utilizarea_comparatorului_rating"
                        value="LOW"
                        checked={usurintaInUtilizareaComparatorului == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInUtilizareaComaparatorului("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_utilizarea_comparatorului_rating"
                        value="MEDIUM"
                        checked={usurintaInUtilizareaComparatorului == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInUtilizareaComaparatorului("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_utilizarea_comparatorului_rating"
                        value="HIGH"
                        checked={usurintaInUtilizareaComparatorului == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInUtilizareaComaparatorului("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_utilizarea_comparatorului_rating"
                        value="IDK"
                        checked={usurintaInUtilizareaComparatorului == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Usurinta in utilizare programatorului on-line
                    </td>
                    <td onClick={() =>{
                      setUsurintaInUtilizareaProgramatorului("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_utilizarea_programatorului_rating"
                        value="LOW"
                        checked={usurintaInUtilizareaProgramatorului == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInUtilizareaProgramatorului("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_utilizarea_programatorului_rating"
                        value="MEDIUM"
                        checked={usurintaInUtilizareaProgramatorului == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInUtilizareaProgramatorului("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_utilizarea_programatorului_rating"
                        value="HIGH"
                        checked={usurintaInUtilizareaProgramatorului == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInUtilizareaProgramatorului("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_utilizarea_programatorului_rating"
                        value="IDK"
                        checked={usurintaInUtilizareaProgramatorului == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Usurinta in configurarea ofertei (utilizarea cosului de
                      cumparaturi)
                    </td>
                    <td onClick={() =>{
                      setUsurintaInConfigurareaOfertei("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_configurarea_ofertei_rating"
                        value="LOW"
                        checked={usurintaInConfigurareaOfertei == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInConfigurareaOfertei("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_configurarea_ofertei_rating"
                        value="MEDIUM"
                        checked={usurintaInConfigurareaOfertei == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInConfigurareaOfertei("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_configurarea_ofertei_rating"
                        value="HIGH"
                        checked={usurintaInConfigurareaOfertei == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setUsurintaInConfigurareaOfertei("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="usurinta_in_configurarea_ofertei_rating"
                        value="IDK"
                        checked={usurintaInConfigurareaOfertei == "IDK"}
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                Evaluare tehnician LPV (daca e cazul)
                <br />
                Cum evaluați calitatea serviciilor efectuate de specialiștii
                LPV:
              </div>
              <div style={{ width: "100%", marginBottom: "15px" }}>
                <table>
                  <tr>
                    <th style={{ textAlign: "left" }}>#</th>
                    <th>Slab</th>
                    <th>Potrivita</th>
                    <th>Foarte Bine</th>
                    <th>Nu stiu/Nu raspund</th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Atitudine/disponibilitate
                    </td>

                    <td onClick={() =>{
                      setAtitudine("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="atitudine_rating"
                        value="LOW"
                        checked={atitudine == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setAtitudine("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="atitudine_rating"
                        value="MEDIUM"
                        checked={atitudine == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setAtitudine("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="atitudine_rating"
                        value="HIGH"
                        checked={atitudine == "HIGH"}
                      />
                    </td>
                    <td  onClick={() =>{
                      setAtitudine("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="atitudine_rating"
                        value="IDK"
                        checked={atitudine == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>Cunostinte tehnice</td>
                    <td onClick={() =>{
                      setCunostinteTehnice("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="cunostinte_tehnice_rating"
                        value="LOW"
                        checked={cunostinteTehnice == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setCunostinteTehnice("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="cunostinte_tehnice_rating"
                        value="MEDIUM"
                        checked={cunostinteTehnice == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setCunostinteTehnice("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="cunostinte_tehnice_rating"
                        value="HIGH"
                        checked={cunostinteTehnice == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setCunostinteTehnice("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="cunostinte_tehnice_rating"
                        value="IDK"
                        checked={cunostinteTehnice == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>Tinuta</td>
                    <td onClick={() =>{
                      setTinuta("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="tinuta_rating"
                        value="LOW"
                        checked={tinuta == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setTinuta("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="tinuta_rating"
                        value="MEDIUM"
                        checked={tinuta == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setTinuta("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="tinuta_rating"
                        value="HIGH"
                        checked={tinuta == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setTinuta("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="tinuta_rating"
                        value="IDK"
                        checked={tinuta == "IDK"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      Profesionalismul personalului tehnic
                    </td>
                    <td onClick={() =>{
                      setProfesionalismulPers("LOW");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="profesionalismul_pers_rating"
                        value="LOW"
                        checked={profesionalismulPers == "LOW"}
                      />
                    </td>
                    <td onClick={() =>{
                      setProfesionalismulPers("MEDIUM");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="profesionalismul_pers_rating"
                        value="MEDIUM"
                        checked={profesionalismulPers == "MEDIUM"}
                      />
                    </td>
                    <td onClick={() =>{
                      setProfesionalismulPers("HIGH");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="profesionalismul_pers_rating"
                        value="HIGH"
                        checked={profesionalismulPers == "HIGH"}
                      />
                    </td>
                    <td onClick={() =>{
                      setProfesionalismulPers("IDK");
                    }}>
                      <input
                        style={{ height: "15px" }}
                        type="radio"
                        name="profesionalismul_pers_rating"
                        value="IDK"
                        checked={profesionalismulPers == "IDK"}
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="textarea-style mb-30">
                  <Field
                    required
                    placeholder="Ce considerați că am putea face mai bine in activitatea noastra pentru dvs.? Ce lucruri vă doriți să îmbunătățim?"
                    type="text"
                    component="textarea"
                    id="form-message"
                    className="form-control"
                    rows={4}
                    name="context"
                    style={{ minHeight: "150px" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-20">
                  <div
                    className="mb-20"
                    style={{
                      fontWeight: "500",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Va multumim !
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              "Loading..."
            ) : (
              <button
                style={{ backgroundColor: "darkgreen" }}
                className="submit submit-auto-width"
                type="submit"
              >
                Trimite mesaj
              </button>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

Feedback.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return { loading: state.aboutUs.loading };
};

export default connect(mapStateToProps, { createContactUs })(
  reduxForm({ form: "contact_us_complaints_form" })(Feedback)
);
