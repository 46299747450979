import React, { useEffect } from "react";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC } from "../../../requests";
import "./stripeStyle.css";

const stripePromise = loadStripe(STRIPE_PUBLIC);

const PaymentForm = ({ stripeData, paymentLoading = false }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  useEffect(() => {
    setIsLoading(paymentLoading);
  }, [paymentLoading]);

  const handleSubmit = async (e, stripe, elements) => {
    e.preventDefault();
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
   
      setIsLoading(false);
      stripeData(error);
    } else {

      setIsLoading(false);
      stripeData(paymentMethod);
      // ... SEND to your API server to process payment intent
    }
  };
 
  const stripe = useStripe();
  const elements = useElements();
  return (
    <>
      <CardElement />
      <button
        disabled={isLoading || paymentLoading}
        className={`stripe-button ${
          (isLoading || paymentLoading) && "stripe-button-disabled"
        }`}
        onClick={(e) => {
          setIsLoading(true);
          handleSubmit(e, stripe, elements);
        }}
      >
        Cumpara
      </button>
    </>
  );
};

const StripePaymentForm = ({ stripeData }) => (
  <Elements stripe={stripePromise}>
    <PaymentForm stripeData={stripeData} />
  </Elements>
);

export default StripePaymentForm;
