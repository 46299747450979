import axios from "axios";
import { toastr } from "react-redux-toastr";
import requests, { BACKEND_URL } from "../../requests";
import { LOADING_FOOTER, FOOTER_LIST } from "../types/types";

import { errorHandle } from "./common";

export const getFooterList = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_FOOTER,
      payload: true
    });
    //   Get Token from state

    try {
      const footerData = await axios.get(
        `${BACKEND_URL}${requests.FOOTER_LIST}`
      );

      if (footerData.status === 200) {
        dispatch({ type: FOOTER_LIST, payload: footerData.data.data });
      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_FOOTER);
    }
  };
};

export const subscribeNewsletter = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_FOOTER,
      payload: true
    });
    //   Get Token from state

    try {
      const footerData = await axios.post(
        `${BACKEND_URL}${requests.SUBSCRIBE_NEWSLETTERS}`,
        data
      );
      if (footerData.status === 200) {
        toastr.success("News Letter", "News Letter has been subscribes");
      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_FOOTER);
    }
  };
};
