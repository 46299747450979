import React, { useState, useEffect } from "react";

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
const TopBar = ({ user, websiteInfo }) => {
  const [fullName, setFullName] = useState("");
  useEffect(() => {
    if (user) {
      setFullName(user.first_name + " " + user.last_name);
    }
  }, [user]);
  return (
    <div className="header-top header-top-ptb-1 d-none d-lg-block">
      <div className="container">
        <div className="topbar-info-wrapper">
          <div className="topbar-contact">
            <i className="far fa-envelope"></i>
            <span>{websiteInfo?.email}</span>
            <div style={{ marginLeft: "10px" }} className="topbar-our-location">
              <i className="fas fa-map-marker-alt"></i>
              <span>{websiteInfo?.address}</span>
            </div>
          </div>

          <div className="topbar-message">
            <Carousel
              infinite={true}
              arrows={false}
              autoPlay
              autoPlaySpeed={7000}
              customTransition="all 1s horizontal"
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 1,
                },
              }}
            >
              <div className="topbar-message-text">Item 1</div>
              <div className="topbar-message-text">Item 2</div>
              <div className="topbar-message-text">Item 3</div>
              <div className="topbar-message-text">Item 4</div>
            </Carousel>
          </div>
          <div className="topbar-login">
            {!user ? (
              <i className="far fa-user"></i>
            ) : (
              <>
                {user?.avatar === null ? (
                  <Avatar
                    name={fullName}
                    size="24"
                    round={true}
                    className="letters-avatar "
                    colors={["#0275d8", "#d82f3d", "#01baef"]}
                    maxInitials="2"
                  />
                ) : (
                  <img
                    style={{
                      width: "24px",
                      height: "24px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    src={user?.avatar}
                    alt="user-avatar"
                  />
                )}
              </>
            )}

            {user?.email ? (
              <Link to="/profil">
                {user?.first_name} {user?.last_name}
              </Link>
            ) : (
              <>
                <Link to="/log_in">Autentificare</Link>
                <Link to="/register"> | Creaza Cont</Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ product, auth, aboutUs }) => ({
  categories: product.categories,
  user: auth.user,
  websiteInfo: aboutUs.websiteInfoDetails,
});
export default connect(mapStateToProps, {})(TopBar);
