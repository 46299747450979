import {
  LOADING_TERMS,
  TERMS_LIST,
  LOADING_COOKIES,
  COOKIES_LIST,
  LOADING_PRIVACY,
  PRIVACY_LIST,
  LOADING_RETURN,
  RETURN_LIST
} from '../types/types'

const initialState = {
  loading: false,
  terms: [],
  cookies: [],
  returnPolicy: [],
  privacy: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TERMS:
      return { ...state, loading: action.payload || false }
    case TERMS_LIST:
      return { ...state, terms: action.payload }

    case LOADING_COOKIES:
      return { ...state, loading: action.payload || false }
    case COOKIES_LIST:
      return { ...state, cookies: action.payload }

    case LOADING_PRIVACY:
      return { ...state, loading: action.payload || false }
    case PRIVACY_LIST:
      return { ...state, privacy: action.payload }

    case LOADING_RETURN:
      return { ...state, loading: action.payload || false }
    case RETURN_LIST:
      return { ...state, returnPolicy: action.payload }

    default:
      return state
  }
}
