import React, { useState } from "react";
import { Link } from "react-router-dom";
import slug from "slug";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import ReactStars from "react-rating-stars-component";
import { Modal, ModalBody } from "reactstrap";
import ProductQuickView from "../../../Common/ProductQuickView";

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { storeInWishlist } from "../../../redux/actions/wishlist";
import { handleCompare } from "../../../helper/cart";

const ProductCardCarousel = ({ products, storeInWishlist }) => {
  const handleAddToWishlist = (product) => {
    storeInWishlist({ product_id_ls: [product?.id] });
  };

  const [modal, setModal] = useState({
    show: false,
    id: "",
  });

  const toggle = (show = true, id) =>
    setModal({
      show,
      id,
    });

  return (
    <>
      <Carousel
        infinite={true}
        arrows={false}
        draggable={true}
        autoPlay
        autoPlaySpeed={4000}
        customTransition="all 1s linear"
        minimumTouchDrag={80}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 6,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 2,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 4,
          },
        }}
      >
        {products?.map(
          (product, index) =>
            index >= 0 &&
            index <= 11 && (
              <div className="product-cart-wrap small hover-up">
                <div className="product-img-action-wrap">
                  <div
                    className="product-img product-img-zoom"
                    style={{
                      border: "1px solid #d1e8f2",
                      borderRadius: "20px",
                      height: "180px",
                    }}
                  >
                    <Link
                      to={`/product/${product?.id}/${slug(
                        product?.title || "product",
                        "_"
                      )}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <img
                        className="default-img"
                        src={product?.images?.[0]?.image}
                        alt={product?.title}
                        style={{ objectFit: "contain" }}
                      />
                      {/* <img
                        className="hover-img"
                        src={product?.images?.[1]?.image}
                        alt={product?.title}
                        style={{ objectFit: "contain" }}
                      /> */}
                    </Link>
                  </div>
                  <div className="product-action-1">
                    <a
                      onClick={() => toggle(true, product?.id)}
                      aria-label="Vizualizeaza rapid"
                      className="action-btn hover-up"
                    >
                      <i
                        style={{ fontSize: ".8rem" }}
                        className="far fa-eye"
                      ></i>
                    </a>
                    <a
                      aria-label="Adauga la favorit"
                      className="action-btn hover-up"
                      onClick={() => handleAddToWishlist(product)}
                    >
                      <i
                        style={{ fontSize: ".8rem" }}
                        className="far fa-heart"
                      ></i>
                    </a>
                    <a
                      aria-label="Compara"
                      className="action-btn hover-up"
                      onClick={() => handleCompare(product)}
                    >
                      <i
                        style={{ fontSize: ".8rem" }}
                        className="fas fa-random"
                      ></i>
                    </a>
                  </div>
                  <div
                    className="
                    product-badges
                    product-badges-position
                    product-badges-mrg
                  "
                  >
                    {" "}
                    {product?.discount_percentage > 0 ? (
                      <span className="hot">
                        {product.discount_percentage}% Reducere
                      </span>
                    ) : null}
                    {product?.discount_amount > 0 ? (
                      <span className="best">
                        -{product.discount_amount} RON Reducere
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="product-content-wrap">
                  <h2
                    style={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Link
                      to={`/product/${product?.id}/${slug(
                        product?.title || "product",
                        "_"
                      )}`}
                      style={{
                        textDecoration: "none",
                      }}
                      className="text-limit-2"
                    >
                      {product?.title}
                    </Link>
                  </h2>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      {product.reviews_count < 1 ? (
                         <div style={{ margin: '0.875rem 0'}}></div>
                      ) : (
                        <ReactStars
                          count={product?.average_rating}
                          size={16}
                          emptyIcon={<i className="far fa-star"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          color="#ffd700"
                          edit={false}
                        />
                      )}
                    </div>
                  </div>
                  {product?.send_offer === true ? (
                    <a
                      href={`/product/${product?.id}/${slug(
                        product?.title || "product",
                        "_"
                      )}`}
                      className="btn btn-secondary btn-sm"
                    >
                      Cere Oferta
                    </a>
                  ) : (
                    <div
                      className="product-price"
                      style={{
                        minHeight: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>{product?.price} RON </span>
                      {product?.discount_percentage > 0 ||
                      product?.discount_amount > 0 ? (
                        <span className="old-price">
                          {product?.price_including_vat}
                          RON
                        </span>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            )
        )}
      </Carousel>
      <Modal
        isOpen={modal?.show}
        toggle={() => toggle(false)}
        style={{ maxWidth: "1100px", width: "100%" }}
      >
        <ModalBody toggle={() => toggle(false)}>
          <ProductQuickView id={modal?.id} />
        </ModalBody>
      </Modal>
    </>
  );
};

ProductCardCarousel.propTypes = {
  // products: PropTypes.object
};

const mapStateToProps = ({ product, banner }) => ({});
export default connect(mapStateToProps, {
  storeInWishlist,
})(ProductCardCarousel);
