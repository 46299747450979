import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import ReactHtmlParser from "react-html-parser";

const VerticalTimelineLPV = ({ aboutUsTimeline }) => {
  return (
    <>
      <VerticalTimeline>
        {aboutUsTimeline.map((item) => (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            icon={ReactHtmlParser(item.year)}
          >
            <img
              className="vertical-timeline-element-image"
              src={item.image}
              alt={item.title}
            ></img>
            {item.title !== "null" ? (
              <h4
                style={{ marginTop: "10px" }}
                className="vertical-timeline-element-title"
              >
                {item.title}{" "}
              </h4>
            ) : null}
            {item.subtitle !== "null" ? (
              <h5 className="vertical-timeline-element-subtitle">
                {item.subtitle}
              </h5>
            ) : null}
            <div className="timeline-text-weight">
              {ReactHtmlParser(item.message)}
            </div>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </>
  );
};

export default VerticalTimelineLPV;
