import React from "react";
import AliceCarousel from "react-alice-carousel";
import FeaturedImage from "./FeaturedImage";

import "react-alice-carousel/lib/alice-carousel.css";
import "./carouselstyle.css";

class CarouselFinal extends React.Component {
  state = {
    currentIndex: 0,
    itemsInSlide: 1,
    responsive: { 0: { items: 3 } },
    galleryItems: [],
    modal: false
  };

  componentDidMount() {
    const { images } = this.props;
    if (images) this.setState({ galleryItems: images });
  }

  componentDidUpdate(preProps) {
    const { images } = this.props;
    if (preProps.images !== images) {
      this.setState({ galleryItems: images });
    }
  }

  handleOnSlideChange = (event) => {
    const { itemsInSlide, item } = event;
    this.setState({ itemsInSlide, currentIndex: item });
  };

  slideTo = (i) => this.setState({ currentIndex: i });

  // my handlers
  handleEnlargeClick = () => {
    this.setState({ modal: true });
  };

  handleModalFalse = () => {
    this.setState({ modal: false });
  };

  render() {
    const { currentIndex, galleryItems, responsive } = this.state;

    return (
      <>
        <div className="alice-wrapper">
          <FeaturedImage
            items={galleryItems}
            currentIndex={this.state.currentIndex}
          />
          <AliceCarousel
            // items={galleryItems}
            dotsDisabled={true}
            slideToIndex={currentIndex}
            responsive={responsive}
            onInitialized={this.handleOnSlideChange}
            onSlideChanged={this.handleOnSlideChange}
            onResized={this.handleOnSlideChange}
            mouseTracking={false}
          >
            {galleryItems.length > 1 ? (
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  maxHeight: "150px"
                }}
              >
                {galleryItems?.map((item, i) => (
                  <div
                    key={i}
                    onClick={() => this.slideTo(i)}
                    style={{
                      border: "1px solid #cbcbcb",
                      borderRadius: "5px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <img
                      width="90%"
                      height="90%"
                      src={item}
                      style={{ objectFit: "contain" }}
                      alt={item?.title}
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </AliceCarousel>
        </div>
      </>
    );
  }
}

export default CarouselFinal;
