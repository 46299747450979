import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ProductRecom = ({}) => {
  const [suputila, setSuputila] = React.useState(null);

  const getSupUtila = (e) => {
    setSuputila(+e.target.value);
  };

  const [inaltime, setInaltime] = React.useState(null);

  const getInaltime = (e) => {
    setInaltime(+e.target.value);
  };

  const [total, setTotal] = React.useState(null);

  useEffect(() => setTotal((suputila * inaltime * 50) / 1000));

  return (
    <>
      <div className="site__body">
        <div className="page-header">
          <div className="page-header__container container">
            <div className="page-header__breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Calculator Recomandare</Link>
                    <svg className="breadcrumb-arrow" width="6px" height="9px">
                      <use xlinkHref="images/sprite.svg#arrow-rounded-right-6x9" />
                    </svg>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="page-header__title">
              <h1>Recomandare CT regim ACM Instant</h1>
            </div>
          </div>
        </div>
        <div className="block">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="block">
                  <div className="container">
                    <form>
                      <label for="basic-url">
                        Ce suprafata utila are casa dvs ?
                      </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">MP</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby="basic-addon3"
                          value={suputila}
                          onChange={getSupUtila}
                          required
                        />
                      </div>

                      <label for="basic-url">
                        Ce inaltime medie au incaperile dvs.?
                      </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">M</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby="basic-addon3"
                          value={inaltime}
                          onChange={getInaltime}
                          required
                        />
                      </div>
                      <label>
                        Puterea CT necesara este <strong>{total} KW</strong>
                      </label>

                      {total > 0 && (
                        <>
                          <div>Centrale recomandate : </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRecom;
