import React from 'react'
import TopBar from './section/TopBar'
import MiddleBar from './section/MiddleBar'
import Navigation from './section/Navigation'

const HeaderDesktop = () => {
  return (
    <>
      <header className="header-area header-style-1 header-height-2">
        <TopBar />
        <MiddleBar />
        <Navigation />
      </header>
    </>
  )
}
export default HeaderDesktop 