import React, { useEffect } from "react";
import AboutUsText from "./sections/AboutUsText";
import CardEmployee from "./sections/CardEmployee";
import VerticalTimeline from "./sections/VerticalTimeline";
import {
  getAboutUs,
  getDirectorMessage,
  getAboutContact,
  getAboutTeam,
  getTimeLine
} from "../../redux/actions/about_us";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const AboutUs = ({
  getAboutUs,
  aboutUs,
  getDirectorMessage,
  directorMessage,
  getAboutContact,
  aboutUsContact,
  getAboutTeam,
  aboutTeamData,
  aboutUsTimeline,
  getTimeLine
}) => {
  useEffect(() => {
    getAboutUs();
    getDirectorMessage();
    getAboutContact();
    getAboutTeam();
    getTimeLine();
  }, []);

  return (
    <main className="main single-page">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              Home
            </Link>
            <span /> Despre noi
          </div>
        </div>
      </div>
      <AboutUsText
        aboutUs={aboutUs?.[0] || {}}
        directorMessage={directorMessage?.[0] || {}}
        aboutUsContact={aboutUsContact?.[0] || {}}
      />
      <VerticalTimeline
        aboutUsTimeline={aboutUsTimeline || {}}
      />
      <CardEmployee
        aboutTeamData={aboutTeamData}
        directorMessage={directorMessage?.[0] || {}}
      />
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    aboutUs: state.aboutUs.aboutUs,
    directorMessage: state.aboutUs.directorMessage,
    aboutUsContact: state.aboutUs.aboutUsContact,
    aboutTeamData: state.aboutUs.aboutTeamData,
    aboutUsTimeline: state.aboutUs.aboutUsTimeline
  };
};
export default connect(mapStateToProps, {
  getAboutUs,
  getDirectorMessage,
  getAboutContact,
  getAboutTeam,
  getTimeLine
})(AboutUs);
