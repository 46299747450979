import React, { useState, useEffect } from "react";
import BlogEntry from "./sections/BlogEntry";
import BlogFeaturedPost from "./sections/BlogFeaturedPost";
import BlogLastEntry from "./sections/BlogLastEntry";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { getBlogPosts } from "../../redux/actions/blog";
import { ITEMS_PER_PAGE } from "../../requests";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const items_per_page = ITEMS_PER_PAGE;

const Blog = ({ getBlogPosts, blogPosts, pagination, websiteInfo }) => {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getBlogPosts({ items_per_page });
  }, []);

  const handlePagination = (page) => {
    setCurrentPage(page.selected);
    getBlogPosts({ page_num: page.selected + 1, items_per_page });
  };

  return (
    <section>
      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
        className="page-header__breadcrumb"
      >
        <nav aria-label="breadcrumb">
          <div className="page-header breadcrumb-wrap">
            <div className="container">
              <div className="breadcrumb">
                <Link to="/">
                    <a rel="nofollow">Acasa</a>
                </Link>
                <span></span> Blog
                <span></span> Ultimele Articole
              </div>
            </div>
          </div>
        </nav>
      </motion.div>
      <div className="mt-50 mb-50">
        <div className="container custom">
          <div className="page-header__container container">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="page-header__title"
            >
              <div className="single-header mb-50">
                <h1 className="font-xxl text-brand">
                  {websiteInfo?.blog_title}
                </h1>
                {/* <div className="entry-meta meta-1 font-xs mt-15 mb-15">
                  <span className="post-by">32 Sub Categories</span>
                  <span className="post-on has-dot">1020k Article</span>
                  <span className="time-reading has-dot">480 Authors</span>
                  <span className="hit-count  has-dot">29M Views</span>
                </div> */}
              </div>
            </motion.div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="block">
                <div className="posts-view">
                  <div className="posts-view__list posts-list posts-list--layout--classic">
                    <motion.div
                      initial={{ x: -100, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 1, delay: 0.5 }}
                      className="posts-list__body"
                    >
                      <div className="row">
                        <BlogFeaturedPost blogPosts={blogPosts} />
                        <BlogEntry blogPosts={blogPosts} />
                      </div>
                    </motion.div>
                  </div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="posts-view__pagination"
                  >
                    <ReactPaginate
                      previousLabel="<"
                      nextLabel=">"
                      forcePage={currentPage}
                      onPageChange={(page) => handlePagination(page)}
                      pageCount={pagination?.total / items_per_page}
                      breakLabel="..."
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={2}
                      activeClassName="active"
                      pageClassName="page-item"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      nextClassName="page-item next"
                      previousClassName="page-item prev"
                      previousLinkClassName="page-link"
                      pageLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-center pr-1 mt-1"
                    />
                  </motion.div>
                </div>
              </div>
            </div>
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="col-12 col-lg-4"
            >
              <div className="block block-sidebar block-sidebar--position--end">
                <div className="sidebar-widget widget_alitheme_lastpost mb-20">
                  <div className="widget-header position-relative mb-20 pb-10">
                    <h5 className="widget-title">Ultimele Articole</h5>
                  </div>
                  <div className="row">
                    <BlogLastEntry blogPosts={blogPosts} />
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ blog, aboutUs }) => ({
  websiteInfo: aboutUs.websiteInfoDetails,
  blogPosts: blog.blogPosts?.data || [],
  pagination: {
    next_page_num: blog.blogPosts.next_page_num,
    total: blog.blogPosts.total_number_of_blogposts,
  },
});

export default connect(mapStateToProps, {
  getBlogPosts
})(Blog);
