import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getProducts,
  getProductSpecification,
  createProductReview,
  productReviewList
} from "../../redux/actions/products";
import Moment from "react-moment";

import Carousel from "react-multi-carousel";

import ReactStars from "react-rating-stars-component";

import Avatar from "react-avatar";

import ReactHtmlParser from "react-html-parser";

import ProductQuickView from "../../Common/ProductQuickView";

import { Link } from "react-router-dom";

import slug from "slug";

import { storeInWishlist } from "../../redux/actions/wishlist";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ProductPage = ({
  getProducts,
  products,
  match,
  productDetail = {},
  attributesSpecification,
  getProductSpecification,
  createProductReview,
  productReviewList,
  reviewList,
  storeInWishlist
}) => {
  const id = match?.params?.id;

  useEffect(() => {
    getProducts();
    getProductSpecification(id);
  }, [id]);

  const handleAddToWishlist = (product) => {
    storeInWishlist({ product_id_ls: [product?.id] });
  };


  useEffect(() => {
    const formData = new FormData();
    formData.append("product_id", id);
    productReviewList(formData);
  }, [id]);



  // --------------- PRODUCT TABS

  const [showDescription, setShowDescription] = useState(true);
  const [showSpecifications, setShowSpecifications] = useState(false);
  const [showReview, setShowReview] = useState(false);

  function clickDescription() {
    setShowDescription(true);
    setShowSpecifications(false);
    setShowReview(false);
  }

  function clickSpecifications() {
    setShowDescription(false);
    setShowSpecifications(true);
    setShowReview(false);
  }

  function clickReview() {
    setShowDescription(false);
    setShowSpecifications(false);
    setShowReview(true);
  }

  // --------------- END PRODUCT TABS

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
const handleCompare=(product)=>{
  const getCompareData = localStorage.getItem('compare')

  


}
  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/" rel="nofollow">
              LPV
            </a>
            <span /> {productDetail?.title}
          </div>
        </div>
      </div>
      <section className="mt-50 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="product-detail accordion-detail">
                <ProductQuickView id={id} />
                {productDetail?.recommended_products?.length > 0 ? (
                  <div className="row mt-60 mb-60">
                    <div className="col-12">
                      <h3 className="section-title style-1 mb-30">
                        Produse recomandate LPV
                      </h3>
                    </div>
                    <div className="col-12">
                      <div className="row related-products">
                        <div className="col-lg-12">
                          <Carousel
                            infinite={true}
                            arrows={false}
                            draggable={true}
                            autoPlay
                            autoPlaySpeed={4000}
                            customTransition="all 1s linear"
                            minimumTouchDrag={80}
                            responsive={{
                              desktop: {
                                breakpoint: {
                                  max: 3000,
                                  min: 1024,
                                },
                                items: 5,
                              },
                              mobile: {
                                breakpoint: {
                                  max: 464,
                                  min: 0,
                                },
                                items: 1,
                              },
                              tablet: {
                                breakpoint: {
                                  max: 1024,
                                  min: 464,
                                },
                                items: 3,
                              },
                            }}
                          >
                            {productDetail?.recommended_products?.map(
                              (product, index) => (
                  //               <div className="product-cart-wrap small hover-up">
                  //                 <div className="product-img-action-wrap">
                  //                   <div className="product-img product-img-zoom">
                  //                     <Link
                  //                       to={`/product/${product?.id}/${slug(
                  //                         product?.title || "product",
                  //                         "_"
                  //                       )}`}
                  //                       style={{
                  //                         textDecoration: "none",
                  //                       }}
                  //                     >
                  //                       <img style={{height: "200px", objectFit: "contain"}}
                  //                         className="default-img"
                  //                         src={product?.images?.[0]?.image}
                  //                         alt={product?.title}
                  //                       />
                  //                       {/* <img
                  //                         className="hover-img"
                  //                         src={product?.images?.[1]?.image}
                  //                         alt={product?.title}
                  //                       /> */}
                  //                     </Link>
                  //                   </div>
                  //                   <div className="product-action-1">
                  //                     <a
                  //                       aria-label="Vizualizeaza rapid"
                  //                       className="action-btn hover-up"
                  //                     >
                  //                       <i
                  //                         style={{ fontSize: ".8rem" }}
                  //                         className="far fa-eye"
                  //                       ></i>
                  //                     </a>
                  //                     <a
                  //                       aria-label="Adauga la favorit"
                  //                       className="action-btn hover-up"
                  //                       onClick={() =>
                  //                         handleAddToWishlist(product)
                  //                       }
                  //                     >
                  //                       <i
                  //                         style={{ fontSize: ".8rem" }}
                  //                         className="far fa-heart"
                  //                       ></i>
                  //                     </a>
                  //                     <button
                  //                       aria-label="Compara"
                  //                       className="action-btn hover-up"
                  //                       onClick={() =>
                  //                         handleCompare(product)
                  //                       }
                  //                     >
                  //                       <i
                  //                         style={{ fontSize: ".8rem" }}
                  //                         className="fas fa-random"
                  //                       ></i>
                  //                     </button>
                  //                   </div>
                  //                   <div
                  //                     className="
                  //   product-badges
                  //   product-badges-position
                  //   product-badges-mrg
                  // "
                  //                   >
                  //                     {" "}
                  //                     {product?.discount_percentage > 0 ? (
                  //                       <span className="hot">
                  //                         -{product.discount_percentage}%
                  //                       </span>
                  //                     ) : null}
                  //                     {product?.discount_amount > 0 ? (
                  //                       <span className="best">
                  //                         -{product.discount_amount} RON
                  //                       </span>
                  //                     ) : null}
                  //                   </div>
                  //                 </div>
                  //                 <div className="product-content-wrap">
                  //                   <h2>
                  //                     <Link
                  //                       to={`/product/${product?.id}/${slug(
                  //                         product?.title || "product",
                  //                         "_"
                  //                       )}`}
                  //                       style={{
                  //                         textDecoration: "none",
                  //                       }}
                  //                       className="text-limit-2"
                  //                     >
                  //                       {product?.title}
                  //                     </Link>
                  //                   </h2>
                  //                   <div>
                  //                     <div
                  //                       style={{
                  //                         display: "flex",
                  //                         alignContent: "center",
                  //                         justifyContent: "center",
                  //                       }}
                  //                     >
                  //                       {product.average_rating < 1 ? (
                  //                         <ReactStars
                  //                           count={5}
                  //                           size={16}
                  //                           emptyIcon={
                  //                             <i className="far fa-star"></i>
                  //                           }
                  //                           fullIcon={
                  //                             <i className="fa fa-star"></i>
                  //                           }
                  //                           color="#cbcbcb"
                  //                           edit={false}
                  //                         />
                  //                       ) : (
                  //                         <ReactStars
                  //                           count={product?.average_rating}
                  //                           size={16}
                  //                           emptyIcon={
                  //                             <i className="far fa-star"></i>
                  //                           }
                  //                           fullIcon={
                  //                             <i className="fa fa-star"></i>
                  //                           }
                  //                           color="#ffd700"
                  //                           edit={false}
                  //                         />
                  //                       )}
                  //                     </div>
                  //                   </div>
                  //                   <div className="product-price">
                  //                     <span>{product?.price} RON </span>
                  //                     {/* <span className="old-price">
                  //                       {product?.price_including_vat}
                  //                     </span> */}
                  //                   </div>
                  //                 </div>
                  //               </div>

                  <div className="product-cart-wrap" style={{ margin: "0 5px" }}>
                  <div className="product-img-action-wrap">
                    <div
                      className="product-img product-img-zoom"
                      style={{ height: "180px", display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                      <Link
                        to={`/product/${product?.id}/${slug(
                          product?.title || "product",
                          "_"
                        )}`}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <img
                          className="default-img"
                          src={product?.images?.[0]?.image}
                          alt={product?.title}
                          style={{ objectFit: "contain" }}
                        />
                        {/* <img
                          className="hover-img"
                          src={product?.images?.[1]?.image}
                          alt={product?.title}
                          style={{ objectFit: "contain" }}
                        /> */}
                      </Link>
                    </div>
                    {/* <div className="product-action-1">
                      <a
                        onClick={() => toggle(true, product?.id)}
                        aria-label="Vizualizeaza rapid"
                        className="action-btn hover-up"
                      >
                        <i
                          style={{ fontSize: ".8rem" }}
                          className="far fa-eye"
                        ></i>
                      </a>
                      <a
                        aria-label="Adauga la favorit"
                        className="action-btn hover-up"
                        onClick={() => handleAddToWishlist(product)}
                      >
                        <i
                          style={{ fontSize: ".8rem" }}
                          className="far fa-heart"
                        ></i>
                      </a>
                      <a
                        aria-label="Compara"
                        className="action-btn hover-up"
                        onClick={() => handleCompare(product)}
                      >
                        <i
                          style={{ fontSize: ".8rem" }}
                          className="fas fa-random"
                        ></i>
                      </a>
                    </div> */}
                    <div
                      className="
                      product-badges
                      product-badges-position
                      product-badges-mrg
                    "
                    >
                      {" "}
                      {product?.discount_percentage > 0 ? (
                        <span className="hot">
                          {product.discount_percentage}% Reducere
                        </span>
                      ) : null}
                      {product?.discount_amount > 0 ? (
                        <span className="best">
                          -{product.discount_amount} RON
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="product-content-wrap">
                    <div className="product-category">
                      <span>{product?.categories?.category?.name}</span>
                    </div>
                    <h2
                      style={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        to={`/product/${product?.id}/${slug(
                          product?.title || "product",
                          "_"
                        )}`}
                        style={{
                          textDecoration: "none",
                        }}
                        className="text-limit-2"
                      >
                        {product?.title}
                      </Link>
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      {product.reviews_count < 1 ? (
                        <ReactStars
                          count={5}
                          size={16}
                          emptyIcon={<i className="far fa-star"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          color="#cbcbcb"
                          edit={false}
                        />
                      ) : (
                        <ReactStars
                          count={product?.average_rating}
                          size={16}
                          emptyIcon={<i className="far fa-star"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          color="#ffd700"
                          edit={false}
                        />
                      )}
                    </div>
                    {product?.send_offer === true ? (
                      <>
                        <a
                          href={`/product/${product?.id}/${slug(
                            product?.title || "product",
                            "_"
                          )}`}
                          style={{ marginBottom: "4px" }}
                          className="product-price"
                        >
                          <span style={{ fontSize: ".9rem" }}>Cere Oferta </span>
                        </a>
                        <div className="product-action-1 show">
                          <a
                            aria-label="Vezi produs"
                            className="action-btn hover-up"
                            href={`/product/${product?.id}/${slug(
                              product?.title || "product",
                              "_"
                            )}`}
                          >
                            <i className="fas fa-eye"></i>
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="product-price">
                          <span>{product?.price} RON </span>
                          {product?.discount_percentage > 0 ||
                          product?.discount_amount > 0 ? (
                            <span className="old-price">
                              {product?.price_including_vat}
                              RON
                            </span>
                          ) : null}
                        </div>
                        <div className="product-action-1 show">
                          <a
                            aria-label="Adauga in cos"
                            className="action-btn hover-up"
                            // onClick={() => handleAddToCart(product)}
                            href={`/product/${product?.id}/${slug(
                              product?.title || "product",
                              "_"
                            )}`}
                          >
                            <i className="far fa-eye"></i>
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                              )
                            ) || []}
                          </Carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-lg-12 entry-main-content">
                    <div
                      style={{ border: "none" }}
                      className="main-navigation-details"
                    >
                      <h2
                        style={{ cursor: "pointer" }}
                        onClick={clickDescription}
                        className={`section-title custom-style-h2 ${
                          showDescription && "cs2-active"
                        }`}
                      >
                        Descriere
                      </h2>
                      {productDetail?.is_custom_product === false ? (
                        <h2
                          style={{ cursor: "pointer" }}
                          onClick={clickSpecifications}
                          className={`section-title custom-style-h2 ${
                            showSpecifications && "cs2-active"
                          }`}
                        >
                          Specificatii
                        </h2>
                      ) : null}

                      <h2
                        style={{ cursor: "pointer" }}
                        onClick={clickReview}
                        className={`section-title custom-style-h2 ${
                          showReview && "cs2-active"
                        }`}
                      >
                        Recenzii
                      </h2>
                    </div>
                    {showDescription ? (
                      <>
                        {productDetail?.is_custom_product === false ? (
                          <div className="custom-tab-product mb-50">
                            {ReactHtmlParser(productDetail.product_description)}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "25px",
                            }}
                          >
                            {productDetail?.steps?.map((step, index) => (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "15px",
                                  padding: "15px",
                                  borderRadius: "0 0.5rem",
                                  backgroundColor: "#fff",
                                  boxShadow:
                                    "0.25rem 0.25rem 0.6rem rgb(0 0 0 / 5%), 0 0.5rem 1.125rem rgb(75 0 0 / 5%)",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#000",
                                    background:
                                      "linear-gradient(135deg, #83e4e2 0%, #a2ed56 100%)",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    position: "abosolute",
                                    fontWeight: "700",
                                  }}
                                >
                                  {index + 1}.
                                </div>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "1rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {step?.title}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ) : null}
                    {showSpecifications ? (
                      <table className="font-md mb-30">
                        <tbody>
                          {attributesSpecification?.map((spec) => (
                            <tr className="specification-tr">
                              <th className="text-muted">
                                {spec.product_attribute_title}
                              </th>
                              <td>
                                <p>{spec.content}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : null}
                    {showReview ? (
                      <div className="comments-area style-2">
                        <div className="row">
                          <div className="col-lg-8">
                            {reviewList.length > 0 ? (
                              <>
                                <h4 className="mb-30">
                                  Parerile clientilor LPV
                                </h4>
                                <div className="comment-list">
                                  {reviewList?.map((review) => (
                                    <div className="single-comment justify-content-between d-flex">
                                      <div className="user justify-content-between d-flex">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minWidth: "100px",
                                          }}
                                          className="thumb text-center"
                                        >
                                          {review?.user?.avatar === null ? (
                                            <Avatar
                                              name={review?.user?.first_name}
                                              size="70"
                                              round={true}
                                              colors={[
                                                "#0275d8",
                                                "#d82f3d",
                                                "#01baef",
                                              ]}
                                              maxInitials="2"
                                            />
                                          ) : (
                                            <img
                                              src={review?.user?.avatar}
                                              alt={review?.user?.first_name}
                                              width={70}
                                              height={70}
                                              style={{ objectFit: "cover" }}
                                            />
                                          )}
                                          <h6 className="mt-10">
                                            <span>
                                              {review?.user?.first_name}{" "}
                                              {review?.user?.last_name}
                                            </span>
                                          </h6>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "column",
                                            padding: "0 0 10px 0",
                                          }}
                                          className="desc"
                                        >
                                          <ReactStars
                                            count={review?.stars}
                                            size={16}
                                            emptyIcon={
                                              <i className="far fa-star"></i>
                                            }
                                            fullIcon={
                                              <i className="fa fa-star"></i>
                                            }
                                            color="#ffd700"
                                            edit={false}
                                          />
                                          <p>{review?.content}</p>
                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <p className="font-xs mr-30">
                                                <Moment format="DD.MM.YYYY">
                                                  {review?.created_date}
                                                </Moment>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : <h4>Nicio recenzie încă</h4>}
                          
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = ({ product }) => ({
  productDetail: product?.productDetail,
  products: product.products,
  attributesSpecification: product.attributesSpecification,
  reviewList: product?.reviewList
});

export default connect(mapStateToProps, {
  getProducts,
  getProductSpecification,
  createProductReview,
  productReviewList,
  storeInWishlist
})(ProductPage);
