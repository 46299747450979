import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const RecomandareCTACMBoilerExtern = ({}) => {
  const [productsTwo, setProductsTwo] = useState([]);

  // MULTISTEP FORM START
  const [formStep, setFormStep] = React.useState(0);

  // STEP 0 BUTTONS

  // STEP 2 GET INFO AND CALCULATE

  const [suputila, setSuputila] = React.useState(null);

  const getSupUtila = (e) => {
    setSuputila(+e.target.value);
  };

  const [inaltime, setInaltime] = React.useState(null);

  const getInaltime = (e) => {
    setInaltime(+e.target.value);
  };

  const [bai, setBai] = React.useState(null);

  const getBai = (e) => {
    setBai(+e.target.value);
  };

  const [bucatarii, setBucatarii] = React.useState(null);

  const getBucatarii = (e) => {
    setBucatarii(+e.target.value);
  };

  const [persoane, setPersoane] = React.useState(null);

  const getPersoane = (e) => {
    setPersoane(+e.target.value);
  };

  const [ctotal, setCtotal] = React.useState(null);

  const [boiler, setBoiler] = React.useState(null);

  useEffect(() =>
    setCtotal((suputila * inaltime * 50) / 1000 + bai * 2 + bucatarii * 2)
  );

  useEffect(() => setBoiler(persoane * 30));

  return (
    <>
      <div className="site__body">
        <div className="page-header">
          <div className="page-header__container container">
            <div className="page-header__breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Calculator Recomandare</Link>
                    <svg className="breadcrumb-arrow" width="6px" height="9px">
                      <use xlinkHref="images/sprite.svg#arrow-rounded-right-6x9" />
                    </svg>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="page-header__title">
              <h1>Recomandare CT preparare ACM cu boiler extern</h1>
            </div>
          </div>
        </div>
        <div className="block">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="block">
                  <div className="container">
                    <form>
                      <label for="basic-url">
                        Ce suprafata utila are casa dvs ?
                      </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">MP</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby="basic-addon3"
                          value={suputila}
                          onChange={getSupUtila}
                          required
                        />
                      </div>

                      <label for="basic-url">
                        Ce inaltime medie au incaperile dvs.?
                      </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">M</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby="basic-addon3"
                          value={inaltime}
                          onChange={getInaltime}
                          required
                        />
                      </div>

                      <label for="basic-url">Cate bai aveti?</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Buc</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby="basic-addon3"
                          value={bai}
                          onChange={getBai}
                          required
                        />
                      </div>

                      <label for="basic-url">Cate bucatarii aveti?</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Buc</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby="basic-addon3"
                          value={bucatarii}
                          onChange={getBucatarii}
                          required
                        />
                      </div>
                      <br />
                      <hr />
                      <label for="basic-url">
                        Pentru boiler separat de centrala termica va rugam sa
                        mentionati cate persoane locuiesc in cladire?
                      </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Pers</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby="basic-addon3"
                          value={persoane}
                          onChange={getPersoane}
                          required
                        />
                      </div>
                      <label>
                        Daca doresti CT doar pentru incalzire si preparare apa
                        calda prin boiler, ai nevoie de un boiler de:{" "}
                        <strong>{boiler} litri</strong> - Vezi oferta pentru
                        boilere
                      </label>

                      <hr />
                      <br />
                      <label>
                        Puterea CT necesara este <strong>{ctotal} KW</strong>
                      </label>
                      {ctotal > 0 && bai > 0 && bucatarii > 0 && (
                        <>
                          <div>Centrale recomandate :</div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecomandareCTACMBoilerExtern;
