import React from "react";

const FeaturedImage = ({ items, currentIndex }) => {
  return (
    <div className="featured-wrapper">
      <img
        src={items?.[currentIndex]}
        width="100%"
        height="100%"
        alt="Product"
      />
    </div>
  );
};

export default FeaturedImage;
