import React, { useEffect, useState } from "react";
import UserMenuSideNav from "./UserMenuSideNav";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { getAddressList, createAddress } from "../../../redux/actions/auth";
import { Link } from "react-router-dom";

const UserAddress = ({ user, getAddressList, userAddress, createAddress }) => {
  const [modal, setModal] = useState(false);
  const [editAddress, setEditAddress] = useState({});

  const toggle = (address = {}, addressType = "") => {
    setEditAddress({ ...address, address_type: addressType });
    setModal(!modal);
  };

  useEffect(() => {
    getAddressList();
  }, []);



  const handleChange = (e) => {
    setEditAddress({
      ...editAddress,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateUpdate = () => {
    createAddress(editAddress);
    toggle();
  };

  return (
    <>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                LPV
              </Link>
              <span>Contul Meu</span>
              <span>Adrese</span>
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row mt-40 mb-40">
              <div className="col-lg-3">
                <UserMenuSideNav />
              </div>
              <div className="col-lg-9">
                <div className="row mt-4 mt-lg-0">
                  <div className="col-lg-12">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                      }}
                    >
                      <h5 className="text-center" style={{ margin: "0" }}>
                        Adrese de livrare / facturare
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  {Object.keys(user?.shippling_address || {})?.length > 0 ? (
                    <div className="col-lg-6">
                      <div
                        style={{
                          border: "1px solid #e2e9e1",
                          borderRadius: "5px",
                          padding: "15px",
                        }}
                      >
                        <h5
                          className=" text-center"
                          style={{ marginBottom: "20px" }}
                        >
                          Adresa de livrare
                        </h5>
                        <hr />
                        {userAddress?.map((address) => (
                          <>
                            {address?.address_type === "SHIPPING" ? (
                              <>
                                <h5 className="address-profile-name mt-4">{`${address?.first_name} ${address?.last_name}`}</h5>
                                <div className="address-profile-info">
                                  {address?.country}, {address?.city}
                                  <br />
                                  {address?.street_name}, {address?.street_name}
                                  <br />
                                  {address?.street_details}
                                  <br />
                                  Cod postal: {address?.zip_code}
                                </div>
                                <div className="address-profile-contact mt-3">
                                  <h5>Telefon</h5>
                                  <div>{address?.phone_number}</div>
                                </div>
                                <div className="address-profile-contact mt-3">
                                  <h5>Adresa Email</h5>
                                  <div>{address?.email}</div>
                                </div>
                                <div className="mt-4">
                                  <Button
                                    style={{
                                      border: "1px solid #cbcbcb",
                                      borderRadius: "5px",
                                      padding: "10px 20px",
                                      color: "#cbcbcb",
                                      backgroundColor: "#fff",
                                      width: "100%",
                                    }}
                                    onClick={() => toggle(address, "SHIPPING")}
                                  >
                                    Editeaza
                                  </Button>
                                </div>
                              </>
                            ) : null}
                          </>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-6">
                      <div
                        style={{
                          border: "1px solid #e2e9e1",
                          borderRadius: "5px",
                          padding: "15px",
                        }}
                      >
                        <h5
                          className=" text-center"
                          style={{ marginBottom: "20px" }}
                        >
                          Adresa de livrare
                        </h5>
                        <hr />
                        <div
                          style={{
                            height: "250px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "120px",
                              height: "120px",
                              border: "3px dotted #e2e9e1",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => toggle({}, "SHIPPING")}
                          >
                            <i
                              style={{ color: "#e2e9e1", fontSize: "2rem" }}
                              className="far fa-plus"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {Object.keys(user?.billing_address || {})?.length > 0 ? (
                    <div className="col-lg-6 mt-4 mt-lg-0">
                      <div
                        style={{
                          border: "1px solid #e2e9e1",
                          borderRadius: "5px",
                          padding: "15px",
                          height: "100%",
                        }}
                      >
                        <h5
                          className=" text-center"
                          style={{ marginBottom: "20px" }}
                        >
                          Adresa de facturare
                        </h5>
                        <hr />
                        {userAddress?.map((address) => (
                          <>
                            {address?.address_type === "BILLING" ? (
                              <>
                                <h5 className="address-profile-name mt-4">{`${address?.first_name} ${address?.last_name}`}</h5>
                                <div className="address-profile-info">
                                  {address?.country}, {address?.city}
                                  <br />
                                  {address?.street_name}, {address?.street_name}
                                  <br />
                                  {address?.street_details}
                                  <br />
                                  Cod postal: {address?.zip_code}
                                </div>
                                <div className="address-profile-contact mt-3">
                                  <h5>Telefon</h5>
                                  <div>{address?.phone_number}</div>
                                </div>
                                <div className="address-profile-contact mt-3">
                                  <h5>Adresa Email</h5>
                                  <div>{address?.email}</div>
                                </div>
                                <div className="mt-4">
                                  <Button
                                    style={{
                                      border: "1px solid #cbcbcb",
                                      borderRadius: "5px",
                                      padding: "10px 20px",
                                      color: "#cbcbcb",
                                      backgroundColor: "#fff",
                                      width: "100%",
                                    }}
                                    onClick={() => toggle(address, "BILLING")}
                                  >
                                    Editeaza
                                  </Button>
                                </div>
                              </>
                            ) : null}
                          </>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-6 mt-4 mt-lg-0">
                      <div
                        style={{
                          border: "1px solid #e2e9e1",
                          borderRadius: "5px",
                          padding: "15px",
                        }}
                      >
                        <h5
                          className=" text-center"
                          style={{ marginBottom: "20px" }}
                        >
                          Adresa de facturare
                        </h5>
                        <hr />
                        <div
                          style={{
                            height: "250px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "120px",
                              height: "120px",
                              border: "3px dotted #e2e9e1",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => toggle({}, "BILLING")}
                          >
                            <i
                              style={{ color: "#e2e9e1", fontSize: "2rem" }}
                              className="far fa-plus"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal isOpen={modal} toggle={() => toggle()}>
        <ModalHeader toggle={() => toggle()}>
          {editAddress?.address_type === "BILLING"
            ? "Adresa de facturare"
            : "Adrese de livrare"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FormGroup>
                <Label for="userName">Nume</Label>
                <Input
                  type="text"
                  name="first_name"
                  id="userName"
                  placeholder="Nume"
                  value={editAddress?.first_name}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>
            <div className="col-lg-6 col-md-12">
              <FormGroup>
                <Label for="userName">Prenume</Label>
                <Input
                  type="text"
                  name="last_name"
                  id="userName"
                  placeholder="Prenume"
                  value={editAddress?.last_name}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FormGroup>
                <Label for="userName">Telefon</Label>
                <Input
                  type="number"
                  name="phone_number"
                  id="userName"
                  placeholder="Numar de telefon"
                  value={editAddress?.phone_number}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>
            <div className="col-lg-6 col-md-12">
              <FormGroup>
                <Label for="userName">Email</Label>
                <Input
                  type="text"
                  name="email"
                  id="userName"
                  placeholder="Adresa Email"
                  value={editAddress?.email}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup>
            <Label for="userName">Tara</Label>
            <Input
              type="text"
              name="country"
              id="userName"
              placeholder="Tara"
              value={editAddress?.country}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="userName">Oras</Label>
            <Input
              type="text"
              name="city"
              id="userName"
              placeholder="Oras"
              value={editAddress?.city}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="userName">Strada</Label>
            <Input
              type="text"
              name="street_name"
              id="userName"
              placeholder="Numele strazii"
              value={editAddress?.street_name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="userName">Numar Strada</Label>
            <Input
              type="text"
              name="street_number"
              id="userName"
              placeholder="Numarul strazii"
              value={editAddress?.street_number}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="userName">Detalii adresa</Label>
            <Input
              type="text"
              name="street_details"
              id="userName"
              placeholder="Bloc / Scara etc."
              value={editAddress?.street_details}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="userName">Cod Postal</Label>
            <Input
              type="text"
              name="zip_code"
              id="userName"
              placeholder="Codul postal"
              value={editAddress?.zip_code}
              onChange={handleChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleCreateUpdate}>
            Salveaza
          </Button>{" "}
          <Button color="secondary" onClick={() => toggle()}>
            Anuleaza
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  userAddress: auth.userAddress,
});
export default connect(mapStateToProps, {
  getAddressList,
  createAddress,
})(UserAddress);
