import { objectOf } from 'prop-types'
import React from 'react'

// Carousel
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'

const BrandCarousel = ({ partners }) => {
  return (
    <Carousel
      infinite={true}
      arrows={false}
      draggable={true}
      autoPlay
      autoPlaySpeed={4000}
      customTransition="all 1s linear"
      minimumTouchDrag={80}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 6
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 2
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items: 4
        }
      }}
    >
      {partners?.map((partner, index) => (
        <div
          className="brand-logo"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 15px'
          }}
        >
          <a
            href={partner?.link}
            style={{
              textDecoration: 'none'
            }}
          >
            <img
              style={{ height: '100px', objectFit: 'contain' }}
              className="img-grey-hover"
              src={partner?.image}
              alt={partner?.header}
            />
          </a>
        </div>
      ))}
    </Carousel>
  )
}

export default BrandCarousel
