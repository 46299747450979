import { combineReducers } from 'redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { reducer as formReducer } from 'redux-form'

import auth from './auth'
import aboutUs from './about_us'
import product from './products'
import banner from './banner'
import wishlist from './wishlist'
import blog from './blog'
import footer from './footer'
import legal from './legal'

const reducer = combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  auth,
  aboutUs,
  product,
  banner,
  wishlist,
  blog,
  footer,
  legal
})
const middleware = [thunk]

const store = createStore(
  reducer,
  {},
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
