import { toastr } from "react-redux-toastr";
import requests, { BACKEND_URL, TMP_TOKEN } from "../../requests";
import { USER_LOADED_SUCCESS } from "../types/types";

// Error Handle
export const errorHandle = (error, dispatch, type) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.code &&
    error.response.data.code === "token_not_valid"
  ) {
    localStorage.removeItem("access");
    toastr.error(
      error.response.data &&
        ((error.response.data.messages &&
          error.response.data.messages[0] &&
          error.response.data.messages[0].message) ||
          error.response.data.detail)
    );
    dispatch({
      type: "AUTH_ERROR",
      payload: { error: error.response.data },
    });
    window.location.href = "/log_in";
  } else if (
    error &&
    error.response &&
    error.response.data &&
    (error.response.data.message || error.response.data.detail)
  ) {
    toastr.error(
      (error.response.data.message &&
        error.response.data.message[0] &&
        error.response.data.message) ||
        error.response.data.detail
    );
  } else if (error?.response?.status) {
    if (error?.response?.data?.email) {
      toastr.error(error?.response?.data?.email?.join(" & "));
    } else if (error?.response?.data?.password) {
      toastr.error(error?.response?.data?.password?.join(" & "));
    } else if (error?.response?.data?.first_name) {
      toastr.error(error?.response?.data?.first_name?.join(" & "));
    } else if (error?.response?.data?.last_name) {
      toastr.error(error?.response?.data?.last_name?.join(" & "));
    } else {
      toastr.error("Validation Error");
    }
  }
  dispatch({
    type: type,
    payload: false,
  });
  dispatch({
    type: "ERROR",
    payload: { error: error?.response?.data },
  });
};

// get Use Details
// export const checkUserType = async (dispatch, getState) => {

// };

// Handle Token
export const tokenConfig = () => {
  // const token = TOKEN;
  // const token = getState().products.token || TOKEN;
  const token = localStorage.getItem("access"); // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // if Token , add to headers config
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  else {
    window.location.href = "/log_in";
  }
  return config;
};
