import axios from 'axios'
import { toastr } from 'react-redux-toastr'

import requests, { BACKEND_URL } from '../../requests'
import {
  GET_ABOUT_US,
  GET_DIRECTOR_MESSAGE,
  LOADING_ABOUT_US,
  LOADING_DIRECTOR_MESSAGE,
  GET_ABOUT_US_CONTACT_LIST,
  LOADING_ABOUT_US_CONTACT_LIST,
  LOADING_ABOUT_US_TEAM_LIST,
  GET_ABOUT_US_TEAM_LIST,
  GET_CONTACT_DETAILS_LIST,
  GET_ABOUT_US_LIST_TIMELINE,
  LOADING_ABOUT_US_LIST_TIMELINE,
  WEBSITE_INFO,
  AUTHORIZED_CATEGORY_LIST
} from '../types/types'
import { errorHandle, tokenConfig } from './common'

export const getAboutUs = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US,
      payload: true
    })
    //   Get Token from stat
    try {
      const aboutUsData = await axios.get(
        `${BACKEND_URL}${requests.ABOUT_US_LIST}`
      )
      dispatch({
        type: LOADING_ABOUT_US,
        payload: false
      })
      dispatch({ type: GET_ABOUT_US, payload: aboutUsData.data.data })
    } catch (error) {
  

      errorHandle(error, dispatch, LOADING_ABOUT_US)
    }
  }
}

export const getTimeLine = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US_LIST_TIMELINE,
      payload: true
    })
    //   Get Token from state

    try {
      const timeLineData = await axios.get(
        `${BACKEND_URL}${requests.ABOUT_US_LIST_TIMELINE}`
      )
      dispatch({
        type: LOADING_ABOUT_US_LIST_TIMELINE,
        payload: false
      })
      dispatch({
        type: GET_ABOUT_US_LIST_TIMELINE,
        payload: timeLineData.data.data
      })
    } catch (error) {
    

      errorHandle(error, dispatch, LOADING_ABOUT_US_LIST_TIMELINE)
    }
  }
}

export const getDirectorMessage = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US,
      payload: true
    })
    //   Get Token from state

    try {
      const directorMessageData = await axios.get(
        `${BACKEND_URL}${requests.ABOUT_US_DIRECTOR_MESSAGE_LIST}`
      )
      dispatch({
        type: LOADING_DIRECTOR_MESSAGE,
        payload: false
      })
      dispatch({
        type: GET_DIRECTOR_MESSAGE,
        payload: directorMessageData.data.data
      })
    } catch (error) {
   

      errorHandle(error, dispatch, LOADING_DIRECTOR_MESSAGE)
    }
  }
}

export const getAboutContact = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US_CONTACT_LIST,
      payload: true
    })
    //   Get Token from state

    try {
      const aboutContactData = await axios.get(
        `${BACKEND_URL}${requests.ABOUT_US_CONTACT_LIST}`
      )
      dispatch({
        type: LOADING_ABOUT_US_CONTACT_LIST,
        payload: false
      })
      dispatch({
        type: GET_ABOUT_US_CONTACT_LIST,
        payload: aboutContactData.data.data
      })
    } catch (error) {
     

      errorHandle(error, dispatch, LOADING_ABOUT_US_CONTACT_LIST)
    }
  }
}

export const getAboutTeam = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US_TEAM_LIST,
      payload: true
    })
    //   Get Token from state

    try {
      const aboutTeamData = await axios.get(
        `${BACKEND_URL}${requests.ABOUT_US_TEAM_LIST}`
      )
      dispatch({
        type: LOADING_ABOUT_US_TEAM_LIST,
        payload: false
      })
      dispatch({
        type: GET_ABOUT_US_TEAM_LIST,
        payload: aboutTeamData.data.data
      })
    } catch (error) {
  

      errorHandle(error, dispatch, LOADING_ABOUT_US_TEAM_LIST)
    }
  }
}

export const getContactData = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US_TEAM_LIST,
      payload: true
    })
    //   Get Token from state

    try {
      const aboutTeamData = await axios.get(
        `${BACKEND_URL}${requests.ABOUT_US_TEAM_LIST}`
      )
      dispatch({
        type: LOADING_ABOUT_US_TEAM_LIST,
        payload: false
      })
      dispatch({
        type: GET_ABOUT_US_TEAM_LIST,
        payload: aboutTeamData.data.data
      })
    } catch (error) {
   

      errorHandle(error, dispatch)
    }
  }
}

export const createContactUs = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US,
      payload: true
    })
    //   Get Token from state

    try {
      const createContactUs = await axios.post(
        `${BACKEND_URL}${requests.CONTACT_US_CREATE}`,
        data
      )
      dispatch({
        type: LOADING_ABOUT_US,
        payload: false
      })
      if (createContactUs.status == 200) {
        toastr.success('Contact us', 'We Will contact you soon')
      }
    } catch (error) {
     

      errorHandle(error, dispatch, LOADING_ABOUT_US)
    }
  }
}

export const getContactDetails = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US_TEAM_LIST,
      payload: true
    })
    //   Get Token from state

    try {
      const contactDetailsData = await axios.get(
        `${BACKEND_URL}${requests.CONTACT_DETAILS_LIST}`
      )
      dispatch({
        type: LOADING_ABOUT_US_TEAM_LIST,
        payload: false
      })
      dispatch({
        type: GET_CONTACT_DETAILS_LIST,
        payload: contactDetailsData.data.data
      })
    } catch (error) {
     

      errorHandle(error, dispatch, LOADING_ABOUT_US_TEAM_LIST)
    }
  }
}

export const getWebsiteInfo = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US_TEAM_LIST,
      payload: true,
    });
    //   Get Token from state

    try {
      const websiteDetails = await axios.get(
        `${BACKEND_URL}${requests.WEBSITE_LISTING}`
      );
      dispatch({
        type: LOADING_ABOUT_US_TEAM_LIST,
        payload: false,
      });
      if (websiteDetails.status === 200) {
        dispatch({
          type: WEBSITE_INFO,
          payload: websiteDetails.data.data?.[0],
        });
      }
    } catch (error) {


      errorHandle(error, dispatch, LOADING_ABOUT_US_TEAM_LIST);
    }
  };
};

export const getAuthorizeCategory = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_ABOUT_US_TEAM_LIST,
      payload: true,
    });
    //   Get Token from state

    try {
      const authorizeCategory = await axios.get(
        `${BACKEND_URL}${requests.AUTHORIZED_CATEGORY_LIST}`
      );
      dispatch({
        type: LOADING_ABOUT_US_TEAM_LIST,
        payload: false,
      });
      if (authorizeCategory.status === 200) {
        dispatch({
          type: AUTHORIZED_CATEGORY_LIST,
          payload: authorizeCategory.data.data,
        });
      }
    } catch (error) {
    

      errorHandle(error, dispatch, LOADING_ABOUT_US_TEAM_LIST);
    }
  };
};