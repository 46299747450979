import React, { useEffect } from "react";
import RightSideContact from "./sections/RightSideContact";
import { connect } from "react-redux";
import { getContactDetails } from "../../redux/actions/about_us";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import Complaints from "./sections/Complaints";
import Suggestions from "./sections/Sugestions";
import { Link } from "react-router-dom";

const ContactUs = ({ contactDetails, getContactDetails }) => {
  useEffect(() => {
    getContactDetails();
  }, []);

  const contactDetailsFirst = contactDetails?.[0];

  return (
    <section>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              LPV
            </Link>
            <span /> Contact
          </div>
        </div>
      </div>
      <section className="hero-2" style={{ padding: "0" }}>
        <iframe
          src="https://maps.google.com/maps?hl=en&amp;q=%C8%98oseaua%20Alexandriei%20Nr.197,%20Bucure%C8%99ti%20051529+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          frameBorder={0}
          scrolling="no"
          marginHeight={0}
          marginWidth={0}
          title="contact-us-map"
          width="100%"
          height="440px"
        />
       
      </section>
      <section className="section-border pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <h4 className="mb-15 text-brand">Adresa</h4>
              <p>{ReactHtmlParser(contactDetailsFirst?.address || "")}</p>
              <p>
                <strong>Contact</strong>
                <br />
                {ReactHtmlParser(contactDetailsFirst?.opening_hours || "")}
              </p>
            </div>
            <div className="col-md-6 mb-4 mb-md-0">
              <h4 className="mb-15 text-brand">Salut !</h4>
              {ReactHtmlParser(contactDetailsFirst?.comment || "")}
            </div>
          </div>
        </div>
      </section>

      <RightSideContact />
      <section className="section-border pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                {/* <h4>Ai sugestii sau reclamatii ?</h4>
                <div
                  style={{ display: "flex", gap: "10px", marginTop: "25px" }}
                >
                  <Complaints />
                  <Suggestions />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

ContactUs.propTypes = {
  loading: PropTypes.isRequired,
  contactDetails: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
  return {
    loading: state.aboutUs.loading,
    contactDetails: state.aboutUs.contactDetails
  };
};
export default connect(mapStateToProps, {
  getContactDetails
})(ContactUs);
