import React from 'react'
import { createContactUs } from '../../../redux/actions/about_us'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'

const RightSideInfo = ({ handleSubmit, reset, dispatch, loading, createContactUs }) => {
  const handleCreateContactForm = (data) => {
    const formData = new FormData()
    formData.append('email', data?.email)
    formData.append('title', data?.subject)
    formData.append('message', data?.message)
    createContactUs(formData)
    dispatch(reset('contact_us_form'))
  }
  return (
    <>
      <section className="pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 m-auto">
              <div className="contact-from-area padding-20-row-col wow FadeInUp">
                <h3 className="mb-10 text-center">Lasa-ne un mesaj</h3>
                <form className="contact-form-style text-center" id="contact-form" onSubmit={handleSubmit(handleCreateContactForm)} method="post">
                  <div className="row">
              
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <Field
                          required
                          component="input"
                          type="email"
                          id="form-email"
                          className="form-control"
                          placeholder="Adresa Email"
                          name="email"
                        />
                      </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <Field
                          required
                          type="text"
                          id="form-subject"
                          className="form-control"
                          placeholder="Subiect"
                          name="subject"
                          component="input"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="textarea-style mb-30">
                        <Field
                          required
                          placeholder="Mesaj"
                          type="text"
                          component="textarea"
                          id="form-message"
                          className="form-control"
                          rows={4}
                          name="message"
                          style={{minHeight: "150px"}}
                        />
                      </div>
                      {loading ? (
                        'Loading...'
                      ) : (
                        <button className="submit submit-auto-width" type="submit">Trimite mesaj</button>
                      )}
                    </div>
                  </div>
                </form>
                <p className="form-messege" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

RightSideInfo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
}
const mapStateToProps = (state) => {
  return { loading: state.aboutUs.loading }
}

export default connect(mapStateToProps, { createContactUs })(
  reduxForm({ form: 'contact_us_form' })(RightSideInfo)
)


