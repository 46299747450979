import React, { useEffect } from 'react'
import HeaderDesktop from './HeaderDesktop'
import { connect } from 'react-redux'
import { checkAuthenticated, load_user } from '../redux/actions/auth'

import Footer from './Footer'
import { getWebsiteInfo } from '../redux/actions/about_us'

const Layout = ({
  checkAuthenticated,
  load_user,
  children,
  user,
  getWebsiteInfo,
}) => {
  useEffect(() => {
    // checkAuthenticated();
    load_user();
    getWebsiteInfo();
  }, []);

  return (
    <div>
      <HeaderDesktop />
      {children}
      <Footer />
    </div>
  );
};
const mapStateToProps = ({ auth }) => ({
  user: auth.user
})
export default connect(mapStateToProps, {
  checkAuthenticated,
  load_user,
  getWebsiteInfo,
})(Layout);
