import React, { useEffect } from "react";

const FilterSubCategories = ({
  productSubCategoryList,
  setFilterState,
  filterState,
  subCategoryId,
}) => {
  useEffect(() => {
    if (productSubCategoryList.subsubcategories) {
      let newCategory = [];

      productSubCategoryList.subsubcategories.map((item) => {
        newCategory.push(item.subsubcategory_id);
      });
      newCategory = new Set(newCategory);
      newCategory = [...newCategory];

      setFilterState({
        ...filterState,
        subsubcategory_id_ls: newCategory,
      });
    }
  }, []);

  const handleCategoryCheck = (e, content, id) => {
    let newCategory = [...filterState?.subsubcategory_id_ls];

    if (e.target.checked) {
      newCategory.push(id);
    } else {
      newCategory = newCategory.filter((item) => item !== id);
    }
    newCategory = new Set(newCategory);
    newCategory = [...newCategory];

    setFilterState({
      ...filterState,
      subsubcategory_id_ls: newCategory,
    });
  };

  return (
    <>
      {productSubCategoryList.subsubcategories && subCategoryId && (
        <div className="sidebar-widget price_range range mb-30">
          <div className="widget-header position-relative mb-20 pb-10">
            <h5 className="widget-title mb-10">Categoria de filtrare</h5>
          </div>
          <div className="list-group custom-list-group">
            <div className="list-group-item mb-10 mt-10">
              <div>
                <label className="fw-900 ">
                  {productSubCategoryList.subCategory}
                </label>
                <div>
                  {productSubCategoryList.subsubcategories.map(
                    (subCategory) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <div
                          style={{
                            marginBottom: "5px",
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={filterState?.subsubcategory_id_ls?.includes(
                              subCategory.subsubcategory_id
                            )}
                            onClick={(e) =>
                              handleCategoryCheck(
                                e,
                                subCategory,
                                subCategory.subsubcategory_id
                              )
                            }
                          />
                          <label
                            className="form-check-label mb-0"
                            htmlFor="exampleCheckbox1"
                          >
                            <span>{subCategory.subsubcategory_name}</span>
                          </label>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterSubCategories;
