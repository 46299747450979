import React, { useEffect } from "react";
import { connect } from "react-redux";
import { productCompare } from "../../redux/actions/products";
import slug from "slug";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const Compare = ({ productCompare, productCompareData }) => {
  const history = useHistory();
  useEffect(() => {
    const compare = JSON.parse(localStorage.getItem("compare"));
    productCompare(compare, false);
  }, []);
  const handleRemove = (id) => {
    const compares = JSON.parse(localStorage.getItem("compare"));
    if (compares) {
        let ids, flag = false, onlyOne = false;
        if (compares && compares.length > 1) {
            ids = compares?.filter((compare) => compare != id);
            if (ids.length >= 1) {
                flag = true;
            } else {
                flag = false;
            }
        } else {
            ids = [id];
            onlyOne = true;
        }
        productCompare(ids, onlyOne);
      if (ids?.length >= 1 && flag == true ) {
        localStorage.setItem("compare", JSON.stringify(ids));
      } else {
        localStorage.removeItem("compare");
      }
    }
  };

  const dict = {};

  productCompareData
    ?.map((proattribute) => {
      proattribute?.common_product_attributes?.map((attribute) => {
        const list = [...(dict[attribute?.title] || [])];
        list.push(attribute.content);
        dict[attribute?.title] = list;
      });
    })
    ?.filter((attribute) => attribute);

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              LPV
            </Link>
            <span /> Comparator
          </div>
        </div>
      </div>
      <section className="mt-50 mb-50">
        <div className="container">
          <div className="row">
            {productCompareData.length > 0 ? (
              <div className="col-12">
                <div>
                  <table>
                    <tr>
                      <th>Imagine</th>
                      {productCompareData?.map((product) => (
                        <td style={{ textAlign: "center" }}>
                          <img
                            style={{
                              width: "200px",
                              height: "200px",
                              objectFit: "cover",
                            }}
                            src={product?.images?.[0]?.image}
                          />
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th>Title</th>
                      {productCompareData?.map((product) => (
                        <td style={{ textAlign: "center" }}>
                          {product?.title}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th>Price</th>
                      {productCompareData?.map((product) => (
                        <td style={{ textAlign: "center" }}>
                          {product?.price_including_vat}
                        </td>
                      ))}
                    </tr>
                    {Object?.keys?.(dict)?.map((product) => {
                      return (
                        <tr>
                          <th>{product}</th>
                          {dict[product]?.map?.((common) => (
                            <td>{common || "-"}</td>
                          ))}
                        </tr>
                      );
                    })}
                    {productCompareData?.map((product, index) => {
                      const first = [
                        ...Array(
                          productCompareData?.length +
                            index -
                            productCompareData?.length
                        ),
                      ].map((e, i) => <td key={i}>-</td>);
                      const second = [
                        ...Array(productCompareData?.length - index - 1),
                      ].map((e, i) => <td key={i}>-</td>);

                      return (
                        <>
                          {product?.uncommon_product_attributes?.map(
                            (unComman) => {
                              return (
                                <>
                                  <tr>
                                    <th>{unComman?.title}</th>

                                    {first}
                                    <td>{unComman?.content || "-"}</td>
                                    {second}
                                  </tr>
                                </>
                              );
                            }
                          )}
                        </>
                      );
                    })}

                    <tr>
                      <td style={{ border: 0 }}> </td>
                      {productCompareData?.map((product, index) => {
                        return (
                          <td style={{ border: 0 }}>
                            <Button onClick={() => handleRemove(product?.id)}>
                              Elimina
                            </Button>
                          </td>
                        );
                      })}
                    </tr>
                  </table>
                </div>
              </div>
            ) : (
              <div className="col-12 m-auto" style={{"text-align":"center"}}>
                <p className="font-lg text-grey-700 mb-30">
                  Nu ai nici un produs adaugat in comparator
                </p>

                <a
                  className="btn btn-default submit-auto-width font-xs hover-up"
                  href="/"
                >
                  <i className="fas fa-store-alt"></i> Inapoi la prima pagina
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = ({ product }) => ({
  productCompareData: product?.productCompare
});
export default connect(mapStateToProps, {
  productCompare
})(Compare);
