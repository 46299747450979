import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import { createAddress } from "../../../redux/actions/auth";
const BillingDetails = ({
  setAddress,
  address,
  createAddress,
  shippingAddress,
  setBillingAddress,
  user,
}) => {
  useEffect(() => {
    setAddress({
      ...shippingAddress,
      email: user?.email,
      phone_number: user?.phone_number,
      first_name: user?.first_name,
      last_name: user?.last_name,
    });
    setBillingAddress({
      ...(user?.billing_address || {}),
    });
  }, [shippingAddress, user]);

  const handleChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const createUser = () => {
    createAddress({ ...address, address_type: "Shipping" });
  };
  return (
    <>
      <div className="col-12 col-lg-6 col-xl-7">
        <div className="card mb-lg-0">
          <div className="card-body">
            <h3 className="card-title">Shipping Addres</h3>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="checkout-first-name">Nume</label>{" "}
                <input
                  
                  type="text"
                  className="form-control"
                  id="checkout-first-name"
                  placeholder="First Name"
                  name="first_name"
                  value={address?.first_name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="checkout-last-name">Prenume</label>{" "}
                <input
                  
                  type="text"
                  className="form-control"
                  id="checkout-last-name"
                  placeholder="Last Name"
                  name="last_name"
                  value={address?.last_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="checkout-first-name">Telephone</label>{" "}
                <input
                  type="number"
                  className="form-control"
                  id="checkout-first-name"
                  placeholder="Telephone"
                  value={address?.phone_number}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="checkout-last-name">Email</label>{" "}
                <input
                  type="text"
                  className="form-control"
                  id="checkout-last-name"
                  placeholder="email"
                  name="email"
                  value={address?.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="checkout-street-address">Country</label>{" "}
              <input
                type="text"
                className="form-control"
                value={address?.country}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="checkout-street-address">City</label>{" "}
              <input
                type="text"
                className="form-control"
                id="checkout-street-address"
                placeholder="Street Address"
                name="city"
                value={address?.city}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-street-address">Street Name</label>{" "}
              <input
                type="text"
                className="form-control"
                id="checkout-address"
                name="street_number"
                value={address?.street_number}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-city">Street Number</label>{" "}
              <input
                type="text"
                className="form-control"
                id="checkout-city"
                name="street_name"
                value={address?.street_name}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="checkout-street-address">Streeet Detailss</label>{" "}
              <input
                type="text"
                className="form-control"
                id="checkout-street-address"
                placeholder="Street Address"
                name="street_details"
                value={address?.street_details}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-street-address">Zip Code</label>{" "}
              <input
                type="text"
                className="form-control"
                rows={4}
                name="zip_code"
                value={address?.zip_code}
                onChange={handleChange}
              />
            </div>
            {user?.billing_address?.id && (
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="radio1"
                    onChange={(event) => {
                      if (event?.target.checked) {
                        setBillingAddress(user?.billing_address);
                      } else {
                        setBillingAddress({});
                      }
                    }}
                  />
                  {user?.billing_address?.country} -
                  {user?.billing_address?.city} -{" "}
                  {user?.billing_address?.street_number} -
                  {user?.billing_address?.street_name} -
                  {user?.billing_address?.street_details} -
                  {user?.billing_address?.zip_code} -
                </Label>
              </FormGroup>
            )}
            {!address?.id && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={createUser}
              >
                Add Address
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ wishlist, auth }) => ({});

export default connect(mapStateToProps, {
  createAddress,
})(BillingDetails);
