import React, { useState } from "react";
import { createContactUs } from "../../../redux/actions/about_us";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Complaints = ({
  handleSubmit,
  reset,
  dispatch,
  loading,
  createContactUs,
}) => {
  const handleCreateContactForm = (data) => {
    const formData = new FormData();
    formData.append("email", data?.email);
    formData.append("title", data?.first_name || "Reclamatie Client");
    formData.append("message", data?.message);
    formData.append("first_name", data?.first_name);
    formData.append("last_name", data?.last_name);
    formData.append("address", data?.address);
    formData.append("context", data?.context);
    formData.append("telephone", data?.telephone);
    formData.append("company_name", data?.company_name);
    formData.append("type", "COMPLAINT");
    createContactUs(formData);
    dispatch(reset("contact_us_form"));
    setModal(false);
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <>
      <a style={{ color: "black" }} onClick={toggle}>
        Formular reclamatii
      </a>
      <Modal
        style={{ maxWidth: "1000px", width: "100%" }}
        isOpen={modal}
        toggle={toggle}
      >
        <form
          className="contact-form-style text-center"
          id="contact-form"
          onSubmit={handleSubmit(handleCreateContactForm)}
          method="post"
        >
          <ModalHeader toggle={toggle}>
            <span style={{ color: "#fff" }}>
              Completeaza formularul de reclamatii
            </span>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 mb-20">
                <div
                  className="mb-20"
                  style={{
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "left",
                    fontWeight: "500"
                  }}
                >
                  Ne străduim permanent să fim mai buni, să răspundem mai
                  eficient provocărilor generate de activitatile noastre curente
                  și să depășim obiectivele stabilite. În timp, am învățat că
                  cea mai bună metodă de a progresa este să ascultăm părerea
                  partenerilor și clienților noștri.
                </div>
                <div
                  className="mb-20"
                  style={{ width: "100%", textAlign: "left" }}
                >
                  Știm că aveți un program încărcat, însă vă rugăm să ne
                  dedicați 1-2 minute, pentru a ne ajuta să ne îmbunătățim
                  serviciile
                </div>
                <div style={{ width: "100%", textAlign: "left" }}>
                  Cu mulțumiri,
                  <br />
                  <span>Ciuhu Laura Mihaela</span>
                  <br />
                  Proprietar brand LPV
                </div>
              </div>
              <hr />
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    component="input"
                    type="text"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Nume Companie (optional)"
                    name="company_name"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="text"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Nume"
                    name="first_name"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="text"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Prenume"
                    name="last_name"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="text"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Locatie"
                    name="address"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="number"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Telefon"
                    name="telephone"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-style mb-20">
                  <Field
                    required
                    component="input"
                    type="email"
                    id="form-email-complaint"
                    className="form-control"
                    placeholder="Adresa Email"
                    name="email"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="textarea-style mb-30">
                  <Field
                    required
                    placeholder="Mentioneaza contextul colaborarii cu LPV"
                    type="text"
                    component="textarea"
                    id="form-message"
                    className="form-control"
                    rows={4}
                    name="context"
                    style={{ minHeight: "150px" }}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="textarea-style mb-30">
                  <Field
                    required
                    placeholder="Descrieti reclamatia dvs"
                    type="text"
                    component="textarea"
                    id="form-message"
                    className="form-control"
                    rows={4}
                    name="message"
                    style={{ minHeight: "150px" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-20">
                  <div
                    className="mb-20"
                    style={{
                      fontWeight: "500",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Va multumim !
                  </div>
                  <div
                    className="mb-20"
                    style={{
                      fontStyle: "italic",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Reclamatia dvs. este foarte importanta pentru noi; in mod
                    automat aceasta ajunge la proprietarul LPV si urmareste
                    rezolvarea solicitarii dvs. Pentru urgente va rugam sa
                    sunati la Laura Mihaela Ciuhu, proprietar LPV
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              "Loading..."
            ) : (
              <button
                style={{ backgroundColor: "darkgreen" }}
                className="submit submit-auto-width"
                type="submit"
              >
                Trimite mesaj
              </button>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

Complaints.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return { loading: state.aboutUs.loading };
};

export default connect(mapStateToProps, { createContactUs })(
  reduxForm({ form: "contact_us_complaints_form" })(Complaints)
);
