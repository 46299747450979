import axios from "axios";
import requests, { BACKEND_URL, ITEMS_PER_PAGE } from "../../requests";
import { LOADING_BLOG_POSTS, BLOG_POSTS, BLOG_READ_POST } from "../types/types";

import { errorHandle } from "./common";

export const getBlogPosts = (data = {}) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_BLOG_POSTS,
      payload: true,
    });
    //   Get Token from state

    try {
      const blogPostsData = await axios.post(
        `${BACKEND_URL}${requests.BLOG_POSTS}`,
        { ...data, items_per_page: data?.items_per_page || ITEMS_PER_PAGE }
      );
  

      dispatch({ type: BLOG_POSTS, payload: blogPostsData.data });
      // dispatch({
      //   type: LOADING_BLOG_POSTS,
      //   payload: false,
      // });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_BLOG_POSTS);
    }
  };
};

export const getReadBlogPost = (id) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_BLOG_POSTS,
      payload: true,
    });
    //   Get Token from state

    try {
      const readBlogPostData = await axios.get(
        `${BACKEND_URL}${requests.BLOG_READ_POST}${id}`
      );

      dispatch({ type: BLOG_READ_POST, payload: readBlogPostData.data.data });
      // dispatch({
      //   type: LOADING_BLOG_POSTS,
      //   payload: false,
      // });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_BLOG_POSTS);
    }
  };
};
