export const URL = process.env.REACT_APP_URL || "https://api.lpvservice.ro";
export const BACKEND_URL = `${URL}/api/v1/`;
export const BACKEND_AUTH_URL = `${URL}/auth/`;
export const TMP_TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjI1NzU4MTg2LCJqdGkiOiJiZjkwNjhmNjIzNGI0NzBhOTU3ZjIyNzVhOTUwNmI1ZSIsInVzZXJfaWQiOjR9.9RRvlW5CpRBweqfKKVKrbI-XAlgIs0iQtmiIDWcxyVc";
export const ITEMS_PER_PAGE = 10;
export const orderStatus = (status) => {
  switch (status) {
    case "PENDING":
      return {
        icon: "clock",
        name: "Pending",
      };

    case "PROCESSING":
      return {
        icon: "clock",
        name: "Processing",
      };

    case "SHIPPED":
      return {
        icon: "truck",
        name: "Shipped",
      };

    case "DELIVERED":
      return {
        icon: "check",
        name: "Delivered",
      };
    case "CANCELLED":
      return {
        icon: "times",
        name: "Cancelled",
      };

    default:
      return {
        icon: "clock",
        name: "Pending",
      };
  }
};
/* eslint-disable */ export default {
  //   Auth
  LOAD_USER: "user-details/",
  ORDER_LIST: "order/list/",
  ORDER_DETAILS: "order/read/",

  UPDATE_USER_PROFILE: "update-user-details/",
  CHECK_AUTHENTICATED: "jwt/verify/",
  LOGIN_USER: "jwt/create/",
  GOOGLE_LOGIN: "social-auth/validate-and-login/google/",
  FACEBOOK_LOGIN: "social-auth/validate-and-login/facebook/",
  SIGNUP_USER: "users/",
  VERIFY: "users/activation",
  RESET_PASSWORD: "users/reset_password/",
  RESET_PASSWORD_CONFIRM: "users/reset_password_confirm/",
  CHANGE_PASSWORD: "change-password/",
  FORGOT_PASSWORD: "password-reset/",
  // Address
  ADDRESS_LIST: "address/list",
  ADDRESS_CREATE: "address/create/",
  ADDRESS_UPDATE: "address/update/",
  ADDRESS_DELETE: "address/delete/",

  // About us
  ABOUT_US_LIST: "about-us/list",
  ABOUT_US_CREATE: "about-us/create/",
  ABOUT_US_READ: "about-us/read/",
  ABOUT_US_DELETE: "about-us/delete/",

  ABOUT_US_TEAM_LIST: "about-us-team/list",
  ABOUT_US_TEAM_CREATE: "about-us-team/create/",
  ABOUT_US_TEAM_READ: "about-us-team/read/",
  ABOUT_US_TEAM_DELETE: "about-us-team/delete/",

  ABOUT_US_CONTACT_LIST: "about-us-contact/list",
  ABOUT_US_CONTACT_CREATE: "about-us-contact/create/",
  ABOUT_US_CONTACT_READ: "about-us-contact/read/",
  ABOUT_US_CONTACT_DELETE: "about-us-contact/delete/",

  ABOUT_US_DIRECTOR_MESSAGE_LIST: "about-us-director-message/list",
  ABOUT_US_DIRECTOR_MESSAGE_CREATE: "about-us-director-message/create/",
  ABOUT_US_DIRECTOR_MESSAGE_READ: "about-us-director-message/read/",
  ABOUT_US_DIRECTOR_MESSAGE_DELETE: "about-us-director-message/delete/",

  ABOUT_US_LIST_TIMELINE: "about-us-timeline/list",

  // Contact us
  CONTACT_US_LIST: "contact-us/list",
  CONTACT_US_READ: "contact-us/read/",
  CONTACT_US_CREATE: "contact-us/create",
  CONTACT_US_DELETE: "contact-us/delete/",

  // Contact Details
  CONTACT_DETAILS_LIST: "contact-us-details/list",
  // FAQ
  FAQ_LIST: "faq/list/",
  FAQ_READ: "faq/read/",
  FAQ_CREATE: "faq/create/",
  FAQ_DELETE: "faq/delete/",

  // Product
  PRODUCT_LIST: "product/list/",
  PRODUCT_READ: "product/read/",
  PRODUCT_CREATE: "product/create/",
  PRODUCT_DELETE: "product/delete/",
  //Product Type
  PRODUCT_TYPE_LIST: "product-type/list/",
  PRODUCT_ATTRIBUTE_CONTENT: "product-attribute-and-attribute-contents/",

  // Review
  PRODUCT_REVIEW_LIST: "review/list/",
  PRODUCT_REVIEW_CREATE: "review/create/",

  SEND_OFFER_REQUEST: "custom-product-send-offer-request-create/",

  // Brand
  PRODUCT_BRAND: "brand/list/",
  PRODUCT_COMPARE: "compare-products/",
  // Attribute
  PRODUCT_ATTRIBUTE_SPECIFICATION: "product-attribute-contents-of-product/",

  // Voucher
  VOUCHER_LIST: "voucher/list/",
  VOUCHER_READ: "voucher/read/",
  VOUCHER_CREATE: "voucher/create/",
  VOUCHER_DELETE: "voucher/delete/",
  APPLY_VOUCHER: "apply-voucher-code-in-cart/",

  // Wishlist
  WISHLIST_LIST: "wishlist/product-list/",
  WISHLIST_READ: "wishlist/read",
  WISHLIST_CREATE: "wishlist/add-products-in-wishlist/",
  WISHLIST_DELETE: "wishlist/remove-products-from-wishlist/",
  // Cart
  CART_LISTING: "product-list-in-cart/",
  CART_CREATE: "add-product-in-cart/",
  REMOVE_PRODUCT_FROM_CART: "remove-product-from-cart/",
  CART_UPDATE: "update-product-in-cart/",

  // Menu
  CATEGORIES_API: "all-categories/",

  // BANNERS
  HEROS_BANNER: "hero-banner/list",
  FEAUTURED_BANNER: "features-banner/list",
  COMMERCIAL_BANNER: "commercial-banner/list",
  PARTNER_BANNER: "partner-banner/list",

  // BlOG
  BLOG_POSTS: "blog-post/list",
  BLOG_READ_POST: "blog-post/read/",

  // FOOTER
  FOOTER_LIST: "footer-section-details/list",
  SUBSCRIBE_NEWSLETTERS: "newsletter-subscribe/",

  // LEGAL PAGES
  TERMS_LIST: "legal/terms/list",
  COOKIES_LIST: "legal/cookies/list",
  PRIVACY_LIST: "legal/privacy/list",
  RETURN_LIST: "legal/returns/list",
  // Payment
  PAYMENT_STRIPE_TOKEN: "payment/check-stripe-payment-token/",
  PLACE_ORDER: "place-order/",
  QUESTION_BUNDLE_READ: "question-bundle/read/",
  QUESTION_DETAIL: "question-details/",
  ADD_SERVICE_ORDER_PRODUCT: "add-services-in-order-product/",
  SERVICE_LIST: "service-list-in-cart/",
  SEND_OFFER_IMAGE: "add-images-custom-product-send-offer-request/",
  BANNER_ONE: "banner-one/list/",
  BANNER_TWO: "banner-two/list/",
  WEBSITE_LISTING: "website-info/list",
  REMOVE_SERVICES_FROM_ORDER: "remove-all-services-from-order-products/",
  AUTHORIZED_CATEGORY_LIST: "about-us-authorization-category/list/",
  CREATE_APPOINTMENT: "appointment/create/",
  AVAILABLE_DATE_TIME: "appointment/available-datetime/",
};
export const STRIPE_SECRET = process.env.REACT_APP_STRIPE_SECRET || "test";
export const STRIPE_PUBLIC = process.env.REACT_APP_STRIPE_PUBLIC || "test";
export const FACEBOOK_APP_ID= process.env.REACT_APP_FACEBOOK_APP_ID || "test";
export const GOOGLE_CLIENT_ID =process.env.REACT_APP_GOOGLE_CLIENT_ID || "test";
export const GOOGLE_SECRET_KEY = process.env.REACT_APP_GOOGLE_SECRET_KEY || "test";

