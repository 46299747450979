import React, { useEffect, useState } from "react";
import StripeElement from "../../../Common/Payment/Stripe/StripeElement";
import OrderItem from "./OrderItem";
import OrderPaymentMethod from "./OrderPaymentMethod";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { stripePaymentCheckStatus } from "../../../redux/actions/wishlist";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

const OrderSumary = ({
  carts,
  summary,
  shippingAddress,
  billingAddress,
  paymentSuccessfully,
  paymentLoading,
  stripePaymentCheckStatus,
}) => {
  const [stripeModal, setStripeModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [agree, setAgree] = useState(false);
  const toggleModal = () => {
    setStripeModal(!stripeModal);
  };
  const handleStripe = (data) => {

    if (data?.code) {
      toastr.error(
        data?.code || "Error in Card",
        data?.message || "Check Card Details"
      );
    } else if (data?.type && !data?.id) {
      toastr.error(
        data?.type || "Internal Error",
        data?.message || "try again we not charged from your card"
      );
    } else if (data?.id) {
      if (shippingAddress?.id) {
        delete shippingAddress.user;
      }
      if (billingAddress?.id) {
        delete billingAddress.user;
      }
      const paymentObject = {
        payment_method_type: "STRIPE",
        // TODO: once Fix pass dynamic token
        stripe_payment_method_id: "pm_card_us" || data?.id,
        total_amount: summary?.total_price,
        product_item_id_ls: carts?.map((cart) => cart?.id),
        shipping_address: shippingAddress || {},
        billing_address: billingAddress || {},
      };
      stripePaymentCheckStatus(paymentObject);
      if (paymentSuccessfully) {
      }
    }
  };

  
  return (
    <>
      <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
        <div className="card mb-0">
          <div className="card-body">
            <h3 className="card-title">Sumar comanda</h3>
            <table className="checkout__totals">
              <thead className="checkout__totals-header">
                <tr>
                  <th>Produs</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody className="checkout__totals-products">
                <OrderItem carts={carts} />
              </tbody>
              <tbody className="checkout__totals-subtotals">
                <tr>
                  <th>Subtotal</th>
                  <td>{summary?.total_products_amount} Lei</td>
                </tr>
                <tr>
                  <th>Discount</th>
                  <td>0 Lei</td>
                </tr>
              </tbody>

              <tfoot className="checkout__totals-footer">
                <tr>
                  <th>Total</th>
                  <td>{summary?.total_price} Lei</td>
                </tr>
              </tfoot>
            </table>
            <OrderPaymentMethod
              setPaymentMethod={setPaymentMethod}
              paymentMethod={paymentMethod}
              setAgree={setAgree}
            />
            <button
              type="button"
              onClick={toggleModal}
              className="btn btn-primary btn-xl btn-block"
              disabled={!agree}
            >
              Plaseaza Comanda
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={paymentMethod === "stripe" && stripeModal}
        toggle={() => toggleModal()}
        centered
      >
        <ModalHeader
          toggle={() => toggleModal()}
          // close={renderCloseBtn}
          tag="div"
        >
          <h5 className="modal-title">
            <span className="align-middle">Stripe</span>
          </h5>
        </ModalHeader>
        <ModalBody>
          <StripeElement
            stripeData={handleStripe}
            paymentLoading={paymentLoading || false}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ wishlist, auth }) => ({
  carts: wishlist.carts,
  summary: wishlist.summary,
  user: auth.user,
  paymentSuccessfully: wishlist.paymentSuccessfully,
  paymentLoading: wishlist.paymentLoading,
});
export default connect(mapStateToProps, {
  stripePaymentCheckStatus,
})(OrderSumary);
