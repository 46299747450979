import axios from "axios";
import { toastr } from "react-redux-toastr";
import { getCartProduct, storeInCart } from "../../helper/cart";

import requests, { BACKEND_URL } from "../../requests";
import {
  GET_WISHLIST,
  LOADING_WISHLIST,
  LOADING_CART,
  GET_CARTS,
  VERIFY_VOUCHER,
  LOADING_PAYMENT,
  PAYMENT_SUCCESSFULLY,
  AUTH_LOADING,
  GET_AVAILABLE_DATE,
  LOCAL_STORAGE_CART,
} from "../types/types";
import { errorHandle, tokenConfig } from "./common";

export const storeInWishlist = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_WISHLIST,
      payload: true,
    });
    //   Get Token from state

    try {
      const storeInWishlist = await axios.post(
        `${BACKEND_URL}${requests.WISHLIST_CREATE}`,
        data,
        tokenConfig(getState)
      );

      dispatch({
        type: LOADING_WISHLIST,
        payload: false,
      });
      toastr.success("Product Add To Wishlist");

      dispatch(getWishlist());
      dispatch(getCarts());
    } catch (error) {
      errorHandle(error, dispatch, LOADING_WISHLIST);
    }
  };
};

export const getWishlist = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_WISHLIST,
      payload: true,
    });
    //   Get Token from state

    try {
      const getWishlistData = await axios.get(
        `${BACKEND_URL}${requests.WISHLIST_LIST}`,
        tokenConfig(getState)
      );
      dispatch({
        type: LOADING_WISHLIST,
        payload: false,
      });
      dispatch({ type: GET_WISHLIST, payload: getWishlistData.data.data });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_WISHLIST);
    }
  };
};

export const deleteWishlist = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_WISHLIST,
      payload: true,
    });
    //   Get Token from state

    try {
      const deleteInWishlist = await axios.post(
        `${BACKEND_URL}${requests.WISHLIST_DELETE}`,
        data,
        tokenConfig(getState)
      );

      dispatch({
        type: LOADING_WISHLIST,
        payload: false,
      });
      toastr.success("Product deleted from wishlist");
      dispatch(getWishlist());
    } catch (error) {
      errorHandle(error, dispatch, LOADING_WISHLIST);
    }
  };
};

export const storeProductInCart = (data, fromComponent = true) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state

    try {
      const storeInCart = await axios.post(
        `${BACKEND_URL}${requests.CART_CREATE}`,
        data,
        tokenConfig(getState)
      );

      dispatch({
        type: LOADING_CART,
        payload: false,
      });

      if (fromComponent) {
        dispatch(getWishlist());
        dispatch(getCarts());
        toastr.success("Produs adaugat in cos");
      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_CART);
    }
  };
};

export const getCarts = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state

    try {
      const getCartData = await axios.get(
        `${BACKEND_URL}${requests.CART_LISTING}`,
        tokenConfig(getState)
      );
      dispatch({
        type: LOADING_CART,
        payload: false,
      });
      dispatch({
        type: GET_CARTS,
        payload: {
          carts: getCartData.data.data?.product_items,
          summary: getCartData.data.data?.summary,
        },
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_CART);
    }
  };
};

export const updateInCart = (data, display = true) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state
    try {
      const updateInWishlist = await axios.post(
        `${BACKEND_URL}${requests.CART_UPDATE}`,
        data,
        tokenConfig(getState)
      );

      dispatch({
        type: LOADING_CART,
        payload: false,
      });
      if (display) {
        toastr.success("Product Cart Updated");
        dispatch(getCarts());
      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_CART);
    }
  };
};

export const deleteCart = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state

    try {
      const deleteInWishlist = await axios.post(
        `${BACKEND_URL}${requests.REMOVE_PRODUCT_FROM_CART}`,
        data,
        tokenConfig(getState)
      );

      dispatch({
        type: LOADING_CART,
        payload: false,
      });
      toastr.success("Product deleted from cart");
      dispatch(getCarts());
    } catch (error) {
      errorHandle(error, dispatch, LOADING_WISHLIST);
    }
  };
};

export const voucherVerify = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state

    try {
      const voucherData = await axios.post(
        `${BACKEND_URL}${requests.APPLY_VOUCHER}`,
        { voucher_code: data },
        tokenConfig()
      );

      if (voucherData?.status === 200) {
        dispatch({
          type: VERIFY_VOUCHER,
          payload: voucherData?.data?.data?.is_active
            ? voucherData?.data?.data
            : "",
        });
        dispatch({
          type: LOADING_CART,
          payload: false,
        });
        dispatch(getCarts());
        toastr.success("Voucher Applied Successfully");
      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_WISHLIST);
    }
  };
};

// Payment Method
export const placeOrder = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PAYMENT,
      payload: true,
    });
    //   Get Token from state

    try {
      const placeOrder = await axios.post(
        `${BACKEND_URL}${requests.PLACE_ORDER}`,
        data,
        tokenConfig(getState)
      );

      if (placeOrder?.status === 200) {
        toastr.success("Order Place", "Successfully Place Order");
        dispatch({
          type: PAYMENT_SUCCESSFULLY,
          payload: true,
        });
        window.location.href = "/user-order-history";
      }
      dispatch({
        type: LOADING_PAYMENT,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_PAYMENT,
        payload: false,
      });
      dispatch({
        type: PAYMENT_SUCCESSFULLY,
        payload: false,
      });
      errorHandle(error, dispatch, AUTH_LOADING);
    }
  };
};

export const stripePaymentCheckStatus = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_PAYMENT,
      payload: true,
    });
    //   Get Token from state

    try {
      const stripeVerify = await axios.post(
        `${BACKEND_URL}${requests.PAYMENT_STRIPE_TOKEN}`,
        {
          payment_method_type: data?.payment_method_type,
          total_amount: data?.total_amount,
          stripe_payment_method_id: data?.stripe_payment_method_id,
        },
        tokenConfig(getState)
      );

      if (stripeVerify?.status === 200) {
        data["stripe_payment_intent_id"] =
          stripeVerify?.data?.data?.stripe_payment_intent_id;

        dispatch(placeOrder(data));
      }
      dispatch({
        type: LOADING_PAYMENT,
        payload: false,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_WISHLIST);
    }
  };
};

export const getAvailableDate = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state

    try {
      const getCartData = await axios.get(
        `${BACKEND_URL}${requests.AVAILABLE_DATE_TIME}`
      );
      dispatch({
        type: LOADING_CART,
        payload: false,
      });
      dispatch({
        type: GET_AVAILABLE_DATE,
        payload: getCartData?.data?.data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_CART);
    }
  };
};

export const createAppointmentUser = (data) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state

    try {
      const serviceAdd = await axios.post(
        `${BACKEND_URL}${requests.CREATE_APPOINTMENT}`,
        data,
        tokenConfig(getState)
      );
      if (serviceAdd?.status === 200) {
        dispatch({
          type: LOADING_CART,
          payload: false,
        });
        toastr.success(
          "Appointment Created",
          "Appointment Created Successfully"
        );
      }
    } catch (error) {
      errorHandle(error, dispatch, LOADING_CART);
    }
  };
};

export const addServiceInCart = (data, id, display = true) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state
    if (id) {
      try {
        const serviceAdd = await axios.post(
          `${BACKEND_URL}${requests.ADD_SERVICE_ORDER_PRODUCT}${id}`,
          data,
          tokenConfig(getState)
        );
        if (serviceAdd?.status === 200) {
          dispatch({
            type: LOADING_CART,
            payload: false,
          });
          dispatch(getCarts());
          // dispatch(
          //   createAppointmentUser({
          //     appointment_date: data.appointmentDate?.appointment_date,
          //     appointment_time: data.appointmentDate?.appointment_time,
          //   })
          // );
          if (display) toastr.success("Service Added", "Service Added in Cart");
        }
      } catch (error) {
        errorHandle(error, dispatch, LOADING_CART);
      }
    } else {
      toastr.error(
        "Cart Id Error",
        " Please contact to admin something went to wrong"
      );
    }
  };
};

export const getLocalStorageCart = () => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state

    try {
      const data = getCartProduct();
      dispatch({
        type: LOCAL_STORAGE_CART,
        payload: data,
      });
    } catch (error) {
      errorHandle(error, dispatch, LOADING_WISHLIST);
    }
  };
};

export const storeLocalStorageCart = (product) => {
  return async (dispatch, getState) => {
    // Loading
    dispatch({
      type: LOADING_CART,
      payload: true,
    });
    //   Get Token from state
    try {
      await storeInCart(product);
      toastr.success("Produs adaugat in cos");
      // setTimeout(() => {
      dispatch(getLocalStorageCart());
      // }, [1000]);
    } catch (error) {
      errorHandle(error, dispatch, LOADING_WISHLIST);
    }
  };
};
