import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { validationInput } from "../../helper/common";
import AskForOfferModal from "./AskForOfferModal/AskForOfferModal";
import icon1 from "../../assets/imgs/popup/popup-1.png";

const RecommendedAC = ({ setCustomModal, customModal, websiteInfo }) => {
  const [modal, setModal] = useState(false);
  const [showAskForModal, setShowAskForModal] = useState(false);

  const toggleSecond = () => {
    setModal(!modal);
  };

  const toggle = () => {
    if (customModal) {
      setCustomModal(false);
    }
    setModal(false);
  };

  const askForOffer = () => {
    setShowAskForModal(true);
  };
  const closeAskForModal = () => {
    setShowAskForModal(false);
  }

  const [surface, setSurface] = useState();
  const [roomHeight, setRoomHeight] = useState();

  let result = surface * roomHeight * 50 * 3.42;
  // useEffect(() => {
  //   setShowAskForModal(customModal);
  // }, [customModal]);


  const surfaceResult =
    result > 23000
      ? 24000
      : result > 21000 
      ? 22000
      : result > 15000 
      ? 18000
      : result > 10000 
      ? 12000 
      : 9000;

  return (
    <div style={{ width: "100%" }}>
      <div
        className="recom-ac banner-features wow fadeIn animated hover-up animated"
        style={{
          backgroundColor: "fff",
          border: "1px solid white",
          filter: "drop-shadow(5px 5px 5px rgba(160, 160, 160, 0.25))",
          borderRadius: "5px",
          padding: "20px 15px 15px 10px",
          fontWeight: "700",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          height: "200px",
          position: "relative",
        }}
        onClick={toggleSecond}
      >
        <div className="blur"></div>
        <div
          style={{
            zIndex: "5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={icon1} />
          <span style={{ lineHeight: "1", fontSize: "1.1rem" }}>
            Recomandare aer conditionat
          </span>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: "500px" }}>
        <ModalHeader toggle={toggle}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#fff" }}>Recomandare aer conditionat</span>
            <i
              style={{ color: "#fff", fontSize: "1.3rem" }}
              className="far fa-times"
              onClick={toggle}
            ></i>
          </div>
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div
              style={{
                minHeight: "65px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "65px" }}
                src="https://i.imgur.com/ilNpJSh.png"
                alt="surface"
              />
            </div>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label for="surface">
                  Ce suprafata utila are incaperea dvs?
                </Label>
                <Input
                  type="number"
                  name="surface"
                  id="surface"
                  max={300}
                  placeholder="Suprafata utila (mp)"
                  value={surface}
                  onChange={(e) => {
                    const value = validationInput(e);
                    setSurface(value);
                  }}
                />
              </FormGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <div
              style={{
                minHeight: "65px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "65px" }}
                src="https://i.imgur.com/AtWtnTI.png"
                alt="roomHeight"
              />
            </div>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label for="roomHeight">Ce inaltime are incaperea dvs.?</Label>
                <Input
                  type="number"
                  name="roomHeight"
                  id="roomHeight"
                  placeholder="Inaltime camera (m)"
                  value={roomHeight}
                  max={300}
                  onChange={(e) => {
                    const value = validationInput(e);
                    setRoomHeight(value);
                  }}
                />
              </FormGroup>
            </div>
          </div>
          {result > 0 && result < 25000 ? (
            <>
              {" "}
              <hr />
              <p
                style={{
                  padding: "10px",
                  border: "2px solid #0275d8",
                  borderRadius: "5px",
                  color: "#0275d8",
                }}
              >
                {result < 25000 ? (
                  <>
                    Capacitatea de racire a aerului conditionat necesara este de
                    :
                    <strong style={{ textDecoration: "underline" }}>
                      {surfaceResult} BTU
                    </strong>
                  </>
                ) : null}
              </p>
            </>
          ) : null}
        </ModalBody>
        {result > 0 && result < 25000 ? (
          <ModalFooter>
            <Link to={`/recomandare/1?btu=${surfaceResult}`} target="_blank">
              <Button color="primary" >
                Vezi oferta
              </Button>
            </Link>
          </ModalFooter>
        ) : result > 0 && result >= 25000 ? (
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                
                askForOffer();
              }}
            >
              Solicitați o ofertă
            </Button>
          </ModalFooter>
        ) : null}
      </Modal>
              
      {showAskForModal ? (
        <AskForOfferModal showModal={showAskForModal} onClose={()=> {
          closeAskForModal();
        }} />
      ) : null}
    </div>
  );
};

export default RecommendedAC;
