import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import slug from "slug";
import { Link } from "react-router-dom";

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Parsers
import ReactHtmlParser from "react-html-parser";

// Page Components
import ProductCardCategory from "./sections/ProductCardCategory";
import ProductCardCarousel from "./sections/ProductCardCarousel";
import BlogEntry from "./sections/BlogEntrys";
import BrandCarousel from "./sections/BrandCarousel";
import ProductCardBestSeller from "./sections/ProductCardBestSeller";
import RecommendedAC from "../RecommendedCalculators/RecommendedAC.js";
import RecomandareCentralaExtern from "../RecommendedCalculators/RecommendedCTExtern";
import RecomandareCentralaIntern from "../RecommendedCalculators/RecommendedCTInstant";

//Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import sample from "lodash.samplesize";
// Apis
import { getProducts, getCategories } from "../../redux/actions/products";
import {
  getHerosData,
  getFeauturedBanner,
  getCommercialBanner,
  getPartnerBanner,
  getFirstBanner,
  getSecondBanner,
} from "../../redux/actions/banner";
import { getBlogPosts } from "../../redux/actions/blog";
import { getBestSellerProducts } from "../../redux/actions/products";

const Home = ({
  getProducts,
  products,
  heroBanner,
  getHerosData,
  feauturedBanner,
  getFeauturedBanner,
  commercialBanner,
  getCommercialBanner,
  partnerBanner,
  getPartnerBanner,
  getCategories,
  categories,
  blogPosts,
  getBlogPosts,
  getBestSellerProducts,
  bestSellerProducts,
  ultimateProduct,
  getFirstBanner,
  getSecondBanner,
  firstBanner,
  secondBanner,
  websiteInfo,
}) => {
  // Active Category For Grid Component

  const [activeCategoryId, setActiveCategoryId] = useState(
    Object.keys(categories)?.map?.(
      (category) => categories?.[category]?.category_id
    )?.[0]
  );


  const [activeBestSellerCategoryId, setActiveBestSellerCategoryId] = useState(
    Object.keys(categories)?.map?.(
      (category) => categories?.[category]?.category_id
    )?.[0]
  );

  // UseEffect for API
  useEffect(() => {
    getProducts({ show_carousel: true });
    getProducts({}, false, false, true);
    getBestSellerProducts({ best_seller: true });
    getHerosData();
    getFeauturedBanner();
    getCommercialBanner();
    getPartnerBanner();
    getCategories();
    getBlogPosts();
    getFirstBanner();
    getSecondBanner();
  }, []);

  // SET ACTIVE BUTTON BASED ON CATEGORY
  useEffect(() => {
    if (categories) {
      const first_category = Object.keys(categories)?.map?.(
        (category) => categories?.[category]?.category_id
      )?.[0];
      handleCategoriesProduct(first_category);
      handleCategoriesProductBest(first_category);
    }
  }, [categories]);

  // CHANGE API TO RETURN PRODUCTS BASED ON CATEGORY BUTTON SELECTED
  const [dealsOutlet, setDealsOutlet] = useState();
  const [topSelling, setTopSelling] = useState();
  const [hotReleases, setHotReleases] = useState();
  const [servicii, setServicii] = useState([]);

  useEffect(() => {
    const deals_outlet = ultimateProduct && sample(ultimateProduct, 3);
    const top_selling = ultimateProduct && sample(ultimateProduct, 3);
    const hot_releases = ultimateProduct && sample(ultimateProduct, 3);
    setDealsOutlet(deals_outlet);
    setTopSelling(top_selling);
    setHotReleases(hot_releases);
    const serviciiFilter = ultimateProduct?.filter(
      (product) => !product?.categories?.subsubcategory?.id
    );
    setServicii(serviciiFilter);
  }, [ultimateProduct]);

  const handleCategoriesProduct = (category_id) => {
    setActiveCategoryId(category_id);
    getProducts(
      {
        category_id,
        show_carousel: true,
      },
      false,
      false
    );
  };

  const handleCategoriesProductBest = (category_id) => {
    // setActiveCategoryId(category_id);
    setActiveBestSellerCategoryId(category_id);
    getBestSellerProducts({
      category_id,
      best_seller: true,
    });
  };

  return (
    <>
      <Helmet>
        <title>LPV</title>
        <meta
          name="description"
          content="Aparate aer conditionat, montaj aer conditionat"
        />
        <meta
          name="keywords"
          content="aer conditionat,montaj aer conditionat,aer conditionat gree, aer fujistsu"
        />
      </Helmet>
      <main className="main">
        <section className="home-slider position-relative pt-25 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="position-relative">
                  <Carousel
                    infinite={true}
                    arrows={false}
                    draggable={true}
                    autoPlay
                    autoPlaySpeed={4000}
                    showDots
                    customTransition="all 1s linear"
                    minimumTouchDrag={80}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 1,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 1,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 1,
                      },
                    }}
                  >
                    {heroBanner.map((item) => (
                      <Link to={{pathname: item?.button_link}} target="_blank">
                        <img
                          className="img-non-dragable"
                          style={{
                            height: "450px",
                            borderRadius: "10px",
                            width: "100%",
                            objectFit: "contain",
                            borderRight: "10px",
                          }}
                          src={item.image}
                          alt="Test"
                        />
                      </Link>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="featured section-padding">
          <div className="container">
            <div className="row">
              {feauturedBanner.map((feauture, index) => (
                <div
                  style={{ height: "150px" }}
                  className="col-lg-2 col-md-4 mb-md-3 mb-lg-0 mt-3"
                >
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={
                      index === 0
                        ? "border-color-1 banner-features"
                        : index === 1
                        ? "border-color-2 banner-features"
                        : index === 2
                        ? "border-color-3 banner-features"
                        : index === 3
                        ? "border-color-4 banner-features"
                        : index === 4
                        ? "border-color-5 banner-features"
                        : index === 5
                        ? "border-color-6 banner-features"
                        : null
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ fontSize: "2.6rem", marginBottom: "10px" }}
                        className={
                          index === 0
                            ? "color-1"
                            : index === 1
                            ? "color-2"
                            : index === 2
                            ? "color-3"
                            : index === 3
                            ? "color-4"
                            : index === 4
                            ? "color-5"
                            : index === 5
                            ? "color-6"
                            : null
                        }
                      >
                        {ReactHtmlParser(feauture.icon)}
                      </div>
                      <h4
                        style={{
                          color: "#fff",
                          width: "100%",
                          fontSize: "1rem",
                          padding: "0",
                          margin: "0",
                        }}
                        className={
                          index === 0
                            ? "color-1"
                            : index === 1
                            ? "color-2"
                            : index === 2
                            ? "color-3"
                            : index === 3
                            ? "color-4"
                            : index === 4
                            ? "color-5"
                            : index === 5
                            ? "color-6"
                            : null
                        }
                      >
                        {feauture.header}
                        <br />
                        <span
                          style={{
                            fontWeight: "400",
                            marginTop: "20px",
                            fontSize: ".9rem",
                            paddingTop: "10px",
                          }}
                          className={
                            index === 0
                              ? "color-1"
                              : index === 1
                              ? "color-2"
                              : index === 2
                              ? "color-3"
                              : index === 3
                              ? "color-4"
                              : index === 4
                              ? "color-5"
                              : index === 5
                              ? "color-6"
                              : null
                          }
                        >
                          {feauture.content}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <RecommendedAC websiteInfo={websiteInfo} />
              </div>
              <div className="col-lg-4">
                <RecomandareCentralaExtern />
              </div>
              <div className="col-lg-4">
                <RecomandareCentralaIntern />
              </div>
            </div>
          </div>
        </section>
        <section
          className="product-tabs section-padding wow fadeIn animated"
          style={{ padding: "25px 0 0 0" }}
        >
          <div className="container">
            <div className="tab-header">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {Object.keys(categories)?.map((category, index) => (
                  <li
                    className={
                      category === "Servicii tehnice"
                        ? "display-none"
                        : "nav-item"
                    }
                    role="presentation"
                    key={`${category}-${index}`}
                  >
                    <button
                      onClick={() =>
                        handleCategoriesProduct(
                          categories?.[category]?.["category_id"]
                        )
                      }
                      className={`nav-link ${
                        activeCategoryId ==
                          categories?.[category]?.["category_id"] && " active"
                      }`}
                      type="button"
                    >
                      {category}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="tab-content wow fadeIn animated" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="tab-one"
                role="tabpanel"
                aria-labelledby="tab-one"
              >
                <div className="row product-grid-4">
                  <ProductCardCategory products={products} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="banner-2 section-padding pb-0">
          <div className="container">
            {commercialBanner.map((banner) => (
              <div
                className="banner-img banner-big wow fadeIn animated f-none"
                key={banner?.id}
              >
                <Link to={{pathname: banner?.button_link}} target="_blank">
                  <img
                    style={{ width: "100%", borderRadius: "10px" }}
                    src={banner?.image}
                    alt="no_image"
                  />
                </Link>
              </div>
            ))}
          </div>
        </section>
        <section className="pt-25 pb-20 mt-25 mb-45">
          <div className="container wow fadeIn animated">
            <div>
              <h3 className="section-title mb-20">
                {/* <span>Ultimele</span>  */}
                Servicii
              </h3>
            </div>
            <div>
              <ProductCardCarousel products={servicii?.slice(0, 10)} />
            </div>
          </div>
        </section>
        <section className="mb-45 mt-25 mobile-hide">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-sm-5 mb-md-0">
                <div
                  style={{ height: "100%" }}
                  className="banner-img wow fadeIn animated mb-md-4 mb-lg-0"
                >
                  <img
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    src={firstBanner?.[0]?.image}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-sm-5 mb-md-0">
                <h4 className="section-title style-1 mb-30 wow fadeIn animated">
                  Cautate
                </h4>
                <div className="product-list-small wow fadeIn animated">
                  {dealsOutlet?.map((deal) => (
                    <article className="row align-items-center">
                      <figure className="col-md-4 mb-0">
                        <a
                          style={{}}
                          href={`/product/${deal?.id}/${slug(
                            deal?.title || "product",
                            "_"
                          )}`}
                        >
                          <img
                            src={deal?.images?.[0]?.image}
                            alt={deal?.title}
                            style={{
                              width: "71px",
                              height: "71px",
                              objectFit: "contain",
                              padding: "5px",
                              border: "1px solid #cbcbcb",
                              borderRadius: "5px",
                            }}
                          />
                        </a>
                      </figure>
                      <div className="col-md-8 mb-0">
                        <h4
                          className="title-small"
                          style={{
                            height: "44px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <a
                            className="text-limit-3"
                            href={`/product/${deal?.id}/${slug(
                              deal?.title || "product",
                              "_"
                            )}`}
                          >
                            {deal?.title}
                          </a>
                        </h4>

                        {deal?.send_offer === true ? (
                          <a
                            href={`/product/${deal?.id}/${slug(
                              deal?.title || "product",
                              "_"
                            )}`}
                            style={{ marginBottom: "4px" }}
                            className="product-price"
                          >
                            <span style={{ fontSize: "1rem" }}>
                              Cere Oferta
                            </span>
                          </a>
                        ) : (
                          <div className="product-price">
                            <span>{deal?.price} RON</span>
                            {deal?.discount_amount > 1 ||
                            deal?.discount_percentage_amount > 1 ? (
                              <span className="old-price">
                                {deal?.price_including_vat} RON
                              </span>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </article>
                  ))}
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-sm-5 mb-md-0">
                <h4 className="section-title style-1 mb-30 wow fadeIn animated">
                  Populare
                </h4>
                <div className="product-list-small wow fadeIn animated">
                  {topSelling?.map((deal) => (
                    <article className="row align-items-center">
                      <figure className="col-md-4 mb-0">
                        <a
                          style={{}}
                          href={`/product/${deal?.id}/${slug(
                            deal?.title || "product",
                            "_"
                          )}`}
                        >
                          <img
                            src={deal?.images?.[0]?.image}
                            alt={deal?.title}
                            style={{
                              width: "71px",
                              height: "71px",
                              objectFit: "contain",
                              padding: "5px",
                              border: "1px solid #cbcbcb",
                              borderRadius: "5px",
                            }}
                          />
                        </a>
                      </figure>
                      <div className="col-md-8 mb-0">
                        <h4
                          className="title-small"
                          style={{
                            height: "44px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <a
                            className="text-limit-3"
                            href={`/product/${deal?.id}/${slug(
                              deal?.title || "product",
                              "_"
                            )}`}
                          >
                            {deal?.title}
                          </a>
                        </h4>
                        {deal?.send_offer === true ? (
                          <a
                            href={`/product/${deal?.id}/${slug(
                              deal?.title || "product",
                              "_"
                            )}`}
                            style={{ marginBottom: "4px" }}
                            className="product-price"
                          >
                            <span style={{ fontSize: "1rem" }}>
                              Cere Oferta
                            </span>
                          </a>
                        ) : (
                          <div className="product-price">
                            <span>{deal?.price} RON</span>
                            {deal?.discount_amount > 1 ||
                            deal?.discount_percentage_amount > 1 ? (
                              <span className="old-price">
                                {deal?.price_including_vat} RON
                              </span>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </article>
                  ))}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="section-title style-1 mb-30 wow fadeIn animated">
                  Noutati
                </h4>
                <div className="product-list-small wow fadeIn animated">
                  {hotReleases?.map((deal) => (
                    <article className="row align-items-center">
                      <figure className="col-md-4 mb-0">
                        <a
                          style={{}}
                          href={`/product/${deal?.id}/${slug(
                            deal?.title || "product",
                            "_"
                          )}`}
                        >
                          <img
                            src={deal?.images?.[0]?.image}
                            alt={deal?.title}
                            style={{
                              width: "71px",
                              height: "71px",
                              objectFit: "contain",
                              padding: "5px",
                              border: "1px solid #cbcbcb",
                              borderRadius: "5px",
                            }}
                          />
                        </a>
                      </figure>
                      <div className="col-md-8 mb-0">
                        <h4
                          className="title-small"
                          style={{
                            height: "44px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <a
                            className="text-limit-3"
                            href={`/product/${deal?.id}/${slug(
                              deal?.title || "product",
                              "_"
                            )}`}
                          >
                            {deal?.title}
                          </a>
                        </h4>
                        {deal?.send_offer === true ? (
                          <a
                            href={`/product/${deal?.id}/${slug(
                              deal?.title || "product",
                              "_"
                            )}`}
                            style={{ marginBottom: "4px" }}
                            className="product-price"
                          >
                            <span style={{ fontSize: "1rem" }}>
                              Cere Oferta
                            </span>
                          </a>
                        ) : (
                          <div className="product-price">
                            <span>{deal?.price} RON</span>
                            {deal?.discount_amount > 1 ||
                            deal?.discount_percentage_amount > 1 ? (
                              <span className="old-price">
                                {deal?.price_including_vat} RON
                              </span>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </article>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding mt-25">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="section-title mb-20">
                  <span>Aricole</span> din Blog
                </h3>
                <div className="mb-4 mb-lg-0">
                  <BlogEntry blogPosts={blogPosts} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-grey-9 section-padding">
          <div className="container pt-15 pb-25">
            <div className="heading-tab d-flex">
              <div className="heading-tab-left wow fadeIn animated">
                <h3 className="section-title mb-20">
                  <span>Best</span> Seller
                </h3>
              </div>
              <div className="heading-tab-right wow fadeIn animated">
                <ul
                  className="nav nav-tabs right no-border"
                  id="myTab-1"
                  role="tablist"
                >
                  {Object.keys(categories)?.map((category, index) => (
                    <li
                      className="nav-item"
                      role="presentation"
                      key={`${category}-${index}`}
                    >
                      <button
                        onClick={() =>
                          handleCategoriesProductBest(
                            categories?.[category]?.["category_id"]
                          )
                        }
                        className={`nav-link ${
                          activeBestSellerCategoryId ==
                            categories?.[category]?.["category_id"] && " active"
                        }`}
                        type="button"
                      >
                        {category}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 d-none d-lg-flex">
                <div
                  className="banner-img style-2 wow fadeIn animated"
                  style={{ height: "100%", maxHeight: "345px" }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                    src={secondBanner?.[0]?.image}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-9 col-md-12">
                <ProductCardBestSeller products={bestSellerProducts} />
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding mt-25">
          <div className="container pb-20">
            <h3 className="section-title mb-20 wow fadeIn animated">
              <span>Branduri</span> Partenere
            </h3>
            <div>
              <BrandCarousel partners={partnerBanner} />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

Home.propTypes = {
  products: PropTypes.array.isRequired,
  heroBanner: PropTypes.array.isRequired,
  feauturedBanner: PropTypes.array.isRequired,
  partnerBanner: PropTypes.array.isRequired,
  bannerLoading: PropTypes.bool,
  categories: PropTypes.object.isRequired,
  blogPosts: PropTypes.array.isRequired,
};

const mapStateToProps = ({ product, banner, blog, aboutUs }) => ({
  products: product.products,
  ultimateProduct: product.ultimateProduct,
  heroBanner: banner.herosBanner,
  commercialBanner: banner.commercialBanner,
  feauturedBanner: banner.feauturedBanner,
  partnerBanner: banner.partnerBanner,
  bannerLoading: banner.loading,
  categories: product.categories,
  bestSellerProducts: product.bestSellerProducts,
  blogPosts: blog.blogPosts?.data || [],
  firstBanner: banner.firstBanner,
  secondBanner: banner.secondBanner,
  websiteInfo: aboutUs.websiteInfoDetails,
});

export default connect(mapStateToProps, {
  getProducts,
  getHerosData,
  getFeauturedBanner,
  getCommercialBanner,
  getPartnerBanner,
  getCategories,
  getBlogPosts,
  getBestSellerProducts,
  getFirstBanner,
  getSecondBanner,
})(Home);
