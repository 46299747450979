import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TeamModalDescription from "./TeamModalDescription";
import ReactHtmlParser from "react-html-parser";

const CardEmployee = ({ aboutTeamData, directorMessage }) => {
  return (
    <>
      <section id="work" className="mt-40 pt-50 pb-50 section-border">
        <div className="container">
          <div className="row mb-50">
            <div className="col-lg-12 col-md-12">
  
              <h2 className="mb-15 text-grey-1 wow fadeIn animated text-center mt-30">
                {ReactHtmlParser(directorMessage?.title)}
              </h2>
              <p className="text-grey-3 wow fadeIn animated mt-60">
                {ReactHtmlParser(directorMessage?.message)}
              </p>
            </div>
          </div>

          <Carousel
            className=""
            additionalTransfrom={0}
            autoPlay
            arrows={true}
            autoPlaySpeed={5000}
            centerMode={false}
            containerclassName="container-with-dots"
            dotListclassName=""
            draggable
            focusOnSelect={false}
            infinite
            itemclassName=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 3,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            showDots={false}
            sliderclassName=""
            slidesToSlide={1}
            swipeable
          >
            {aboutTeamData.map((teamMember) => (
              <>
                <div
                  style={{ padding: "0 15px" }}
                  className="text-center"
                  key={teamMember.id}
                >
                  <img
                    className="btn-shadow-brand hover-up border-radius-10 bg-brand-muted wow fadeIn animated"
                    src={teamMember.image}
                    alt={teamMember.name}
                    style={{ objectFit: "cover", height: "300px" }}
                  />
                  <h4 className="mt-30 mb-15 wow fadeIn animated">
                    {teamMember.name}
                  </h4>
                  <p className="text-grey-3 wow fadeIn animated">
                    {teamMember.role}
                  </p>
                </div>
                <TeamModalDescription teamMember={teamMember} />
              </>
            ))}
          </Carousel>
        </div>
      </section>
    </>
  );
};

export default CardEmployee;
