import React, { useEffect, useState, useRef } from "react";
import UserMenuSideNav from "./UserMenuSideNav";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import { updateUserProfile, passwordUpdate } from "../../../redux/actions/auth";
import { Link } from "react-router-dom";

const EditProfile = ({ user, updateUserProfile, passwordUpdate }) => {
  const [userData, setUserData] = useState({});
  const [password, setPassword] = useState({});
  const [fullName, setFullName] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  useEffect(() => {
    if (user) {
      setFullName(user.first_name + " " + user.last_name);
    }
  }, [user]);
  const inputFile = useRef(null);

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);
  const handleOnChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnChangePassword = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = (avatar) => {
    const formData = new FormData();
    formData.append("first_name", userData.first_name);
    formData.append("last_name", userData.last_name);
    formData.append("phone_number", userData.phone_number);
    formData.append("email", userData.email);
    if (avatar) {
      formData.append("avatar", avatar);
    }

    updateUserProfile(formData);
  };

  const handlePassword = (avatar) => {
    // validation
    setWarningMessage("");
    if (
      !password.old_password ||
      !password.new_password ||
      !password.confirm_password
    ) {
      setWarningMessage("All fields are required");
      return;
    } else if (password.new_password !== password.confirm_password) {
      setWarningMessage("New Password and Confirm Password are not same");
      return;
    }

    passwordUpdate({
      old_password: password.old_password,
      new_password: password.new_password,
      confirm_password: password.confirm_password,
    });
    setTimeout(() => {
      setPassword({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
    }, 1000);
  };
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                LPV
              </Link>
              <span>Contul Meu</span>
              <span>Editeaza Cont</span>
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row mt-40 mb-40">
              <div className="col-lg-3">
                <UserMenuSideNav />
              </div>
              <div className="col-lg-9">
                <div className="row mt-4 mt-lg-0">
                  <div className="col-lg-12 text-center">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                      }}
                    >
                      <h5 style={{ margin: "0" }}>Editeaza Profilul</h5>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-12 text-center">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "25px",
                      }}
                    >
                      {user?.avatar === null ? (
                        <Avatar
                          name={fullName}
                          size="90"
                          round={true}
                          className="letters-avatar mb-2"
                          colors={["#0275d8", "#d82f3d", "#01baef"]}
                          maxInitials="2"
                        />
                      ) : (
                        <img
                          className="mb-4"
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          src={user?.avatar}
                          alt="user-avatar"
                        />
                      )}

                      <input
                        id="myInput"
                        type="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        onChange={(e) => handleSave(e.target.files[0])}
                      />

                      <br />
                      <button
                        className="btn btn-secondary"
                        onClick={onButtonClick}
                      >
                        Schimba Poza
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-6">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                      }}
                    >
                      <h5
                        className=" text-center"
                        style={{ marginBottom: "20px" }}
                      >
                        Informatii Generale
                      </h5>
                      <hr />
                      <div className="form-group">
                        <label htmlFor="profile-first-name">Nume</label>{" "}
                        <input
                          type="text"
                          className="form-control"
                          id="profile-first-name"
                          placeholder="Numele dvs."
                          name="first_name"
                          value={userData?.first_name}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="profile-last-name">Prenume</label>{" "}
                        <input
                          type="text"
                          className="form-control"
                          id="profile-last-name"
                          placeholder="Prenumele dvs."
                          name="last_name"
                          value={userData?.last_name}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="profile-phone">Numar telefon</label>{" "}
                        <input
                          type="number"
                          className="form-control"
                          id="profile-phone"
                          placeholder="Numarul dvs."
                          name="phone_number"
                          value={userData?.phone_number}
                          onChange={handleOnChange}
                        />
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSave()}
                      >
                        Salveaza
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4 mt-lg-0">
                    <div
                      style={{
                        border: "1px solid #e2e9e1",
                        borderRadius: "5px",
                        padding: "15px",
                      }}
                    >
                      <h5
                        className=" text-center"
                        style={{ marginBottom: "20px" }}
                      >
                        Schimba Parola
                      </h5>
                      <hr />
                      <div className="form-group">
                        <label htmlFor="profile-phone">Parola Veche</label>{" "}
                        <input
                          type="password"
                          className="form-control"
                          id="profile-phone"
                          placeholder="Old Password"
                          name="old_password"
                          value={password?.old_password}
                          onChange={handleOnChangePassword}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="profile-phone">Parola Noua</label>{" "}
                        <input
                          type="password"
                          className="form-control"
                          id="profile-phone"
                          placeholder="New Password"
                          name="new_password"
                          value={password?.new_password}
                          onChange={handleOnChangePassword}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="profile-phone">
                          Repeta Noua Parola
                        </label>{" "}
                        <input
                          type="password"
                          className="form-control"
                          id="profile-phone"
                          placeholder="Repeat New Password"
                          name="confirm_password"
                          value={password?.confirm_password}
                          onChange={handleOnChangePassword}
                        />
                      </div>
                      {warningMessage?.length > 0 && (
                        <div className="text-center">
                          <p className="text-danger">{warningMessage}</p>
                        </div>
                      )}
                      <button
                        className="btn btn-primary"
                        onClick={() => handlePassword()}
                      >
                        Salveaza
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});
export default connect(mapStateToProps, {
  updateUserProfile,
  passwordUpdate,
})(EditProfile);
