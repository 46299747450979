import React from "react";
import { NavLink } from "react-router-dom";
const UserMenuSideNav = ({}) => {
  return (
    <>
      <div className="dashboard-menu">
        <ul className="nav flex-column" role="tablist">
          <li className="nav-item">
            <li className="nav-link disabled">Meniu cont</li>
          </li>

          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              id="orders-tab"
              data-bs-toggle="tab"
              to="/profil"
              role="tab"
              aria-controls="orders"
              aria-selected="false"
            >
              <i className="far fa-user-circle mr-10"></i>
              Sumar Cont
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              id="track-orders-tab"
              data-bs-toggle="tab"
              to="/user-edit-profile"
              role="tab"
              aria-controls="track-orders"
              aria-selected="false"
            >
              <i className="fas fa-pencil mr-10"></i>
              Editeaza Profil
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              className="nav-link"
              id="address-tab"
              data-bs-toggle="tab"
              to="/user-order-history"
              role="tab"
              aria-controls="address"
              aria-selected="true"
            >
              <i className="fas fa-box mr-10"></i>
              Istoric Comenzi
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              activeClassName="active"
              id="account-detail-tab"
              data-bs-toggle="tab"
              to="/user-address"
              role="tab"
              aria-controls="account-detail"
              aria-selected="true"
            >
              <i className="fas fa-map-marked-alt mr-10"></i>
              Adrese
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              activeClassName="non-active"
              to="/"
              onClick={(e) => {
                localStorage.removeItem("access");
                window.location.href = "/";
              }}
            >
              <i className="fas fa-sign-out-alt mr-10"></i>
              Deconectare
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserMenuSideNav;
