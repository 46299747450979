import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const BlogLastEntry = ({ blogPosts }) => {

  return (
    <>
      {blogPosts?.map(
        (post, index) =>
          index >= 0 &&
          index < 7 && (
            <div className="col-md-6 col-sm-6 sm-grid-content mb-30">
              <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                <Link to={`/blog/${post.id}`}>
                  <img src={post.image} alt={post.title} />
                </Link>
              </div>
              <div className="post-content media-body">
                <h6 className="post-title mb-10 text-limit-2-row">
                  <a className="text-limit-2" href={`/blog/${post.id}`}>{post.title}</a>
                </h6>
                <div className="entry-meta meta-13 font-xxs color-grey">
                  <span className="post-on mr-10">
                    <Moment format="DD.MM.YYYY">{post.created_date}</Moment>
                  </span>
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
};

export default BlogLastEntry;
